export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function retry<T>(
	callback: () => Promise<T>,
	times: number,
	delay?: number,
	err?: any,
): Promise<T> {
	if (!times) {
		return Promise.reject(err);
	}

	return callback().catch((err) => {
		return sleep(delay ?? 0).then(() => {
			return retry(callback, times - 1, delay, err);
		});
	});
}

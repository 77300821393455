import {
	Button,
	ButtonGroup,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
} from '@chakra-ui/react';
import { t } from '@transifex/native';
import { T } from '@transifex/react';
import { FC, ReactNode } from 'react';
import { app } from '../../../@xmcloud/core/messages/en';
import { Size } from '../../../@xmcloud/types';

export interface Props {
	onClose: () => void;
	isLoading?: boolean;
	isOpen: boolean;
	children: ReactNode;
	title?: string;
	width?: string;
	size?: Size;
	drawerHeader?: ReactNode;
	drawerBodyOnClick?: () => void;
	showBottomCloseButton?: boolean;
	dataTestId?: string;
}

export const AppDrawer: FC<Props> = ({
	onClose,
	isOpen,
	isLoading = false,
	children,
	title = '',
	size = 'md',
	drawerHeader = null,
	drawerBodyOnClick,
	showBottomCloseButton = true,
	dataTestId,
}) => {
	return (
		<Drawer
			onClose={onClose}
			isOpen={isOpen}
			size={size}
			blockScrollOnMount={false}
		>
			<DrawerOverlay />
			<DrawerContent data-testid={dataTestId}>
				<DrawerCloseButton _focus={{ border: 'none' }} />
				{Boolean(drawerHeader) ? (
					<DrawerHeader boxShadow="sm">{drawerHeader}</DrawerHeader>
				) : (
					<DrawerHeader>{t(title)}</DrawerHeader>
				)}

				<DrawerBody onClick={drawerBodyOnClick}>{children}</DrawerBody>
				{showBottomCloseButton && (
					<DrawerFooter>
						<ButtonGroup>
							<Button onClick={onClose} mr="2" variant="outline">
								<T _str={app.close} />
							</Button>
						</ButtonGroup>
					</DrawerFooter>
				)}
			</DrawerContent>
		</Drawer>
	);
};

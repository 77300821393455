import { useEnvVars } from './EnvVarsContext';
import { Grid, IconButton, Tooltip } from '@chakra-ui/react';
import { mdiLockOutline, mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js';
import { createColumnHelper } from '@tanstack/react-table';
import { Icon } from '../../shared-components';
import { Table } from '../../shared-components/table/Table';
import { EnvVariable, type EnvVariableInput } from './envVarsModel';
import { useCallback, useMemo } from 'react';
import texts from './envVarsTexts';
import { t as tf } from '@transifex/native';

type EnvVariableColumn = EnvVariable & {
	actions: string;
};

const { EnvVarsTable: t } = texts;
const columnHelper = createColumnHelper<EnvVariableColumn>();

type EnvVarsTableProps = {
	onMutationDelete?: (variable: EnvVariableInput) => void;
};

function EnvVarsTable({ onMutationDelete }: EnvVarsTableProps): JSX.Element {
	const {
		variables,
		deleteVariable,
		setSelectedVariable,
		toggleFormVisibility,
		setLoading,
	} = useEnvVars();

	const handleEdit = useCallback(
		(variable: EnvVariable) => {
			setSelectedVariable(variable);
			toggleFormVisibility();
		},
		[setSelectedVariable, toggleFormVisibility],
	);

	const handleDelete = useCallback(
		async (variable: EnvVariable) => {
			try {
				if (onMutationDelete) {
					setLoading(true);
					return onMutationDelete(variable);
				}
				deleteVariable(variable.id);
			} catch (error) {
				console.error('Error deleting variable:', error);
			} finally {
				setLoading(false);
			}
		},
		[deleteVariable, onMutationDelete, setLoading],
	);

	const columns = useMemo(
		() => [
			columnHelper.accessor('name', {
				id: 'name',
				header: () => tf(t.columns.name),
				cell: (info) => info.getValue(),
			}),
			columnHelper.accessor('value', {
				id: 'value',
				header: () => tf(t.columns.value),
				cell: (info) => info.getValue(),
			}),
			columnHelper.accessor('secret', {
				id: 'secret',
				header: () => tf(t.columns.secret),
				cell: (info) => {
					return info.getValue() ? (
						<Icon
							layerStyle="icon.subtle"
							boxSize="icon.2xs"
							color="neutral"
							aria-label={tf(t.ariaLabels.lockIcon)}
							path={mdiLockOutline}
						/>
					) : null;
				},
			}),
			columnHelper.accessor('actions', {
				id: 'actions',
				header: 'Actions',
				cell: (info) => {
					const variable = info.row.original;

					return (
						<>
							<Tooltip label={tf(t.tooltips.editVariable)}>
								<IconButton
									variant={'ghost'}
									size="sm"
									icon={<Icon path={mdiPencilOutline} />}
									aria-label={tf(t.ariaLabels.editVariable)}
									onClick={() => handleEdit(variable)}
									data-testid="edit-variable-button"
								/>
							</Tooltip>
							<Tooltip label={tf(t.tooltips.removeVariable)}>
								<IconButton
									variant={'ghost'}
									size="sm"
									icon={<Icon path={mdiTrashCanOutline} />}
									aria-label={tf(t.ariaLabels.removeVariable)}
									onClick={() => handleDelete(variable)}
									data-testid="remove-variable-button"
								/>
							</Tooltip>
						</>
					);
				},
			}),
		],
		[handleDelete, handleEdit],
	);

	return (
		<Grid width="100%">
			<Table
				columns={columns}
				tableData={variables}
				showPagination={false}
				initialSort={[]}
				tableProps={{
					mb: 0,
				}}
			/>
		</Grid>
	);
}

export { EnvVarsTable };

import React from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { getAuthSettings } from '../config/envConfig';
import { handlePush } from '../../@xmcloud/utils/helpers';
import { fetchLastSuccessfulLoginOrganizationId } from './Auth0Storage';

export const Auth0ProviderWithHistory: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const initOrganizationId = fetchLastSuccessfulLoginOrganizationId();
	const { domain, clientId, audience } = getAuthSettings();

	const query = new URLSearchParams(window.location.search);
	const error = query.get('error') || '';
	if (error && error === 'unauthorized') {
		handlePush('/401');
	}

	const onRedirectCallback = (appState?: AppState) => {
		handlePush(appState?.returnTo || window.location.pathname);
	};

	if (!domain || !clientId || !audience) {
		throw new Error('Missing auth provider properties');
	}

	return (
		<Auth0Provider
			{...{
				audience,
				domain,
				clientId,
				redirectUri: window.location.origin,
				onRedirectCallback,
				useRefreshTokens: true,
				scope: 'offline_access',
				...(initOrganizationId && {
					organization_id: initOrganizationId,
				}),
			}}
		>
			{children}
		</Auth0Provider>
	);
};

import { AlertDialogConfirmationModal } from '../../../shared-components';
import { Text, useToast } from '@chakra-ui/react';
import { connections } from '../../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';
import { useDeleteSourceControlIntegrationMutation } from '../../../services/repositories';
import { QueryClient } from 'react-query';
import config from '../../../config/config';

interface Props {
	connectionId: string;
	setOpenModal: (show: boolean) => void;
	openModal: boolean;
	queryClient: QueryClient;
	currentPage: number;
}

const DeleteSourceControl: React.FC<Props> = ({
	openModal,
	setOpenModal,
	connectionId,
	queryClient,
	currentPage,
}) => {
	const toast = useToast();
	const { mutate, isLoading } = useDeleteSourceControlIntegrationMutation();

	function handleSubmit() {
		if (!connectionId) return;
		mutate(connectionId, {
			onSuccess: () => {
				queryClient.invalidateQueries(
					config.repository.get_repo_integrations_v2.queryKey(
						currentPage,
					),
				);
				toast({
					status: 'success',
					description: t(connections.deleteConnectionSuccess),
				});
				setOpenModal(false);
			},
		});
	}

	return (
		<AlertDialogConfirmationModal
			isOpen={openModal}
			title={connections.delete}
			onClose={() => setOpenModal(false)}
			dataTestId="delete-hosting-confirmation-modal"
			isLoading={isLoading}
			onConfirm={handleSubmit}
		>
			<Text>{connections.deleteConfirmation}</Text>
		</AlertDialogConfirmationModal>
	);
};

export default DeleteSourceControl;

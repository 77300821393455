import { HStack, MenuItem, Stack, Text } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { connections } from '../../../@xmcloud/core/messages/en';
import { vercelInstallationBaseUrl } from '../../config/envConfig';
import { useGetStateForInstallation } from '../../services/hosting';
import {
	Footer,
	Icon,
	XMCSButton,
	XMCSMenuButton,
} from '../../shared-components';
import HostingIntegrationsTable from './components/HostingIntegrationsTable';
import { mdiTriangleOutline, mdiOpenInNew } from '@mdi/js';
import { useSharedState } from '../../../@xmcloud/hooks';
import { IS_SM_SCREEN } from '../../../@xmcloud/hooks/context/useSharedState';
import { FC, useState } from 'react';
import CreateConnection from './components/CreateConnection';

const vercelInstallationUrl = (state: string) =>
	`${vercelInstallationBaseUrl}/?state=${state}`;

export const HostingIntegrations: FC = () => {
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);
	const { refetch: fetchState, isFetching: isFetchingState } =
		useGetStateForInstallation({ enabled: false });
	const [isOpen, setIsOpenOpen] = useState(false);
	const [installationState, setInstallationState] = useState('');

	return (
		<>
			<Stack
				direction="row"
				justify="flex-end"
				mt={6}
				mb={isSmScreen ? 0 : 5}
			>
				{!isSmScreen && (
					<XMCSMenuButton
						text={connections.create}
						data-testid="hosting-connection-menubutton"
						isLoading={isFetchingState}
						onClick={() => {
							fetchState().then(({ data }) => {
								const state = data?.data ?? '';
								setInstallationState(state);
							});
						}}
					>
						<MenuItem
							onClick={() => {
								window.open(
									vercelInstallationUrl(installationState),
								);
							}}
							icon={<Icon path={mdiTriangleOutline} />}
							data-testid="menu-item-vercel"
							isDisabled={isFetchingState || !installationState}
						>
							<HStack justifyContent="space-between">
								<Text>{t(connections.vercel)}</Text>
								<Icon
									path={mdiOpenInNew}
									boxSize="5"
									color="chakra-placeholder-color"
								/>
							</HStack>
						</MenuItem>
					</XMCSMenuButton>
				)}
			</Stack>

			<HostingIntegrationsTable />
			{Boolean(isSmScreen) && (
				<Footer>
					<XMCSButton
						onClick={() => setIsOpenOpen(true)}
						text={connections.create}
						isLoading={isFetchingState}
					/>
				</Footer>
			)}
			{isOpen && (
				<CreateConnection
					isOpen={isOpen}
					setIsOpenOpen={setIsOpenOpen}
					refetch={fetchState}
					isLoading={isFetchingState}
					connections={allConnections}
					url={vercelInstallationUrl}
				/>
			)}
		</>
	);
};

const allConnections = [
	{ name: connections.vercel, iconName: mdiTriangleOutline },
];

import { FC, useState } from 'react';
import { MenuItem, Stack, Tooltip } from '@chakra-ui/react';
import { t } from '@transifex/native';
import {
	connections,
	systemHealthStatus,
} from '../../../@xmcloud/core/messages/en';
import { useRepositoryIntegrateState } from '../../services/repositories';
import {
	Footer,
	Icon,
	SuffixIconText,
	XMCSButton,
	XMCSMenuButton,
} from '../../shared-components';
import SourceControlIntegrationsTable from './components/SourceControlIntegrationsTable';
import { mdiGithub, mdiMicrosoftAzureDevops, mdiOpenInNew } from '@mdi/js';
import { useComponentHealthy, useSharedState } from '../../../@xmcloud/hooks';
import { IS_SM_SCREEN } from '../../../@xmcloud/hooks/context/useSharedState';
import CreateConnection from './components/CreateConnection';
import {
	adoIntegrationUrl,
	githubIntegrationUrl,
} from '../../../@xmcloud/utils/constants';

const { errMsg4, errMsg1 } = systemHealthStatus;
const { create: createtxt, github: githubtxt, ado: adotxt } = connections;

const getIntegrationUrl = (state: string, provider: string): string => {
	if (provider === githubtxt) {
		return githubIntegrationUrl(state);
	}

	if (provider === adotxt) {
		return adoIntegrationUrl(state);
	}

	return '';
};

export const SourceControlIntegrations: FC = () => {
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);
	const {
		refetch: fetchIntegrateState,
		isFetching: isFetchingIntegrateState,
	} = useRepositoryIntegrateState({ enabled: false });
	const [isOpen, setIsOpenOpen] = useState(false);
	const [integrationState, setIntegrationState] = useState('');

	const {
		isConfigurationHealthy,
		isADOHealthy,
		isGithubHealthy,
		isLoading: isComponentHealthLoading,
		isError: isComponentHealthError,
	} = useComponentHealthy({});

	const isCreateDisable = isComponentHealthError || !isConfigurationHealthy;

	const isAdoCreateDisable = !isADOHealthy;

	const isGithubCreateDisable = !isGithubHealthy;

	const toolTipMessage = isComponentHealthError
		? t(errMsg1)
		: !isConfigurationHealthy
			? t(errMsg4, {
					variableTxt2: 'create connection',
					variableTxt: 'configuration',
				})
			: '';

	return (
		<>
			<Stack
				direction="row"
				justify="flex-end"
				mt={6}
				mb={isSmScreen ? 0 : 5}
			>
				{!isSmScreen && (
					<Tooltip
						label={toolTipMessage}
						isDisabled={!isCreateDisable}
						shouldWrapChildren
					>
						<XMCSMenuButton
							text={createtxt}
							data-testid="sc-connection-menubutton"
							isLoading={
								isFetchingIntegrateState ||
								isComponentHealthLoading
							}
							isDisabled={isCreateDisable}
							onClick={() => {
								fetchIntegrateState().then(({ data }) => {
									const state = data?.data ?? '';
									setIntegrationState(state);
								});
							}}
						>
							<Tooltip
								label={t(errMsg4, {
									variableTxt2: 'create connection',
									variableTxt: 'Github',
								})}
								isDisabled={!isGithubCreateDisable}
							>
								<MenuItem
									onClick={() => {
										window.open(
											githubIntegrationUrl(
												integrationState,
											),
										);
									}}
									data-testid="menu-item-github"
									icon={<Icon path={mdiGithub} />}
									isDisabled={
										isFetchingIntegrateState ||
										!integrationState ||
										isGithubCreateDisable
									}
								>
									<SuffixIconText
										{...{
											text: githubtxt,
											path: mdiOpenInNew,
										}}
									/>
								</MenuItem>
							</Tooltip>
							<Tooltip
								label={t(errMsg4, {
									variableTxt2: 'create connection',
									variableTxt: 'Azure DevOps',
								})}
								isDisabled={!isAdoCreateDisable}
							>
								<MenuItem
									onClick={() => {
										window.open(
											adoIntegrationUrl(integrationState),
										);
									}}
									data-testid="menu-item-ado"
									icon={
										<Icon path={mdiMicrosoftAzureDevops} />
									}
									isDisabled={
										isFetchingIntegrateState ||
										!integrationState ||
										isAdoCreateDisable
									}
								>
									<SuffixIconText
										{...{
											text: adotxt,
											path: mdiOpenInNew,
										}}
									/>
								</MenuItem>
							</Tooltip>
						</XMCSMenuButton>
					</Tooltip>
				)}
			</Stack>
			<SourceControlIntegrationsTable />
			{Boolean(isSmScreen) && (
				<Footer>
					<Tooltip
						label={toolTipMessage}
						isDisabled={!isCreateDisable}
						shouldWrapChildren
					>
						<XMCSButton
							onClick={() => setIsOpenOpen(true)}
							text={createtxt}
							isLoading={
								isFetchingIntegrateState ||
								isComponentHealthLoading
							}
							isDisabled={isCreateDisable}
						/>
					</Tooltip>
				</Footer>
			)}
			{isOpen && (
				<CreateConnection
					isOpen={isOpen}
					setIsOpenOpen={setIsOpenOpen}
					refetch={fetchIntegrateState}
					isLoading={isFetchingIntegrateState}
					connections={allConnections}
					url={getIntegrationUrl}
				/>
			)}
		</>
	);
};

const onlyGitConnection = [{ name: githubtxt, iconName: mdiGithub }];

const allConnections = [
	...onlyGitConnection,
	{ name: adotxt, iconName: mdiMicrosoftAzureDevops },
];

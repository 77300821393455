import { IRepositoryBranchDetails } from '../app/models/repositoriesModel';

export const BranchMock: IRepositoryBranchDetails = {
	name: 'master',
};

export const BranchesMock: IRepositoryBranchDetails[] = [
	{
		name: 'master',
	},
	{
		name: 'development',
	},
	{
		name: 'testing',
	},
];

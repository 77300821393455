import { AxiosResponse } from 'axios';
import React from 'react';
import {
	ProjectCreationStepsTypes,
	ProjectCreationStepsStatusType,
	CreationStatusReturnType,
	CreationStepStatus,
} from '../../@xmcloud/types';
import { ICreateDeploymentResponse } from '../models/deploymentModel';
import { useDeploymentDeployMutation } from './deployments';
import { useCreateEnvironmentDeploymentMutation } from './environments';

export interface DeploymentCreateAndDeployParams {
	environmentId: string;
	onSuccess?: (deploymentId: string, data?: any) => void;
	onError?: () => void;
	reportStatus?: (
		step: ProjectCreationStepsTypes,
		value: CreationStepStatus,
	) => void;
	getStatus?: (step?: ProjectCreationStepsTypes) => CreationStatusReturnType;
}

export const useDeploymentCreateAndDeploy = (): [
	(params: DeploymentCreateAndDeployParams) => void,
	boolean,
] => {
	const { mutate: createDeployment } =
		useCreateEnvironmentDeploymentMutation();
	const { mutate: deploymentDeploy } = useDeploymentDeployMutation();
	const [isLoading, setIsLoading] = React.useState(false);

	return [
		(params: DeploymentCreateAndDeployParams) => {
			setIsLoading(true);
			const createDeploymentStatus =
				params.getStatus &&
				(params.getStatus(
					ProjectCreationStepsTypes.CREATE_DEPLOYMENT,
				) as CreationStepStatus);

			const isDeploymentShouldCreate =
				createDeploymentStatus?.status ===
				ProjectCreationStepsStatusType.COMPLETED
					? false
					: true;

			const handleSuccess = (deploymentId: string, data?: any) => {
				setIsLoading(false);
				params.onSuccess && params.onSuccess(deploymentId, data);
			};

			const handleError = () => {
				setIsLoading(false);
				params.onError && params.onError();
			};

			const onDeploymentCreated = (
				res: AxiosResponse<ICreateDeploymentResponse>,
			) => {
				params.reportStatus &&
					params.reportStatus(
						ProjectCreationStepsTypes.START_DEPLOYMENT,
						{ status: ProjectCreationStepsStatusType.INPROGRESS },
					);
				const deploymentId = res.data?.id || '';
				deploymentDeploy(
					{ deploymentId },
					{
						onSuccess: (_data) => {
							params.reportStatus &&
								params.reportStatus(
									ProjectCreationStepsTypes.START_DEPLOYMENT,
									{
										status: ProjectCreationStepsStatusType.COMPLETED,
										successResult: _data,
									},
								);
							handleSuccess(deploymentId, _data);
						},
						onError: (err) => {
							params.reportStatus &&
								params.reportStatus(
									ProjectCreationStepsTypes.START_DEPLOYMENT,
									{
										status: ProjectCreationStepsStatusType.FAILED,
										error: err,
									},
								);
							handleError();
						},
					},
				);
			};

			if (
				isDeploymentShouldCreate === false &&
				createDeploymentStatus?.successResult
			) {
				// skip the creation and proceed to the next step
				onDeploymentCreated(createDeploymentStatus?.successResult);
				return;
			}

			params.reportStatus &&
				params.reportStatus(
					ProjectCreationStepsTypes.CREATE_DEPLOYMENT,
					{ status: ProjectCreationStepsStatusType.INPROGRESS },
				);

			createDeployment(
				{ environmentId: params.environmentId },
				{
					onSuccess: (response) => {
						params.reportStatus &&
							params.reportStatus(
								ProjectCreationStepsTypes.CREATE_DEPLOYMENT,
								{
									status: ProjectCreationStepsStatusType.COMPLETED,
									successResult: response,
								},
							);
						onDeploymentCreated(response);
					},
					onError: (err) => {
						params.reportStatus &&
							params.reportStatus(
								ProjectCreationStepsTypes.CREATE_DEPLOYMENT,
								{
									status: ProjectCreationStepsStatusType.FAILED,
									error: err,
								},
							);
						handleError();
					},
				},
			);
		},
		isLoading,
	];
};

import { Skeleton, SkeletonText, Stack } from '@chakra-ui/react';

export const LoadingSkeleton: React.FC = () => (
	<Stack gap="6">
		{[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
			<Stack key={i}>
				<Skeleton height="12" maxW="lg" />
				<SkeletonText noOfLines={1} skeletonHeight="3" maxW="md" />
			</Stack>
		))}
	</Stack>
);

import { Stack, Tab, TabList } from '@chakra-ui/react';
import { TextInfoIcon, ToggleStack } from '../../../../shared-components';
import { t } from '@transifex/native';

export const Toggle: React.FC<{
	setOpenInfo: (a: any) => void;
	setIsPreview: (a: boolean) => void;
	dataTestId1: string;
	dataTestId2: string;
	tabText1: string;
	tabText2: string;
	title: string;
	tooltipText: string;
	isDisabled?: boolean;
}> = ({
	setOpenInfo,
	setIsPreview,
	dataTestId1,
	dataTestId2,
	tabText1,
	tabText2,
	title,
	tooltipText,
	isDisabled = false,
}) => {
	return (
		<Stack>
			<TextInfoIcon
				text={title}
				tooltipText={tooltipText}
				onClick={() => setOpenInfo({ isOpen: true, info: 2 })}
			/>
			<ToggleStack
				bgColor="chakra-body-bg"
				defaultIndex={isDisabled ? -1 : 0}
				data-testid={`toggle-stack-${title}`}
			>
				<TabList>
					<Tab
						onClick={() => setIsPreview(true)}
						data-testid={dataTestId1}
						isDisabled={isDisabled}
					>
						{t(tabText1)}
					</Tab>
					<Tab
						onClick={() => setIsPreview(false)}
						data-testid={dataTestId2}
						isDisabled={isDisabled}
					>
						{t(tabText2)}
					</Tab>
				</TabList>
			</ToggleStack>
		</Stack>
	);
};

import { useState } from 'react';
import { FormikValues } from '../hooks/CreateProjectContext';
import { ResponseStatusEnum } from '../../../../@xmcloud/utils/helpers';
import {
	useCreateRepositoryMutation,
	useGetRepositoryTemplates,
} from '../../../services/repositories';
import { useGetSourceControlAccessToken } from '../../../services/accessToken';
import { IRepositoryTemplateDetails } from '../../../models/repositoriesModel';
import { useGetGitHubBranches } from '../../../services/githubApi';
import { useOLDProjectCreation } from './useOLDProjectCreation';
import { handleResponseStaus } from './helpers';

const PROVIDER = 'github';
const { ERROR, LOADING } = ResponseStatusEnum;

export function useOLDGithubProjectCreation(values: FormikValues) {
	const [isProjectCreated, setIsProjectCreated] = useState(false);
	const [repositoryDetails, setRepositoryDetails] = useState({
		repositoryBranch: '',
		repositoryName: '',
		repositoryId: '',
		owner: '',
	});

	const {
		data: repositoryTemplatesData,
		isLoading: isLoadingRepositoryTemplates,
	} = useGetRepositoryTemplates(PROVIDER);

	const { data: accessTokenData } = useGetSourceControlAccessToken(
		values.sourceControlIntegrationId,
	);

	const repositoryTemplates =
		repositoryTemplatesData?.data || ([] as IRepositoryTemplateDetails[]);
	const foundationTemplate = repositoryTemplates[0];
	const {
		mutate: createGithubRepo,
		isLoading: isLoadingCreateGithubRepo,
		status: createGithubRepoStatus,
	} = useCreateRepositoryMutation();

	const accessToken = accessTokenData?.data.accessToken || '';

	const {
		loading: isRepositoryLoading,
		status: gitHubBranchesStatus,
		refetch,
	} = useGetGitHubBranches({
		token: accessToken,
		owner: repositoryDetails.owner,
		repo: repositoryDetails.repositoryName,
		onSuccess: onGitHubBranchesSuccess,
		_enabled: !isProjectCreated,
	});

	//if the github repo is created successfully, then trigger the next step
	function onGitHubBranchesSuccess() {
		if (isProjectCreated) return;
		//trigger the 2nd step
		onProjectCreation(
			values,
			repositoryDetails.repositoryName,
			repositoryDetails.repositoryBranch,
			repositoryDetails.repositoryId,
		);
		setIsProjectCreated(true);
	}

	const {
		onProjectCreation,
		handleEnvironmentCreation,
		projectId,
		shouldRetry: shouldRetryOnCreateProject,
		isLoading: isLoadingProjectCreation,
		shouldReTryEnvironmentCreation,
		shouldReTryDeploymentCreation,
		shouldReTryDeploymentDeploy,
		currentEnvironmentId,
		currentDeploymentId,
		onDeploymentCreation,
		onDeploymentDeploy,
		createProjectStatus,
		createEnvironmentStatus,
		createDeploymentStatus,
		deploymentDeployStatus,
	} = useOLDProjectCreation();

	const shouldRetryCreateGithubRepo = createGithubRepoStatus === ERROR;
	const shouldRetryGitHubBranches = gitHubBranchesStatus === ERROR;
	const shouldRetryCreateProject = createProjectStatus === ERROR;

	const shouldRetry =
		shouldRetryCreateGithubRepo ||
		shouldRetryOnCreateProject ||
		shouldRetryGitHubBranches ||
		shouldRetryCreateProject;

	const onGithubProjectCreation = (values: FormikValues) => {
		const {
			sourceControlIntegrationId,
			sourceControlIntegrationName,
			repository,
		} = values;

		const skipRepositoryCreation = false;
		const templateRepository = foundationTemplate?.name || '';

		//create github repo payload
		const createGithubRepoPayload = {
			integrationId: sourceControlIntegrationId,
			owner: sourceControlIntegrationName,
			repositoryName: repository,
			templateRepository,
			templateOwner: foundationTemplate?.owner || '',
		};

		function onRetry() {
			if (shouldRetryCreateGithubRepo) {
				handleCreationProcess(createGithubRepoPayload);
			} else if (shouldRetryGitHubBranches) {
				refetch();
			} else if (shouldRetryCreateProject) {
				onProjectCreation(
					values,
					repositoryDetails.repositoryName,
					repositoryDetails.repositoryBranch,
					repositoryDetails.repositoryId,
				);
			} else if (shouldReTryEnvironmentCreation) {
				handleEnvironmentCreation(
					values,
					projectId,
					repositoryDetails.repositoryBranch,
				);
			} else if (shouldReTryDeploymentCreation) {
				onDeploymentCreation(projectId, currentEnvironmentId);
			} else if (shouldReTryDeploymentDeploy) {
				onDeploymentDeploy(
					projectId,
					currentEnvironmentId,
					currentDeploymentId,
				);
			}
		}
		//1st step: create github repo
		const handleCreationProcess = (payload: any) => {
			if (skipRepositoryCreation) {
				onProjectCreation(values);
				return;
			}

			createGithubRepo(payload, {
				onSuccess: (data) => {
					const repositoryName = data.data.name;
					const repositoryBranch = data.data.defaultBranch;
					const repositoryId = data.data.id;
					const owner = data.data.owner;

					setRepositoryDetails({
						repositoryBranch,
						repositoryName,
						repositoryId,
						owner,
					});
				},
			});
		};

		if (shouldRetry) {
			//retry step if it fails
			onRetry();
		} else {
			//trigger the 1st step
			handleCreationProcess(createGithubRepoPayload);
		}
	};

	const isLoading =
		isLoadingCreateGithubRepo ||
		isLoadingProjectCreation ||
		isLoadingRepositoryTemplates ||
		isRepositoryLoading;

	const repositoryCreationStatus = handleResponseStaus(
		createGithubRepoStatus,
		isRepositoryLoading ? LOADING : gitHubBranchesStatus,
	);

	const projectCreationStatus = handleResponseStaus(createProjectStatus);

	const environmentCreationStatus = handleResponseStaus(
		createEnvironmentStatus,
	);

	const deploymentCreationStatus = handleResponseStaus(
		createDeploymentStatus,
		deploymentDeployStatus,
	);

	return {
		onGithubProjectCreation,
		isLoading,
		shouldRetry,
		repositoryCreationStatus,
		projectCreationStatus,
		environmentCreationStatus,
		deploymentCreationStatus,
	};
}

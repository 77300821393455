import { CircularProgress, Flex, FlexProps } from '@chakra-ui/react';

export const CircularLoading: React.FC<FlexProps> = ({ ...rest }) => (
	<Flex h="70%" justifyContent="center" align="center" {...rest}>
		<CircularProgress
			isIndeterminate
			capIsRound
			color="primary"
			trackColor="neutral-color.200"
		/>{' '}
	</Flex>
);

export const variants = {
	error: {
		illustration: {
			colored:
				'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert',
			grayed: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-neutral',
		},
		label: 'Something went wrong',
		error: null,
	},
	'bad-request': {
		illustration: {
			colored:
				'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-circle',
			grayed: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-circle-neutral',
		},
		label: 'Bad request',
		error: 'Error 400',
	},
	unauthorized: {
		illustration: {
			colored:
				'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-stop',
			grayed: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-stop-neutral',
		},
		label: 'Unauthorized',
		error: 'Error 401',
	},
	'access-denied': {
		illustration: {
			colored:
				'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-lock',
			grayed: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-lock-neutral',
		},
		label: 'Forbidden',
		error: 'Error 403',
	},
	'not-found': {
		illustration: {
			colored:
				'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-map-search',
			grayed: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-map-search-neutral',
		},
		label: 'Page not found',
		error: 'Error 404',
	},
	'server-error': {
		illustration: {
			colored:
				'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-circle',
			grayed: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-circle-neutral',
		},
		label: 'Internal server error',
		error: 'Error 500',
	},
	unavailable: {
		illustration: {
			colored:
				'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-wrench-clock',
			grayed: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-wrench-clock-neutral',
		},
		label: 'Service Unavailable',
		error: 'Error 503',
	},
};

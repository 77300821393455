import { Box, Center, Stack, Text } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { FC, ReactNode } from 'react';
interface Props {
	primaryTitle: string;
	secondaryTitle?: string;
	thirdTitle?: string;
	subTitle?: string;
	isLink?: boolean;
	linkIcon?: string;
	iconSize?: number;
	onClick?: () => void;
	children?: ReactNode;
	href?: string;
	tooltip?: string;
	titleFontWeight?: string;
	subTitleFontWeight?: string;
}

export const ComplexTextBox: FC<Props> = ({
	primaryTitle,
	secondaryTitle,
	thirdTitle,
	subTitle,
	isLink = false,
	linkIcon,
	iconSize = 20,
	children,
	href,
	tooltip,
	subTitleFontWeight = 'normal',
	titleFontWeight = 'semibold',
	...rest
}) => {
	return (
		<Box color="chakra-subtle-text" marginInlineEnd={1}>
			<Stack direction="row">
				<Center>{children}</Center>
				<Text
					as="span"
					fontWeight={titleFontWeight}
					color="chakra-body-text"
				>
					{t(primaryTitle)}
				</Text>

				{secondaryTitle && (
					<Text
						pl={0.5}
						as="span"
						fontWeight={subTitleFontWeight}
						color="chakra-body-text"
					>
						{t(secondaryTitle)}
					</Text>
				)}
				{thirdTitle && (
					<Text
						pl={0.5}
						as="span"
						fontWeight={subTitleFontWeight}
						color="chakra-body-text"
					>
						{t(thirdTitle)}
					</Text>
				)}
			</Stack>
			{subTitle && (
				<Stack direction="row">
					<Box pl={'4'}></Box>
					<Text fontWeight={subTitleFontWeight} variant="small">
						{subTitle}
					</Text>
				</Stack>
			)}
		</Box>
	);
};

import { init as initApm, Transaction } from '@elastic/apm-rum';
import { apmServerUrl, apmServiceName, environment } from '../config/envConfig';

export function setupApm() {
	if (!apmServerUrl) {
		return;
	}

	const apm = initApm({
		centralConfig: true,
		serviceName: apmServiceName,
		serverUrl: apmServerUrl,
		environment,
	});

	const newTransactionNames = new Map<Transaction, string>();

	apm.observe('transaction:start', (transaction) => {
		if (transaction.type === 'user-interaction') {
			const { activeElement } = document;

			if (!activeElement) {
				return;
			}

			const label =
				((activeElement as HTMLInputElement).labels?.length
					? (activeElement as HTMLInputElement).labels?.item(0)
							.innerText
					: activeElement?.getAttribute('aria-label')) ??
				activeElement?.getAttribute('title') ??
				(activeElement as HTMLElement)?.innerText?.substring(0, 50) ??
				'';

			const technicalName =
				activeElement?.getAttribute('data-testid') ?? '';
			const newUserInteractionName =
				label && technicalName
					? `${label} (${technicalName})`
					: label || technicalName;

			newTransactionNames.set(transaction, newUserInteractionName);
		}
	});

	apm.observe('transaction:end', (transaction) => {
		const newTransactionName = newTransactionNames.get(transaction);
		newTransactionNames.delete(transaction);

		// Transaction types can change over time (i.e. start as user-interaction, end as route-change)
		if (newTransactionName && transaction.type === 'user-interaction') {
			transaction.name = newTransactionName;
		}
	});
}

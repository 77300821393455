import { FC, useRef, useState, useCallback } from 'react';
import {
	Alert,
	InputFormControl,
	ModalMainContent,
	ModalWrapper,
	SelectFormControl,
	XMCSButton,
} from '../../../shared-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
	HostingProvider,
	ICreateHostingProjectPayload,
} from '../../../models/hostingModel';
import { t } from '@transifex/native';
import {
	IGetEnvironmentResponse,
	ISite,
} from '../../../models/environmentModel';
import {
	connections,
	projects,
	sites as enSites,
} from '../../../../@xmcloud/core/messages/en';
import {
	useCreateHostingProjectMutation,
	useGetHostingInstallationsList,
	useGetStateForInstallation,
	useValidateHostingInstallation,
} from '../../../services/hosting';
import { ListItem, UnorderedList, useToast } from '@chakra-ui/react';
import { vercelInstallationBaseUrl } from '../../../config/envConfig';
import { T } from '@transifex/react';
import { vercelGithub } from '../../../../@xmcloud/utils/constants';

const { requiredConnection, canNotValidateConnection } = connections;

const integrationId = 'integrationId';
interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
	site: ISite;
	environment: IGetEnvironmentResponse;
}

const LinkToVercelProject: FC<Props> = ({
	openModal,
	setOpenModal,
	site,
	environment,
}) => {
	const initialValues = useRef<ICreateHostingProjectPayload>({
		provider: HostingProvider.Vercel,
		siteId: site.id,
		siteName: site.name,
		environmentId: environment.id,
		name: (
			environment.projectName +
			'-' +
			environment.name +
			'-' +
			site.name
		)
			.toLowerCase()
			.replaceAll(' ', '-'),
		integrationId: '',
		posMappings: site.posMappings,
	}).current;

	const toast = useToast();
	const [isValidationLoading, setIsValidationLoading] = useState(false);

	const validationSchema = yup.object({
		name: yup
			.string()
			.trim()
			.required(t(projects.requiredName))
			.max(63, projects.vercelMaxNameLength),
		integrationId: yup.string().required(t(requiredConnection)),
	});

	const { mutate, isLoading } = useCreateHostingProjectMutation({
		environmentId: environment.id,
	});

	const {
		errors,
		values,
		handleChange,
		handleSubmit: formikHandleSubmit,
		setFieldValue,
		setFieldError,
		setFieldTouched,
		touched,
	} = useFormik({
		enableReinitialize: true,
		initialValues,
		initialTouched: {
			name: true,
		},
		validationSchema,
		validateOnBlur: false,
		validateOnMount: true,
		onSubmit: async (values) => {
			const hasError = await handleInstallationValidation();
			if (hasError) return;

			mutate(values, {
				onSuccess: () => {
					setOpenModal(false);
					toast({
						description: enSites.successInstallations,
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
				},
			});
		},
	});

	const { refetch: installationValidationRefetch } =
		useValidateHostingInstallation({
			integrationId: values.integrationId,
			enabled: false,
		});

	async function handleInstallationValidation() {
		setIsValidationLoading(true);
		const { data, error } = await installationValidationRefetch();
		if (error) {
			setFieldError(integrationId, t(canNotValidateConnection));
			setIsValidationLoading(false);
			return true;
		}
		if (data && data.data.isValid === false) {
			setFieldError(integrationId, data?.data.message);
			setIsValidationLoading(false);
			return true;
		}
		setIsValidationLoading(false);
		return false;
	}

	return (
		<ModalWrapper
			isOpen={openModal}
			title={enSites.linkToVercel}
			onClose={() => setOpenModal(false)}
			dataTestId="linkTo-vercel-project-modal"
			size="lg"
		>
			<form
				data-testid="linkTo-vercel-project-form"
				onSubmit={formikHandleSubmit}
			>
				<ModalMainContent
					onClose={() => setOpenModal(false)}
					isLoading={isLoading || isValidationLoading}
					rightButtonText={enSites.createAndLink}
				>
					<InputFormControl
						{...{
							isInvalid: Boolean(errors.name && touched.name),
							label: enSites.projectName,
							name: 'name',
							value: values.name,
							onChange: handleChange,
							error: errors.name,
							onFocus: () => setFieldTouched('name', true),
						}}
					/>
					<SelectRepository
						{...{
							isInvalid: Boolean(
								errors.integrationId && touched.integrationId,
							),
							error: errors.integrationId,
							setFieldValue,
							setFieldError,
							onFocus: () => setFieldTouched(integrationId, true),
							values,
						}}
					/>
				</ModalMainContent>
			</form>
		</ModalWrapper>
	);
};

export default LinkToVercelProject;

interface SelectProps {
	error: string | undefined;
	isInvalid: boolean;
	setFieldValue: (...arg: any) => void;
	setFieldError: (field: string, errorMsg: string) => void;
	onFocus: () => void;
	values: ICreateHostingProjectPayload;
}

const SelectRepository: FC<SelectProps> = ({
	error,
	values,
	isInvalid,
	setFieldValue,
	setFieldError,
	onFocus,
}) => {
	const { data, isLoading } = useGetHostingInstallationsList({
		enabled: true,
	});
	const options =
		data?.data.map((i) => ({ label: i.name, value: i.id })) || [];
	const onChange = (e: any) => {
		setFieldValue(integrationId, e.value);
	};

	const { refetch: fetchState, isFetching: isFetchingState } =
		useGetStateForInstallation({ enabled: false });

	const openVercelInstallationTabWithState = useCallback(() => {
		setFieldError && setFieldError(integrationId, '');
		fetchState().then(({ data }) => {
			const state = data?.data ?? '';
			const vercelInstallationUrl = `${vercelInstallationBaseUrl}/?state=${state}`;
			window.open(vercelInstallationUrl);
		});
	}, [fetchState, setFieldError]);

	return (
		<>
			<SelectFormControl
				{...{
					error,
					isInvalid,
					isLoading,
					options,
					onChange,
					label: enSites.selectInstallation,
					name: integrationId,
					onFocus,
					currentValue: values.integrationId,
					dataTestId: 'vercel-installations-select',
				}}
			/>
			<XMCSButton
				text={enSites.createVercel}
				variant="ghost"
				colorScheme="primary"
				isLoading={isLoading && isFetchingState}
				onClick={openVercelInstallationTabWithState}
				my={4}
			/>
			<Alert
				status="info"
				description=""
				isVisibleCloseBtn={false}
				minW="100%"
			>
				<UnorderedList spacing={2} py="2.5" px="1.5">
					<ListItem>
						<T _str={enSites.vercelInfo1} />
					</ListItem>
					<ListItem>
						Vercel{' '}
						<XMCSButton
							text={enSites.githubIntegration}
							variant="link"
							isLoading={isLoading}
							onClick={() => window.open(vercelGithub)}
						/>{' '}
						<T _str={enSites.vercelInfo2} />
					</ListItem>
					<ListItem>
						<T _str={enSites.vercelInfo3} />
					</ListItem>
				</UnorderedList>
			</Alert>
		</>
	);
};

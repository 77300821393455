import { useAuthQuery } from '../apiUtils/queryHelper';
import config from '../config/config';

export interface IOrganizationIdentityModal {
	accountId: string;
	createdBy: string;
	defaultDeploymentRegion: string;
	defaultLanguage: string;
	displayName: string;
	id: string;
	mfa: { required: boolean };
	name: string;
	phoneNumber: string;
	region: string;
	type: string;
}

export const useGetOrganization = () => {
	return useAuthQuery(
		config.identity.get_organization.queryKey,
		async (axiosInstance) => {
			return axiosInstance?.get<IOrganizationIdentityModal>(
				config.identity.get_organization.url,
			);
		},
		{},
		config.identity.get_organization.scope,
	);
};

import {
	IProcessStatusInfo,
	ProcessKeys,
	ProcessStatusesEnum,
} from '../app/models/deployLogger';

export const ProcessStatusesTestMock: IProcessStatusInfo[] = [
	{
		name: ProcessKeys.Provisioning,
		state: ProcessStatusesEnum.Running,
		time: 1345,
	},
	{
		name: ProcessKeys.PostActions,
		state: ProcessStatusesEnum.Running,
		time: 13445,
	},
	{
		name: ProcessKeys.Deployment,
		state: ProcessStatusesEnum.Pending,
		time: 61345,
	},
	{
		name: ProcessKeys.Build,
		state: ProcessStatusesEnum.Succeeded,
		time: 1345,
	},
];

export const ProcessStatusesMock: IProcessStatusInfo[] = [
	{
		name: ProcessKeys.Provisioning,
		state: ProcessStatusesEnum.Running,
		time: 0,
	},
	{
		name: ProcessKeys.PostActions,
		state: ProcessStatusesEnum.Pending,
		time: 0,
	},
	{
		name: ProcessKeys.Deployment,
		state: ProcessStatusesEnum.Running,
		time: 0,
	},
	{ name: ProcessKeys.Build, state: ProcessStatusesEnum.Running, time: 0 },
];

import { Box, ListItem, Spacer, Stack, UnorderedList } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { Text } from '@chakra-ui/react';

interface Props {
	title: string;
	description?: string;
	mb?: number;
	children?: ReactNode;
	mt?: number;
	errors?: string[];
}
interface ErrorProps {
	error: string;
	color: string;
}
export const Card: FC<Props> = ({
	title,
	description,
	mb = 0,
	mt = 0,
	children,
	errors,
}) => {
	return (
		<Box
			border="1px"
			w="100%"
			borderColor="chakra-border-color"
			borderRadius="md"
			p="5"
			mb={mb}
			data-testid="card-box"
			mt={mt}
		>
			<Stack direction="row" justify="left">
				<>{children}</>
				<Stack>
					<Text variant="strong" lineHeight={'base'} as={'span'}>
						{title}
					</Text>
				</Stack>
				<Spacer />
				<Stack>
					<Text lineHeight={'base'} as={'span'}>
						{description}
					</Text>
				</Stack>
			</Stack>
			{errors && errors.length > 0 && (
				<Stack px={7} pt={3} direction="row" justify="left">
					<UnorderedList>
						{errors.map((error) => (
							<ListItem key={error}>
								<ShowError
									error={error}
									color="danger"
								></ShowError>
							</ListItem>
						))}
					</UnorderedList>
				</Stack>
			)}
		</Box>
	);
};

const ShowError: FC<ErrorProps> = ({ error, color }) => {
	return <Text color={color}>{error}</Text>;
};

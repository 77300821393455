import { useState } from 'react';
import { useDeploymentDeployMutation } from '../../../services/deployments';
import {
	useCreateEnvironmentDeploymentMutation,
	useLinkRepositoryEnvironmentMutation,
	useUpsertEnvironmentVariableMutation,
} from '../../../services/environments';
import { useCreateProjectEnvironmentMutation } from '../../../services/projects';
import { EnvironmentTypesEnum } from '../../create-project/helpers';
import { TenantType } from '../../../../@xmcloud/types';
import { ResponseStatusEnum } from '../../../../@xmcloud/utils/helpers';
import { useParams } from 'react-router-dom';
import { useCreateEditEnvContext } from './CreateEditEnvContext';
import { useToast } from '@chakra-ui/react';
import { editCreateEnvironment } from '../../../../@xmcloud/core/messages/en';
import { useQueryClient } from 'react-query';
import config from '../../../config/config';

const { createEnv: createEnvtxt } = editCreateEnvironment;

const { CM, EH } = EnvironmentTypesEnum;
const { ERROR } = ResponseStatusEnum;
const { NonProduction } = TenantType;
const { queryKey } = config.environments.environments_queryByType;

export function useCreateEnvironment() {
	const { projectId } = useParams<{ projectId: string }>();
	const [currentEnvironmentId, setCurrentEnvironmentId] = useState('');
	const [currentDeploymentId, setCurrentDeploymentId] = useState('');
	const toast = useToast();
	const queryClient = useQueryClient();

	const { values, isCm, isADOProvider, onClose } = useCreateEditEnvContext();
	const {
		environmentName,
		tenantType,
		deployOnCommit,
		repositoryBranch,
		repository,
		repositoryId,
		repositoryRelativePath,
		environmentVariables,
		sourceControlIntegrationId,
		sourceControlIntegrationName,
		cmEnvironmentId,
		adoOrganization,
		adoProjectName,
	} = values;

	const adoRepoPath = `${adoOrganization}/${adoProjectName}/${repository}`;

	const {
		mutate: createEnvironment,
		isLoading: createEnvironmentIsLoading,
		status: createEnvironmentStatus,
	} = useCreateProjectEnvironmentMutation();
	const {
		mutate: createDeployment,
		isLoading: createDeploymentIsLoading,
		status: createDeploymentStatus,
	} = useCreateEnvironmentDeploymentMutation();
	const {
		mutate: deploymentDeploy,
		isLoading: deploymentDeployIsLoading,
		status: deploymentDeployStatus,
	} = useDeploymentDeployMutation();

	const {
		mutate: linkRepositoryToEnvironment,
		isLoading: isLoadingLinkRepositoryEnvironment,
		status: linkRepositoryEnvironmentStatus,
	} = useLinkRepositoryEnvironmentMutation({
		id: currentEnvironmentId,
	});
	const {
		mutate: createVariable,
		isLoading: isCreateVariableMutationLoading,
		status: createEnvironmentVariableStatus,
	} = useUpsertEnvironmentVariableMutation();

	function handleClose() {
		queryClient.invalidateQueries(queryKey(isCm ? CM : EH, projectId!));
		toast({
			status: 'success',
			description: createEnvtxt,
		});
		onClose();
	}

	//1st step: creation of environment after project creation failure
	const shouldRetryFirstStep = createEnvironmentStatus === ERROR;
	// 2nd step: creation of deployment after environment creation failure
	const shouldRetrySeconsStep = linkRepositoryEnvironmentStatus === ERROR;
	// 3rd step: trigger deployment after deployment creation failure
	const shouldRetryThirdProcess = createDeploymentStatus === ERROR;
	// 4th step: deployment trigger after  failure
	const shouldRetryFourthProcess = deploymentDeployStatus === ERROR;
	// 5th step: environment variable creation failure
	const shouldRetryFifthProcess = createEnvironmentVariableStatus === ERROR;

	//5th step: environment variables creation
	const onCreateVariables = (environmentId: string) => {
		let promises = [] as any[];

		for (const variable of environmentVariables) {
			const { name, secret, value } = variable;
			promises.push(
				createVariable(
					{
						environmentId: environmentId,
						variableName: name,
						data: {
							secret: secret,
							value: value,
							target: isCm ? 'CM' : 'EH',
						},
					},
					{},
				),
			);
		}

		Promise.allSettled(promises).finally(() => {
			handleClose();
		});
	};

	//4th step: trigger deployment after deployment creation
	const onDeploymentDeploy = (
		environmentId: string,
		deploymentId: string,
	) => {
		deploymentDeploy(
			{ deploymentId },
			{
				onSuccess: () => {
					if (environmentVariables.length) {
						onCreateVariables(environmentId);
					} else {
						handleClose();
					}
				},
			},
		);
	};

	//3rd step: creation of deployment after link repository to environment
	const onDeploymentCreation = (environmentId: string) => {
		createDeployment(
			{ environmentId },
			{
				onSuccess: (res) => {
					const deploymentId = res.data?.id || '';
					setCurrentDeploymentId(deploymentId);
					onDeploymentDeploy(environmentId, deploymentId);
				},
			},
		);
	};

	//2nd step: link repository to environment
	const onLinkRepositoryEnvironment = (environmentId: string) => {
		linkRepositoryToEnvironment(
			{
				repository: isADOProvider ? adoRepoPath : repository,
				repositoryId: `${repositoryId}`,
				integrationId: sourceControlIntegrationId,
				repositoryRelativePath,
				repositoryBranch,
				environmentId,
				sourceControlIntegrationName,
				deployOnCommit,
			},
			{
				onSuccess: () => {
					onDeploymentCreation(environmentId);
				},
			},
		);
	};

	//1st step: creation of environment(onEnvironmentCreation)
	const onEnvironmentCreation = () => {
		const envPayload = {
			name: environmentName,
			tenantType: isCm ? tenantType : NonProduction,
			deployOnCommit,
			type: isCm ? CM : EH,
			repositoryBranch: '',
			repositoryDetails: null,
			...(!isCm && {
				editingHostEnvironmentDetails: {
					cmEnvironmentId,
				},
			}),
		};

		createEnvironment(
			{ projectId: projectId!, environment: envPayload },
			{
				onSuccess: (res) => {
					const newEnvironment = res.data;
					setCurrentEnvironmentId(newEnvironment.id);
					onLinkRepositoryEnvironment(newEnvironment.id);
				},
			},
		);
	};

	function onEnvironmentLevelProcess(currentEnvironmentId: string) {
		if (shouldRetryFirstStep) {
			onEnvironmentCreation();
		} else if (shouldRetrySeconsStep) {
			onLinkRepositoryEnvironment(currentEnvironmentId);
		} else if (shouldRetryThirdProcess) {
			onDeploymentCreation(currentEnvironmentId);
		} else if (shouldRetryFourthProcess) {
			onDeploymentDeploy(currentEnvironmentId, currentDeploymentId);
		} else if (shouldRetryFifthProcess) {
			onCreateVariables(currentEnvironmentId);
		} else {
			onEnvironmentCreation();
		}
	}

	const isLoading =
		createEnvironmentIsLoading ||
		createDeploymentIsLoading ||
		deploymentDeployIsLoading ||
		isLoadingLinkRepositoryEnvironment ||
		isCreateVariableMutationLoading;

	const shouldRetry =
		shouldRetryFirstStep ||
		shouldRetrySeconsStep ||
		shouldRetryThirdProcess ||
		shouldRetryFourthProcess ||
		shouldRetryFifthProcess;

	return {
		isLoading,
		onEnvironmentLevelProcess,
		shouldRetry,
		currentEnvironmentId,
	};
}

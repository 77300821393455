import { CircularProgress, Stack } from '@chakra-ui/react';
import { Icon } from '..';

interface Props {
	IconName?: string;
	value?: number;
	text: string;
	iconColor?: string;
	gap?: number[];
	isIndeterminate?: boolean;
	textColor?: string;
	my?: number;
	iconSize?: number;
	xsText?: string;
	noOfLines?: number;
}

export const ProgressIcon: React.FC<Props> = ({
	IconName,
	value,
	iconColor,
	gap = [1.5, 2, 2, 2],
	isIndeterminate = false,
	my = 0,
}) => {
	return (
		<Stack direction="row" gap={gap} my={my}>
			{value === 0 || isIndeterminate ? (
				<CircularProgress
					{...{
						...(value === 0 && { value, trackColor: 'warning' }),
						...(isIndeterminate && { isIndeterminate }),
					}}
					size={5}
					color="primary"
				/>
			) : (
				<Icon path={IconName} color={iconColor} />
			)}
		</Stack>
	);
};

import { FC, useMemo, useRef } from 'react';
import {
	Alert,
	InputFormControl,
	ModalMainContent,
	ModalWrapper,
	SelectFormControl,
} from '../../../shared-components';
import { useFormik } from 'formik';
import {
	app,
	createDeployment,
	credentials,
} from '../../../../@xmcloud/core/messages/en';
import {
	useGetProjectEnvironmentsList,
	useGetProjectList,
} from '../../../services/projects';
import { createClientSchema } from '../../../../@xmcloud/yup-validations/createClient';
import { AuthenticationClientType } from '../../../models/clientModel';

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
	onSubmit: (values: any) => void;
	isLoading?: boolean;
	clientType: AuthenticationClientType;
}

const LABEL = 'label';
const DESCRIPTION = 'description';
const PROJECT = 'project';
const ENVIRONMENT = 'environment';

const clientTypeLabel = {
	[AuthenticationClientType.AutomationClient]: credentials.automationClient,
	[AuthenticationClientType.EdgeAdministrationClient]: credentials.edgeClient,
	[AuthenticationClientType.OrganizationAutomationClient]:
		credentials.automationClient,
};

const CreateClientForm: FC<Props> = ({
	openModal,
	setOpenModal,
	onSubmit,
	isLoading = false,
	clientType,
}) => {
	const initialValues = useRef({
		label: '',
		description: '',
		project: '',
		environment: '',
	}).current;

	const { data: projects, isLoading: projectIsLoading } = useGetProjectList();

	const projectOptions =
		projects?.data.map((project) => ({
			label: project.name,
			value: project.id,
		})) || [];

	const validationSchema = createClientSchema(
		clientType !== AuthenticationClientType.OrganizationAutomationClient,
	);

	const {
		errors,
		values,
		handleChange,
		handleSubmit: formikHandleSubmit,
		setFieldValue,
		setFieldTouched,
		touched,
	} = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		validateOnBlur: true,
		onSubmit: async (values) => {
			onSubmit(values);
		},
	});

	const { data: environments, isLoading: environmentIsLoading } =
		useGetProjectEnvironmentsList({
			projectId: values.project,
			enabled: !!values.project,
		});

	const environmentOptions =
		environments?.data.map((environment) => ({
			label: environment.name,
			value: environment.id,
		})) || [];
	const environmentLength = environments?.data.length || 0;

	const projectName = useMemo(
		() => projects?.data?.find((p) => p.id === values.project)?.name || '',
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[values.project],
	);

	return (
		<ModalWrapper
			isOpen={openModal}
			title={clientTypeLabel[clientType]}
			onClose={() => setOpenModal(false)}
		>
			<form
				data-testid="create-client-form"
				onSubmit={formikHandleSubmit}
			>
				<ModalMainContent
					onClose={() => setOpenModal(false)}
					isLoading={isLoading}
					rightButtonText={app.create}
				>
					<InputFormControl
						error={errors.label}
						isInvalid={Boolean(errors.label && touched.label)}
						label={credentials.label}
						name={LABEL}
						onChange={handleChange}
						value={values.label}
						onFocus={() => setFieldTouched(LABEL, true)}
					/>
					<InputFormControl
						error={errors.description}
						isInvalid={Boolean(
							errors.description && touched.description,
						)}
						label={credentials.description}
						name={DESCRIPTION}
						onChange={handleChange}
						value={values.description}
						isTextArea={true}
						pt={6}
						onFocus={() => setFieldTouched(DESCRIPTION, true)}
					/>
					{clientType !==
						AuthenticationClientType.OrganizationAutomationClient && (
						<SelectFormControl
							error={errors.project}
							isInvalid={Boolean(
								errors.project && touched.project,
							)}
							isLoading={projectIsLoading}
							label={credentials.project}
							name={PROJECT}
							onChange={(e: any) => {
								setFieldValue(ENVIRONMENT, '');
								setFieldValue(PROJECT, e.value);
							}}
							options={projectOptions}
							currentValue={values.project}
							onFocus={() => setFieldTouched(PROJECT, true)}
						/>
					)}
					{clientType !==
						AuthenticationClientType.OrganizationAutomationClient && (
						<SelectFormControl
							error={errors.environment}
							isInvalid={
								!Boolean(environmentLength)
									? false
									: Boolean(values.project) &&
										Boolean(errors.environment) &&
										Boolean(touched.environment)
							}
							isLoading={environmentIsLoading}
							label={credentials.environment}
							name={ENVIRONMENT}
							onChange={(e: any) => {
								setFieldValue(ENVIRONMENT, e.value);
							}}
							options={environmentOptions}
							currentValue={values.environment}
							isDisabled={
								!Boolean(values.project) || !environmentLength
							}
							onFocus={() => setFieldTouched(ENVIRONMENT, true)}
							placeholder={
								!Boolean(values.project) || environmentIsLoading
									? createDeployment.select
									: !Boolean(environmentLength)
										? createDeployment.noEnvironment
										: createDeployment.select
							}
						/>
					)}

					{values.project &&
						!environmentLength &&
						!environmentIsLoading && (
							<Alert
								description={credentials.noEnvInfo}
								isVisibleCloseBtn={false}
								status="warning"
								mt="4"
								boldText={projectName}
								minW="100%"
								boldTextTransform="none"
							/>
						)}
				</ModalMainContent>
			</form>
		</ModalWrapper>
	);
};

export default CreateClientForm;

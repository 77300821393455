import { useState } from 'react';
import { Stack, Text } from '@chakra-ui/react';
import {
	commonCw,
	createTransfer,
} from '../../../../@xmcloud/core/messages/en';
import { useCreateTransfer } from '../../../../@xmcloud/hooks';
import {
	MergeStrategiesPopover,
	SelectedItemsWithMergeStrategies,
} from '../components';
import { t } from '@transifex/native';
import { HeadingLg, XMCSButton } from '../../../shared-components';
import { useParams } from 'react-router';

export const MergeStrategies: React.FC = () => {
	const { transferId } = useParams<{ transferId: string }>();
	const { next, prev } = useCreateTransfer();
	const [allStrategiesSelected, setAllStrategiesSelected] = useState(false);
	const { previous } = commonCw;

	const {
		transferOptionsStepDescription,
		mergeStrategiesStepTitle,
		createTransferNextButtonTitle,
	} = createTransfer;

	async function handleNext() {
		next(transferId);
	}

	const { values } = useCreateTransfer();
	const transferConfigurationNodes = values.configuration?.nodes;

	return (
		<Stack gap={'6'}>
			<Stack gap={'2'}>
				<HeadingLg text={mergeStrategiesStepTitle} />
				<Stack direction="row">
					<Text variant="subtle" my={2}>
						{t(transferOptionsStepDescription)}
					</Text>
					<MergeStrategiesPopover />
				</Stack>
			</Stack>
			<SelectedItemsWithMergeStrategies
				nodes={transferConfigurationNodes}
				setAllStrategiessSelected={setAllStrategiesSelected}
			/>
			<Stack py={5} direction="row">
				<XMCSButton
					text={previous}
					onClick={() => prev(transferId)}
					size="md"
					variant="outline"
				/>
				<XMCSButton
					text={createTransferNextButtonTitle}
					onClick={handleNext}
					size="md"
					isDisabled={!allStrategiesSelected}
				/>
			</Stack>
		</Stack>
	);
};

export default MergeStrategies;

import { useExperimentalFeatures } from '../../../../@xmcloud/hooks';
import { useFeature } from '../../../feature-flag/features';

export const useCreateProjectV3 = () => {
	const { createProjectV3ExperimentalFeature } = useExperimentalFeatures();
	const createProjectV3 = useFeature('CreateProjectV3');
	const enableCreateProjectV3 =
		createProjectV3 && createProjectV3ExperimentalFeature;
	return { enableCreateProjectV3 };
};

import { AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';
import { ESourceControl, PaginatedResponse } from '../../@xmcloud/types';
import {
	useAuthMutation,
	useAuthQuery,
	ExtraAuthOptions,
} from '../apiUtils/queryHelper';
import config from '../config/config';
import {
	IRepositoryIntegrationDetails,
	IRepositoryTemplateDetails,
	IRepositoryProviderDetails,
	ICreateRepositoryPayload,
	ICreateRepositoryResponse,
	IRepositoryHookSubscritionPayload,
} from '../models/repositoriesModel';
import { IValidateResponse } from '../models/projectModel';
import { systemHealthStatus } from '../../@xmcloud/core/messages/en';
import { HttpStatusCodes } from '../apiUtils/resources';

const { errorMsg6, errorMsg5 } = systemHealthStatus;
const { SERVICE_UNAVAILABLE } = HttpStatusCodes;

export const useRepositoryIntegrateState = ({
	enabled,
}: {
	enabled?: boolean;
}) => {
	return useAuthQuery(
		[],
		(axiosInstance) => {
			return axiosInstance?.get<string>(
				config.repository.integrate_state.url,
			);
		},
		{ enabled },
		config.repository.integrate_state.scope,
	);
};

export const useRepositoryIntegrateMutation = (options?: ExtraAuthOptions) => {
	const queryClient = useQueryClient();
	return useAuthMutation(
		(axiosInstance) =>
			({
				code,
				installationId,
				state,
				provider,
			}: {
				code: string;
				installationId: string | null;
				state: string | null;
				provider: ESourceControl;
			}) => {
				return axiosInstance?.post<
					{
						code: string;
						installationId?: string;
						state: string | null;
						provider: ESourceControl;
					},
					AxiosResponse<void>
				>(config.repository.integrate_create.url, {
					code,
					installationId,
					state,
					provider,
				});
			},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(
					config.repository.get_repo_integrations.queryKey,
				);
			},
			skipDefaultErrorHandler: options?.skipDefaultErrorHandler,
		},
		config.repository.integrate_create.scope,
	);
};

export const useGetRepositoryIntegrations = (enabled = true) => {
	return useAuthQuery(
		config.repository.get_repo_integrations.queryKey,
		(axiosInstance) => {
			return axiosInstance?.get<IRepositoryIntegrationDetails[]>(
				config.repository.get_repo_integrations.url,
			);
		},
		{ enabled, refetchOnWindowFocus: true },
		config.repository.get_repo_integrations.scope,
	);
};

export const useGetRepositoryIntegration = (id: string, enabled = true) => {
	const { url, queryKey, scope } = config.repository.get_repo_integration;
	const _enabled = Boolean(id) && enabled;
	return useAuthQuery(
		queryKey(id),
		(axiosInstance) => {
			return axiosInstance?.get<IRepositoryIntegrationDetails>(url(id));
		},
		{ enabled: _enabled },
		scope,
	);
};

export const useGetRepositoryProviders = (enabled = true) => {
	return useAuthQuery(
		config.repository.get_repo_providers.queryKey,
		(axiosInstance) => {
			return axiosInstance?.get<IRepositoryProviderDetails[]>(
				config.repository.get_repo_providers.url,
			);
		},
		{
			enabled,
		},
		config.repository.get_repo_providers.scope,
	);
};

export const useGetRepositoryTemplates = (provider: string | undefined) => {
	return useAuthQuery(
		config.repository.get_repo_templates.queryKey,
		(axiosInstance) => {
			return axiosInstance?.get<IRepositoryTemplateDetails[]>(
				config.repository.get_repo_templates.url,
				{ params: { provider } },
			);
		},
		{ enabled: !!provider },
		config.repository.get_repo_templates.scope,
	);
};

export const useGetRepositoryIntegrationsV2 = (page: number) => {
	const { url, scope, queryKey } = config.repository.get_repo_integrations_v2;
	return useAuthQuery(
		queryKey(page),
		(axiosInstance) => {
			return axiosInstance?.get<
				PaginatedResponse<IRepositoryIntegrationDetails>
			>(url(page, 10));
		},
		{ keepPreviousData: true, refetchOnWindowFocus: true },
		scope,
	);
};

export const useDeleteSourceControlIntegrationMutation = () => {
	return useAuthMutation(
		(axiosInstance) => (integrationId: string) => {
			return axiosInstance?.delete<string, AxiosResponse<any>>(
				config.repository.delete_integration.url(integrationId),
			);
		},
		{},
		config.repository.delete_integration.scope,
	);
};

export const useValidateRepositoryName = (
	repoName: string,
	integrationId: string,
	enabled = false,
) => {
	const { url, scope, queryKey } = config.repository.validate_repo_name_V2;
	return useAuthQuery(
		queryKey(repoName, integrationId),
		(axiosInstance) => {
			return axiosInstance?.get<IValidateResponse>(
				url(repoName, integrationId),
			);
		},
		{ enabled },
		scope,
	);
};

export const useValidateSourceControlIntegrationV2 = ({
	integrationId,
	enabled = false,
}: {
	integrationId: string;
	enabled?: boolean;
}) => {
	const _enabled = Boolean(integrationId) && enabled;
	const { url, scope, queryKey } = config.repository.validate_integration_V2;
	const result = useAuthQuery(
		queryKey(integrationId),
		(axiosInstance) => {
			return axiosInstance?.get<IValidateResponse>(url(integrationId));
		},
		{
			enabled: _enabled,
		},
		scope,
	);
	const { data, isError, error } = result;
	//@ts-ignore
	const errorResponse = error?.response;
	const errorData = errorResponse?.data;

	const isServiceUnavailable = errorResponse?.status === SERVICE_UNAVAILABLE;

	const errorValidationIntegrationMsg =
		errorData?.detail || errorData?.message || errorMsg6;

	const isIntegrationIdValidationValid = data?.data.isValid === true;
	const integrationValidationErrorMsg = isServiceUnavailable
		? errorValidationIntegrationMsg
		: isError
			? errorMsg5
			: !!data?.data.message
				? `${data?.data.message}.`
				: '';

	return {
		...result,
		isIntegrationIdValidationValid,
		integrationValidationErrorMsg,
	};
};

export const useCreateRepositoryMutation = () => {
	const { url, scope } = config.repository.get_repositories;
	return useAuthMutation(
		(axiosInstance) => (repository: ICreateRepositoryPayload) => {
			return axiosInstance?.post<
				ICreateRepositoryPayload,
				AxiosResponse<ICreateRepositoryResponse>
			>(url, repository);
		},
		{},
		scope,
	);
};

export const useRepositoryHookSubscriptionMutation = () => {
	const { url, scope } = config.repository.source_control_hook_subscribe;
	return useAuthMutation(
		(axiosInstance) => (repository: IRepositoryHookSubscritionPayload) => {
			return axiosInstance?.post<
				IRepositoryHookSubscritionPayload,
				AxiosResponse<string>
			>(url, repository);
		},
		{},
		scope,
	);
};

import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Progress, Box, Text, CircularProgress } from '@chakra-ui/react';
import { cmInstanceLog } from '../../../../@xmcloud/core/messages/en';
import { useSharedState } from '../../../../@xmcloud/hooks';
import { IS_SM_SCREEN } from '../../../../@xmcloud/hooks/context/useSharedState';
import { SIZE_QUERY_PARAMETER } from '../../../../@xmcloud/utils/constants';
import {
	downloadLogsWithUrl,
	formatBytes,
} from '../../../../@xmcloud/utils/helpers';
import {
	useGetEnvironmentLog,
	useGetEnvironmentLogSize,
} from '../../../services/environments';
import {
	DownloadButton,
	Footer,
	Pagination,
	RenderPlaceholder,
	RowStack,
	Text14B700,
} from '../../../shared-components';
import LogRows from './LogRows';
import { LogTypes } from '../../../../@xmcloud/types';

const { logs: logstxt } = cmInstanceLog;
const { INFO } = LogTypes;

export const CMInstanceLogs: FC = () => {
	const query = new URLSearchParams(window.location.search);
	const sizeFromURL = query.get(SIZE_QUERY_PARAMETER);
	const fileSize: number = parseInt(sizeFromURL || '0');
	const { environmentId, type } = useParams<{
		environmentId: string;
		type: string;
	}>();
	const [loading, setLoading] = useState(false);
	const [loadingPercentage, setLoadingPercentage] = useState(0);
	const [inProgressText, setInProgressText] = useState('In progress...');
	const [currentSelectValue, setCurrentSelectValue] =
		useState<LogTypes>(INFO);
	let totalSize = 0;
	const {
		data: sizeData,
		isLoading: isHeadLoading,
		isSuccess: isHeadSuccess,
	} = useGetEnvironmentLogSize({
		environmentId: environmentId!,
		logName: type!,
	});

	const {
		logs,
		isLoading: isLogLoading,
		isProgressStart,
		downloadUrl,
		hanldeNextChucnk,
		totalPages,
	} = useGetEnvironmentLog({
		environmentId: environmentId!,
		logName: type!,
		enabled: isHeadSuccess,
		onDownloadProgress: (data) => {
			totalSize =
				(sizeData?.headers['content-length'] as unknown as number) ??
				fileSize;

			if (totalSize > 0) {
				const loadedSize = data.loaded as number;
				const percentage = Math.round((loadedSize / totalSize) * 100);

				setLoadingPercentage(percentage);
				setInProgressText(
					`${formatBytes(loadedSize)}/${formatBytes(totalSize)}`,
				);
			}
		},
	});

	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);

	const isAnyLoading = isLogLoading || isHeadLoading;

	if (isAnyLoading)
		return (
			<Box
				height="full"
				width="full"
				alignItems="center"
				bgColor="whiteAlpha.900"
				display="flex"
				justifyContent="center"
				pb="15%"
			>
				<Box textAlign="center">
					{isHeadLoading && (
						<CircularProgress
							isIndeterminate
							capIsRound
							color="primary"
							trackColor="neutral-color.200"
						/>
					)}
					{!isHeadLoading && isLogLoading && (
						<>
							<Progress
								value={loadingPercentage}
								minWidth="xl"
								size="lg"
							/>
							<Text mt={6}>{loadingPercentage}%</Text>
							<Text mt={6}>{inProgressText}</Text>
						</>
					)}
				</Box>
			</Box>
		);

	if (!logs) return <RenderPlaceholder item={logstxt.toLocaleLowerCase()} />;

	return (
		<>
			<RowStack>
				<Text14B700 text={type!} noOfLines={1} />
				{totalPages > 1 && (
					<Pagination totalPages={totalPages} cb={hanldeNextChucnk} />
				)}
				{!isSmScreen && (
					<DownloadButton
						onClick={() => {
							setLoading(true);
							downloadLogsWithUrl(downloadUrl, type!);
							setTimeout(() => {
								setLoading(false);
							}, 700);
						}}
						isLoading={loading}
					/>
				)}
				{Boolean(isSmScreen) && (
					<Footer>
						<DownloadButton
							onClick={() => {
								setLoading(true);
								downloadLogsWithUrl(downloadUrl, type!);
								setTimeout(() => {
									setLoading(false);
								}, 700);
							}}
							isLoading={loading}
						/>
					</Footer>
				)}
			</RowStack>
			<LogRows
				logs={logs || []}
				isProgressStart={isProgressStart}
				key={isProgressStart ? 1 : logs.length ? 2 : 3}
				currentSelectValue={currentSelectValue}
				setCurrentSelectValue={setCurrentSelectValue}
				totalPages={totalPages}
			/>
		</>
	);
};

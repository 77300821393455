import { Stack } from '@chakra-ui/react';
import React from 'react';
import { HeadingMd, Text, Wrap } from '../../shared-components';
import { thirdStep } from '../../../@xmcloud/core/messages/en';
import {
	EnvVarsAddButton,
	EnvVarsForm,
	EnvVarsTable,
} from '../custom-environment-variables';
const { envVariableTitle, envVariableDesc } = thirdStep;

export const EnvironmentVariables: React.FC<{
	variables: any[];
	minW?: string[];
}> = ({ variables, minW = ['full', '3xl'] }) => (
	<Stack gap="6" minW={minW}>
		<HeadingMd text={envVariableTitle} />
		<Text fontSize="sm" text={envVariableDesc} />
		{variables.length && <EnvVarsTable />}
		<Wrap alignSelf={'flex-start'}>
			<EnvVarsAddButton />
		</Wrap>

		<EnvVarsForm />
	</Stack>
);

import { createTransferMergeStrategies } from '../../../@xmcloud/core/messages/en';
import { MergeStrategy } from '../../models/transferConfiguration';

const {
	latestWinMergeStrategyName,
	overrideExistingItemMergeStrategyName,
	keepExistingItemMergeStrategyName,
	overrideExistingTreeMergeStrategyName,
	overrideExistingItemMergeStrategyDescription,
	keepExistingItemMergeStrategyDescription,
	latestWinMergeStrategyDescription,
	overrideExistingTreeMergeStrategyDescription,
} = createTransferMergeStrategies;

export const status = {
	NotStarted: 'Pending',
	InProgress: 'In progress',
	Complete: 'Complete',
	Cancelled: 'Cancelled',
	Failed: 'Failed',
};

export type TCalculatedStatus = 0 | 1 | 2 | 3 | 4;
export const calculatedAlertStatus = {
	0: 'info',
	1: 'busy',
	2: 'success',
	3: 'error',
	4: 'warning',
	5: 'cancelled',
};

export const MergeStrategiesData = [
	{
		label: overrideExistingItemMergeStrategyName,
		value: MergeStrategy.OverrideExistingItem.toString(),
		desc: overrideExistingItemMergeStrategyDescription,
	},
	{
		label: keepExistingItemMergeStrategyName,
		value: MergeStrategy.KeepExistingItem.toString(),
		desc: keepExistingItemMergeStrategyDescription,
	},
	{
		label: latestWinMergeStrategyName,
		value: MergeStrategy.LatestWin.toString(),
		desc: latestWinMergeStrategyDescription,
	},
	{
		label: overrideExistingTreeMergeStrategyName,
		value: MergeStrategy.OverrideExistingTree.toString(),
		desc: overrideExistingTreeMergeStrategyDescription,
	},
];

export function getMergeStrategyLabel(value: MergeStrategy) {
	const strategy = MergeStrategiesData.find(
		(item) => item.value === value.toString(),
	);
	return strategy ? strategy.label : 'Unknown';
}

import { ReactNode, createContext, useContext, useState, useMemo } from 'react';
import { uniq } from 'lodash';
import {
	findDescendantKeys,
	getExpandedKeys,
	nodesToSelectionKeys,
	extendedNodes,
} from './utils/handlers';
import { Dict } from '../../../../../@xmcloud/types';

type TreeContextType = {
	setSelectionKeys: (keys: TreeSelectionKeys) => void;
	setExpandedKeys: (keys: TreeExpandedKeys) => void;
	selectionKeys: TreeSelectionKeys;
	expandedKeys: TreeExpandedKeys;
	extendedNodes: (nodes: TreeNode[]) => TreeNode[];
	nodesToSelectionKeys: (nodes: TreeNode[]) => TreeSelectionKeys;
	findDescendantKeys: (node: TreeNode) => string[];
	getExpandedKeys: (nodes: TreeNode[]) => TreeExpandedKeys;
	intermediates: Dict<boolean>;
	isNodeLeaf: (node: TreeNode) => boolean;
	nodes: TreeNode[];
	setNodes: (nodes: TreeNode[]) => void;
	isRootSelected: boolean;
	toggleRoot: (isRootSelected: boolean) => void;
};

export const TreeContext = createContext<TreeContextType>(
	{} as TreeContextType,
);

const TreeProvider = ({ children }: { children: ReactNode }) => {
	const [selectionKeys, setSelectionKeys] = useState<TreeSelectionKeys>({});
	const [expandedKeys, setExpandedKeys] = useState<TreeExpandedKeys>({});
	const [nodes, setNodes] = useState<TreeNode[]>([]);
	const [isRootSelected, toggleRoot] = useState(false);

	const parents = useMemo<string[]>(
		() =>
			uniq(
				Object.keys(selectionKeys).filter(
					(k) => selectionKeys[k].descendants?.length,
				),
			),
		[selectionKeys],
	);

	const indeterminates = useMemo<Dict<boolean>>((): Dict<boolean> => {
		const desc = parents.reduce(
			(acc: { [key: string]: number }, key: string) => {
				const desc = findDescendantKeys(selectionKeys[key]);
				const count = desc.length;
				acc[key] = count;
				return acc;
			},
			{},
		);

		const check = parents.reduce(
			(acc: { [key: string]: number }, key: string) => {
				const desc = findDescendantKeys(selectionKeys[key]);

				acc[key] = desc.filter(
					(k: string) => !!selectionKeys[k]?.checked,
				).length;
				return acc;
			},
			{},
		);
		const result = parents.reduce((acc: Dict<boolean>, key: string) => {
			const d = desc[key] ?? 0;
			const c = check[key] ?? 0;
			acc[key] = c !== 0 && d >= c;
			return acc;
		}, {});

		return result;
	}, [parents, selectionKeys]);

	const isNodeLeaf = (node: TreeNode): boolean => {
		return node.leaf === false
			? false
			: !(node.descendants && node.descendants.length);
	};

	return (
		<TreeContext.Provider
			value={{
				selectionKeys,
				setSelectionKeys,
				expandedKeys,
				setExpandedKeys,
				extendedNodes,
				nodesToSelectionKeys,
				findDescendantKeys,
				getExpandedKeys,
				intermediates: indeterminates,
				isNodeLeaf,
				nodes,
				setNodes,
				isRootSelected,
				toggleRoot,
			}}
		>
			{children}
		</TreeContext.Provider>
	);
};

function useTree(): TreeContextType {
	const context = useContext(TreeContext);

	if (context === undefined) {
		throw new Error('useTree must be used within a TreeProvider');
	}

	return context;
}

export { useTree, TreeProvider };

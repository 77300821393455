import {
	Box,
	Popover,
	PopoverBody,
	PopoverContent,
	Text,
	PopoverTrigger,
	Stack,
} from '@chakra-ui/react';
import { mdiInformationOutline } from '@mdi/js';
import { createTransfer } from '../../../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';
import { MergeStrategiesData } from '../../../contenttransfer/handlers';
import { useState } from 'react';
import { Icon } from '../../../../shared-components';

const { mergeStrategiesStepPopoverTitle } = createTransfer;

export const MergeStrategiesPopover = () => {
	const [showPopover, setShowPopover] = useState(false);
	return (
		<Popover isOpen={showPopover} placement="bottom-start">
			<>
				<PopoverTrigger>
					<Box
						onMouseEnter={() => {
							setShowPopover(true);
						}}
					>
						<Icon
							path={mdiInformationOutline}
							boxSize={5}
							color="neutral"
							my={2}
						/>
					</Box>
				</PopoverTrigger>
				<PopoverContent
					onMouseLeave={() => setShowPopover(false)}
					onBlur={() => setShowPopover(false)}
					w="md"
				>
					{
						<PopoverBody p={3}>
							<Text variant="large" fontWeight="semibold" mb={4}>
								{t(mergeStrategiesStepPopoverTitle)}
							</Text>
							<Stack>
								{MergeStrategiesData.map(
									({ label, desc }, index) => (
										<Text
											fontWeight="semibold"
											key={`${label}-${index}`}
										>
											{t(label)}
											<Text
												as="span"
												variant="subtle"
												key={`inner-${label}-${index}`}
											>
												{t(desc)}
											</Text>
										</Text>
									),
								)}
							</Stack>
						</PopoverBody>
					}
				</PopoverContent>
			</>
		</Popover>
	);
};

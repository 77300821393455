import { memo } from 'react';
import TransferDetails from './TransferDetailsStep';
import ItemSelection from './ItemSelectionStep';
import MergeStrategies from './MergeStrategiesStep';
import ReviewAndTransfer from './ReviewAndTransferStep';

export const TransferDetailsStep = memo(TransferDetails);
export const ItemSelectionStep = memo(ItemSelection);
export const MergeStrategiesStep = memo(MergeStrategies);
export const ReviewAndTransferStep = memo(ReviewAndTransfer);

import { HStack } from '@chakra-ui/react';
import React from 'react';
import { Icon } from './Icon';
import { Text } from '../text/index';

export const SuffixIconText: React.FC<{
	text: string;
	path: string;
	color?: string;
}> = ({ text, path, color = 'chakra-placeholder-color' }) => (
	<HStack justifyContent="space-between">
		<Text text={text} />
		<Icon path={path} boxSize="5" color={color} />
	</HStack>
);

import * as yup from 'yup';
import { createDeployment } from '../core/messages/en';

export const createDeploymentSchema = () =>
	yup.object({
		project: yup.string().required(createDeployment.projectRequired),
		environmentId: yup
			.string()
			.required(createDeployment.environmentRequired),
	});

import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthenticatedAxiosProvider } from './app/apiUtils/AxiosProvider';
import { Auth0ProviderWithHistory } from './app/auth/Auth0ProviderWithHistory';
import sitecoreTheme, { toastOptions } from '@sitecore/blok-theme';
import { BrowserRouter } from './@xmcloud/core/routes';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
		},
		mutations: {
			retry: false,
		},
	},
});

const AppProviders: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	return (
		<Auth0ProviderWithHistory>
			<BrowserRouter>
				<ChakraProvider
					theme={sitecoreTheme}
					toastOptions={toastOptions}
				>
					<QueryClientProvider client={queryClient}>
						<AuthenticatedAxiosProvider>
							{children}
						</AuthenticatedAxiosProvider>
						{/*<ReactQueryDevtools initialIsOpen={false} /> */}
					</QueryClientProvider>
				</ChakraProvider>
			</BrowserRouter>
		</Auth0ProviderWithHistory>
	);
};

export default AppProviders;

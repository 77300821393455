import { Navigate, useLocation, useParams } from 'react-router-dom';
import { goTo, mainPaths } from '../../../@xmcloud/core/routes/paths';

const { cmInstanceLogs, deploymentLogs } = goTo;
const { noMatch } = mainPaths;

const oldProjectUrls = {
	all: (projectId: string) => goTo.environments(projectId),
	details: (projectId: string) => goTo.projectDetails(projectId),
	'': '/projects',
};

const oldEnvUrls = {
	deployments: (projectId: string, environmentId: string) =>
		goTo.deployments(projectId, environmentId),
	sites: (projectId: string, environmentId: string) =>
		goTo.sites(projectId, environmentId),
	details: (projectId: string, environmentId: string) =>
		goTo.details(projectId, environmentId),
	logs: (projectId: string, environmentId: string, type?: string) =>
		type
			? goTo.cmInstanceLogs(projectId, environmentId, type)
			: goTo.cmInstanceLogTypes(projectId, environmentId),
	variables: (projectId: string, environmentId: string) =>
		goTo.variables(projectId, environmentId),
	'': '/projects',
};

type KeyType = keyof typeof oldEnvUrls | keyof typeof oldProjectUrls;

const getNewEnvUrl = (
	projectId: string,
	environmentId: string,
	lastIndex: KeyType,
) => {
	if (oldEnvUrls.hasOwnProperty(lastIndex)) {
		// @ts-ignore
		return oldEnvUrls[lastIndex](projectId, environmentId);
	}
	return null;
};

const getNewProjectUrl = (projectId: string, lastIndex: KeyType) => {
	if (oldProjectUrls.hasOwnProperty(lastIndex)) {
		// @ts-ignore
		return oldProjectUrls[lastIndex](projectId);
	}
	return null;
};

export const NavigateTo: React.FC = () => {
	const params = useParams<{
		projectId: string;
		environmentId?: string;
		deploymentId?: string;
		type?: string;
	}>();
	const { pathname } = useLocation();
	const projectId = params?.projectId || '';
	const environmentId = params?.environmentId || '';
	const type = params?.type || '';
	const deploymentId = params?.deploymentId || '';

	const lastIndex = pathname.split('/').pop() as KeyType;

	if (type) {
		return <Navigate to={cmInstanceLogs(projectId, environmentId, type)} />;
	}

	if (deploymentId) {
		return (
			<Navigate
				to={deploymentLogs(projectId, environmentId, deploymentId)}
			/>
		);
	}

	const newEnvUrl = getNewEnvUrl(projectId, environmentId, lastIndex);
	if (newEnvUrl) {
		return <Navigate to={newEnvUrl} />;
	}

	const newProjectUrl = getNewProjectUrl(projectId, lastIndex);
	if (newProjectUrl) {
		return <Navigate to={newProjectUrl} />;
	}

	return <Navigate to={noMatch} />;
};

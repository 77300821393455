import { MenuItem, MenuList, Tooltip, Text } from '@chakra-ui/react';
import { IRepositoryIntegrationDetails } from '../../../models/repositoriesModel';
import { app, connections } from '../../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import { RowActions } from '../../../shared-components';
import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { EnumInNumberSourceControl } from '../../../../@xmcloud/types';
import { convertProviderEnum } from '../../../../@xmcloud/utils/helpers';

const {
	providerName,
	connectionName,
	connectionId,
	createdBy,
	createdAt,
	deleteValidationMessage,
	deleteConnectionTooltipInfo,
} = connections;

const columnHelper = createColumnHelper<IRepositoryIntegrationDetails>();

export const useSourceControlIntegrationsTableColumns = (
	setConnectionId: (id: string) => void,
	setOpenDeleteSourceControl: (show: boolean) => void,
	getTooltipMessage: (provider: EnumInNumberSourceControl | 0) => string,
) => {
	return useMemo(
		() => [
			columnHelper.accessor('account', {
				header: () => t(connectionName),
				cell: (info) => info.getValue(),
				enableSorting: false,
			}),
			columnHelper.accessor('provider', {
				header: () => t(providerName),
				cell: (info) => info.getValue(),
				enableSorting: false,
			}),
			columnHelper.accessor('id', {
				header: () => t(connectionId),
				cell: (info) => info.getValue(),
				enableSorting: false,
			}),
			columnHelper.accessor('createdBy', {
				header: () => t(createdBy),
				cell: (info) => (
					<Text noOfLines={1} whiteSpace="wrap">
						{info.getValue()}
					</Text>
				),
				enableSorting: false,
			}),
			columnHelper.accessor('createdAt', {
				header: () => t(createdAt),
				cell: (info) => getReadableDate(info.getValue()),
				enableSorting: false,
			}),
			columnHelper.display({
				id: 'actions',
				cell: ({
					row: {
						original: { projectIds, id, provider },
					},
				}) => {
					const hasProjectInConnection = Boolean(projectIds?.length);
					const providerEnum = convertProviderEnum(
						provider || '',
						'number',
					);
					const healthMessage = getTooltipMessage(providerEnum);
					const isUnhealthy = !!healthMessage;

					const isDeleteDisabled =
						hasProjectInConnection || isUnhealthy;

					return (
						<RowActions
							ariaLabel="connections-sourcecontrol-table-action-menu"
							data-testid="action-menu-button"
						>
							<MenuList>
								<Tooltip
									label={
										isUnhealthy
											? healthMessage
											: hasProjectInConnection
												? t(deleteConnectionTooltipInfo)
												: t(deleteValidationMessage)
									}
									isDisabled={!isDeleteDisabled}
									shouldWrapChildren
								>
									<MenuItem
										isDisabled={isDeleteDisabled}
										onClick={() => {
											setConnectionId(id);
											setOpenDeleteSourceControl(true);
										}}
										data-testid="delete-source-control"
										minW="3xs"
									>
										{t(app.delete)}
									</MenuItem>
								</Tooltip>
							</MenuList>
						</RowActions>
					);
				},
				maxSize: 0,
			}),
		],
		[getTooltipMessage, setConnectionId, setOpenDeleteSourceControl],
	);
};

import { FC, useState } from 'react';
import { goTo } from '../../../@xmcloud/core/routes/paths';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import { EditProjectProvider } from '../../../@xmcloud/hooks';
import { EditProjectForm } from './EditProjectForm';
import { useParams } from 'react-router-dom';
import { ModalWrapper } from '../../shared-components';
import { projects } from '../../../@xmcloud/core/messages/en';

const { editTitle } = projects;

export const EditProjectMain: FC = () => {
	const [isOpen, setIsOpen] = useState(true);
	const { projectId } = useParams<{ projectId: string }>();

	function onClose() {
		setIsOpen(false);
		handlePush(goTo.environments(projectId!));
	}

	return (
		<ModalWrapper
			isOpen={isOpen}
			title={editTitle}
			onClose={onClose}
			dataTestId="edit-project-modal-"
			size="lg"
		>
			<EditProjectProvider>
				<EditProjectForm {...{ onClose }} />
			</EditProjectProvider>
		</ModalWrapper>
	);
};

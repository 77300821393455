import { Button, Stack } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import { sites as enSites } from '../../../../@xmcloud/core/messages/en';
import { useSharedState, useSites } from '../../../../@xmcloud/hooks';
import { IS_SM_SCREEN } from '../../../../@xmcloud/hooks/context/useSharedState';
import {
	createSiteLink,
	HOSTEDSITE,
	pageSize,
} from '../../../../@xmcloud/utils/constants';
import {
	EnvironmentPublishMode,
	IEdge,
} from '../../../models/environmentModel';
import {
	useEnvironmentPublishSiteMutation,
	useGetEnvironmentPublishStatus,
} from '../../../services/environments';

import {
	Alert,
	EmptyState,
	Footer,
	LoadingSkeleton,
	XMCSButton,
} from '../../../shared-components';
import SitesTable from '../tables/SitesTable';

export const SitesPage: FC = () => {
	const { sites, isLoading, environment, error } = useSites();

	const noneHostedSites = sites.filter(
		(s) => !Object.keys(s).includes(HOSTEDSITE),
	);

	const noneHostedSite = noneHostedSites[0];

	const length = sites.length;
	const renderAlert = length ? Boolean(noneHostedSite) : false;
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);

	if (error)
		return (
			<Alert
				description={enSites.environmentNotReady}
				status="info"
				isVisibleCloseBtn={true}
				mt="4"
			/>
		);
	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;
	const languages = sites[0]?.edges?.map((e: IEdge) => e.node.name) || [];

	return (
		<>
			{renderAlert && (
				<Alert
					description={
						noneHostedSites.length > 1
							? enSites.manySitesInfo
							: enSites.alertInfo
					}
					status="info"
					isVisibleCloseBtn={true}
					mt="4"
					{...(noneHostedSite && { boldText: noneHostedSite.name })}
					boldTextTransform="none"
				/>
			)}
			{!length ? (
				<EmptyState
					mt="20"
					title={enSites.nositesToShow}
					showCreateButton={!isSmScreen}
					dataTestId="empty-state-sites"
				>
					<XMCSButton
						text={enSites.createSite}
						onClick={() =>
							window.open(
								createSiteLink(
									environment?.platformTenantName,
									environment?.organizationId,
								),
							)
						}
					/>
				</EmptyState>
			) : (
				<>
					<Stack
						direction="row"
						justify="flex-end"
						pt={[4, 4, 6, 6]}
						mb={[4, 4, 0, 0]}
					>
						<PublishStatus
							host={environment?.host || ''}
							languages={languages}
							disabled={!Boolean(length)}
						/>
						{!isSmScreen && (
							<XMCSButton
								text={enSites.create}
								onClick={() =>
									window.open(
										createSiteLink(
											environment?.platformTenantName,
											environment?.organizationId,
										),
									)
								}
							/>
						)}
					</Stack>
					<SitesTable {...{ sites, environment }} />
				</>
			)}
			{Boolean(isSmScreen) && (
				<Footer>
					<XMCSButton
						text={length ? enSites.create : enSites.createSite}
						onClick={() =>
							window.open(
								createSiteLink(
									environment?.platformTenantName,
									environment?.organizationId,
								),
							)
						}
					/>
				</Footer>
			)}
		</>
	);
};

const STATUS = {
	queued: 'QUEUED' as const,
	running: 'RUNNING' as const,
	initializing: 'INITIALIZING' as const,
};
const REFETCHINTERVAL = 15000;

export const PublishStatus = ({
	host,
	languages,
	disabled,
}: {
	host: string;
	languages: string[];
	disabled: boolean;
}) => {
	const { environmentId } = useParams<{ environmentId: string }>();
	const [operationId, setOperationId] = useLocalStorage(
		`envId-${environmentId}`,
		'',
	);

	const [operationStatus, setOperationStatus] = useState('');
	const [enabled, setEnabled] = useState(false);
	const { mutate, isLoading } = useEnvironmentPublishSiteMutation({
		host,
	});

	function reset() {
		setEnabled(false);
		setOperationId('');
		setOperationStatus('');
	}

	function onPublishStatusSuccess(data: any) {
		const publishingStatus = data?.data?.data?.publishingStatus;
		if (!publishingStatus) return;
		const { isDone, processed, state, isFailed } = publishingStatus;

		if (isDone) {
			reset();
			return;
		}
		if (isFailed) {
			reset();
			return;
		}

		if (state === STATUS.initializing) setOperationStatus('Initializing');
		if (state === STATUS.queued) setOperationStatus('Queued');
		if (state === STATUS.running)
			setOperationStatus(`Publishing ${processed} items`);
	}

	const { refetch } = useGetEnvironmentPublishStatus({
		host,
		operationId,
		enabled,
		refetchInterval: enabled ? REFETCHINTERVAL : 0,
		onSuccess: onPublishStatusSuccess,
	});

	useEffect(() => {
		if (operationId) {
			setEnabled(true);
			refetch();
		}
	}, [operationId, refetch]);

	return (
		<Button
			size="sm"
			variant="outline"
			onClick={() => {
				mutate(
					{
						publishSiteMode: EnvironmentPublishMode.FULL,
						languages,
					},
					{
						onSuccess: (d) => {
							const { operationId } = d.data.data.publishSite;
							setOperationId(operationId);
							setEnabled(true);
							refetch();
						},
					},
				);
			}}
			isLoading={enabled || isLoading}
			loadingText={enabled ? operationStatus : ''}
			mr={4}
			data-testid="btn-publish"
			disabled={disabled || enabled || isLoading}
		>
			{t(enSites.publish)}
		</Button>
	);
};

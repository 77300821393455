import { t } from '@transifex/native';
import * as yup from 'yup';
import { environmentVariable } from '../core/messages/en';

const {
	alreadyExist,
	requiredName,
	invalidCharacters,
	allTargetExist,
	diffTargetExist,
	requiredValue,
	requiredRH,
	rhInvalidCharacters,
	doubleQuoteNotAllowed,
} = environmentVariable;

export const editCreateVariableSchema = ({
	variablesOfSelectedTarget,
	variablesOfOtherTarget,
	shouldValidateName,
	isAllTargetSelected,
}: {
	variablesOfSelectedTarget: string[];
	variablesOfOtherTarget: string[];
	shouldValidateName: boolean;
	isAllTargetSelected: boolean;
}) =>
	yup.object({
		name: shouldValidateName
			? yup
					.string()
					.trim()
					.lowercase()
					.notOneOf(variablesOfSelectedTarget, t(alreadyExist))
					.required(t(requiredName))
					.matches(/^[A-Za-z_][A-Za-z_0-9]*$/g, t(invalidCharacters))
					.test(
						'variable-name-validation',
						t(allTargetExist),
						function (value) {
							const { path, createError } = this;
							if (variablesOfOtherTarget.indexOf(value) > -1) {
								return createError({
									path,
									message: t(
										isAllTargetSelected
											? diffTargetExist
											: allTargetExist,
									),
								});
							}
							return true;
						},
					)
			: yup.string().notRequired(),
		value: yup
			.string()
			.trim()
			.matches(/^[^"]*$/, t(doubleQuoteNotAllowed))
			.required(t(requiredValue)),
		renderingHost: yup
			.string()
			.trim()
			.required(t(requiredRH))
			.matches(/^[A-Za-z0-9_.-]*$/g, t(rhInvalidCharacters)),
	});

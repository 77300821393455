import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
	projects,
	editProject,
	systemHealthStatus,
} from '../../../@xmcloud/core/messages/en';
import { useEditProject } from '../../../@xmcloud/hooks';
import { EnumInNumberSourceControl } from '../../../@xmcloud/types';
import {
	adoIntegrationUrl,
	githubIntegrationUrl,
	NEW_SOURCE_CONTROL_ACCOUNT,
} from '../../../@xmcloud/utils/constants';
import config from '../../config/config';
import { useRepositoryIntegrateState } from '../../services/repositories';
import { Alert, XMCSButton } from '../../shared-components';
import {
	ACCOUNT,
	SOURCE_CONTROL_INTEGRATION_ID,
} from '../create-project/helpers';
import { t } from '@transifex/native';
import { ProviderText } from './SelectProvider';
import { Tooltip } from '@chakra-ui/react';

const { createConnection, connectionInfo } = projects;
const { confirmation1, accountChangeConfirmation } = editProject;
const { GitHub, ADO } = EnumInNumberSourceControl;
const { errMsg4, errMsg1 } = systemHealthStatus;

export const NewConnection: React.FC = () => {
	const [isNewConnectionClicked, setIsNewConnectionClicked] = useState(false);
	const {
		values,
		errors,
		setFieldValue,
		setEditValues,
		setFieldError,
		isComponentHealthError,
		isCurrentProviderHealthy,
		isComponentHealthLoading,
	} = useEditProject();
	const queryClient = useQueryClient();
	const {
		refetch: fetchIntegrateState,
		isFetching: isFetchingIntegrateState,
	} = useRepositoryIntegrateState({ enabled: false });
	const { provider, hasAccount, providerName } = values;
	const hasIntegrationError = !!errors?.sourceControlIntegrationId;
	const shouldConfirm =
		isNewConnectionClicked && hasAccount && !hasIntegrationError;

	const toolTipMessage = isComponentHealthError
		? t(errMsg1)
		: !isCurrentProviderHealthy
			? t(errMsg4, {
					variableTxt2: 'create connection',
					variableTxt: providerName,
				})
			: '';

	useEffect(() => {
		const channel = new BroadcastChannel(NEW_SOURCE_CONTROL_ACCOUNT);
		channel.addEventListener('message', (e) => {
			if (e.origin !== window.location.origin) return;
			const { get_repo_integrations, validate_integration_V2 } =
				config.repository;
			queryClient.invalidateQueries(get_repo_integrations.queryKey);
			queryClient.invalidateQueries(
				validate_integration_V2.queryKey(e.data.id),
			);
			setFieldError(SOURCE_CONTROL_INTEGRATION_ID, '');
			setFieldValue(ACCOUNT, e.data.account);
			setFieldValue(SOURCE_CONTROL_INTEGRATION_ID, e.data.id);
			setEditValues((prev) => ({
				...prev,
				editAccount: true,
				editRepository: true,
				editOrganization: true,
				editProject: true,
			}));
		});

		return () => {
			channel.close();
		};
	}, [queryClient, setEditValues, setFieldError, setFieldValue]);

	const isGitHubProvider = provider === GitHub;
	const shouldShowAlert = isNewConnectionClicked || isGitHubProvider;
	const isLoading = isFetchingIntegrateState || isComponentHealthLoading;
	const shouldBtnDisabled =
		isComponentHealthError || !isCurrentProviderHealthy;

	return (
		<>
			<Tooltip
				label={toolTipMessage}
				isDisabled={!shouldBtnDisabled}
				shouldWrapChildren
			>
				<XMCSButton
					text={t(
						isNewConnectionClicked
							? confirmation1
							: createConnection,
						{
							provider: ProviderText[provider!],
						},
					)}
					variant="ghost"
					colorScheme={isNewConnectionClicked ? 'danger' : 'primary'}
					isLoading={isLoading}
					onClick={() => {
						if (hasAccount && !hasIntegrationError) {
							setIsNewConnectionClicked((prev) => !prev);
						}

						if (
							!isNewConnectionClicked &&
							hasAccount &&
							!hasIntegrationError
						)
							return;

						fetchIntegrateState().then(({ data }) => {
							const state = data?.data ?? '';
							if (provider === GitHub) {
								window.open(githubIntegrationUrl(state));
							} else if (provider === ADO) {
								window.open(adoIntegrationUrl(state));
							}
						});
					}}
					my={4}
					isDisabled={shouldBtnDisabled}
				/>
			</Tooltip>
			{shouldShowAlert ? (
				<Alert
					status={shouldConfirm ? 'warning' : 'info'}
					description={
						shouldConfirm
							? accountChangeConfirmation
							: connectionInfo
					}
					isVisibleCloseBtn={shouldConfirm}
					minW="100%"
					onClick={() => setIsNewConnectionClicked((prev) => !prev)}
				/>
			) : null}
		</>
	);
};

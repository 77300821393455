import { useAuthQuery } from '../apiUtils/queryHelper';
import config from '../config/config';
import { IGetStatusResponse } from '../models/statusModel';

export const useGetStatus = () => {
	return useAuthQuery(
		'status',
		(axiosInstance) => {
			return axiosInstance?.get<IGetStatusResponse>(config.statusUrl);
		},
		{},
		//config.projects.get_projects_list.scope
	);
};

import {
	ITransferConfiguration,
	ITransferConfigurationNode,
	MergeStrategy,
} from '../../../../models/transferConfiguration';
import { IContentTreeNode } from '../../../../models/contentTreeNode';
import { uniq } from 'lodash';

const ROOT_PATH = '/sitecore';
export const isRoot = (node: ITransferConfigurationNode) =>
	node.path === ROOT_PATH;

export const extractParentPaths = (
	node: ITransferConfigurationNode,
): string[] => {
	const splited = node.path.split('/').filter((p) => p !== '');

	return splited;
};

export const findeNodeByPath = (
	path: string,
	node: IContentTreeNode,
): ITransferConfigurationNode[] | [] => {
	const foundNodes: ITransferConfigurationNode[] = [];
	if (node?.children) {
		const found = node?.children.reduce(
			(nodes: ITransferConfigurationNode[], n: IContentTreeNode) => {
				nodes.push(...findeNodeByPath(path, n));

				return nodes;
			},
			[],
		);
		if (found.length) {
			foundNodes.push(...found);
		}
	}
	if (node?.path === path) {
		foundNodes.push({
			id: node.id,
			item: node.item,
			path: node.path,
			type: node.type,
			mergeStrategy: MergeStrategy.OverrideExistingItem,
		} as ITransferConfigurationNode);
	}

	return foundNodes;
};

export const constructParentPaths = (paths: string[]): string[] => {
	return paths.reduce((acc: string[], path: string) => {
		let newPath = `/${path}`;
		if (acc.length) {
			const lastItem = [...acc].pop();
			newPath = `${lastItem}${newPath}`;
		}
		acc.push(newPath);

		return acc;
	}, []);
};

export const getMissedNodes = (node: IContentTreeNode, paths: string[]) => {
	return paths.reduce((acc: ITransferConfigurationNode[], path: string) => {
		const foundNodes = findeNodeByPath(path, node);
		if (foundNodes?.length) {
			acc.push(...foundNodes);
		}

		return acc;
	}, []);
};

export const getMissedPaths = (
	configuration: ITransferConfiguration,
): string[] => {
	return uniq(
		configuration.nodes.reduce(
			(paths: string[], node: ITransferConfigurationNode) => {
				const extractedPaths = extractParentPaths(node);
				if (extractedPaths.length) {
					extractedPaths.pop();
					if (extractedPaths.length) {
						const parents = constructParentPaths(
							extractedPaths,
						).filter((p) => p !== ROOT_PATH);
						if (parents?.length) paths.push(...parents);
					}
				}

				return paths;
			},
			[],
		),
	);
};

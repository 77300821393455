import { t } from '@transifex/native';
import { MenuItem, MenuList, Text } from '@chakra-ui/react';
import { credentials } from '../../../../@xmcloud/core/messages/en';
import {
	AuthenticationClientType,
	GetEnvironmentClientResponse,
} from '../../../models/clientModel';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import { createColumnHelper } from '@tanstack/react-table';
import { RowActions } from '../../../shared-components';

const { label, description, location, type, clientId, createdDate } =
	credentials;

const clientTypeLabel = {
	[AuthenticationClientType.AutomationClient]: t(credentials.automation),
	[AuthenticationClientType.EdgeAdministrationClient]: t(credentials.edge),
	[AuthenticationClientType.OrganizationAutomationClient]: t(
		credentials.orgAutomation,
	),
};
const columnHelper = createColumnHelper<GetEnvironmentClientResponse>();

export const environmentCredentialsTableColumns = (
	setSelectedClient: (e: GetEnvironmentClientResponse) => void,
	setShowClientRevokeModal: (v: boolean) => void,
) => {
	return [
		columnHelper.accessor('name', {
			header: () => t(label),
			cell: (info) => (
				<Text
					noOfLines={3}
					maxW={['44vw', '44vw', '12vw']}
					whiteSpace="wrap"
				>
					{info.getValue()}
				</Text>
			),
		}),
		columnHelper.accessor('description', {
			header: () => t(description),
			cell: (info) => (
				<Text
					noOfLines={[1, 3]}
					maxW={['44vw', '44vw', '12vw']}
					whiteSpace="wrap"
				>
					{info.getValue()}
				</Text>
			),
		}),
		columnHelper.accessor('projectName', {
			header: () => t(location),
			cell: ({
				row: {
					original: { projectName, environmentName },
				},
			}) => (
				<Text noOfLines={[1, 3]} maxW={['44vw', '44vw', '8vw']}>
					{`${projectName}/${environmentName}`}
				</Text>
			),
		}),
		columnHelper.accessor('clientType', {
			header: () => t(type),
			cell: (info) => clientTypeLabel[info.getValue()],
		}),
		columnHelper.accessor('clientId', {
			header: () => t(clientId),
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor('createdAt', {
			header: () => t(createdDate),
			cell: (info) => getReadableDate(info.getValue()),
		}),
		columnHelper.display({
			id: 'actions',
			cell: ({ row: { original } }) => {
				return (
					<RowActions ariaLabel="credentials-environment-table-action-menu">
						<MenuList>
							<MenuItem
								onClick={() => {
									setSelectedClient(original);
									setShowClientRevokeModal(true);
								}}
							>
								{t(credentials.revoke)}
							</MenuItem>
						</MenuList>
					</RowActions>
				);
			},
			maxSize: 0,
		}),
	];
};

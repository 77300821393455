export const SystemStatusMock = {
	status: 'Healthy',
	totalDuration: '00:00:00.1393910',
	entries: {
		'Deploy API': {
			data: {},
			description: 'Responsible for the API that powers Deploy',
			duration: '00:00:00.0000445',
			status: 'Healthy',
			tags: ['components'],
		},
		Builds: {
			data: {},
			description:
				'Responsible for building source code during the build stage of a deploy. When degraded, builds will be queued.',
			duration: '00:00:00.0024049',
			status: 'Healthy',
			tags: ['components'],
		},
		Configuration: {
			data: {},
			description:
				'Responsible for storing environment configuration information. When degraded, creating environments or deployments will not be possible.',
			duration: '00:00:00.0301767',
			status: 'Healthy',
			tags: ['components'],
		},
		Deployments: {
			data: {},
			description:
				'Responsible for deploying built artifacts to hosting infrastructure during the deployment stage of a deploy.  When degraded, deployments will be queued.',
			duration: '00:00:00.0039121',
			status: 'Healthy',
			tags: ['components'],
		},
		Inventory: {
			data: {},
			description:
				'Responsible for the status and registration of environments.  When degraded, creating environments or deployments will not be possible.',
			duration: '00:00:00.0274656',
			status: 'Healthy',
			tags: ['components'],
		},
		Monitoring: {
			data: {},
			description:
				'Responsible for displaying live log information to clients to report the status of deploy stages.  When degraded, logging will not work.',
			duration: '00:00:00.0031652',
			status: 'Healthy',
			tags: ['components'],
		},
		Organizations: {
			data: {},
			description: 'Responsible for provisioning of organizations.',
			duration: '00:00:00.0031115',
			status: 'Healthy',
			tags: ['components'],
		},
		Pages: {
			data: {},
			description: 'Authoring application for XM Cloud.',
			duration: '00:00:00.0183381',
			status: 'Degraded',
			tags: ['components'],
		},
		Provisioning: {
			data: {},
			description:
				'Responsible for creating hosting infrastructure during the provisioning stage of a deploy.  When degraded, provisioning will be queued.',
			duration: '00:00:00.0024533',
			status: 'Healthy',
			tags: ['components'],
		},
		'Container Registry': {
			data: {},
			description:
				'Provides the ability to store built artifacts.  If component is unhealthy, deployments will be unavailable.',
			duration: '00:00:00.0064554',
			status: 'Healthy',
			tags: ['components'],
		},
		GitHub: {
			data: {
				'API Requests': {
					description: 'Requests for GitHub APIs',
					status: 'operational',
				},
			},
			description:
				'Provides the ability to deploy using a GitHub template.  If status is degraded, deployments will be unavailable.',
			duration: '00:00:00.0058963',
			status: 'Healthy',
			tags: ['components'],
		},
		'Azure DevOps': {
			data: {
				API: {
					status: 'healthy',
					description: 'Everything is looking good',
				},
			},
			description:
				'Provides the ability to deploy using a Azure DevOps source control. If status is degraded, deployments will be unavailable.',
			duration: '00:00:00.0059793',
			status: 'Healthy',
			tags: ['components'],
		},
		Cloudflare: {
			data: {
				API: {
					description:
						'Application programming interface (API) for customers to read and write their configurations at https://api.cloudflare.com',
					status: 'operational',
				},
			},
			description:
				'Provides the ability to use CloudFlare API.  If degraded, environment creation is not possible',
			duration: '00:00:00.0178806',
			status: 'Healthy',
			tags: ['components'],
		},
	},
};

const {
	entries: {
		Builds,
		Configuration,
		Deployments,
		Inventory,
		GitHub,
		'Azure DevOps': AzureDevOps,
		'Deploy API': DeployAPI,
	},
	totalDuration,
} = SystemStatusMock;

export const systemStatusMockErrRes = {
	status: 'Degraded',
	totalDuration,
	entries: {
		...SystemStatusMock.entries,
		Builds: {
			...Builds,
			status: 'Degraded',
		},
		Deployments: {
			...Deployments,
			status: 'Degraded',
		},
		Configuration: {
			...Configuration,
			status: 'Degraded',
		},
		Inventory: {
			...Inventory,
			status: 'Degraded',
		},
		GitHub: {
			...GitHub,
			status: 'Degraded',
		},
		'Azure DevOps': {
			...AzureDevOps,
			status: 'Degraded',
		},
		'Deploy API': {
			...DeployAPI,
			status: 'Degraded',
		},
	},
};

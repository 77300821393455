import { FC, useState } from 'react';
import { Skeleton, Stack } from '@chakra-ui/react';
import { deployments } from '../../../../@xmcloud/core/messages/en';
import {
	Alert,
	ModalMainContent,
	ModalWrapper,
} from '../../../shared-components';
import { useGetProjectEnvironmentsList } from '../../../services/projects';
import EnvironmentCard from './EnvironmentCard';
import { IGetEnvironmentResponse } from '../../../models/environmentModel';
import { usePromoteDeploymentMutation } from '../../../services/environments';
import { useDeploymentDeployMutation } from '../../../services/deployments';
import { handlePush } from '../../../../@xmcloud/utils/helpers';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
const { buildToOtherEnvironmentTitle, deploy, selectEnvironment } = deployments;

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
	deploymentId: string;
	environmentId: string;
	projectId: string;
}

const DeployBuildOtherEnv: FC<Props> = ({
	openModal,
	setOpenModal,
	deploymentId,
	environmentId,
	projectId,
}) => {
	const [selectedEnvironment, setSelectedEnvironment] = useState(
		{} as IGetEnvironmentResponse,
	);

	const [showError, setShowError] = useState(false);

	const { data: environmentsData, isLoading: environmentsIsLoading } =
		useGetProjectEnvironmentsList({ projectId });
	const environments = environmentsData?.data || [];

	const { mutate: promoteDeployment, isLoading: promoteDeploymentIsLoading } =
		usePromoteDeploymentMutation();

	const { mutate: deployDeployment, isLoading: deployDeploymentIsLoading } =
		useDeploymentDeployMutation(environmentId);
	const loading =
		environmentsIsLoading ||
		promoteDeploymentIsLoading ||
		deployDeploymentIsLoading;

	function handlePromote() {
		if (!selectedEnvironment?.id) return setShowError(true);

		setShowError(false);
		if (!deploymentId) return;

		promoteDeployment(
			{ environmentId: selectedEnvironment.id, deploymentId },
			{
				onSuccess: ({ data }) => {
					deployDeployment(
						{ deploymentId: data.id },
						{
							onSuccess: () => {
								setOpenModal(false);
								handlePush(
									goTo.deploymentLogs(
										data.projectId,
										data.environmentId,
										data.id,
									),
								);
							},
						},
					);
				},
			},
		);
	}

	const handleIsDisabled = environments.filter((d) => !d.repositoryBranch);
	const isDisabled = Boolean(handleIsDisabled.length);

	return (
		<ModalWrapper
			isOpen={openModal}
			title={buildToOtherEnvironmentTitle}
			onClose={() => setOpenModal(false)}
			dataTestId="deploy-build-modal"
		>
			<ModalMainContent
				onClose={() => setOpenModal(false)}
				rightButtonText={deploy}
				isLoading={loading}
				onConfirm={handlePromote}
				isDisabled={!isDisabled}
			>
				<Stack direction="column" spacing={10}>
					{environmentsIsLoading ? (
						<Skeleton h="16" />
					) : (
						environments.map((environment) => (
							<EnvironmentCard
								{...{
									environment,
									selectedEnvironment,
									setSelectedEnvironment,
									setShowError,
								}}
								key={environment.id}
							/>
						))
					)}
					{showError && (
						<Alert
							status="error"
							description={selectEnvironment}
							isVisibleCloseBtn={false}
							minW="100%"
						/>
					)}
				</Stack>
			</ModalMainContent>
		</ModalWrapper>
	);
};

export default DeployBuildOtherEnv;

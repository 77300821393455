import { FC } from 'react';
import { useToast } from '@chakra-ui/react';
import { app, credentials } from '../../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';
import { AlertDialogConfirmationModal, Text } from '../../../shared-components';
import { useDeleteClientMutation } from '../../../services/clients';
import {
	AbstractGetClientResponse,
	AuthenticationClientType,
} from '../../../models/clientModel';
import config from '../../../config/config';

interface Props {
	client: AbstractGetClientResponse;
	setOpenModal: (show: boolean) => void;
	openModal: boolean;
}

const { revokeSuccess, revoke, revokeConfirmation } = credentials;
const { get_organization_list, get_environment_list } = config.clients;
const { OrganizationAutomationClient } = AuthenticationClientType;

const RevokeClientConfirmation: FC<Props> = ({
	openModal,
	setOpenModal,
	client,
}) => {
	const toast = useToast();
	const { mutate: revokeClient, isLoading } = useDeleteClientMutation(
		client.clientType === OrganizationAutomationClient
			? get_organization_list.queryKey
			: get_environment_list.queryKey,
	);

	function handleSubmit() {
		revokeClient({
			id: client.id,
			onSuccess: () => {
				toast({
					status: 'success',
					description: t(revokeSuccess),
				});

				setOpenModal(false);
			},
		});
	}

	return (
		<AlertDialogConfirmationModal
			isOpen={openModal}
			title={revoke}
			onClose={() => setOpenModal(false)}
			dataTestId="client-revoke-confirmation-modal"
			isLoading={isLoading}
			onConfirm={handleSubmit}
			leftButtonText={app.revoke}
		>
			<Text text={revokeConfirmation} />
		</AlertDialogConfirmationModal>
	);
};

export default RevokeClientConfirmation;

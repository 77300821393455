import { MenuItem, Stack } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { FC, useState } from 'react';
import { credentials } from '../../../@xmcloud/core/messages/en';
import { useSharedState } from '../../../@xmcloud/hooks';
import { IS_SM_SCREEN } from '../../../@xmcloud/hooks/context/useSharedState';
import { Footer, XMCSButton, XMCSMenuButton } from '../../shared-components';
import CreateCredentials from './components/CreateCredentials';
import EnvironmentCredentialsTable from './components/EnvironmentCredentialsTable';
import CreateCMAutomationClient from './CreateCMAutomationClient';
import CreateEdgeAdministrationClient from './CreateEdgeAdministrationClient';

export const EnvironmentCredentials: FC = () => {
	const [openAutomationModal, setOpenAutomationModal] = useState(false);
	const [openEdgeModal, setOpenEdgeModal] = useState(false);
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);
	const [createCredentialsModal, setCreateCredentialsModal] = useState(false);

	return (
		<>
			<Stack
				direction="row"
				justify="flex-end"
				mt={6}
				mb={isSmScreen ? 0 : 5}
			>
				{!isSmScreen && (
					<XMCSMenuButton
						text={credentials.create}
						data-testid="menu-button"
					>
						<MenuItem
							onClick={() => setOpenEdgeModal(true)}
							data-testid="menu-item-edge"
						>
							{t(credentials.edge)}
						</MenuItem>
						<MenuItem
							onClick={() => setOpenAutomationModal(true)}
							data-testid="menu-item-automation"
						>
							{t(credentials.automation)}
						</MenuItem>
					</XMCSMenuButton>
				)}
			</Stack>

			<EnvironmentCredentialsTable />
			{Boolean(isSmScreen) && (
				<Footer>
					<XMCSButton
						text={credentials.create}
						data-testid="create-credentials-button"
						onClick={() => setCreateCredentialsModal(true)}
					/>
				</Footer>
			)}
			{openAutomationModal && (
				<CreateCMAutomationClient
					openModal={openAutomationModal}
					setOpenModal={setOpenAutomationModal}
				/>
			)}
			{openEdgeModal && (
				<CreateEdgeAdministrationClient
					openModal={openEdgeModal}
					setOpenModal={setOpenEdgeModal}
				/>
			)}
			{createCredentialsModal && (
				<CreateCredentials
					{...{
						isOpen: createCredentialsModal,
						setIsOpenOpen: setCreateCredentialsModal,
						setOpenEdgeModal,
						setOpenAutomationModal,
					}}
				/>
			)}
		</>
	);
};

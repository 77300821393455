import { Box, BoxProps } from '@chakra-ui/react';

export type BoxFrameProps = BoxProps & {
	children: React.ReactNode;
};

export const BoxFrame: React.FC<BoxFrameProps> = ({
	px = ['4', '6', '10', '10'],
	pt = ['6', '6', '8', '8'],
	pb = ['4', '4', '6', '6'],
	title,
	children,
	...rest
}) => (
	<Box
		borderRadius="lg"
		shadow="sm"
		border="1px solid"
		borderColor="chakra-border-color"
		px={px}
		pt={pt}
		pb={pb}
		data-testid="card-box"
		w="100%"
		maxW="3xl"
		{...rest}
	>
		{children}
	</Box>
);

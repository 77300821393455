import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { setupApm } from './app/apm/setupApm';

const container = document.getElementById('root');
const root = createRoot(container!);

setupApm();

const renderApp = () => {
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
	);
};

if (process.env.REACT_APP_MOCK === 'enabled') {
	const { worker } = require('./mocks/browser');
	worker.start().then(() => renderApp());
} else {
	renderApp();
}

import { IGetEnvironmentResponseV2 } from '../../models/environmentModel';
import { EnvironmentFields } from './EnvironmentFields';
import { CreateEditEnvironmentProvider } from './hooks/CreateEditEnvContext';
import { MainModal } from './MainModal';

export const Main: React.FC<{
	isOpen: boolean;
	onClose: () => void;
	isCreate: boolean;
	isCm: boolean;
	environment?: IGetEnvironmentResponseV2;
}> = ({ isOpen, onClose, isCreate, isCm, environment }) => {
	return (
		<CreateEditEnvironmentProvider
			{...{ isCm, isCreate, environment, onClose }}
		>
			<MainModal {...{ isOpen, onClose }}>
				<EnvironmentFields />
			</MainModal>
		</CreateEditEnvironmentProvider>
	);
};

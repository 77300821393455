import {
	mdiFolderOutline,
	mdiFileOutline,
	mdiSourceBranch,
	mdiFileTree,
} from '@mdi/js';
import {
	ITransferConfigurationNode,
	TransferConfigurationNodeType,
} from '../../models/transferConfiguration';
import { Icon } from '..';

/**
 * Returns svg based icon description depends on transfer configuration node type
 */
export const createIcon = (node: ITransferConfigurationNode): JSX.Element => {
	let iconPath: string;
	switch (node.type) {
		case TransferConfigurationNodeType.Folder:
			iconPath = mdiFolderOutline;
			break;
		case TransferConfigurationNodeType.File:
			iconPath = mdiFileOutline;
			break;
		case TransferConfigurationNodeType.Branch:
			iconPath = mdiSourceBranch;
			break;
		case TransferConfigurationNodeType.Tree:
			iconPath = mdiFileTree;
			break;
		default:
			iconPath = mdiFolderOutline;
			break;
	}

	return <Icon path={iconPath} key={`icon-${node.id}`} boxSize={5} />;
};

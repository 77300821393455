import { FC } from 'react';
import { Text, useToast } from '@chakra-ui/react';
import { environmentVariable } from '../../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';
import { AlertDialogConfirmationModal } from '../../../shared-components';
import { useDeleteEnvironmentVariableMutation } from '../../../services/environments';
import config from '../../../config/config';
import { useQueryClient } from 'react-query';

interface Props {
	variableName: string;
	environmentId: string;
	target: string | undefined;
	setOpenModal: (show: boolean) => void;
	openModal: boolean;
}

const DeleteVariableConfirmation: FC<Props> = ({
	openModal,
	setOpenModal,
	variableName,
	environmentId,
	target,
}) => {
	const toast = useToast();
	const queryClient = useQueryClient();
	const { mutate: deleteVariable, isLoading } =
		useDeleteEnvironmentVariableMutation();

	function handleSubmit() {
		deleteVariable(
			{
				environmentId: environmentId,
				variableName: variableName,
				target: Boolean(target)
					? target
					: environmentVariable.targetAll,
			},
			{
				onSuccess: (data, variables) => {
					queryClient.invalidateQueries(
						config.environments.environment_variables.queryKey(
							variables.environmentId,
						),
					);
					toast({
						status: 'success',
						description: t(environmentVariable.deletedSuccessfully),
					});
					setOpenModal(false);
				},
			},
		);
	}

	return (
		<AlertDialogConfirmationModal
			isOpen={openModal}
			title={environmentVariable.delete}
			onClose={() => setOpenModal(false)}
			dataTestId="variable-delete-confirmation-modal"
			isLoading={isLoading}
			onConfirm={handleSubmit}
		>
			<Text>{t(environmentVariable.deleteConfirmation)}</Text>
		</AlertDialogConfirmationModal>
	);
};

export default DeleteVariableConfirmation;

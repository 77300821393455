import { useEffect } from 'react';
import { useEditProject } from '../../../@xmcloud/hooks';
import { EnumInNumberSourceControl } from '../../../@xmcloud/types';
import { convertProviderEnum } from '../../../@xmcloud/utils/helpers';
import { useGetRepositoryIntegrations } from '../../services/repositories';
import { SelectFormControl } from '../../shared-components';
import {
	ACCOUNT,
	ADO_ORGANIZATION,
	ADO_PROJECT_NAME,
	REPOSITORY,
	REPOSITORY_ID,
	SOURCE_CONTROL_INTEGRATION_ID,
} from '../create-project/helpers';

const { GitHub } = EnumInNumberSourceControl;

const CONNECTION_CREATED_ANOTHER_ORG =
	'The source control connection associated with this project was created in another organization.';

const title = (e: boolean, p: number) =>
	`${e ? 'Choose ' : ''}${p === GitHub ? 'GitHub' : 'Azure DevOps'} account`;

export const SelectAccount: React.FC = () => {
	const {
		errors,
		values,
		touched,
		editValues,
		prevValues,
		setValues,
		setEditValues,
		setFieldError,
		setPrevValues,
		setFieldTouched,
		integrationIdValidationMsg,
		isIntegrationValidationFetching,
	} = useEditProject();

	const { editAccount } = editValues;
	const { provider, account, hasAccount } = values;

	const enableAccountField = hasAccount && !editAccount;
	const shouldCallIntegrationsApi = !hasAccount || editAccount;

	const { data, isLoading: isIntegrationsLoading } =
		useGetRepositoryIntegrations(shouldCallIntegrationsApi);

	const repositoryIntegrations = data?.data.filter(
		(i) =>
			i.provider.toString().toLowerCase() ===
			convertProviderEnum(provider, 'string'),
	);

	const integrationsOptions =
		repositoryIntegrations?.map((integration) => ({
			label: integration.account,
			value: integration.id,
		})) || [];

	const isOtherOrg = integrationIdValidationMsg.includes(
		CONNECTION_CREATED_ANOTHER_ORG,
	);

	const accountErrorMsg = isOtherOrg ? '' : integrationIdValidationMsg;

	useEffect(() => {
		if (isOtherOrg) return;
		setFieldError(SOURCE_CONTROL_INTEGRATION_ID, accountErrorMsg);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountErrorMsg]);

	useEffect(() => {
		if (prevValues.sourceControlIntegrationId) return;

		setPrevValues((prev) => ({
			...prev,
			[SOURCE_CONTROL_INTEGRATION_ID]: `${values.sourceControlIntegrationId}`,
		}));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [prevValues.sourceControlIntegrationId]);

	const accountFieldTitle = title(!enableAccountField, provider);
	const isLoading = isIntegrationsLoading || isIntegrationValidationFetching;

	return (
		<SelectFormControl
			{...{
				isLoading: isLoading,
				options: integrationsOptions,
				showAsPlaceHolder: enableAccountField,
				isEditable: true,
				onChange: (e: any) => {
					if (values.sourceControlIntegrationId === e.value) return;
					setValues((prev) => ({
						...prev,
						[ACCOUNT]: e.label,
						[SOURCE_CONTROL_INTEGRATION_ID]: e.value,
						[REPOSITORY]: '',
						[REPOSITORY_ID]: '',
						[ADO_ORGANIZATION]: '',
						[ADO_PROJECT_NAME]: '',
					}));
				},
				label: accountFieldTitle,
				name: SOURCE_CONTROL_INTEGRATION_ID,
				currentValue: enableAccountField
					? account
					: values.sourceControlIntegrationId,
				error: errors.sourceControlIntegrationId || accountErrorMsg,
				isInvalid: Boolean(
					(errors.sourceControlIntegrationId &&
						touched.sourceControlIntegrationId) ||
						accountErrorMsg,
				),
				onFocus: () =>
					setFieldTouched(SOURCE_CONTROL_INTEGRATION_ID, true),
				onEditClick: () => {
					setEditValues((prev) => ({
						...prev,
						editAccount: true,
						editRepository: true,
						editOrganization: true,
						editProject: true,
					}));
				},
			}}
		/>
	);
};

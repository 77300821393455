import { FC, useState } from 'react';
import { environment as envLabels } from '../../../../@xmcloud/core/messages/en';
import { useParams } from 'react-router-dom';
import { useGetPreviewApiToken } from '../../../services/environments';
import { ToggledTokenButton } from './ToggledTokenButton';

const {
	edgePreviewEndpoint,
	generatePreviewTokenTooltip,
	generatePreviewToken,
} = envLabels;

export const ApiTokenComponent: FC<{ host: string }> = ({ host }) => {
	const { environmentId } = useParams<{ environmentId: string }>();

	const { refetch, isFetching } = useGetPreviewApiToken({
		environmentId: environmentId!,
		enabled: false,
	});
	const [token, setToken] = useState('');
	const edgePreviewGraphQLEndpoint = `https://${host}/sitecore/api/graph/edge/ide/`;

	return (
		<ToggledTokenButton
			{...{
				title: edgePreviewEndpoint,
				tooltip: generatePreviewTokenTooltip,
				isLoading: isFetching,
				btnText: generatePreviewToken,
				onClick: () => {
					refetch().then((response) => {
						setToken(response?.data?.data.data.item.itemId || '');
					});
				},
				href: edgePreviewGraphQLEndpoint,
				token,
			}}
		/>
	);
};

import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { LayoutAsideProps } from './model';

export const LayoutLeft: React.FC<LayoutAsideProps> = ({
	children,
	inset,
	background,
	...rest
}) => {
	return (
		<Box
			as="aside"
			w={320}
			p={6}
			order={1}
			overflow="auto"
			layerStyle={inset ? 'section.sidebar' : undefined}
			background={inset ? undefined : background}
			zIndex={2}
			{...rest}
		>
			{children}
		</Box>
	);
};

/**


/**

 * Sitecore UI LayoutLeft components display names

 */

LayoutLeft.displayName = 'LayoutLeft';

import useLocalStorage from 'use-local-storage';

//localStorage keys

const CONTENT_TRANSFER_EXPERIMENTAL_FEATURE =
	'ContentTransferExperimentalFeature';
const CREATE_PROJECT_V3_EXPERIMENTAL_FEATURE =
	'CreateProjectV3ExperimentalFeature';

export function useExperimentalFeatures() {
	const [
		createProjectV3ExperimentalFeature,
		setCreateProjectV3ExperimentalFeature,
	] = useLocalStorage<boolean>(CREATE_PROJECT_V3_EXPERIMENTAL_FEATURE, false);
	const [contentTransferExpFeat, setContentTransferExpFeature] =
		useLocalStorage<boolean>(CONTENT_TRANSFER_EXPERIMENTAL_FEATURE, false);
	return {
		createProjectV3ExperimentalFeature,
		setCreateProjectV3ExperimentalFeature,
		contentTransferExpFeat,
		setContentTransferExpFeature,
	};
}

import { IContentTreeNode } from '../app/models/contentTreeNode';

export const TransferContentMock: IContentTreeNode = {
	id: 'sitecore-id',
	item: 'sitecore',
	path: '/sitecore',
	type: 4,
	children: [
		{
			id: 'sitecore-content-id',
			item: 'Content',
			path: '/sitecore/Content',
			type: 4,
			children: [
				{
					id: 'sitecore-content-home-id',
					item: 'Home',
					path: '/sitecore/Content/Home',
					type: 2,
					children: [],
				},
			],
		},
		{
			id: 'sitecore-layout-id',
			item: 'Layout',
			path: '/sitecore/Layout',
			type: 4,
			children: [
				{
					id: 'sitecore-layout-controllers-id',
					item: 'Controllers',
					path: '/sitecore/Layout/Controllers',
					type: 2,
					children: [],
				},
				{
					id: 'sitecore-layout-default-id',
					item: 'Default',
					path: '/sitecore/Layout/Default',
					type: 2,
					children: [
						{
							id: 'sitecore-layout-default-controllers-id',
							item: 'Default-Controllers',
							path: '/sitecore/Layout/Default/Controllers',
							type: 4,
							children: [
								{
									id: 'mock-transfer-layout-controller-id-internal',
									item: 'Internal',
									path: '/sitecore/Layout/Default/Controllers/Internal',
									type: 2,
									children: [],
								},
								{
									id: 'mock-transfer-layout-controller-id-public-internal',
									item: 'Public-Internal',
									path: '/sitecore/Layout/Default/Controllers/Public-Internal',
									type: 2,
									children: [],
								},
							],
						},
					],
				},
			],
		},
	],
};

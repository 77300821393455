import { projects } from '../../../../@xmcloud/core/messages/en';
import { useDeleteProjectMutation } from '../../../services/projects';
import { IGetProjectResponse } from '../../../models/projectModel';
import DeleteForm from './DeleteForm';

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
	projectDetails: Pick<IGetProjectResponse, 'id' | 'name'>;
}

const DeleteProject: React.FC<Props> = ({
	openModal,
	setOpenModal,
	projectDetails,
}) => {
	const { mutate, isLoading } = useDeleteProjectMutation({
		id: projectDetails.id,
	});

	const handleSubmit = () => {
		mutate(undefined, {
			onSuccess: () => {
				setOpenModal(false);
			},
		});
	};

	return (
		<DeleteForm
			openModal={openModal}
			title={projects.project.toLocaleLowerCase()}
			onClose={() => setOpenModal(false)}
			dataTestId="delete-project-form"
			handleSubmit={handleSubmit}
			isLoading={isLoading}
			checkPhrase={projectDetails.name}
			formFor="project"
		/>
	);
};

export default DeleteProject;

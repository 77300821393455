import { CircularProgress, Stack, StackProps, Text } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { Icon } from '..';

type Props = {
	IconName?: string;
	value?: number;
	text: string;
	iconColor?: string;
	isIndeterminate?: boolean;
	textColor?: string;
	iconSize?: number;
	xsText?: string;
	lineHeight?: number;
	fontSize?: 'xs' | 'sm' | 'md' | 'lg';
	noOfLines?: number;
} & StackProps;

export const IconText: React.FC<Props> = ({
	IconName,
	text,
	iconColor = 'neutral',
	isIndeterminate = false,
	textColor = 'chakra-body-text',
	iconSize = 6,
	xsText = '',
	fontSize = 'md',
	lineHeight,
	noOfLines = 1,
	...rest
}) => (
	<Stack direction="row" {...rest} alignItems="center" gap="1">
		{isIndeterminate ? (
			<CircularProgress
				{...{
					...(isIndeterminate && { isIndeterminate }),
				}}
				size={5}
				color="primary"
			/>
		) : (
			<Icon path={IconName} boxSize={iconSize} color={iconColor} />
		)}
		<Text
			color={textColor}
			textTransform="none"
			fontSize={fontSize}
			noOfLines={noOfLines}
			{...(xsText && {
				_after: {
					content: `"${xsText}"`,
					color: 'chakra-subtle-text',
					fontSize: 'xs',
					fontWeight: 'normal',
					whiteSpace: 'wrap',
					lineHeight: 'shorter',
				},
				...(lineHeight && { lineHeight }),
			})}
		>
			{t(text)}
		</Text>
	</Stack>
);

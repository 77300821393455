import { mdiOpenInNew } from '@mdi/js';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { HoverableCard, Icon, IconText } from '..';

interface Props {
	currentSelection: string;
	setCurrentSelection: (a: string) => void;
	items: { name: string; iconName: string }[];
	showExternalLinkIcon?: boolean;
}

export const CardBoxList: React.FC<Props> = ({
	setCurrentSelection,
	items,
	currentSelection,
	showExternalLinkIcon = true,
}) => (
	<Stack direction="column" spacing={5}>
		{items.map((c) => (
			<HoverableCard
				data-testid={`cardbox-${c.name}`}
				key={c.name}
				onClick={() => setCurrentSelection(c.name)}
				isActive={Boolean(currentSelection === c.name)}
			>
				<HStack
					justify="center"
					justifyContent={
						showExternalLinkIcon ? 'space-between' : 'flex-start'
					}
					h="12"
					px={2}
				>
					{!Boolean(c.iconName) && <Text>{c.name}</Text>}
					{Boolean(c.iconName) && (
						<IconText
							IconName={c.iconName}
							text={c.name}
							iconColor="neutral"
						/>
					)}
					{showExternalLinkIcon && (
						<Icon
							path={mdiOpenInNew}
							boxSize={5}
							color="chakra-placeholder-color"
						/>
					)}
				</HStack>
			</HoverableCard>
		))}
	</Stack>
);

import { app, credentials } from '../../../@xmcloud/core/messages/en';
import { XMCSH3, XMCSTabs } from '../../shared-components';
import { Text } from '@chakra-ui/react';
import { credentialsPaths } from '../../../@xmcloud/core/routes/paths';
import { Outlet, useLocation } from 'react-router-dom';
import { credentialsTabItems } from '../../shared-components/tab/items';

export const CredentialsPage: React.FC = () => {
	const { pathname } = useLocation();
	const isEnvironmentTabActive = pathname.includes(
		credentialsPaths.environment,
	);
	const tabIndex = isEnvironmentTabActive ? 1 : 0;

	return (
		<>
			<XMCSH3 title={app.credentials} />
			<Text variant="subtle" my={4}>
				{credentials.descriptionText}
			</Text>
			<XMCSTabs
				tabIndex={tabIndex}
				tabItems={credentialsTabItems}
				state={{}}
				args={[]}
			/>
			<Outlet />
		</>
	);
};

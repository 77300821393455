import { useMemo, useRef } from 'react';
import { useGetSourceControlAccessToken } from '../../../services/accessToken';
import { TOptions } from '../helpers';
import { ESourceControl } from '../../../../@xmcloud/types';
import { useGetPaginatedGithubRepositories } from '../../../services/githubApi';
import { OLDSelectRepositories } from './OLDSelectRepositories.tsx';
import { useCreateProject } from '../hooks';

const { GitHub } = ESourceControl;

export const OLDSelectGithubRepositories: React.FC<{
	isIntegrationIdValidationLoading: boolean;
}> = ({ isIntegrationIdValidationLoading }) => {
	const inputQuery = useRef('');
	const { values } = useCreateProject();

	const isGithubProvider = values.provider === GitHub;
	const shouldCallApi =
		Boolean(values.sourceControlIntegrationId) &&
		!isIntegrationIdValidationLoading;

	const { data: accessTokenData, isLoading: isLoadingGithubToken } =
		useGetSourceControlAccessToken(values.sourceControlIntegrationId);

	const accessToken = accessTokenData?.data?.accessToken || '';

	const {
		data,
		isLoading: isLoadingGithubRepositories,
		isFetching,
		fetchNextPage,
		hasNextPage,
	} = useGetPaginatedGithubRepositories(
		accessToken,
		values.sourceControlIntegrationId,
		shouldCallApi,
		onSuccess,
	);

	const repositoryOptions: TOptions = useMemo(
		() =>
			data?.map((r: any) => ({
				label: r.name,
				value: r.name,
				id: r.id,
			})) || [],
		[data],
	);

	function onSuccess(lastPage: any) {
		const query = inputQuery.current;

		if (
			query === '' ||
			!hasNextPage ||
			!isGithubProvider ||
			!lastPage.length
		)
			return;

		const hasRepo = lastPage.some((r: any) => r.name.includes(query));

		if (!hasRepo) {
			fetchNextPage();
		}
	}

	const isLoading =
		isIntegrationIdValidationLoading ||
		isLoadingGithubToken ||
		isLoadingGithubRepositories;

	return (
		<OLDSelectRepositories
			{...{
				repositoryOptions,
				isLoading,
				accessToken,
				isFetching,
				fetchNextPage,
				hasNextPage,
				onSuccess,
				inputQuery,
			}}
		/>
	);
};

import { FC, useState } from 'react';
import { useGetHostingInstallationsList } from '../../../services/hosting';
import { pageSize } from '../../../../@xmcloud/utils/constants';
import {
	LoadingSkeleton,
	RenderPlaceholder,
	Table,
} from '../../../shared-components';
import { app } from '../../../../@xmcloud/core/messages/en';
import { IGetHostingInstallation } from '../../../models/hostingModel';
import { useHostingConnectionsTableColumns } from '../columns/hosting';
import DeleteHostingIntegration from './DeleteHostingIntegration';
import { ColumnDef } from '@tanstack/react-table';

const headers = [
	'name',
	'Provider',
	'Connection ID',
	'Created by',
	'Date created',
	'',
];

const HostingIntegrationsTable: FC = () => {
	const [showConnectionDeleteModal, setShowConnectionDeleteModal] =
		useState(false);
	const [selectedInstallation, setSelectedInstallation] = useState('');
	const { data, isLoading } = useGetHostingInstallationsList({
		enabled: true,
	});
	const hostingIntegrations = data?.data || [];

	const columns: ColumnDef<IGetHostingInstallation, any>[] =
		useHostingConnectionsTableColumns(
			setSelectedInstallation,
			setShowConnectionDeleteModal,
		);

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;
	if (hostingIntegrations.length === 0)
		return <RenderPlaceholder item={app.connections.toLocaleLowerCase()} />;

	return (
		<>
			<Table
				{...{
					columns,
					tableData: hostingIntegrations,
					dataTestId: 'hosting-connection-table',
					showPagination: hostingIntegrations.length > pageSize,
					initialSort: [{ id: 'createdAt', desc: true }],
					headers,
				}}
			/>
			{showConnectionDeleteModal && (
				<DeleteHostingIntegration
					installation={selectedInstallation}
					openModal={showConnectionDeleteModal}
					setOpenModal={setShowConnectionDeleteModal}
				/>
			)}
		</>
	);
};

export default HostingIntegrationsTable;

import {
	firstStep,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import {
	AlertStatus,
	HeadingLg,
	InputFormControl,
} from '../../../shared-components';
import { useComponentHealthy } from '../../../../@xmcloud/hooks';
import { FooterBtnGroups, SetupTemplate } from '../components';
import { Divider, Stack } from '@chakra-ui/react';
import { useGetValidateProjectName } from '../../../services/projects';
import { PROJECT_NAME } from '../helpers';
import { useGetRepositoryTemplates } from '../../../services/repositories';
import { useCreateProject } from '../hooks';

const { errMsg4, errMsg3, errMsg2 } = systemHealthStatus;
const { setupProject: titletxt, title: nameFieldtxt } = firstStep;

export const SetupProject: React.FC = () => {
	const {
		isBlockersHealthy,
		isLoading: isComponentHealthLoading,
		getUnhealthyBlockerList,
	} = useComponentHealthy({});
	const {
		values,
		errors,
		handleChange,
		next,
		setFieldError,
		setFieldTouched,
		touched,
	} = useCreateProject();

	const showErrorAlert = !isBlockersHealthy && !isComponentHealthLoading;
	const unhealthyBlockerList = showErrorAlert
		? getUnhealthyBlockerList()
		: [];
	const hasPluralErrors = unhealthyBlockerList.length > 1;

	const { refetch, isFetching } = useGetValidateProjectName(values.name);
	const {
		data: repositoryTemplatesData,
		isLoading: isLoadingRepositoryTemplates,
	} = useGetRepositoryTemplates('github');

	const templates =
		repositoryTemplatesData?.data.map((i) => ({
			label: i.title,
			value: i.name,
			owner: i.owner,
		})) || [];

	async function handleNext() {
		if (!values.name || errors.name) {
			setFieldTouched(PROJECT_NAME, true);
			return;
		}

		const { data } = await refetch();
		const isValid = data?.data.isValid;
		const message = data?.data.message as string;
		if (!isValid) {
			setFieldError(PROJECT_NAME, message);
			return;
		}
		next();
	}

	const isLoading = isFetching || isComponentHealthLoading;

	return (
		<Stack gap="6" pb="6">
			<HeadingLg text={titletxt} />
			<InputFormControl
				{...{
					isInvalid: Boolean(errors.name && touched.name),
					label: nameFieldtxt,
					name: PROJECT_NAME,
					value: values.name,
					onChange: handleChange,
					error: errors.name,
					onFocus: () => setFieldTouched(PROJECT_NAME, true),
					isRequired: true,
				}}
			/>
			<Divider />
			<SetupTemplate
				{...{
					templates,
					isLoading: isLoadingRepositoryTemplates,
				}}
			/>
			<Divider />
			<FooterBtnGroups
				{...{
					onNextClick: handleNext,
					onPreviousClick: () => {},
					isLoading,
					isDisabled: showErrorAlert,
					tooltiptxt: isLoading ? '' : errMsg2,
					py: 0,
					showPreviousBtn: false,
				}}
			/>
			{showErrorAlert && (
				<AlertStatus
					description={hasPluralErrors ? errMsg3 : errMsg4}
					status="error"
					data-testid="bloker-error-alert"
					maxW="md"
					variableTxt2="create a project"
					variableTxt={unhealthyBlockerList.join(', ')}
				/>
			)}
		</Stack>
	);
};

import { Box, Heading, Spacer, Stack } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, ReactNode } from 'react';

interface Props {
	title: string;
	description?: string;
	mb?: number;
	children?: ReactNode;
	mt?: number;
}
export const CardBox: FC<Props> = ({
	title,
	description,
	mb = 0,
	mt = 0,
	children,
}) => {
	return (
		<Box
			border="1px"
			w="100%"
			borderColor="chakra-border-color"
			borderRadius="md"
			px="5"
			py="4"
			mb={mb}
			data-testid="card-box"
			mt={mt}
		>
			<Stack direction="row" justify="space-between">
				<Heading size="sm" role="card-title">
					<T _str={title} />
				</Heading>
				<>{children}</>
			</Stack>
			<Spacer py={1} />
			<Box w="75%" color="chakra-subtle-text">
				<T _str={description} />
			</Box>
		</Box>
	);
};

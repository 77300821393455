import axios from 'axios';
import { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ADO_BRANCH_PREFIX } from '../../@xmcloud/utils/constants';
import config from '../config/config';
import {
	TADOBranches,
	TGetADOOrganizations,
	TGetADOProfile,
	TGetADOProjects,
	TGetADORepositories,
} from '../models/externalApi';
const sourceControlInstance = axios.create();

const axiosInstanceInterceptorADO = (token: string) => {
	const encodedToken = window.btoa(`basic:${token}`);
	sourceControlInstance.defaults.headers.common['Authorization'] =
		`Basic ${encodedToken}`;

	return sourceControlInstance;
};

export const useGetADOProfile = (
	token: string,
	integrationId: string,
	_enabled = true,
) => {
	const { url, queryKey } = config.sourceControl.get_ado_profile;

	const axiosInstance = axiosInstanceInterceptorADO(token);
	const enabled = !!token && _enabled;
	return useQuery({
		queryKey: queryKey(integrationId),
		queryFn: () => axiosInstance.get<TGetADOProfile>(url),
		enabled,
	});
};

export const useGetADOOrganizations = ({
	token,
	publicAlias,
	_enabled = true,
	onError,
}: {
	token: string;
	publicAlias: string;
	_enabled?: boolean;
	onError?: (e: any) => void;
}) => {
	const { url, queryKey } = config.sourceControl.get_ado_organizations;
	const axiosInstance = axiosInstanceInterceptorADO(token);
	const enabled = !!token && !!publicAlias && _enabled;
	return useQuery({
		queryKey: queryKey(publicAlias),
		queryFn: () =>
			axiosInstance.get<TGetADOOrganizations>(url(publicAlias)),
		enabled,
		onError: (e) => {
			onError && onError(e);
		},
	});
};

export const useGetADOProjects = ({
	token,
	organization,
	_enabled = true,
	onError,
}: {
	token: string;
	organization: string;
	_enabled?: boolean;
	onError?: (e: any) => void;
}) => {
	const { url, queryKey } = config.sourceControl.get_ado_projects;
	const axiosInstance = axiosInstanceInterceptorADO(token);
	const enabled = !!token && !!organization && _enabled;
	return useQuery({
		queryKey: queryKey(organization),
		queryFn: () => axiosInstance.get<TGetADOProjects>(url(organization)),
		enabled,
		onError: (e) => {
			onError && onError(e);
		},
	});
};

export const useGetADORepositories = ({
	token,
	organization,
	project,
	_enabled = true,
	onError,
}: {
	token: string;
	organization: string;
	project: string;
	_enabled?: boolean;
	onError?: (e: any) => void;
}) => {
	const { url, queryKey } = config.sourceControl.get_ado_repositories;
	const axiosInstance = axiosInstanceInterceptorADO(token);
	const enabled = !!token && !!organization && !!project && _enabled;
	return useQuery({
		queryKey: queryKey(organization, project),
		queryFn: () =>
			axiosInstance.get<TGetADORepositories>(url(organization, project)),
		enabled,
		onError: (e) => {
			onError && onError(e);
		},
	});
};
export const useGetADORepository = ({
	token,
	organization,
	project,
	repo,
	onSuccess,
	_enabled = true,
	onError,
}: {
	token: string;
	organization: string;
	project: string;
	repo: string;
	onSuccess?: (defaultBranch: string) => void;
	_enabled?: boolean;
	onError?: (e: any) => void;
}) => {
	const [isRefetching, setIsRefetching] = useState(false);
	const refetchInterval = useRef(15000);
	const { url, queryKey } = config.sourceControl.get_ado_repository;
	const axiosInstance = axiosInstanceInterceptorADO(token);
	const enabled =
		!!token && !!organization && !!project && !!repo && _enabled;
	const result = useQuery({
		queryKey: queryKey(organization, project, repo),
		queryFn: () =>
			axiosInstance.get<{ id: string; defaultBranch?: string }>(
				url(organization, project, repo),
			),
		enabled,
		refetchInterval: refetchInterval.current,
		onSuccess: (data) => {
			if (!data.data?.defaultBranch) {
				setIsRefetching(true);
				return;
			}
			refetchInterval.current = 0;
			setIsRefetching(false);
			const defaultBranch = data.data.defaultBranch.replace(
				ADO_BRANCH_PREFIX,
				'',
			);
			onSuccess && onSuccess(defaultBranch);
		},
		onError: (e) => {
			onError && onError(e);
		},
	});
	const isFetching = result.isFetching || isRefetching;
	return { ...result, isFetching };
};

export const useUpdateADORepository = (
	token: string,
	organization: string,
	project: string,
	repoId: string,
	_enabled = true,
) => {
	const { url } = config.sourceControl.get_ado_repository;
	const axiosInstance = axiosInstanceInterceptorADO(token);

	return useMutation({
		mutationFn: (payload: { defaultBranch: string }) => {
			return axiosInstance.patch<{ defaultBranch: string }>(
				url(organization, project, repoId),
				payload,
			);
		},
	});
};

export const useGetADOBranches = (
	token: string,
	organization: string,
	project: string,
	repositoryId: string,
	_enabled = true,
) => {
	const { url, queryKey } = config.sourceControl.get_ado_branches;
	const axiosInstance = axiosInstanceInterceptorADO(token);
	const enabled =
		!!token && !!organization && !!project && !!repositoryId && _enabled;
	return useQuery({
		queryKey: queryKey(organization, project, repositoryId),
		queryFn: () =>
			axiosInstance.get<TADOBranches>(
				url(organization, project, repositoryId),
			),
		enabled,
	});
};

import { FC, useState } from 'react';
import { app, settings } from '../../../@xmcloud/core/messages/en';
import { HStack, Text } from '@chakra-ui/react';
import {
	AlertDialogConfirmationModal,
	HeadingSm,
	InfoIcon,
	XMCSH3,
} from '../../shared-components';
import { isPhoneDevice } from '../../../@xmcloud/utils/helpers';
import { ExperimentalFeatures } from './ExperimentalFeatures';

export const SettingsPage: FC = () => {
	const [openInfo, setOpenInfo] = useState(false);

	return (
		<>
			<XMCSH3 title={app.settings} mb={6} />
			<HStack>
				<HeadingSm text={settings.experimentalFeatures} />
				<InfoIcon
					label={settings.info}
					isDisabled={isPhoneDevice}
					onClick={() => isPhoneDevice && setOpenInfo(true)}
				/>
			</HStack>
			<ExperimentalFeatures />
			{openInfo && (
				<AlertDialogConfirmationModal
					{...{
						isOpen: openInfo,
						onClose: () => setOpenInfo(false),
						shouldShowActionBtn: false,
						rightButtonText: app.close,
					}}
				>
					<Text mt="4">{settings.info}</Text>
				</AlertDialogConfirmationModal>
			)}
		</>
	);
};

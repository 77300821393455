import { IconButton, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { ButtonVariant, ButtonSizes } from '../../../@xmcloud/types';
import { JSX } from '@emotion/react/types/jsx-runtime';
import { Icon } from '..';
import { mdiDotsHorizontal } from '@mdi/js';

interface Props {
	variant?: ButtonVariant;
	size?: ButtonSizes;
	text?: string;
	children: ReactNode;
	as?: FC<any>;
	icon?: JSX.Element;
	minWidth?: string;
}

export const ContextMenu: FC<Props> = ({
	variant = 'ghost',
	as = IconButton,
	size = 'sm',
	children,
	icon = <Icon path={mdiDotsHorizontal} />,
	minWidth = '6',
}) => {
	return (
		<Menu>
			<MenuButton
				as={as}
				variant={variant}
				size={size}
				icon={icon}
				colorScheme="neutral"
				data-testid="action-menu-button"
				minWidth={minWidth}
			/>
			<MenuList>{children}</MenuList>
		</Menu>
	);
};

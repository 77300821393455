import { Stack, Tabs, TabsProps } from '@chakra-ui/react';

type Props = TabsProps & {
	children: React.ReactNode;
	mb?: number;
};

export const ToggleStack: React.FC<Props> = ({ children, mb = 4, ...rest }) => {
	return (
		<Stack mb={mb}>
			<Tabs
				size="sm"
				variant="soft-rounded"
				borderRadius="full"
				border="1px"
				borderColor="chakra-border-color"
				p="1"
				width="fit-content"
				height="fit-content"
				{...rest}
			>
				{children}
			</Tabs>
		</Stack>
	);
};

import { useAuthQuery } from '../apiUtils/queryHelper';
import config from '../config/config';
import { AxiosResponse } from 'axios';
import { TStages } from '../pages/project/deployment-logs/handlers';
import { IHistoricData } from '../models/deployLogger';

export const useGetMonitoringHistoricalData = ({
	deploymentId,
	...options
}: {
	deploymentId: string;
	enabled?: boolean;
	onSuccess?: (data: AxiosResponse<IHistoricData>) => void;
	onError?: (err: unknown) => void;
	retry?: number;
	retryDelay?: number;
	refetchOnWindowFocus?: boolean;
}) => {
	return useAuthQuery(
		config.monitoring.get_historycal_data.queryKey(deploymentId),
		(axiosInstance) => {
			return axiosInstance?.get<IHistoricData>(
				config.monitoring.get_historycal_data.url(deploymentId),
			);
		},
		options,
		config.monitoring.get_historycal_data.scope,
	);
};

export const useGetDeploymentLogs = (deploymentId: string) => {
	const { scope, url, queryKey } = config.monitoring.logs;
	return useAuthQuery(
		queryKey(deploymentId),
		(axiosInstance) => {
			return axiosInstance?.get<string>(url(deploymentId));
		},
		{ enabled: false },
		scope,
	);
};

export const useGetDeploymentStageLogs = (
	deploymentId: string,
	stage: TStages,
) => {
	const { scope, url, queryKey } = config.monitoring.stageLogs;
	return useAuthQuery(
		queryKey(deploymentId, stage),
		(axiosInstance) => {
			return axiosInstance?.get<string>(url(deploymentId, stage));
		},
		{ enabled: false },
		scope,
	);
};

import {
	mdiBookOpenPageVariantOutline,
	mdiForumOutline,
	mdiLifebuoy,
	mdiSchoolOutline,
	mdiSlack,
} from '@mdi/js';
import { t } from '@transifex/native';
import { MediaLinkProps } from './components/MediaLink';

export const sidebarHelpItems: MediaLinkProps[] = [
	{
		title: t('XM Cloud Deploy App documentation'),
		url: 'https://doc.sitecore.com/xmc/en/developers/xm-cloud/xm-cloud-deploy-app.html',
		iconPath: mdiBookOpenPageVariantOutline,
		iconThemeColor: 'gray',
	},
	{
		title: t('Sitecore Developer Portal'),
		url: 'https://developers.sitecore.com/',
		iconPath: mdiForumOutline,
		iconThemeColor: 'gray',
	},
	{
		title: t('Sitecore Support Portal'),
		url: 'https://support.sitecore.com/csm',
		iconPath: mdiLifebuoy,
		iconThemeColor: 'gray',
	},
];

export const sidebarSuggestionItems: MediaLinkProps[] = [
	{
		title: t('Sitecore Learning'),
		url: 'https://learning.sitecore.com/',
		iconPath: mdiSchoolOutline,
		iconThemeColor: 'red',
	},
	{
		title: t('Sitecore Slack (#xm-cloud)'),
		url: 'https://sitecore.chat/',
		iconPath: mdiSlack,
		iconThemeColor: 'red',
	},
	{
		title: t('Sitecore Stack Exchange'),
		url: 'https://sitecore.stackexchange.com/',
		iconPath: mdiSlack,
		iconThemeColor: 'red',
	},
];

import {
	AccordionButton as SDSAccordionButton,
	AccordionIcon,
	Text,
	HStack,
	IconButton,
	Tooltip,
} from '@chakra-ui/react';
import { downloadLogs } from '../../../../@xmcloud/utils/helpers';
import { IGetDeploymentResponse } from '../../../models/deploymentModel';
import { useGetDeploymentStageLogs } from '../../../services/monitoring';
import { IconText, Icon } from '../../../shared-components';
import {
	allLogStatuses,
	Stages,
	statusIconList,
	TCalculatedStatus,
} from './handlers';
import { mdiTrayArrowDown } from '@mdi/js';
import { cmInstanceLog } from '../../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';

interface Props {
	processgroup: any;
	deployment: IGetDeploymentResponse;
}

export const AccordionButton: React.FC<Props> = ({
	processgroup,
	deployment,
}) => {
	const { key, timeElapsed, stage } = processgroup;
	const currentStatus = allLogStatuses[key];
	//@ts-ignore
	const statusNumber: TCalculatedStatus = deployment[currentStatus];
	const status = statusIconList[statusNumber];
	const { refetch } = useGetDeploymentStageLogs(deployment.id, Stages[key]);
	const showDownloadBtn = statusNumber === 2 || statusNumber === 3;

	return (
		<SDSAccordionButton minH="14" data-testid={`accordion-${stage}`}>
			<HStack justify="space-between" w="full" textAlign="left">
				<IconText
					{...{
						text: stage,
						...(status?.isIndeterminate && {
							isIndeterminate: true,
						}),
						...(status?.IconName && {
							IconName: status.IconName,
						}),
						...(status?.iconColor && {
							iconColor: status.iconColor,
						}),
					}}
				/>

				<HStack gap="4" justifyContent="space-between">
					<Text variant="subtle" noOfLines={1}>{`${
						processgroup.status
					}${timeElapsed ? `, ${timeElapsed}` : ''}`}</Text>

					{showDownloadBtn && (
						<Tooltip label={t(cmInstanceLog.download)}>
							<IconButton
								variant="ghost"
								size="sm"
								icon={<Icon path={mdiTrayArrowDown} />}
								aria-label={t(cmInstanceLog.download)}
								onClick={async (e) => {
									e.stopPropagation();
									const { data } = await refetch();
									if (data?.data) {
										const text = data.data as string;
										downloadLogs(
											text,
											`${Stages[key]}.txt`,
										);
									}
								}}
								data-testid={`download-logs-${stage}`}
							/>
						</Tooltip>
					)}
					<AccordionIcon />
				</HStack>
			</HStack>
		</SDSAccordionButton>
	);
};

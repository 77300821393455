import { Skeleton, Stack, StackProps } from '@chakra-ui/react';

export const LoadingSkeleton: React.FC<
	{
		amount?: number;
		height?: number | string;
		mt?: number;
		my?: number;
		spacing?: number;
	} & StackProps
> = ({ amount = 5, height = 10, mt = 6, spacing = 4, ...rest }) => (
	<Stack data-testid="loading-skeleton" mt={mt} spacing={spacing} {...rest}>
		{[...Array(amount)].map((x, i) => (
			<Skeleton h={height} key={i} />
		))}
	</Stack>
);

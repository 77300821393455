import { Container, Stack } from '@chakra-ui/react';

interface Props {
	children: React.ReactNode;
}

export const Footer: React.FC<Props> = ({ children }) => (
	<Container
		as="footer"
		role="contentinfo"
		position="fixed"
		bottom="0"
		right="0"
		left="0"
		width="100%"
		zIndex={2}
		boxShadow="inner"
		bg="chakra-body-bg"
	>
		<Stack
			h="14"
			direction="row"
			justify="space-around"
			alignItems="center"
			align="center"
			width="100%"
			mt={0.5}
		>
			{children}
		</Stack>
	</Container>
);

import { Button, ModalBody, ModalFooter, Stack } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { isEmpty, omit } from 'lodash';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { app, editProject } from '../../../@xmcloud/core/messages/en';
import { useEditProject, useProjectDetails } from '../../../@xmcloud/hooks';
import { EnumInNumberSourceControl } from '../../../@xmcloud/types';
import { difference } from '../../../@xmcloud/utils/helpers';
import { useGetProjectEnvironmentsList } from '../../services/projects';
import { useGetRepositoryIntegration } from '../../services/repositories';
import { Alert } from '../../shared-components';
import { FormFieldLoading } from '../create-project/components';
import {
	ENVIRONMENTS,
	SOURCE_CONTROL_INTEGRATION_ID,
} from '../create-project/helpers';
import { AdoRepository } from './AdoRepository';
import { Environments } from './Environments';
import { GitHubRepository } from './GithubRepository';
import { NewConnection } from './NewConnection';
import { ProjectName } from './ProjectName';
import { SelectAccount } from './SelectAccount';
import { SelectProvider } from './SelectProvider';
import { RepositoryErrorAlert } from './RepositoryErrorAlert';

const { save, cancel } = app;
const { confirmation2, branchChangeConfirmation } = editProject;

const { ADO, GitHub } = EnumInNumberSourceControl;

export const EditProjectForm: React.FC<{ onClose: () => void }> = ({
	onClose,
}) => {
	const { projectId } = useParams<{ projectId: string }>();
	const {
		values,
		errors,
		editValues,
		prevValues,
		formikHandleSubmit,
		isSubmittingLoading,
		isIntegrationValidationFetching,
		isComponentHealthLoading,
		isValidIntegration,
		integrationIdValidationMsg,
		setFieldTouched,
		setFieldError,
	} = useEditProject();

	const {
		project,
		isLoading: isProjectDetailsLoading,
		sourceControlIntegrationId,
	} = useProjectDetails(projectId!);
	const { data: envData, isLoading: isEnvironmentsLoading } =
		useGetProjectEnvironmentsList({
			projectId: projectId!,
		});

	const { editRepository, editAccount } = editValues;
	const { provider, hasAccount, repositoryId, repository, account } = values;

	const environmentsData = envData?.data || [];
	const environmentsLength = environmentsData.length;

	const { data: integrationData, isLoading: isIntegrationLoading } =
		useGetRepositoryIntegration(sourceControlIntegrationId);

	const isGithubProvider = provider === GitHub;
	const isAdoProvider = provider === ADO;

	useEffect(() => {
		if (!isValidIntegration) {
			setFieldTouched(SOURCE_CONTROL_INTEGRATION_ID, true);
			setFieldError(
				SOURCE_CONTROL_INTEGRATION_ID,
				integrationIdValidationMsg,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isValidIntegration]);

	const isAccountChanged =
		hasAccount &&
		prevValues.account &&
		editAccount &&
		prevValues.account !== account;

	const isRepositoryChanged = isAccountChanged
		? true
		: repository &&
			prevValues.repository &&
			repository !== prevValues.repository;

	const showNewConnection = provider && (!hasAccount || editAccount);

	const isInitialLoading =
		isIntegrationLoading ||
		isProjectDetailsLoading ||
		isEnvironmentsLoading ||
		isComponentHealthLoading;

	const isLoading =
		isInitialLoading ||
		isIntegrationValidationFetching ||
		isSubmittingLoading;

	if (isInitialLoading) {
		return (
			<Stack gap={2} pl="6" pb="12">
				{[...Array(4)].map((x, i) => (
					<FormFieldLoading key={i} w="sm" />
				))}
			</Stack>
		);
	}
	const updatedValues = difference(
		omit(values, [ENVIRONMENTS]),
		omit(prevValues, [ENVIRONMENTS]),
	);

	const hasError = !isEmpty(errors);
	const isNameChanged = updatedValues?.name;

	const isSaveBtnDisabled =
		(hasAccount ? !isNameChanged : false) || isLoading || hasError;
	const showConfirmationBtn =
		!!isRepositoryChanged && !hasError && hasAccount;

	const showEnvironments =
		editRepository &&
		!!environmentsLength &&
		isValidIntegration &&
		isRepositoryChanged &&
		repositoryId &&
		!hasError;

	return (
		<form data-testid="edit-project-form" onSubmit={formikHandleSubmit}>
			<ModalBody>
				<ProjectName
					{...{
						projectDetail: project,
						integrationData: integrationData?.data,
						environmentsData,
					}}
				/>
				<SelectProvider />
				{!!provider && <SelectAccount />}
				{isGithubProvider && <GitHubRepository />}
				{isAdoProvider && <AdoRepository />}
				{!!showEnvironments && <Environments />}
				{!!showNewConnection && <NewConnection />}
				{!isLoading && <RepositoryErrorAlert />}
				{!!isRepositoryChanged && (
					<Alert
						status="warning"
						description={branchChangeConfirmation}
						minW="100%"
						mt={4}
					/>
				)}
			</ModalBody>
			<ModalFooter gap={3}>
				{showConfirmationBtn && (
					<Button
						type="submit"
						isLoading={isLoading}
						colorScheme="danger"
						variant="solid"
						data-testid="save-confirm-button-danger"
					>
						{t(confirmation2)}
					</Button>
				)}
				<Button onClick={onClose} variant="ghost">
					{t(cancel)}
				</Button>
				{!showConfirmationBtn && (
					<Button
						type="submit"
						isLoading={isLoading}
						isDisabled={isSaveBtnDisabled}
						data-testid="Save-confirm-button"
					>
						{t(save)}
					</Button>
				)}
			</ModalFooter>
		</form>
	);
};

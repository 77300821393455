import { AlertDialogConfirmationModal } from '../../../shared-components';
import { useDeleteHostingConnectionMutation } from '../../../services/hosting';
import { Text, useToast } from '@chakra-ui/react';
import { connections } from '../../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';

interface Props {
	installation: string;
	setOpenModal: (show: boolean) => void;
	openModal: boolean;
}

const DeleteHostingIntegration: React.FC<Props> = ({
	openModal,
	setOpenModal,
	installation,
}) => {
	const toast = useToast();
	const { mutate: deleteHostingConnection, isLoading } =
		useDeleteHostingConnectionMutation();

	function handleSubmit() {
		if (!installation) return;
		deleteHostingConnection(
			{
				id: installation,
			},
			{
				onSuccess: () => {
					toast({
						status: 'success',
						description: t(connections.deleteConnectionSuccess),
					});
					setOpenModal(false);
				},
			},
		);
	}

	return (
		<AlertDialogConfirmationModal
			isOpen={openModal}
			title={connections.delete}
			onClose={() => setOpenModal(false)}
			dataTestId="delete-hosting-confirmation-modal"
			isLoading={isLoading}
			onConfirm={handleSubmit}
		>
			<Text>{t(connections.deleteConfirmation)}</Text>
		</AlertDialogConfirmationModal>
	);
};

export default DeleteHostingIntegration;

import { mdiInformationOutline } from '@mdi/js';
import { IconProps, Tooltip } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { Icon } from '..';

type Props = IconProps & {
	isDisabled?: boolean;
	label: string;
	onClick?: () => void;
};

export const InfoIcon: React.FC<Props> = ({
	isDisabled = false,
	label,
	onClick,
	...rest
}) => {
	return (
		<Tooltip label={t(label)} shouldWrapChildren isDisabled={isDisabled}>
			<Icon
				color="neutral"
				path={mdiInformationOutline}
				onClick={onClick}
				data-testid="info-icon"
				{...rest}
				boxSize={4}
			/>
		</Tooltip>
	);
};

import { useCreateProject } from '../hooks';
import { OLDReviewFinish } from './OLDReviewFinish';
import { useOwnCodeProjectCreation } from './useOwnCodeProjectCreation';

export const OLDReviewFinishOwnCode: React.FC = () => {
	const { values } = useCreateProject();
	const {
		onOwnCodeProjectCreation,
		isLoading,
		shouldRetry,
		projectCreationStatus,
		environmentCreationStatus,
		deploymentCreationStatus,
	} = useOwnCodeProjectCreation(values);

	return (
		<OLDReviewFinish
			{...{
				isLoading,
				shouldRetry,
				handleStartDeployment: onOwnCodeProjectCreation,
				projectCreationStatus,
				environmentCreationStatus,
				deploymentCreationStatus,
			}}
		/>
	);
};

import { FormikValues } from '../hooks/CreateProjectContext';
import { useCallback } from 'react';
import { ESourceControl } from '../../../../@xmcloud/types';
import { useRepositoryHookSubscriptionMutation } from '../../../services/repositories';
import { useOLDProjectCreation } from './useOLDProjectCreation';
import { handleResponseStaus } from './helpers';

export function useOwnCodeProjectCreation(values: FormikValues) {
	const shouldEditEnvironment = true;
	const {
		onProjectCreation,
		handleEnvironmentCreation,
		projectId,
		shouldRetry: shouldRetryOnCreateProject,
		isLoading: isLoadingProjectCreation,
		shouldReTryEnvironmentCreation,
		shouldReTryDeploymentCreation,
		shouldReTryDeploymentDeploy,
		shouldRetryCreateProject,
		shouldReTryEditEnvironment,
		currentEnvironmentId,
		currentDeploymentId,
		onDeploymentCreation,
		onDeploymentDeploy,
		onEditEnvironment,
		createEnvironmentStatus,
		editEnvironmentStatus,
		createDeploymentStatus,
		deploymentDeployStatus,
		createProjectStatus,
	} = useOLDProjectCreation(shouldEditEnvironment);
	const {
		mutate: repoHookSubscription,
		isLoading: isLoadingHookSubscription,
		status: hookSubscriptionStatus,
	} = useRepositoryHookSubscriptionMutation();

	const shouldRetryOnHookSubscription = hookSubscriptionStatus === 'error';
	const shouldRetry =
		shouldRetryOnCreateProject || shouldRetryOnHookSubscription;

	const onRepoHookSubscription = useCallback(
		(
			values: FormikValues,
			repository: string,
			repositoryBranch: string,
		) => {
			const repoHookSubsPayload = {
				repositoryName: repository,
				integrationId: values.sourceControlIntegrationId,
			};
			repoHookSubscription(repoHookSubsPayload, {
				onSuccess: () => {
					//trigger the 2nd step
					onProjectCreation(values, repository, repositoryBranch);
				},
			});
		},
		[onProjectCreation, repoHookSubscription],
	);

	const onOwnCodeProjectCreation = (values: FormikValues) => {
		const {
			repository: repo,
			repositoryBranch,
			adoOrganization,
			adoProjectName,
		} = values;
		const isADOProvider = values.provider === ESourceControl.ADO;
		const adoRepoPath = `${adoOrganization}/${adoProjectName}/${repo}`;
		const repository = isADOProvider ? adoRepoPath : repo;

		function onRetry() {
			if (shouldRetryOnHookSubscription && isADOProvider) {
				onRepoHookSubscription(values, repository, repositoryBranch);
			} else if (shouldRetryCreateProject) {
				onProjectCreation(values, repository, repositoryBranch);
			} else if (shouldReTryEnvironmentCreation) {
				handleEnvironmentCreation(values, projectId, repositoryBranch);
			} else if (shouldReTryEditEnvironment) {
				onEditEnvironment(
					repositoryBranch,
					projectId,
					currentEnvironmentId,
				);
			} else if (shouldReTryDeploymentCreation) {
				onDeploymentCreation(projectId, currentEnvironmentId);
			} else if (shouldReTryDeploymentDeploy) {
				onDeploymentDeploy(
					projectId,
					currentEnvironmentId,
					currentDeploymentId,
				);
			}
		}

		if (shouldRetry) {
			//retry step if it fails
			onRetry();
		} else {
			//trigger the 1st step
			if (isADOProvider) {
				onRepoHookSubscription(values, repository, repositoryBranch);
			} else {
				onProjectCreation(values, repository, repositoryBranch);
			}
		}
	};

	const isLoading = isLoadingProjectCreation || isLoadingHookSubscription;
	const isAdoProvider = values.provider === ESourceControl.ADO;

	const projectCreationStatus = handleResponseStaus(
		createProjectStatus,
		isAdoProvider ? hookSubscriptionStatus : undefined,
	);

	const environmentCreationStatus = handleResponseStaus(
		createEnvironmentStatus,
		editEnvironmentStatus,
	);

	const deploymentCreationStatus = handleResponseStaus(
		createDeploymentStatus,
		deploymentDeployStatus,
	);

	return {
		onOwnCodeProjectCreation,
		isLoading,
		shouldRetry,
		projectCreationStatus,
		environmentCreationStatus,
		deploymentCreationStatus,
	};
}

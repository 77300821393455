import { useEffect } from 'react';
import { gainsightProductKey } from '../config/envConfig';
import { useGetOrganization } from './organizations';
import { useGetCurrentUser } from './user';
import {
	IGainsightPXUser,
	IGainsightPXAccount,
	IGainsightPXGlobalContext,
} from '../models/gainsightModel';

export const aptrinsicIdentify = (
	user: IGainsightPXUser,
	account: IGainsightPXAccount,
): void => {
	if (!window.aptrinsic || !user) {
		return;
	}
	window.aptrinsic('identify', user, account);
};

export const aptrinsicSetGlobalContext = (
	globalContext: IGainsightPXGlobalContext,
): void => {
	if (!window.aptrinsic) return;
	if (!globalContext.OrganizationID) return;
	window.aptrinsic('set', 'globalContext', globalContext);
};

const getGainsightPXTag = () => {
	return `(function(n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){
		(n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;
	  var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
	  var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
	})(window,document,"https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js","${gainsightProductKey}");
  `;
};

const hasExistingScript = () => {
	const head = document.querySelector('head');
	const script = head?.querySelector(
		`script[src^="https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js?a=${gainsightProductKey}"]`,
	);

	return script;
};

export const useGainsightPX = () => {
	const { data: userData } = useGetCurrentUser();
	const user = userData?.data;
	const { data } = useGetOrganization();
	const organization = data?.data;

	useEffect(() => {
		const gainsightPXTagTemplate = getGainsightPXTag();
		const head = document.querySelector('head');

		if (!head) {
			return;
		}

		const script = document.createElement('script');
		const scriptAlreadyExists = hasExistingScript();

		if (scriptAlreadyExists) {
			return;
		}

		script.innerHTML = gainsightPXTagTemplate;
		head.appendChild(script);
		return () => {
			head.removeChild(script);
		};
	}, []);

	useEffect(() => {
		if (!user) return;

		const userObject: IGainsightPXUser = {
			id: user.email,
			email: user.email,
			lastLogin: user.lastLogin,
			signUpDate: user.created,
		};

		const accountObject: IGainsightPXAccount = {
			id: organization?.accountId,
			sfdcId: organization?.accountId,
		};

		aptrinsicIdentify(userObject, accountObject);

		if (!organization) return;

		const globalContext: IGainsightPXGlobalContext = {
			'Organization DisplayName': organization.displayName,
			OrganizationID: organization.id,
			'Organization Type': organization.type,
		};

		aptrinsicSetGlobalContext(globalContext);
	}, [user, organization]);
};

import { Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { app, repository as repo } from '../../../@xmcloud/core/messages/en';
import { useProjectDetails } from '../../../@xmcloud/hooks';
import { CircularLoading, TextBox } from '../../shared-components';
import { getReadableDate } from '../../../@xmcloud/utils/dateUtils';
import { useMemo } from 'react';

const { id: idtxt, region: regiontxt } = app;
const {
	account: accounttxt,
	name: nametxt,
	repositoryLinkedDate: repositoryLinkedDatetxt,
} = repo;

export const ProjectDetailsPage: React.FC = () => {
	const { projectId } = useParams<{ projectId: string }>();
	const {
		isLoading,
		sourceControlIntegrationName,
		repositoryLinkedAt,
		repository,
		region,
	} = useProjectDetails(projectId!);

	const details = useMemo(
		() =>
			[
				{ title: idtxt, subTitle: projectId, visible: true }, // Project ID
				{ title: regiontxt, subTitle: region, visible: true }, // Region
				{
					title: accounttxt, // Repository Account Name
					subTitle: sourceControlIntegrationName || '-',
					visible: true,
				},
				{
					title: nametxt, // Repository Name
					subTitle: repository || '-',
					visible: true,
				},
				{
					title: repositoryLinkedDatetxt, // Repository Linked Date
					subTitle:
						(repositoryLinkedAt &&
							getReadableDate(repositoryLinkedAt, 'PPp')) ||
						'-',
					visible: true,
				},
			].filter(({ visible }) => visible),
		[
			projectId,
			region,
			sourceControlIntegrationName,
			repository,
			repositoryLinkedAt,
		],
	);

	if (isLoading) return <CircularLoading />;

	return (
		<Stack spacing={5} mt={5} pl={5}>
			{details.map(({ title, subTitle }) => (
				<TextBox {...{ title, subTitle }} key={title} />
			))}
		</Stack>
	);
};

import { ITransferContentNode } from './transferContent';

/**
 * Transfer configuration model
 */
export interface ITransferConfiguration {
	/**
	 * The transfer id
	 */
	transferId: string;
	/**
	 * The transfer name
	 */
	name: string;
	/**
	 * The transfer configuration nodes
	 */
	nodes: ITransferConfigurationNode[];
	/**
	 * The source environment id
	 */
	sourceEnvironmentId: string;
	/**
	 * The target environment id
	 */
	targetEnvironmentId: string;
}

/**
 * Transfer configuration node model
 */
export interface ITransferConfigurationNode extends ITransferContentNode {
	/**
	 * The node id
	 */
	id: string;
	/**
	 * The node type
	 */
	type?: TransferConfigurationNodeType;
	/**
	 * The Merge strategy
	 */
	mergeStrategy?: MergeStrategy;
	/**
	 * Path
	 */
	path: string;
}

/**
 * Transfer configuration node type
 */
export enum TransferConfigurationNodeType {
	Tree,
	Site,
	File,
	Branch,
	Folder,
}

export enum MergeStrategy {
	OverrideExistingItem,
	KeepExistingItem,
	LatestWin,
	OverrideExistingTree,
}

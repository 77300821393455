import { FC, useMemo } from 'react';
import { useAuthQuery } from '../../../app/apiUtils/queryHelper';
import config from '../../../app/config/config';
import { FlagsProvider } from '../../../app/feature-flag/features';

export interface AzureFeatureFlagValue {
	id: string;
	enabled: boolean;
	conditions: {
		clientFilters: { name: string; parameters?: Record<string, unknown> }[];
	};
	description?: string;
	displayName?: string;
}

export function useAzureFeatureFlags(options?: Partial<{ enabled: boolean }>) {
	return useAuthQuery(
		config.featureFlags.scope,
		(axiosInstance) =>
			axiosInstance.get<AzureFeatureFlagValue[]>(config.featureFlags.url),
		options,
	);
}

export function transformFlags(flags: AzureFeatureFlagValue[]) {
	const result: Record<string, boolean> = {};

	for (const flag of flags) {
		result[flag.id] = flag.enabled;
	}

	return result;
}

export const AzureFeaturesProvider: FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { data, isLoading } = useAzureFeatureFlags();
	const togglePoints = useMemo(
		() => transformFlags(data?.data ?? []),
		[data],
	);

	if (isLoading) {
		return null;
	}

	return <FlagsProvider features={togglePoints}>{children}</FlagsProvider>;
};

import {
	ButtonGroup,
	Spinner,
	IconButton,
	Stack,
	Text,
	Tooltip,
	Wrap,
} from '@chakra-ui/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { Icon } from '..';

type Props = {
	disabledNextBtn?: boolean;
	disabledPrevBtn?: boolean;
	onNextClick?: () => void;
	onPrevClick?: () => void;
	currentPage?: number;
	totalPages?: number;
	isFetching?: boolean;
};

export const TablePagination: React.FC<Props> = ({
	disabledNextBtn = false,
	disabledPrevBtn = false,
	onNextClick,
	onPrevClick,
	currentPage = 1,
	totalPages = 1,
	isFetching = false,
}) => (
	<Stack spacing="3" direction="row" alignItems="center" pb={[12, 4]}>
		{isFetching && (
			<Stack spacing="1" direction="row" alignItems="center">
				<Spinner size="xs" color="gray.400" />
				<Text>Fetching...</Text>
			</Stack>
		)}
		<Text variant="subtle">
			{currentPage} of {totalPages}
		</Text>
		<ButtonGroup as={Wrap} size="sm" variant="pagination" spacing="1">
			<Tooltip label="Previous">
				<IconButton
					icon={<Icon path={mdiChevronLeft} />}
					aria-label="previous-button"
					isDisabled={disabledPrevBtn}
					onClick={onPrevClick}
				/>
			</Tooltip>
			<Tooltip label="Next">
				<IconButton
					icon={<Icon path={mdiChevronRight} />}
					aria-label="next-button"
					isDisabled={disabledNextBtn}
					onClick={onNextClick}
				/>
			</Tooltip>
		</ButtonGroup>
	</Stack>
);

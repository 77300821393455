import { Stack, Text, Divider } from '@chakra-ui/react';
import {
	ComplexTextBox,
	Icon,
	LoadingSkeleton,
	TextBox,
} from '../../../shared-components';
import { useParams } from 'react-router';
import { useGetTransferDetails } from '../../../services/transferDetails';
import { transferDetails as messages } from '../../../../@xmcloud/core/messages/en';
import { useGetTransferConfiguration } from '../../../services/transferConfiguration';
import { getMergeStrategyLabel } from '../../contenttransfer/handlers';
import { useEnvironmentDetails } from '../../../../@xmcloud/hooks';
import {
	ITransferConfigurationNode,
	MergeStrategy,
	TransferConfigurationNodeType,
} from '../../../models/transferConfiguration';
import {
	mdiFileOutline,
	mdiFileTree,
	mdiFolderOutline,
	mdiSourceBranch,
} from '@mdi/js';

export const TransferDetails: React.FC = () => {
	const { transferId } = useParams<{ transferId: string }>();
	const { data: transferDetails, isLoading } = useGetTransferDetails({
		id: transferId!,
	});
	const { data: transferConfiguration, isLoading: configIsLoading } =
		useGetTransferConfiguration({
			id: transferId!,
		});
	const { environment: sourceEnvironment, isLoading: sourceIsLoading } =
		useEnvironmentDetails(transferDetails?.data.sourceEnvironmentId!);
	const { environment: targetEnvironment, isLoading: targetIsLoading } =
		useEnvironmentDetails(transferDetails?.data.targetEnvironmentId!);
	if (
		!transferDetails?.data ||
		isLoading ||
		sourceIsLoading ||
		targetIsLoading ||
		configIsLoading
	)
		return <LoadingSkeleton height={24} amount={5} />;

	const details = [
		{
			title: messages.labelCreatedBy,
			subTitle: transferDetails.data.createdBy,
		},
		{
			title: messages.labelCreatedOn,
			subTitle: transferDetails.data.createdAt,
		},
	];
	const links = [
		{
			title: messages.labelSourceEnv,
			subTitle: sourceEnvironment?.name,
			link: sourceEnvironment?.host,
		},
		{
			title: messages.labelTargetEnv,
			subTitle: targetEnvironment?.name,
			link: targetEnvironment?.host,
		},
	];
	const nodes = transferConfiguration?.data.nodes;
	const createIcon = (node: ITransferConfigurationNode): JSX.Element => {
		let iconPath: string;
		switch (node.type) {
			case TransferConfigurationNodeType.Folder:
				iconPath = mdiFolderOutline;
				break;
			case TransferConfigurationNodeType.File:
				iconPath = mdiFileOutline;
				break;
			case TransferConfigurationNodeType.Branch:
				iconPath = mdiSourceBranch;
				break;
			case TransferConfigurationNodeType.Tree:
				iconPath = mdiFileTree;
				break;
			default:
				iconPath = mdiFolderOutline;
				break;
		}

		return <Icon path={iconPath} key={`icon-${node.id}`} boxSize={5} />;
	};
	return (
		<>
			<Stack spacing={5} mt={5} pl={5} pb={[20, 20, 12, 12]}>
				{details &&
					details.map(({ title, subTitle }) => (
						<TextBox {...{ title, subTitle }} key={title} />
					))}
				{links &&
					links.map(({ title, subTitle, link }) => (
						<TextBox
							isLink={true}
							href={link}
							{...{ title, subTitle }}
							key={title}
						/>
					))}

				<Divider />
				<Text variant="subtle" fontWeight="semibold">
					{messages.labelSelectedItems}
				</Text>
				{nodes &&
					nodes.map((node) => (
						<Stack key={'stack' + node.id}>
							<ComplexTextBox
								{...{
									primaryTitle: node.item,
									secondaryTitle: '-',
									thirdTitle: getMergeStrategyLabel(
										node.mergeStrategy ??
											MergeStrategy.OverrideExistingItem,
									),
									subTitle: node.path,
								}}
								key={node.id}
							>
								{createIcon(node as ITransferConfigurationNode)}
							</ComplexTextBox>
						</Stack>
					))}
			</Stack>
		</>
	);
};

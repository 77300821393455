import styled from '@emotion/styled';

type TableWrapperProps = {
	headers?: any[];
};

export const TableWrapper = styled.div<TableWrapperProps>`
	${(props) => {
		let styles = '';
		const headers = props?.headers || [];

		styles += `
				@media (max-width: 768px) {
					    .chakra-table__container{
							overflow-x: hidden !important;
							overflow-y: hidden !important;
						}
						thead {
							display: none;
						}
						td::before {
							float: left;
							font-weight: 700;
							text-transform: capitalize;
							width: 47%;
							overflow:hidden;
							-webkit-box-orient: vertical;
							display: -webkit-box;
							text-overflow: ellipsis;
							-webkit-line-clamp: 1;
						}
						tr {
                            -ms-box-orient: horizontal;
                            display: -webkit-box;
                            display: -moz-box;
                            display: -ms-flexbox;
                            display: -moz-flex;
                            display: -webkit-flex;
                            display: flex;
                            -webkit-flex-flow: row wrap;
                            flex-flow: row wrap;
							width: 100%;
							background: #F8F8F8;
							border: 1px solid #F8F8F8;
							border-radius: 4px;
							margin-bottom: 14px;
							position: relative;
							max-width: 88vw;
						}
						tr td {
							padding:8px !important;
							width: 100% !important;
							text-align: left;
							border: none !important;
							text-align: left;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						tr td: last-of-type {
							position: absolute;
							right: 0;
							top: -4px;
							width: 52px !important;
							overflow: visible;
						}
						tr td:first-of-type {
							padding:14px 8px 8px 8px !important;
							font-weight: 700;
							overflow:hidden;
							-webkit-box-orient: vertical;
							display: -webkit-box;
							text-overflow: ellipsis;
							-webkit-line-clamp: 1;
						}
						${headers.map(
							(h) =>
								`td:nth-of-type(${
									headers.indexOf(h) + 1
								})::before {
									content: "${
										headers.indexOf(h) + 1 === 1 ||
										headers.indexOf(h) + 1 ===
											headers.length
											? ''
											: h
									} ";}`,
						)}
						${headers.map(
							(h) =>
								`td:nth-of-type(${
									headers.indexOf(h) + 1
								}) {order: ${
									headers.indexOf(h) + 1 === headers.length
										? 1
										: headers.indexOf(h) + 1
								};
							       }`,
						)}
								
				}
				
			`;
		return styles;
	}}
`;

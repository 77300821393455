import { useCreateRepositoryMutation } from '../../../services/repositories';
import { useCreateProject } from './CreateProjectContext';
import {
	ResponseStatusEnum,
	ResponseStatusTypes,
} from '../../../../@xmcloud/utils/helpers';
import { useState } from 'react';
import { useGetGitHubBranches } from '../../../services/githubApi';
import {
	CM_REPO_BRANCH,
	CM_REPOSITORY,
	CM_REPOSITORY_ID,
	EnvironmentTypesEnum,
	getProcessStatus,
} from '../helpers';
import { useCreateProjectMutationV2 } from '../../../services/projects';
import { useEnvironmentCreation } from '.';

const { ERROR } = ResponseStatusEnum;
const { CM } = EnvironmentTypesEnum;

export const useGitHubProjectCreation = ({
	token,
	onCmEnvironmentCreationSuccess,
}: {
	token: string;
	onCmEnvironmentCreationSuccess?: (
		cmEnvironmentId: string,
		projectId: string,
	) => void;
}) => {
	const { values, isByoc, setValues, isSingleEnvironmentCreation } =
		useCreateProject();
	const [projectID, setprojectID] = useState('');

	const {
		mutate: createGithubRepo,
		isLoading: isCreateGithubRepoLoading,
		status: createGithubRepoStatus,
	} = useCreateRepositoryMutation();

	const { loading: isRepositoryLoading, branchStatus } = useGetGitHubBranches(
		{
			token,
			owner: values.repositoryOwner,
			repo: values.cmRepository,
			onSuccess: onGitHubBranchesSuccess,
			_enabled:
				createGithubRepoStatus === 'success' &&
				!!values.repositoryOwner,
		},
	);

	const {
		mutate: createProject,
		isLoading: isLoadingCreateProject,
		status: createProjectStatus,
	} = useCreateProjectMutationV2();

	function onGitHubBranchesSuccess() {
		onSecondProcess();
	}

	const {
		isLoading: isLoadingCreateEnvironment,
		onEnvironmentLevelProcess,
		environmentCreationStatus,
		deploymentCreationStatus,
		environmentVariableCreationStatus,
		shouldRetry: shouldRetryThirdProcess,
	} = useEnvironmentCreation({
		shouldGoto: isSingleEnvironmentCreation,
		environmentType: CM,
		onCmEnvironmentCreationSuccess,
	});

	const shouldRetryFirstProcess = createGithubRepoStatus === ERROR;
	const shouldRetrySecondProcess = createProjectStatus === ERROR;

	function onFirstProcess() {
		const payload = {
			integrationId: values.cmSourceControlIntegrationId,
			owner: values.cmSourceControlIntegrationName,
			repositoryName: values.cmRepository,
			templateRepository: values.template,
			templateOwner: values.templateOwner,
		};
		createGithubRepo(payload, {
			onSuccess: (data) => {
				const repositoryName = data.data.name;
				const repositoryBranch = data.data.defaultBranch;
				const repositoryId = data.data.id;
				const owner = data.data.owner;
				setValues((prev) => ({
					...prev,
					[CM_REPOSITORY]: repositoryName,
					[CM_REPOSITORY_ID]: repositoryId,
					[CM_REPO_BRANCH]: repositoryBranch,
					repositoryOwner: owner,
				}));
			},
		});
	}

	function onSecondProcess() {
		const projectPayload = {
			name: values.name,
			repository: values.cmRepository,
			sourceControlIntegrationId: values.cmSourceControlIntegrationId,
			repositoryId: `${values.cmRepositoryId}`,
			sourceControlIntegrationName: values.cmSourceControlIntegrationName,
			repositoryRelativePath: './authoring',
		};
		createProject(
			{ project: projectPayload },
			{
				onSuccess: (data) => {
					const currentProjectId = data.data.id;
					setprojectID(currentProjectId);
					onThirdProcess(currentProjectId);
					setValues((prev) => ({
						...prev,
						projectId: currentProjectId,
					}));
				},
			},
		);
	}

	const onThirdProcess = (projectId: string) => {
		onEnvironmentLevelProcess(projectId, values.cmEnvironmentId);
	};

	function onHandleCreationProcess() {
		if (shouldRetryFirstProcess) {
			onFirstProcess();
		} else if (shouldRetrySecondProcess) {
			onSecondProcess();
		} else if (shouldRetryThirdProcess) {
			onThirdProcess(projectID);
		} else {
			if (isByoc) {
				onSecondProcess();
				return;
			}
			onFirstProcess();
		}
	}

	const repositoryCreationStatus = getProcessStatus(
		createGithubRepoStatus,
		branchStatus as ResponseStatusTypes,
	);
	const projectCreationStatus = getProcessStatus(createProjectStatus);

	const isLoading =
		isCreateGithubRepoLoading ||
		isRepositoryLoading ||
		isLoadingCreateProject ||
		isLoadingCreateEnvironment;
	const shouldRetry =
		shouldRetryFirstProcess ||
		shouldRetrySecondProcess ||
		shouldRetryThirdProcess;

	return {
		isLoading,
		onHandleCreationProcess,
		repositoryCreationStatus,
		projectCreationStatus,
		environmentCreationStatus,
		deploymentCreationStatus,
		environmentVariableCreationStatus,
		shouldRetry,
	};
};

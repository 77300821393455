import { GenericErrorTemplate } from '../../shared-components';

export const Error404Page: React.FC = () => (
	<GenericErrorTemplate
		title="Page not found"
		subtext="Error 404"
		text="The page you are looking for cannot be found"
		variant="not-found"
	/>
);

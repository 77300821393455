import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { Link as ReactRouterLink } from 'react-router-dom';

type Props = LinkProps & {
	text: string | null;
	to: string;
};

export const Link: React.FC<Props> = ({ text, to, ...rest }) => (
	<ChakraLink
		as={ReactRouterLink}
		to={to}
		{...rest}
		boxShadow="none !important"
	>
		{text}
	</ChakraLink>
);

export const ExternalLink: React.FC<
	{ href: string; text: string } & LinkProps
> = ({ href, text, ...rest }) => (
	<ChakraLink href={href} isExternal {...rest}>
		{t(text)}
	</ChakraLink>
);

import React from 'react';

export type ReactChildArray = ReturnType<typeof React.Children.toArray>;
export const flattenChildren = (children: React.ReactNode): ReactChildArray => {
	const childrenArray = React.Children.toArray(children);
	return childrenArray.reduce((flatChildren: ReactChildArray, child) => {
		if ((child as React.ReactElement<any>).type === React.Fragment) {
			return flatChildren.concat(
				flattenChildren(
					(child as React.ReactElement<any>).props.children,
				),
			);
		}
		flatChildren.push(child);
		return flatChildren;
	}, []);
};

export const convertChildrenArrayToObj = (childrenArray: any[]) => {
	const childrenKeyValueOnj = {} as { [key: string]: React.ReactElement };
	childrenArray.forEach((child) => {
		const displayName = child.type.displayName;
		if (displayName) {
			childrenKeyValueOnj[displayName] = child as React.ReactElement;
		}
	});
	return childrenKeyValueOnj;
};

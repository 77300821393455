import { Box, Link, Text, Tooltip } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { FC, ReactNode } from 'react';
import { mdiInformationOutline } from '@mdi/js';
import { Icon } from '..';
interface Props {
	title: string;
	subTitle?: string;
	isLink?: boolean;
	linkIcon?: string;
	iconSize?: number;
	onClick?: () => void;
	children?: ReactNode;
	href?: string;
	tooltip?: string;
	titleFontWeight?: string;
	subTitleFontWeight?: string;
}

export const TextBox: FC<Props> = ({
	title,
	subTitle = 'No defined item',
	isLink = false,
	linkIcon,
	iconSize = 20,
	children,
	href,
	tooltip,
	subTitleFontWeight = 'normal',
	titleFontWeight = 'semibold',
	...rest
}) => {
	return (
		<Box>
			<Text fontWeight={titleFontWeight}>
				{t(title)}
				{tooltip && (
					<Tooltip label={tooltip} aria-label="A tooltip">
						<span>
							<Icon
								path={mdiInformationOutline}
								ml={2}
								color="neutral"
							/>
						</span>
					</Tooltip>
				)}
			</Text>
			{isLink ? (
				<Link
					fontWeight={subTitleFontWeight}
					href={href}
					isExternal
					boxShadow="none !important"
					{...{
						...rest,
					}}
				>
					{t(subTitle)}
					<Icon path={linkIcon} fontSize={iconSize} ml="1" />
				</Link>
			) : (
				<Text fontWeight={subTitleFontWeight}>{subTitle}</Text>
			)}
			{children}
		</Box>
	);
};

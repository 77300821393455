import { Heading, HeadingProps } from '@chakra-ui/react';
import { t } from '@transifex/native';

type Props = HeadingProps & {
	title: string;
};

export const XMCSH3: React.FC<Props> = ({ title, ...rest }) => (
	<Heading as="h1" fontSize="1.75rem" {...rest}>
		{t(title)}
	</Heading>
);

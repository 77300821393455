import { FC, useEffect } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import {
	CircularLoading,
	HeaderStack,
	XMCSBreadCrumb,
	XMCSTabs,
} from '../../shared-components';
import {
	goTo,
	mainPaths,
	transferDetailsPaths,
} from '../../../@xmcloud/core/routes/paths';
import { transferDetailsPageItems } from '../../shared-components/breadcrumb/items';
import { transferTabItems } from '../../shared-components/tab/items';
import { useGetTransfer } from '../../services/transfers';
import { transfers } from '../../../@xmcloud/core/messages/en';
import {
	CONTENT_TRANSFER,
	IS_SM_SCREEN,
	useSharedState,
} from '../../../@xmcloud/hooks/context/useSharedState';
import {
	IGetTransferResponse,
	TransferProcessStatus,
} from '../../models/transferModel';
import { Button, Stack } from '@chakra-ui/react';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import { useExperimentalFeatures } from '../../../@xmcloud/hooks/useExperimentalFeatures';

export const TransferDetailsPage: FC = () => {
	const { status } = transferDetailsPaths;
	const { transferId } = useParams<{ transferId: string }>();
	const { pathname } = useLocation();
	const { data: transfer, isLoading } = useGetTransfer({ id: transferId! });
	const isDetailsTabActive = pathname.includes(status);
	const tabIndex = isDetailsTabActive ? 0 : 1;
	const transferName = transfer?.data.name;
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);
	const { contentTransferExpFeat } = useExperimentalFeatures();
	const { state: contentTransferEnabled } = useSharedState(
		CONTENT_TRANSFER,
		contentTransferExpFeat,
	);

	useEffect(() => {
		if (!contentTransferEnabled) {
			//It blocks to reach current page if user try to add the url manually, if feature flag is disabled
			handlePush(mainPaths.noMatch);
		}
	}, [contentTransferEnabled]);

	if (isLoading) return <CircularLoading />;

	return (
		<>
			<Stack gap={4}>
				<XMCSBreadCrumb items={transferDetailsPageItems} />
				{!isSmScreen && (
					<HeaderStack title={transferName}>
						{transfer &&
							!isTransferFinished(
								transfer?.data,
							) /* revert isTransferFinished(transfer)*/ && (
								<Button
									size="sm"
									variant="outline"
									onClick={() =>
										handlePush(
											goTo.transferAgain(transferId!),
										)
									}
								>
									{transfers.transferAgain}
								</Button>
							)}
					</HeaderStack>
				)}

				<XMCSTabs
					tabIndex={tabIndex}
					tabItems={transferTabItems}
					state={{}}
					args={[transferId!]}
				/>
			</Stack>
			<Outlet />
		</>
	);
};
const isTransferFinished = (transfer: IGetTransferResponse): boolean => {
	return (
		transfer.calculatedStatus === TransferProcessStatus.Complete ||
		transfer.calculatedStatus === TransferProcessStatus.Failed ||
		transfer.calculatedStatus === TransferProcessStatus.Cancelled
	);
};

import { FC, useMemo, useState } from 'react';
import { app } from '../../../../@xmcloud/core/messages/en';
import { pageSize } from '../../../../@xmcloud/utils/constants';
import {
	AbstractGetClientResponse,
	GetEnvironmentClientResponse,
} from '../../../models/clientModel';
import { useGetEnvironmentClientList } from '../../../services/clients';
import {
	LoadingSkeleton,
	RenderPlaceholder,
	Table,
} from '../../../shared-components';
import { environmentCredentialsTableColumns } from '../columns/environments';
import RevokeClientConfirmation from './RevokeClientConfirmation';
import { ColumnDef } from '@tanstack/react-table';

const headers = ['name', 'Description', 'Client ID', 'Date created', ''];

const EnvironmentCredentialsTable: FC = () => {
	const [showClientRevokeModal, setShowClientRevokeModal] = useState(false);
	const [selectedClient, setSelectedClient] = useState(
		{} as AbstractGetClientResponse,
	);

	const { data, isLoading } = useGetEnvironmentClientList();
	const clients = data?.data?.items || [];

	const columns: ColumnDef<GetEnvironmentClientResponse, any>[] =
		useMemo(() => {
			return environmentCredentialsTableColumns(
				setSelectedClient,
				setShowClientRevokeModal,
			);
		}, []);

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;
	if (clients.length === 0)
		return <RenderPlaceholder item={app.credentials.toLocaleLowerCase()} />;

	return (
		<>
			<Table
				{...{
					columns,
					tableData: clients,
					dataTestId: 'credentials-environment-table',
					showPagination: clients.length > pageSize,
					initialSort: [{ id: 'createdAt', desc: true }],
					headers,
				}}
			/>
			{showClientRevokeModal && (
				<RevokeClientConfirmation
					client={selectedClient}
					openModal={showClientRevokeModal}
					setOpenModal={setShowClientRevokeModal}
				/>
			)}
		</>
	);
};

export default EnvironmentCredentialsTable;

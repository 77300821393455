import { Stack, Tooltip } from '@chakra-ui/react';
import { FC, useState } from 'react';
import {
	credentials,
	systemHealthStatus,
} from '../../../@xmcloud/core/messages/en';
import { useComponentHealthy, useSharedState } from '../../../@xmcloud/hooks';
import { IS_SM_SCREEN } from '../../../@xmcloud/hooks/context/useSharedState';
import { Footer, XMCSButton } from '../../shared-components';
import OrganizationCredentialsTable from './components/OrganizationCredentialsTable';
import CreateDeployAutomationClient from './CreateDeployAutomationClient';
import { t } from '@transifex/native';

export const OrganizationCredentials: FC = () => {
	const [clientsModalIsOpen, setClientsModalIsOpen] = useState(false);
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);
	const {
		isConfigurationHealthy,
		isLoading: isComponentHealthLoading,
		isError: isComponentHealthError,
	} = useComponentHealthy({});

	const { errMsg4, errMsg1 } = systemHealthStatus;
	const isCreateDisable = !isConfigurationHealthy || isComponentHealthError;

	const toolTipMessage = isComponentHealthError
		? t(errMsg1)
		: t(errMsg4, {
				variableTxt2: 'create credentials',
				variableTxt: 'configuration',
			});

	return (
		<>
			<Stack
				direction="row"
				justify="flex-end"
				mt={6}
				mb={isSmScreen ? 0 : 5}
			>
				{!isSmScreen && (
					<Tooltip
						label={toolTipMessage}
						isDisabled={!isCreateDisable}
						shouldWrapChildren
					>
						<XMCSButton
							text={credentials.create}
							data-testid="create-credentials-button"
							onClick={() => {
								setClientsModalIsOpen(true);
							}}
							isDisabled={isCreateDisable}
							isLoading={isComponentHealthLoading}
						/>
					</Tooltip>
				)}
			</Stack>

			<OrganizationCredentialsTable />
			{Boolean(isSmScreen) && (
				<Footer>
					<Tooltip
						label={toolTipMessage}
						isDisabled={!isCreateDisable}
						shouldWrapChildren
					>
						<XMCSButton
							text={credentials.create}
							data-testid="create-credentials-button"
							onClick={() => {
								setClientsModalIsOpen(true);
							}}
							isDisabled={isCreateDisable}
							isLoading={isComponentHealthLoading}
						/>
					</Tooltip>
				</Footer>
			)}

			{clientsModalIsOpen && (
				<CreateDeployAutomationClient
					openModal={clientsModalIsOpen}
					setOpenModal={setClientsModalIsOpen}
				/>
			)}
		</>
	);
};

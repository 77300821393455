import { AxiosResponse } from 'axios';
import { QueryKey, useQueryClient } from 'react-query';
import { useAuthMutation, useAuthQuery } from '../apiUtils/queryHelper';
import config from '../config/config';
import {
	ICreateClientPayload,
	ICreateClientResponse,
	ICreateEdgeClientPayload,
	ICreateEdgeClientResponse,
	ICreateEnvironmentAutomationClientPayload,
	ICreateEnvironmentAutomationClientResponse,
	ICreateEnvironmentClientPayload,
	ICreateEnvironmentClientResponse,
	ICreateOrganizationAutomationClientPayload,
	ICreateOrganizationAutomationClientResponse,
	IGetEnvironmentClientListResponse,
	IGetOrganizationClientListResponse,
} from '../models/clientModel';

export type CreateEnvironmentClientMutation = ReturnType<
	ReturnType<typeof makeEnvironmentClientMutation>
>;

export type CreateClientMutation = ReturnType<
	ReturnType<typeof makeClientMutation>
>;

type MakeClientConfig = {
	scope: string;
	url: string;
};

const makeClientMutation = <
	TPayload extends ICreateClientPayload,
	TResponse extends ICreateClientResponse,
>(
	config: MakeClientConfig,
	invalidateKey: QueryKey,
) => {
	return () => {
		const queryClient = useQueryClient();

		return useAuthMutation(
			(axiosInstance) =>
				({ client }: { client: TPayload }) => {
					return axiosInstance?.post<
						TPayload,
						AxiosResponse<TResponse>
					>(config.url, client);
				},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(invalidateKey);
				},
			},
			config.scope,
		);
	};
};

const makeEnvironmentClientMutation = (
	config: MakeClientConfig,
	invalidateKey: QueryKey,
) =>
	makeClientMutation<
		ICreateEnvironmentClientPayload,
		ICreateEnvironmentClientResponse
	>(config, invalidateKey);

export const useCreateEdgeClientMutation = makeClientMutation<
	ICreateEdgeClientPayload,
	ICreateEdgeClientResponse
>(config.clients.create_edge, config.clients.get_environment_list.queryKey);

export const useCreateEnvironmentAutomationClientMutation = makeClientMutation<
	ICreateEnvironmentAutomationClientPayload,
	ICreateEnvironmentAutomationClientResponse
>(
	config.clients.create_environment_automation,
	config.clients.get_environment_list.queryKey,
);

export const useCreateOrganizationAutomationClientMutation = makeClientMutation<
	ICreateOrganizationAutomationClientPayload,
	ICreateOrganizationAutomationClientResponse
>(
	config.clients.create_organization_automation,
	config.clients.get_organization_list.queryKey,
);

export const useGetEnvironmentClientList = () => {
	return useAuthQuery(
		[config.clients.get_environment_list.queryKey],
		(axiosInstance) => {
			return axiosInstance?.get<IGetEnvironmentClientListResponse>(
				config.clients.get_environment_list.url,
			);
		},
		{},
		config.clients.get_environment_list.scope,
	);
};

export const useGetOrganizationClientList = () => {
	return useAuthQuery(
		[config.clients.get_organization_list.queryKey],
		(axiosInstance) => {
			return axiosInstance?.get<IGetOrganizationClientListResponse>(
				config.clients.get_organization_list.url,
			);
		},
		{},
		config.clients.get_organization_list.scope,
	);
};

export interface DeleteClientVariables {
	id: string;
	onSuccess?(value: AxiosResponse<any>): void;
}

export const useDeleteClientMutation = (queryKey: QueryKey) => {
	const queryClient = useQueryClient();

	return useAuthMutation(
		(axiosInstance) =>
			({ id, onSuccess }: DeleteClientVariables) => {
				return axiosInstance
					?.delete(config.clients.delete.url(id))
					.then(onSuccess);
			},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(queryKey);
			},
		},
		config.clients.delete.scope,
	);
};

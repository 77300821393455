import { useState, useLayoutEffect } from 'react';
import { Router } from 'react-router-dom';
import { history } from './history/history';
import { useAuth0 } from '@auth0/auth0-react';
import { mainPaths } from './paths';
import { handlePush } from '../../utils/helpers';

export function BrowserRouter({ children }: { children: React.ReactNode }) {
	const [state, setState] = useState({
		action: history.action,
		location: history.location,
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useLayoutEffect(() => history.listen(setState), [history]);
	const { isAuthenticated } = useAuth0();

	if (
		!isAuthenticated &&
		state.location.pathname === mainPaths.systemStatus
	) {
		handlePush(mainPaths.status);
	}

	return (
		<Router
			location={state.location}
			navigationType={state.action}
			navigator={history}
		>
			{children}
		</Router>
	);
}

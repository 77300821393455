import { DeployProcessStatus, IGetDeploymentResponse } from './deploymentModel';

export enum ProcessKeys {
	Provisioning = 'Provisioning',
	PostActions = 'PostActions',
	Deployment = 'Deployment',
	Build = 'Build',
}

export enum ProcessStatusesEnum {
	Pending = 'Not started',
	Running = 'In progress',
	Succeeded = 'Completed',
	Failed = 'Failed',
	Skipped = 'Skipped',
}

export function toProcessStatusEnum(
	status: DeployProcessStatus,
): ProcessStatusesEnum {
	switch (status) {
		case DeployProcessStatus.NotStarted:
			return ProcessStatusesEnum.Pending;
		case DeployProcessStatus.InProgress:
			return ProcessStatusesEnum.Running;
		case DeployProcessStatus.Failed:
			return ProcessStatusesEnum.Failed;
		case DeployProcessStatus.Skipped:
			return ProcessStatusesEnum.Skipped;
		case DeployProcessStatus.Complete:
			return ProcessStatusesEnum.Succeeded;
		default:
			return ProcessStatusesEnum.Pending;
	}
}

// TODO: change react app naming to correspond monitoring service names.
// "LogMessageType" corresponds monitoring "logLevel" name.
export enum LogMessageTypes {
	Info = 'info',
	Error = 'error',
	Warning = 'warn',
}

export type LogMessageType =
	| LogMessageTypes.Info
	| LogMessageTypes.Error
	| LogMessageTypes.Warning;
export type ProcessStatusName =
	| ProcessStatusesEnum.Pending
	| ProcessStatusesEnum.Running
	| ProcessStatusesEnum.Succeeded
	| ProcessStatusesEnum.Failed
	| ProcessStatusesEnum.Skipped;

// Log message item
export interface ILogMessage {
	logLevel: LogMessageType;
	logMessage: string;
	stage: ProcessKeys;
	logTime: string;
}

export interface ILogMessageListItem {
	logLevel: LogMessageType;
	logMessage: string;
	stage: ProcessKeys;
	logTime: string;
	id: number;
}

// Log message group, used to group by process key
export interface ILogMessageGroup {
	key:
		| ProcessKeys.Provisioning
		| ProcessKeys.Build
		| ProcessKeys.Deployment
		| ProcessKeys.PostActions;
	messages: ILogMessage[];
}

// Process status, used to describe current status for particular process
export interface IProcessStatusInfo {
	name: ProcessKeys; // stage name
	state: ProcessStatusName;
	time?: number;
}

export type ProcessGroups = {
	[key in ProcessKeys]: ILogMessageListItem[];
};

export interface IDeployLogContextOptions {
	processGroups: ProcessGroups;
	statuses: IProcessStatusInfo[];
	clearContext: () => void;
	addMessage: (message: ILogMessage) => void;
	addMessages: (messages: ILogMessage[]) => void;
	setStatus: (status: IProcessStatusInfo) => void;
	setStatuses: (statuses: IProcessStatusInfo[]) => void;
	setHistoricalMessages: (messages: ILogMessage[]) => void;
	globalStatus: ProcessStatusesEnum | null;
	deployment?: IGetDeploymentResponse;
}

export interface IHistoricData {
	stage: IProcessStatusInfo[];
	logs: ILogMessage[];
}
export interface IMonitoringHistoricDataResponse {}

export const processKeys: ProcessKeys[] = [
	ProcessKeys.Provisioning,
	ProcessKeys.Build,
	ProcessKeys.Deployment,
	ProcessKeys.PostActions,
];

import { MenuItem, Tooltip, MenuList } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { app, projects } from '../../../../@xmcloud/core/messages/en';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import { IGetProjectResponseV2 } from '../../../models/projectModel';
import { Link, RowActions } from '../../../shared-components';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { handleTo } from '../../../../@xmcloud/utils/helpers';
import { createColumnHelper } from '@tanstack/react-table';

const { deleteProject, canNotBeDeleted, nuOfEnvironments, nuOfEnv } = projects;
const { dateCreated, name, id } = app;

const { environments: environmentsGoto, projectDetails: projectDetailsGoTo } =
	goTo;

export type PickNameId = Pick<IGetProjectResponseV2, 'id' | 'name'>;
export type TProjects = IGetProjectResponseV2 & { nuOfEnvironments: number };

const columnHelper = createColumnHelper<TProjects>();

export const projectsTableColumns = ({
	projectDetails,
	setOpenModal,
	isSmScreen,
	isCreateProjectV3,
}: {
	projectDetails: React.MutableRefObject<PickNameId>;
	setOpenModal: (openModal: boolean) => void;
	isSmScreen: boolean;
	isCreateProjectV3: boolean;
}) => {
	return [
		columnHelper.accessor('name', {
			header: () => t(name),
			cell: (info) => (
				<Link
					text={info.row.original.name}
					to={handleTo(
						isCreateProjectV3
							? projectDetailsGoTo(info.row.original.id)
							: environmentsGoto(info.row.original.id),
					)}
					noOfLines={2}
					whiteSpace="wrap"
				/>
			),
		}),
		columnHelper.accessor('id', {
			header: () => t(id),
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor('nuOfEnvironments', {
			cell: (info) => {
				return info.row.original?.environments.length ?? 0;
			},
			header: () => (isSmScreen ? t(nuOfEnv) : t(nuOfEnvironments)),
		}),
		columnHelper.accessor('lastUpdatedAt', {
			header: () => t(dateCreated),
			cell: (info) => getReadableDate(info.renderValue() as string),
		}),
		columnHelper.display({
			id: 'actions',
			cell: ({ row: { original: project } }) => (
				<RowActions ariaLabel="projects-table-action-menu">
					<MenuList>
						<Tooltip
							label={t(canNotBeDeleted)}
							isDisabled={!project.environments.length}
							shouldWrapChildren
						>
							<MenuItem
								isDisabled={!!project.environments.length}
								onClick={() => {
									projectDetails.current = {
										name: project.name,
										id: project.id,
									};
									setOpenModal(true);
								}}
								minW="3xs"
							>
								{t(deleteProject)}
							</MenuItem>
						</Tooltip>
					</MenuList>
				</RowActions>
			),
			maxSize: 0,
		}),
	];
};

import { useCallback } from 'react';
import { useCreateProject } from './CreateProjectContext';
import {
	connections,
	editProject,
	environments,
	repository,
	secondStep,
} from '../../../../@xmcloud/core/messages/en';
import {
	adoOrganizationNamespaces,
	adoProjectNamespaces,
	environmentNameSpaces,
	EnvironmentTypesEnum,
	providerNameSpaces,
	repositoryNameSpaces,
	sourceControlIntegrationIdNameSpaces,
} from '../helpers';
import { useValidateRepositoryName } from '../../../services/repositories';
import { ESourceControl } from '../../../../@xmcloud/types';

const { ADO } = ESourceControl;
const { EH } = EnvironmentTypesEnum;

const { requiredRepositoryName } = repository;
const { requiredConnection } = connections;
const { orgRequired, projectNameRequired } = secondStep;
const { requiredEnvironmentName } = environments;
const { requiredProvider } = editProject;

export const useValidateCmAndEhSteps = ({
	isIntegrationIdValidationValid,
	integrationValidationErrorMsg,
	nameSpace = 'default',
}: {
	isIntegrationIdValidationValid: boolean;
	integrationValidationErrorMsg: string;
	nameSpace: EnvironmentTypesEnum | 'default';
}) => {
	const { values, errors, setFieldTouched, setFieldError, next } =
		useCreateProject();

	const isByoc = values.isByoc;

	const sourceControlIntegrationIdNameSpace =
		sourceControlIntegrationIdNameSpaces[nameSpace];
	const adoOrganizationNamespace = adoOrganizationNamespaces[nameSpace];
	const adoProjectNamespace = adoProjectNamespaces[nameSpace];
	const repositoryNamespace = repositoryNameSpaces[nameSpace];
	const providerNameSpace = providerNameSpaces[nameSpace];
	const isADOProvider = values[providerNameSpace] === ADO;
	const environmentNameSpace = environmentNameSpaces[nameSpace];

	const sourceControlIntegrationId =
		values[sourceControlIntegrationIdNameSpace];

	const currentRepository = isADOProvider
		? `${values[adoOrganizationNamespace]}/${values[adoProjectNamespace]}/${values[repositoryNamespace]}`
		: values[repositoryNamespace];
	const { refetch, isFetching } = useValidateRepositoryName(
		currentRepository,
		sourceControlIntegrationId,
	);

	const handleNext = useCallback(async () => {
		if (!values.createEditingHostForByoc && isByoc) {
			next();
			return;
		}
		if (!values[environmentNameSpace]) {
			setFieldTouched(environmentNameSpace, true);
			setFieldError(environmentNameSpace, requiredEnvironmentName);
			return;
		}
		if (errors[environmentNameSpace]) return;
		if (nameSpace === EH && !isByoc) {
			next();
			return;
		}
		if (!values[providerNameSpace]) {
			setFieldTouched(providerNameSpace, true);
			setFieldError(providerNameSpace, requiredProvider);
			return;
		}
		if (!sourceControlIntegrationId) {
			setFieldTouched(sourceControlIntegrationIdNameSpace, true);
			setFieldError(
				sourceControlIntegrationIdNameSpace,
				requiredConnection,
			);
			return;
		}
		if (errors[sourceControlIntegrationIdNameSpace]) return;
		if (!isIntegrationIdValidationValid) {
			setFieldError(
				sourceControlIntegrationIdNameSpace,
				integrationValidationErrorMsg,
			);
			return;
		}
		if (isADOProvider) {
			if (!values[adoOrganizationNamespace]) {
				setFieldTouched(adoOrganizationNamespace, true);
				setFieldError(adoOrganizationNamespace, orgRequired);
				return;
			}
			if (!values[adoProjectNamespace]) {
				setFieldTouched(adoProjectNamespace, true);
				setFieldError(adoProjectNamespace, projectNameRequired);
				return;
			}
		}
		if (!values[repositoryNamespace]) {
			setFieldTouched(repositoryNamespace, true);
			setFieldError(repositoryNamespace, requiredRepositoryName);
			return;
		}
		if (errors[repositoryNamespace]) return;

		if (values.isByoc) {
			next();
			return;
		}
		const { data, isError, error } = await refetch();
		const isValidRepositoryName = data?.data.isValid;
		const messageRepositoryName = data?.data.message as string;
		if (!isValidRepositoryName) {
			setFieldError(repositoryNamespace, messageRepositoryName);
			return;
		}
		if (isError) {
			//@ts-ignore
			setFieldError(repositoryNamespace, error?.response?.data?.detail);
			return;
		}

		next();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		sourceControlIntegrationId,
		errors,
		isIntegrationIdValidationValid,
		next,
		values,
		setFieldTouched,
		setFieldError,
		integrationValidationErrorMsg,
	]);

	return {
		handleNext,
		isLoading: isFetching,
	};
};

import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { Link } from 'react-router-dom';
import { goTo } from '../../../@xmcloud/core/routes/paths';
import { handleTo } from '../../../@xmcloud/utils/helpers';
import { TabItems } from './items';

interface Props {
	tabIndex: number;
	tabItems: TabItems[];
	state: { projectName?: string; environmentName?: string; host?: string };
	args: string[];
}

export const XMCSTabs: React.FC<Props> = ({ tabIndex, tabItems, args }) => {
	const formatAndCleanTestId = (fnName: string) => {
		const cleanName = fnName.startsWith('old')
			? fnName.slice(3).charAt(0).toLowerCase() + fnName.slice(4)
			: fnName;

		return cleanName;
	};

	return (
		<Tabs
			index={tabIndex}
			overflowY="hidden"
			mb="5"
			sx={{
				/* Hide scrollbar for Chrome, Safari and Opera */
				'&::-webkit-scrollbar': {
					display: 'none',
				},
				'&': {
					msOverflowStyle: 'none' /* IE and Edge */,
					scrollbarWidth: 'none' /* Firefox */,
				},
			}}
		>
			<TabList>
				{tabItems.map(({ text, fnName }) => (
					<Tab
						key={text}
						data-testid={`tab-${formatAndCleanTestId(fnName)}`}
						// @ts-ignore
						to={handleTo(goTo[fnName](...args))}
						as={Link}
						minW="max-content"
						outline="none !important"
						boxShadow="none !important"
					>
						{t(text)}
					</Tab>
				))}
			</TabList>
		</Tabs>
	);
};

import { useEffect, useMemo } from 'react';
import {
	connections,
	systemHealthStatus,
} from '../../../@xmcloud/core/messages/en';
import { useEditProject } from '../../../@xmcloud/hooks';
import { EnumInNumberSourceControl } from '../../../@xmcloud/types';
import { convertProviderEnum } from '../../../@xmcloud/utils/helpers';
import { useGetRepositoryProviders } from '../../services/repositories';
import { SelectFormControl } from '../../shared-components';
import {
	ACCOUNT,
	ADO_ORGANIZATION,
	ADO_PROJECT_NAME,
	PROVIDER,
	PROVIDER_NAME,
	REPOSITORY,
	REPOSITORY_ID,
	SOURCE_CONTROL_INTEGRATION_ID,
} from '../create-project/helpers';
import { t } from '@transifex/native';

const { errMsg1, providerStatusCanNotSelected, noProviderAvailable } =
	systemHealthStatus;
const { ADO, GitHub } = EnumInNumberSourceControl;

const { chooseProvider, sourceControlProvider } = connections;

export const SelectProvider: React.FC = () => {
	const {
		errors,
		values,
		touched,
		editValues,
		isADOHealthy,
		isGithubHealthy,
		isComponentHealthError,
		isComponentHealthLoading,
		setValues,
		setEditValues,
		setFieldError,
		setFieldTouched,
	} = useEditProject();

	const { editProvider } = editValues;
	const { provider, providerName, hasAccount } = values;

	const enableProviderField = hasAccount && !editProvider;

	const { data: providerData, isLoading: isProvidersLoading } =
		useGetRepositoryProviders(!enableProviderField);

	const providers = providerData?.data;

	const providersOptions = useMemo(() => {
		return (
			providers?.map((p) => {
				const value = convertProviderEnum(p.name, 'number');
				return {
					label: ProviderText[value as EnumInNumberSourceControl],
					value: value as number,
				};
			}) || []
		);
	}, [providers]);

	const hasAnyHealthyProvider = isADOHealthy || isGithubHealthy;
	const isAdoError = provider === ADO && !isADOHealthy;
	const isGithubError = provider === GitHub && !isGithubHealthy;
	const providerHealthError = t(providerStatusCanNotSelected, {
		provider: ProviderText[provider],
	});

	useEffect(() => {
		if (!editProvider) return;
		if (isComponentHealthError) {
			// Get status API failed
			setFieldTouched(PROVIDER_NAME, true);
			setFieldError(PROVIDER_NAME, errMsg1);
		} else if (!hasAnyHealthyProvider) {
			// There is no healthy provider at all
			setFieldTouched(PROVIDER_NAME, true);
			setFieldError(PROVIDER_NAME, noProviderAvailable);
		} else if (isAdoError || isGithubError) {
			setFieldTouched(PROVIDER_NAME, true);
			setFieldError(PROVIDER_NAME, providerHealthError);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [providerName, errors, editProvider, hasAnyHealthyProvider]);

	return (
		<SelectFormControl
			{...{
				error: errors.providerName,
				isInvalid: Boolean(errors.providerName && touched.providerName),
				isLoading: isProvidersLoading || isComponentHealthLoading,
				options: providersOptions,
				showAsPlaceHolder: enableProviderField,
				isEditable: true,
				isDisabled: isComponentHealthError || !hasAnyHealthyProvider,
				onChange: (e: any) => {
					if (values.provider === e.value) return;
					setValues((prev) => ({
						...prev,
						[PROVIDER]: e.value,
						[PROVIDER_NAME]: e.label,
						[SOURCE_CONTROL_INTEGRATION_ID]: '',
						[ACCOUNT]: '',
						[ADO_ORGANIZATION]: '',
						[ADO_PROJECT_NAME]: '',
						[REPOSITORY]: '',
						[REPOSITORY_ID]: '',
					}));
				},
				label: enableProviderField
					? sourceControlProvider
					: chooseProvider,
				name: PROVIDER,
				currentValue: enableProviderField ? providerName : provider,
				onFocus: () => setFieldTouched(PROVIDER_NAME, true),
				onEditClick: () => {
					setEditValues((prev) => ({
						...prev,
						editProvider: true,
						editAccount: true,
						editRepository: true,
						editOrganization: true,
						editProject: true,
					}));
				},
			}}
		/>
	);
};

const { github, ado } = connections;

export const ProviderText = {
	0: '',
	1: github,
	2: ado,
};

import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	ButtonGroup,
	Wrap,
} from '@chakra-ui/react';
import { FC, ReactNode, UIEvent } from 'react';
import { app } from '../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';
import { Size } from '../../../@xmcloud/types';
import styles from '../../../styles/scrollbar.module.css';
import { ColorScheme } from 'chakra-react-select';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	children: ReactNode;
	dataTestId?: string;
	size?: Size;
	scrollBehavior?: 'inside' | 'outside';
}

export const ModalWrapper: FC<Props> = ({
	isOpen,
	onClose,
	title,
	children,
	dataTestId = 'modal-wrapper',
	size = 'md',
	scrollBehavior = 'outside',
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			data-testid={dataTestId}
			size={size}
			scrollBehavior={scrollBehavior}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{t(title)}</ModalHeader>
				<ModalCloseButton />
				{children}
			</ModalContent>
		</Modal>
	);
};

interface ModalMainContentProps {
	onClose: () => void;
	children: ReactNode;
	rightButtonText?: string;
	leftButtonText?: string;
	onConfirm?: () => void;
	isLoading?: boolean;
	type?: 'button' | 'submit';
	buttonGroupFlexDirection?: 'row' | 'row-reverse';
	isDisabled?: boolean;
	showLeftButton?: boolean;
	pt?: number;
	pb?: number;
	analyticsId?: string;
	onScroll?: (e: UIEvent | any) => void;
	colorScheme?: ColorScheme;
}

export const ModalMainContent: FC<ModalMainContentProps> = ({
	children,
	onClose,
	onConfirm,
	rightButtonText = app.save,
	leftButtonText = app.cancel,
	isLoading,
	type = 'submit',
	buttonGroupFlexDirection = 'row',
	isDisabled = false,
	showLeftButton = true,
	colorScheme = 'primary',
	pt = 2,
	pb = 2,
	analyticsId,
	onScroll,
}) => {
	return (
		<>
			<ModalBody
				pt={pt}
				pb={pb}
				className={styles['custom-scrollbar']}
				onScroll={onScroll}
			>
				{children}
			</ModalBody>
			<ModalFooter>
				<ButtonGroup as={Wrap} direction={buttonGroupFlexDirection}>
					{showLeftButton && (
						<Button
							onClick={onClose}
							size="md"
							data-testid="cancel-button"
							variant="ghost"
						>
							{t(leftButtonText)}
						</Button>
					)}
					<Button
						size="md"
						colorScheme={colorScheme}
						onClick={onConfirm}
						isLoading={isLoading}
						type={type}
						isDisabled={isDisabled}
						data-testid={`${rightButtonText}-confirm-button`}
						data-behavior-analytics-id={`confirm-${analyticsId}-button`}
					>
						{t(rightButtonText)}
					</Button>
				</ButtonGroup>
			</ModalFooter>
		</>
	);
};

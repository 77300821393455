import { FC } from 'react';
import {
	Heading,
	HeadingProps,
	Text as SDSText,
	TextProps,
} from '@chakra-ui/react';
import { T } from '@transifex/react';

interface Props extends TextProps {
	text: string;
	boldText?: string;
	boldText2?: string;
}

const Text14B700: FC<Props> = ({ text, ...rest }) => {
	return (
		<SDSText variant="strong" {...rest}>
			{text}
		</SDSText>
	);
};

const TransifexTextAsBold: FC<Props> = ({
	text,
	boldText,
	boldText2,
	as = 'strong',
	...rest
}) => (
	<T
		_str={text}
		bold={
			<SDSText as={as} {...rest}>
				<T _str={boldText ? boldText : ''} />
			</SDSText>
		}
		bold2={
			<SDSText as={as} {...rest}>
				<T _str={boldText2 ? boldText2 : ''} />
			</SDSText>
		}
	/>
);
const Text: FC<Props> = ({ text, ...rest }) => {
	return (
		<SDSText {...rest}>
			<T _str={text || ''} />
		</SDSText>
	);
};
export const HeadingXl: React.FC<Props & HeadingProps> = ({
	text,
	...rest
}) => (
	<Heading fontSize="1.75rem" {...rest}>
		<T _str={text} />
	</Heading>
);
export const HeadingLg: React.FC<Props & HeadingProps> = ({
	text,
	...rest
}) => (
	<Heading size="lg" {...rest}>
		<T _str={text} />
	</Heading>
);
export const HeadingMd: React.FC<Props & HeadingProps> = ({
	text = '',
	...rest
}) => (
	<Heading size="md" {...rest}>
		<T _str={text} />
	</Heading>
);
export const HeadingSm: React.FC<Props & HeadingProps> = ({
	text,
	...rest
}) => (
	<Heading size="sm" {...rest}>
		<T _str={text} />
	</Heading>
);

export const HeadingXs: React.FC<Props & HeadingProps> = ({
	text,
	...rest
}) => (
	<Heading size="xs" {...rest}>
		<T _str={text} />
	</Heading>
);

export { Text14B700, TransifexTextAsBold, Text };

import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertStatus,
	AlertTitle,
	Box,
	Button,
	Spacer,
	Stack,
} from '@chakra-ui/react';
import {
	Card,
	Icon,
	LoadingSkeleton,
	ProgressIcon,
} from '../../../shared-components';
import { t } from '@transifex/native';
import { useParams } from 'react-router';
import { useGetTransferDetails } from '../../../services/transferDetails';
import { convertToTransferDetails } from '../../../services/transferStage';
import { statusIconsTextList } from '../../project/columns/deployments';
import { TransferProcessStatus } from '../../../models/transferModel';
import {
	transferDetails as messages,
	transfers,
} from '../../../../@xmcloud/core/messages/en';
import { ITransferDetails } from '../../../models/transferDetails';
import {
	TCalculatedStatus,
	calculatedAlertStatus,
} from '../../contenttransfer/handlers';
import { useEnvironmentDetails } from '../../../../@xmcloud/hooks';
import { ITransferStage } from '../../../models/transferStage';
import { mdiForward } from '@mdi/js';

export const TransferStatus: React.FC = () => {
	const { transferId } = useParams<{ transferId: string }>();
	const { data, isLoading } = useGetTransferDetails({ id: transferId! });
	const transfer = data?.data;
	let transferDetails;

	if (transfer) {
		transferDetails = convertToTransferDetails(transfer);

		// remove
		transferDetails.calculatedStatus = TransferProcessStatus.Complete;
	}

	const { environment, isLoading: isEnvironmentLoading } =
		useEnvironmentDetails(transferDetails?.targetEnvironmentId!);

	const dashboardUrl = `https://${environment?.host}/sitecore`;

	const calculatedStatus: TCalculatedStatus =
		transferDetails?.calculatedStatus ?? 4;
	const status = calculatedAlertStatus[calculatedStatus] as AlertStatus;

	const stageData: {
		stage: ITransferStage;
		status: any;
		description: string;
	}[] = [
		{
			stage: transferDetails?.transferInitialization!,
			status: getStatusIcon(
				transferDetails?.transferInitialization?.status!,
			),
			description: messages.initializationDescription,
		},
		{
			stage: transferDetails?.transferringContent!,
			status: getStatusIcon(
				transferDetails?.transferringContent?.status!,
			),
			description: messages.transferringContentDescription,
		},
		{
			stage: transferDetails?.movingItemsToDb!,
			status: getStatusIcon(transferDetails?.movingItemsToDb?.status!),
			description: messages.movingToDbDescription,
		},
	];

	return (
		<>
			<Stack
				gap={2}
				display={'flex'}
				flex-direction="column"
				align-items="flex-start"
				pt={6}
			>
				{isLoading || isEnvironmentLoading ? (
					<LoadingSkeleton amount={5} height={24} />
				) : (
					transferDetails && (
						<>
							{(isTransferringContentSuccessful(
								transferDetails,
							) ||
								isTransferSuccessful(transferDetails)) && (
								<Alert
									status={status}
									mt="0"
									mb="4"
									minH="14"
									display="flex"
									flexWrap="wrap"
								>
									<Box>
										<AlertIcon />
									</Box>
									<Box>
										<AlertTitle>
											{t(
												isTransferSuccessful(
													transferDetails,
												)
													? messages.transferCompleted
													: messages.transferringContentCompleted,
											)}
										</AlertTitle>
										{!isTransferSuccessful(
											transferDetails,
										) && (
											<AlertDescription>
												{t(
													messages.canEditItemsMessage,
												)}
											</AlertDescription>
										)}
									</Box>

									<Spacer />
									<Box>
										<Button
											size="sm"
											variant="outline"
											onClick={() =>
												window.open(dashboardUrl)
											}
											rightIcon={
												<Icon path={mdiForward} />
											}
										>
											{transfers.goToXmc}
										</Button>
									</Box>
								</Alert>
							)}
							{stageData.map((data) => (
								<Card
									key={data.stage.id}
									title={data.stage.name}
									mb={0}
									description={data.description}
									errors={data.stage.errors}
								>
									<ProgressIcon
										{...{
											...(data.status?.value === 0 && {
												value: 0,
											}),
											...(data.status
												?.isIndeterminate && {
												isIndeterminate: true,
											}),
											...(data.status?.IconName && {
												IconName: data.status.IconName,
											}),
											...(data.status?.iconColor && {
												iconColor:
													data.status.iconColor,
											}),
										}}
									/>
								</Card>
							))}
						</>
					)
				)}
			</Stack>
		</>
	);
};

const getStatusIcon = (status: TransferProcessStatus) => {
	return statusIconsTextList[status];
};

const isTransferSuccessful = (transfer: ITransferDetails): boolean =>
	transfer.calculatedStatus === TransferProcessStatus.Complete;

const isTransferringContentSuccessful = (transfer: ITransferDetails): boolean =>
	transfer.transferInitialization.status === TransferProcessStatus.Complete &&
	transfer.transferringContent.status === TransferProcessStatus.Complete;

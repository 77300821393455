import type { SyntheticEvent } from 'react';
import { memo, useRef, useMemo, useCallback } from 'react';
import { chakra, IconButton } from '@chakra-ui/react';

import {
	Checkbox,
	Box,
	HStack,
	MenuItem,
	Button,
	Tooltip,
} from '@chakra-ui/react';
import { useTree } from './useTreeContext';
import {
	mdiChevronDown,
	mdiChevronRight,
	mdiFileOutline,
	mdiFolderOutline,
	mdiSourceBranch,
	mdiSourceBranchRemove,
	mdiTriangleSmallDown,
} from '@mdi/js';
import { ContextMenu, Icon } from '../../../../shared-components';
import { t } from '@transifex/native';
import { TransferConfigurationNodeType } from '../../../../models/transferConfiguration';
import { mdiFileTree } from '@mdi/js';
import { createTransfer } from '../../../../../@xmcloud/core/messages/en';
import { Maybe } from 'yup';

export const UITreeNode = memo(
	({
		root,
		node,
		path,
		index,
		isReadOnly = false,
		isDisabled = false,
	}: UITreeNodeProps) => {
		const {
			findDescendantKeys,
			selectionKeys,
			setSelectionKeys,
			expandedKeys,
			setExpandedKeys,
			intermediates,
			isNodeLeaf,
			isRootSelected,
			toggleRoot,
		} = useTree();

		const contentRef = useRef<any>(null);
		const descendants = useMemo<TreeNode[]>(
			() => node.descendants ?? [],
			[node.descendants],
		);

		const isRoot = useMemo<boolean>(() => {
			return node.path === '/sitecore';
		}, [node]);

		const isExpanded = useMemo<boolean>(
			() => Object.prototype.hasOwnProperty.call(expandedKeys, node.key),
			[expandedKeys, node.key],
		);

		const isChecked = useMemo<boolean>(() => {
			const selection = selectionKeys[node.key];
			return selection?.checked ?? false;
		}, [node.key, selectionKeys]);

		const isBranch = useMemo<boolean>(() => {
			const selection = selectionKeys[node.key];
			return selection?.isBranch ?? false;
		}, [node.key, selectionKeys]);

		const isNodeDisabled = useMemo<boolean>(() => {
			const selection = selectionKeys[node.key];
			return selection?.isDisabled ?? false;
		}, [node.key, selectionKeys]);

		const isChildrenChecked = useMemo<boolean>(() => {
			const descendants = findDescendantKeys(node);
			const keys: TreeNode[] = [];
			descendants.forEach((key) => {
				if (selectionKeys[key]) {
					keys.push(selectionKeys[key]);
				}
			});

			return keys.every((item) => item.checked);
		}, [selectionKeys, findDescendantKeys, node]);

		const isIndeterminate = useMemo<boolean>(() => {
			if (
				node.key === 'all' &&
				Object.values(intermediates).some((bool) => bool)
			) {
				return true;
			}
			return intermediates[node.key] ?? false;
		}, [node.key, intermediates]);

		const expand = useCallback((): void => {
			const keys: TreeExpandedKeys = {
				...expandedKeys,
				[node.key]: true,
			};
			setExpandedKeys(keys);
		}, [node.key, expandedKeys, setExpandedKeys]);

		const collapse = useCallback((): void => {
			const keys = Object.keys(expandedKeys).reduce(
				(acc: TreeExpandedKeys, key: string) => {
					if (isRoot) {
						acc[node.key] = true;
					} else {
						if (key === node.key) return acc;
						acc[key] = true;
					}

					return acc;
				},
				{},
			);
			setExpandedKeys(keys);
		}, [node.key, expandedKeys, setExpandedKeys, isRoot]);

		const toggleSelectionKeys = useCallback(
			(checked: boolean): void => {
				const descendants = findDescendantKeys(node);
				const keys = {
					...selectionKeys,
					[node.key]: {
						...selectionKeys[node.key],
						checked,
						intermediate: false,
					},
					...descendants.reduce((acc: any, key: string) => {
						acc[key] = {
							...selectionKeys[key],
							checked,
							intermediate: false,
						};
						return acc;
					}, {}),
				};

				setSelectionKeys(keys);
			},
			[node, selectionKeys, setSelectionKeys, findDescendantKeys],
		);

		const toggleBranch = useCallback(
			(checked: boolean): void => {
				const descendants = findDescendantKeys(node);
				const keys = {
					...selectionKeys,
					[node.key]: {
						...selectionKeys[node.key],
						checked,
						intermediate: false,
						isBranch: checked,
						isDisabled: checked,
					},
					...descendants.reduce((acc: any, key: string) => {
						acc[key] = {
							...selectionKeys[key],
							checked,
							intermediate: false,
							isDisabled: checked,
							isBranch: checked ? false : checked,
						};
						return acc;
					}, {}),
				};

				setSelectionKeys(keys);
			},
			[node, selectionKeys, setSelectionKeys, findDescendantKeys],
		);

		const onTogglerClick = useCallback(
			(event: SyntheticEvent<HTMLButtonElement>): void => {
				if (isExpanded) collapse();
				else expand();

				event.preventDefault();
				event.stopPropagation();
			},
			[collapse, expand, isExpanded],
		);

		const selectCurrentNode = useCallback(
			(checked: boolean): void => {
				const keys = {
					...selectionKeys,
					[node.key]: {
						...selectionKeys[node.key],
						checked,
						intermediate: false,
					},
				};

				setSelectionKeys(keys);
			},
			[node, selectionKeys, setSelectionKeys],
		);

		const selectChildren = useCallback(
			(checked: boolean): void => {
				const descendants = findDescendantKeys(node);
				const keys = {
					...selectionKeys,
					...descendants.reduce((acc: any, key: string) => {
						acc[key] = {
							...selectionKeys[key],
							checked,
							intermediate: false,
						};
						return acc;
					}, {}),
				};

				setSelectionKeys(keys);
			},
			[node, selectionKeys, setSelectionKeys, findDescendantKeys],
		);

		const handleRootToggleClick = useCallback(() => {
			toggleBranch(!isChecked);
			toggleRoot(!isChecked);

			if (isChecked) expand();
			else collapse();
		}, [isChecked, collapse, expand, toggleBranch, toggleRoot]);

		const createLabel = (): JSX.Element => (
			<Box as="span">{node.label}</Box>
		);

		const createCheckbox = (): JSX.Element => (
			<Checkbox
				size="md"
				isChecked={isChecked}
				isIndeterminate={isNodeDisabled ? false : isIndeterminate}
				isReadOnly={isReadOnly}
				isDisabled={isNodeDisabled}
				onChange={(event) => {
					selectCurrentNode(event.currentTarget.checked);
				}}
			/>
		);

		const createIcon = (): JSX.Element => {
			switch (node.type) {
				case TransferConfigurationNodeType.Folder:
					return <Icon path={mdiFolderOutline} boxSize={5} />;
				case TransferConfigurationNodeType.File:
					return <Icon path={mdiFileOutline} boxSize={5} />;
				default:
					return <Icon path={mdiFileOutline} boxSize={5} />;
			}
		};

		const createToggler = (): Maybe<JSX.Element> => {
			if (!descendants?.length) return null;

			return (
				<IconButton
					aria-label="Expand"
					size="xs"
					variant="toggle"
					onClick={onTogglerClick}
					_hover={{
						bg: 'primary-bg',
						color: 'primary-fg',
					}}
					icon={
						<Icon
							path={isExpanded ? mdiChevronDown : mdiChevronRight}
						/>
					}
				/>
			);
		};

		const createPopover = (): Maybe<JSX.Element> => {
			if (!descendants?.length || isNodeDisabled) return null;

			return (
				<Tooltip
					label={t(createTransfer.selectOptionsTooltip)}
					shouldWrapChildren
				>
					<ContextMenu
						size="xs"
						icon={<Icon path={mdiTriangleSmallDown} />}
					>
						<MenuItem
							onClick={() => {
								selectCurrentNode(!isChecked);
							}}
						>
							{t(
								`${
									isChecked
										? createTransfer.unselectParentTreeNode
										: createTransfer.selectParentTreeNode
								}"${node.label}"`,
							)}
						</MenuItem>
						<MenuItem
							onClick={() => {
								selectChildren(!isChildrenChecked);
							}}
						>
							{t(
								`${
									isChildrenChecked
										? createTransfer.unselectSubItemsOfParentTreeNode
										: createTransfer.selectSubItemsOfParentTreeNode
								}"${node.label}"`,
							)}
						</MenuItem>
						<MenuItem
							onClick={() => {
								toggleSelectionKeys(!isChecked);
							}}
						>
							{t(
								`${
									isChecked
										? createTransfer.unselectParentTreeNode
										: createTransfer.selectParentTreeNode
								}"${node.label}" ${
									createTransfer.andSubItemsOfParentTreeNode
								}`,
							)}
						</MenuItem>
					</ContextMenu>
				</Tooltip>
			);
		};

		const createBranch = (): Maybe<JSX.Element> => {
			if (
				!descendants?.length ||
				isRootSelected ||
				(isNodeDisabled && !isBranch)
			)
				return null;

			return (
				<Tooltip
					label={t(createTransfer.selectAsBranchTooltip)}
					shouldWrapChildren
				>
					<IconButton
						size="xs"
						variant="toggle"
						aria-label="Expand"
						onClick={() => {
							toggleBranch(!isChecked);
						}}
						cursor="pointer"
						icon={
							<Icon
								path={
									isBranch
										? mdiSourceBranchRemove
										: mdiSourceBranch
								}
							/>
						}
					/>
				</Tooltip>
			);
		};

		const createContent = (): JSX.Element => (
			<Box
				role="treeitem"
				ref={contentRef}
				tabIndex={0}
				aria-posinset={index + 1}
				aria-expanded={isExpanded}
				aria-selected={isChecked}
				sx={{
					pl: descendants?.length ? 0 : 14,
				}}
			>
				{
					<>
						{!isRoot && (
							<HStack py={1}>
								{createToggler()}
								{createBranch()}
								{createCheckbox()}
								{createPopover()}
								{createIcon()}
								{createLabel()}
							</HStack>
						)}
						<HStack pl={6}>{createChildren()}</HStack>
					</>
				}
			</Box>
		);

		const createChildren = (): Maybe<JSX.Element> => {
			if (!descendants?.length || !isExpanded) return null;

			return (
				<chakra.ul
					role="group"
					sx={{
						pl: isNodeLeaf(node) ? 0 : 10,
						listStyleType: 'none',
					}}
				>
					{descendants.map((descendant: TreeNode, key: number) => (
						<UITreeNode
							key={descendant.key}
							node={descendant}
							parent={node}
							root={root}
							index={key}
							last={key === descendants.length - 1}
							path={`${path}-${key}`}
							isReadOnly={isReadOnly}
							isDisabled={isDisabled}
						/>
					))}
				</chakra.ul>
			);
		};

		const createRoot = (): Maybe<JSX.Element> => {
			if (!isRoot) return null;

			return (
				<HStack>
					<Box>
						<Button
							leftIcon={<Icon path={mdiFileTree} />}
							justifyContent="flex-start"
							variant="ghost"
							colorScheme={isRootSelected ? 'primary' : 'neutral'}
							onClick={handleRootToggleClick}
						>
							{t(
								isRootSelected
									? createTransfer.unSelectEntireTreeLabel
									: createTransfer.selectEntireTreeLabel,
							)}
						</Button>
					</Box>
				</HStack>
			);
		};

		const createNode = (): JSX.Element => (
			<chakra.li
				sx={{
					pl: isNodeLeaf(node) ? 2 : 1,
				}}
				py={2}
			>
				{createRoot()}
				{createContent()}
			</chakra.li>
		);

		return createNode();
	},
);

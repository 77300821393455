import { useEffect, useMemo, useState } from 'react';
import {
	AlertDialogConfirmationModal,
	BoxFrame,
	CodeBlock,
	DownloadButton,
	HeadingSm,
	InfoIcon,
	SelectFormControl,
	ToggleStack,
	TransifexTextAsBold,
} from '../shared-components';
import { IGetProjectResponse } from '../models/projectModel';
import { IGetEnvironmentResponse } from '../models/environmentModel';
import { app, devSettings } from '../../@xmcloud/core/messages/en';
import { downloadLogs, isPhoneDevice } from '../../@xmcloud/utils/helpers';
import {
	Stack,
	TabList,
	Tab,
	Divider,
	Text,
	Link,
	Wrap,
} from '@chakra-ui/react';
import { edgePlatformUrl, environment } from '../config/envConfig';
import {
	EnvironmentClass,
	siteDeveloperSettingsLink,
} from '../../@xmcloud/utils/constants';
import { EnumInNumberSourceControl } from '../../@xmcloud/types';
import { fetchLastSuccessfulLoginOrganizationId } from '../auth/Auth0Storage';
import { t } from '@transifex/native';

const {
	subTitle1,
	subTitle2,
	subTitle3,
	download,
	chooseSite,
	preview,
	live,
	ContextId,
	info1,
	info2,
	title,
	title1,
	info,
	txt1,
	txt2,
	txt3,
	txt4,
	noSite,
	txt5,
} = devSettings;
const SITE_NAME = 'siteName';
const organizationId = fetchLastSuccessfulLoginOrganizationId();

export const OLDCombinedProjectDevSettings: React.FC<{
	project: IGetProjectResponse;
	environmentDetail: IGetEnvironmentResponse;
	editingSecret: string;
	isEditingSecretAvailable: boolean;
	sites: any[];
	provider: string | number;
}> = ({
	project,
	environmentDetail,
	editingSecret,
	isEditingSecretAvailable,
	sites = [],
	provider,
}) => {
	const [isPreview, setIsPreview] = useState(true);
	const [siteName, setSiteName] = useState('');
	const [openInfo, setOpenInfo] = useState({ isOpen: false, info: 1 });

	const previewContextId = environmentDetail?.previewContextId || '';
	const liveContextId = environmentDetail?.liveContextId || '';

	const isProduction = environment === EnvironmentClass.prod;
	const repository = project?.repository || '';
	const isAdoProvider = provider === EnumInNumberSourceControl.ADO;
	const isGitProvider = provider === EnumInNumberSourceControl.GitHub;
	const adoRepo = project?.repository?.split('/') || [];

	const gitClone = `git clone https://github.com/${project?.sourceControlIntegrationName}/${repository}.git`;
	const adoClone = `git clone https://dev.azure.com/${adoRepo[0]}/${adoRepo[1]}/_git/${adoRepo[2]}`;

	const envVariablesLines = [
		...(!isProduction ? [`SITECORE_EDGE_URL=${edgePlatformUrl}`] : []),
		`SITECORE_EDGE_CONTEXT_ID=${
			isPreview ? previewContextId : liveContextId
		}`,
		`SITECORE_SITE_NAME=${siteName}`,
		...(isPreview
			? [
					isEditingSecretAvailable
						? `JSS_EDITING_SECRET=${editingSecret}`
						: `#JSS_EDITING_SECRET=${devSettings.editingSecretNotFound}`,
				]
			: []),
	];

	const checkoutCodeLines = [
		`${
			isAdoProvider
				? adoClone
				: isGitProvider
					? gitClone
					: 'clone your repository'
		}`,
		`cd ${repository}\\src`,
	];

	const runCodeLines = [`npm install`, `npm run start:connected`];

	const siteList = useMemo(
		() => sites.map((s) => ({ label: s.name, value: s.name })),
		[sites],
	);
	const platformTenantName = environmentDetail?.platformTenantName || '';
	const siteDevSettingshref = siteDeveloperSettingsLink({
		platformTenantName,
		organizationId,
		siteName,
	});

	useEffect(() => {
		if (sites.length && !siteName) {
			setSiteName(sites[0].name);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sites.length]);

	return (
		<>
			<Stack
				w="100%"
				py={6}
				direction={['column', 'column', 'row', 'row']}
				gap={8}
			>
				<Stack w={['100%', '100%', '70%', '70%']}>
					<HeadingSm text={title} />
					<Stack
						direction="row"
						gap={6}
						pb="4"
						flexWrap="wrap"
						bgColor={['chakra-subtle-bg', 'chakra-body-bg']}
					>
						<SelectFormControl
							{...{
								options: siteList,
								onChange: (e: any) => {
									setSiteName(e.value);
								},
								label: chooseSite,
								name: SITE_NAME,
								currentValue: siteName,
								maxW: 'xs',
								isDisabled: !siteList.length,
								pt: 0,
								showInfoIcon: true,
								infoText: info1,
								placeholder: siteList.length
									? 'Select...'
									: noSite,
								onInfoIconClick: () =>
									isPhoneDevice &&
									setOpenInfo({ isOpen: true, info: 1 }),
							}}
						/>
						<Stack>
							<Text variant="subtle" fontWeight="semibold">
								{t(ContextId)}
								<InfoIcon
									{...{
										label: info2,
										ml: 1.5,
										onClick: () =>
											isPhoneDevice &&
											setOpenInfo({
												isOpen: true,
												info: 2,
											}),
									}}
								/>
							</Text>
							<ToggleStack bgColor="chakra-body-bg">
								<TabList>
									<Tab
										onClick={() => setIsPreview(true)}
										data-testid="contextid-preview-button"
									>
										{t(preview)}
									</Tab>
									<Tab
										onClick={() => setIsPreview(false)}
										data-testid="contextid-live-button"
									>
										{t(live)}
									</Tab>
								</TabList>
							</ToggleStack>
						</Stack>
					</Stack>
					<CodeBlock
						items={envVariablesLines}
						clipboardText={envVariablesLines.join('\n')}
						data-testid="code-block"
						key="environment-code-block"
					/>
					<Divider py="3" />
					<Stack pt={[10, 10, 5, 5]}>
						<HeadingSm text={title1} />
						<Stack gap={5}>
							<Stack>
								<Text pb={[4, 4, 1, 1]}>{subTitle1}</Text>
								<CodeBlock
									items={checkoutCodeLines}
									clipboardText={checkoutCodeLines.join('\n')}
									data-testid="code-block"
									key="checkout-code-block"
								/>
							</Stack>
							<Wrap>
								<Text w="full">{subTitle2}</Text>
								<DownloadButton
									text={download}
									onClick={() => {
										downloadLogs(
											envVariablesLines.join('\n'),
											'.env.local',
										);
									}}
									data-testid="download-env-button"
								/>
							</Wrap>
							<Stack>
								<Text>{subTitle3}</Text>
								<CodeBlock
									items={runCodeLines}
									clipboardText={runCodeLines.join('\n')}
									data-testid="code-block"
									key="run-code-block"
								/>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
				<Stack w={['100%', '100%', '30%', '30%']} pt={[2, 2, 8, 8]}>
					<BoxFrame
						bg="chakra-subtle-bg"
						px={['3', '4', '5', '5']}
						py={['3', '4', '5', '5']}
						w="100%"
						h="100%"
					>
						<Text py={2} fontSize="xl" fontWeight="semibold">
							{t(info)}
						</Text>
						<Text py={3}>{t(txt1)}</Text>
						<Text py={3}>{t(txt2)}</Text>
						<Text pt={3} pb={6}>
							{t(txt3)}
							{!siteName ? (
								t(txt5)
							) : (
								<Link
									isExternal
									href={siteDevSettingshref}
									boxShadow="none !important"
									data-testid="site-dev-settings-link"
								>
									{t(txt5)}
								</Link>
							)}
						</Text>
						<TransifexTextAsBold
							text={t(txt4)}
							boldText={'Site'}
							boldText2={'Context'}
						/>
					</BoxFrame>
				</Stack>
			</Stack>
			{openInfo.isOpen && (
				<AlertDialogConfirmationModal
					{...{
						isOpen: openInfo.isOpen,
						onClose: () => setOpenInfo({ isOpen: false, info: 1 }),
						shouldShowActionBtn: false,
						rightButtonText: app.close,
					}}
				>
					<Text mt="4">{t(openInfo.info === 1 ? info1 : info2)}</Text>
				</AlertDialogConfirmationModal>
			)}
		</>
	);
};

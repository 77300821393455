import { FC } from 'react';
import {
	ModalMainContent,
	ModalWrapper,
	CopyTextControl,
} from '../../../shared-components';
import { credentials } from '../../../../@xmcloud/core/messages/en';
import { Stack } from '@chakra-ui/react';
import {
	AuthenticationClientType,
	ICreateClientResponse,
} from '../../../models/clientModel';

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
	clientType: AuthenticationClientType;
	client: ICreateClientResponse;
}

const clientTypeLabel = {
	[AuthenticationClientType.AutomationClient]: credentials.automationClient,
	[AuthenticationClientType.EdgeAdministrationClient]: credentials.edgeClient,
	[AuthenticationClientType.OrganizationAutomationClient]:
		credentials.automationClient,
};

const ClientCreatedModal: FC<Props> = ({
	openModal,
	setOpenModal,
	clientType,
	client,
}) => {
	return (
		<ModalWrapper
			size="2xl"
			isOpen={openModal}
			title={clientTypeLabel[clientType]}
			onClose={() => setOpenModal(false)}
		>
			<ModalMainContent
				onClose={() => setOpenModal(false)}
				onConfirm={() => setOpenModal(false)}
				rightButtonText={credentials.done}
				showLeftButton={false}
				type="button"
			>
				<Stack spacing="6">
					<CopyTextControl
						title={credentials.clientId}
						description={client.clientId || ''}
					/>
					<CopyTextControl
						title={credentials.clientSecret}
						description={client.clientSecret || ''}
						helperText={credentials.clientSecretWarningText}
					/>
				</Stack>
			</ModalMainContent>
		</ModalWrapper>
	);
};

export default ClientCreatedModal;

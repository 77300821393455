import { ITenantDetails } from '../app/models/platformInventoryModel';

export const tenantDetailsMock: ITenantDetails = {
	id: '1eef0bad-0988-4c65-cb97-08dc8a173f8c',
	systemId: '8db0ad22-445f-43fb-8d8e-f23c9396c974',
	name: 'mock-name',
	displayName: 'mock-display-name',
	organizationId: 'test-org-id',
	annotations: {
		URL: 'https://mock-name.sitecore-staging.cloud/sitecore',
		'XMCloud.ProjectName': 'suma',
		'XMCloud.ProjectId': '4wapsIDLue4DVThA7lf6IX',
		'XMCloud.EnvironmentName': 'Env-12',
		'XMCloud.CustomerEnvironmentType': 'nonprod',
		'XMCloud.CDPEmbeddedTenantID': '0b660dcd-69f8-4eb7-45e3-08dbca4c7c1f',
		'TenantClaims.cdp_client_key': 'xmuat7c0kxc4xr13vd6k6bpkznbrj852',
		'XMCloud.EnvironmentId': '5GBtzZJ7diiqqmpnNUYSuU',
	},
	labels: {
		CustomerEnvironmentType: 'nonprod',
		Environment: 'dev',
		ProductCode: 'XMCloud',
		Region: 'West Europe',
		RegionCode: 'euw',
	},
	state: 'Active',
	region: null,
};

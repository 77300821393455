import { environment as envLabels } from '../../../../@xmcloud/core/messages/en';
import { useGetEdgeToken } from '../../../services/environments';
import { useParams } from 'react-router-dom';
import { edgeUrl } from '../../../config/envConfig';
import { ToggledTokenButton } from './ToggledTokenButton';
import { useState } from 'react';

const {
	edgeDeliveryEndpoint,
	generateDeliveryToken,
	generateDeliveryTokenTooltip,
} = envLabels;

const EDGE_IDE_URL = `${edgeUrl}/api/graphql/ide`;

const EdgeToken: React.FC = () => {
	const { environmentId } = useParams<{
		environmentId: string;
	}>();
	const [token, setToken] = useState('');

	const { refetch, isFetching } = useGetEdgeToken({
		environmentId: environmentId!,
		enabled: false,
	});

	return (
		<ToggledTokenButton
			{...{
				title: edgeDeliveryEndpoint,
				tooltip: generateDeliveryTokenTooltip,
				isLoading: isFetching,
				btnText: generateDeliveryToken,
				onClick: () => {
					refetch().then((response) => {
						setToken(response?.data?.data?.apiKey || '');
					});
				},
				href: EDGE_IDE_URL,
				token,
			}}
		/>
	);
};

export default EdgeToken;

export enum TransferProcessStatus {
	NotStarted,
	InProgress,
	Complete,
	Failed,
	Cancelled,
}

export enum TransferValidationStatus {
	Resolved = 1,
	Failed = 3,
}

export interface IGetTransferResponse {
	organizationId: string;
	environmentId: string;
	id: string;
	name: string;
	sourceEnvironmentId: string;
	targetEnvironmentId: string;
	calculatedStatus: TransferProcessStatus;
	createdBy: string;
	createdAt: string;
	startedAt: string;
	completedAt: string | null;
	isCanceled: boolean;
	totalItemCount: number;
	processedItemCount: number;
	skippedItemCount: number;
}

export interface ICreateTransferResponse {
	createdAt: string;
	createdBy: string;
	environmentId: string;
	id: string;
	organizationId: string;
	projectId: string;
}

export type IPaginatedTransferResponse = {
	totalCount: number;
	pageSize: number;
	pageNumber: number;
	data: IGetTransferResponse[];
};

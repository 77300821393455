import { VStack } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { environmentVariable } from '../../../@xmcloud/core/messages/en';
import {
	InputFormControl,
	ModalMainContent,
	ModalWrapper,
	SwitchFormControl,
} from '../../shared-components';
import { useEnvVars } from './EnvVarsContext';
import { EnvVariableInput, type EnvVariable } from './envVarsModel';
import texts from './envVarsTexts';
import { t as tf } from '@transifex/native';
import { AxiosResponse } from 'axios';
import { IEnvironmentVariablePayload } from '../../models/environmentModel';

const { EnvVarsForm: t } = texts;

type EnvVarsFormProps = {
	onMutationSubmit?: (
		values: EnvVariableInput,
	) => Promise<AxiosResponse<IEnvironmentVariablePayload, any>>;
};

/**
 * Form for adding/editing environment variables.
 * @param onMutationSubmit, callback to be called when a variable is created/updated, to trigger the API mutation if needed
 */
function EnvVarsForm({
	onMutationSubmit: onVariableMutation,
}: EnvVarsFormProps): JSX.Element {
	const {
		variables,
		addVariable,
		updateVariable,
		isFormVisible,
		toggleFormVisibility,
		selectedVariable,
		setSelectedVariable,
		isLoading,
		setLoading,
		isExistingProject,
	} = useEnvVars();

	const validate = (values: EnvVariable) => {
		const errors: Partial<EnvVariable> = {};
		if (
			variables.some(
				(variable) =>
					variable.name === values.name &&
					variable.id !== selectedVariable?.id,
			)
		) {
			errors.name = t.messages.duplicateName;
		}
		return errors;
	};

	const {
		values,
		handleSubmit,
		handleChange,
		setFieldValue,
		errors,
		setErrors,
		resetForm,
	} = useFormik({
		initialValues: {
			id: selectedVariable?.id || '',
			name: selectedVariable?.name || '',
			value: selectedVariable?.value || '',
			secret: selectedVariable?.secret || false,
		},
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: envVariableSchema,
		validate,
		/**
		 * if API mutation is present, handles the form submission by calling the API mutation.
		 * If not present, it only adds or updates the variable in the context.
		 * We apply Realistic UI instead of Optimistic UI due to complexity of rollbacks. That means we don't update the UI until the API call is successful.
		 * @param values {EnvVariable} - form values
		 */
		onSubmit: async (values) => {
			try {
				if (onVariableMutation) {
					setLoading(true);
					await onVariableMutation(values);
					return handleClose();
				}
				if (selectedVariable) {
					updateVariable(values);
				} else {
					addVariable(values);
				}
				handleClose();
			} catch (error) {
				console.error('Error handling variable:', error);
			} finally {
				setLoading(false);
			}
		},
	});

	/**
	 * This is a controlled input, so we need to keep track of the input value separately from the formik values,
	 * to be able to show the correct value when the form is opened for an existing project.
	 */
	const [inputValue, setInputValue] = useState(
		isExistingProject ? '' : values.value,
	);

	const handleFieldChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = e.target;
			handleChange(e);

			if (errors[name as keyof typeof errors]) {
				setErrors({ ...errors, [name]: undefined });
			}
			if (name === 'value') {
				setInputValue(value);
			}
		},
		[errors, handleChange, setErrors],
	);

	const handleClose = () => {
		setErrors({});
		resetForm();
		setInputValue('');
		setSelectedVariable(null);
		toggleFormVisibility();
	};

	const getValueInputProps = useCallback(() => {
		const isSecretAndExisting =
			selectedVariable?.secret && isExistingProject;
		const inputValueToUse = isSecretAndExisting ? inputValue : values.value;

		return {
			label: t.labels.value,
			name: 'value',
			error: errors.value || '',
			value: inputValueToUse,
			onChange: handleFieldChange,
			isRequired: true,
			isInvalid: Boolean(errors.value),
		};
	}, [
		errors.value,
		handleFieldChange,
		inputValue,
		isExistingProject,
		selectedVariable?.secret,
		values.value,
	]);

	return (
		<ModalWrapper
			isOpen={isFormVisible}
			onClose={handleClose}
			title={selectedVariable ? tf(t.titles.edit) : tf(t.titles.add)}
			size="lg"
		>
			<form>
				<ModalMainContent
					onClose={handleClose}
					onConfirm={handleSubmit}
					rightButtonText={tf(t.buttons.save)}
					leftButtonText={tf(t.buttons.cancel)}
					isLoading={isLoading}
					isDisabled={isLoading}
				>
					<VStack spacing={6}>
						<InputFormControl
							label={tf(t.labels.name)}
							name={'name'}
							value={values.name}
							onChange={handleFieldChange}
							error={errors.name}
							isRequired
							isInvalid={Boolean(errors.name)}
							isReadOnly={isExistingProject && !!selectedVariable}
						/>
						<InputFormControl {...getValueInputProps()} />
						<SwitchFormControl
							label={tf(t.labels.secret)}
							name={'secret'}
							isChecked={values.secret}
							onChange={(e: any) =>
								setFieldValue('secret', e.target.checked)
							}
							isDisabled={isExistingProject && !!selectedVariable}
							infoText={tf(t.placeholders.secret)}
							helperText={tf(t.placeholders.secretHelper)}
						/>
					</VStack>
				</ModalMainContent>
			</form>
		</ModalWrapper>
	);
}

const envVariableSchema = Yup.object().shape({
	name: Yup.string()
		.trim()
		.lowercase()
		.required(tf(environmentVariable.requiredName))
		.matches(
			/^[A-Za-z_][A-Za-z_0-9]*$/g,
			tf(environmentVariable.invalidCharacters),
		),
	value: Yup.string()
		.trim()
		.matches(/^[^"]*$/, tf(environmentVariable.doubleQuoteNotAllowed))
		.required(tf(environmentVariable.requiredValue)),
	secret: Yup.boolean(),
});

export { EnvVarsForm };

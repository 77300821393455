import {
	AuthenticationClientType,
	GetEnvironmentClientResponse,
	GetOrganizationClientResponse,
} from '../app/models/clientModel';
import { EnvironmentsMock } from './environment';

export const EnvironmentClientMock: GetEnvironmentClientResponse = {
	clientId: 'ezJawezzj6nbuPAY9sRd0BlwCdbymyfF',
	clientType: AuthenticationClientType.AutomationClient,
	createdAt: '2014-04-01T00:00:00Z',
	description: 'Example description',
	environmentName: 'Sample environment',
	projectName: 'Sample project',
	id: 'dbId12345',
	name: 'Example label',
};

export function generateClientMock(index = 0): GetEnvironmentClientResponse {
	const randomMockEnv =
		EnvironmentsMock[Math.floor(Math.random() * EnvironmentsMock.length)];

	return {
		clientId: `${index}ezJawezzj6nbuPAY9sRd0BlwCdbymyf`,
		clientType:
			Math.random() < 0.5
				? AuthenticationClientType.AutomationClient
				: AuthenticationClientType.EdgeAdministrationClient,
		createdAt: '2014-04-01T00:00:00Z',
		description: Array.from(Array(Math.floor(Math.random() * 4)))
			.map(() => `Example description for a client ${index}`)
			.join(' '),
		environmentName: randomMockEnv.name,
		projectName: randomMockEnv.projectName,
		id: `dbId${index}`,
		name: `Example ${index + 1}`,
	};
}

export function generateOrganizationClientMock(
	index = 0,
): GetOrganizationClientResponse {
	return {
		clientId: `clientId${index}`,
		clientType: AuthenticationClientType.OrganizationAutomationClient,
		createdAt: '2015-03-01T00:00:00Z',
		description: `Example description for a organization client ${index}`,
		id: `dbId${index}`,
		name: `Example ${index + 1}`,
	};
}

export interface IGetHostingInstallation {
	provider: HostingProvider;
	organizationId: string;
	id: string;
	name: string;
	createdBy: string;
	createdAt: string;
	userName: string;
	userId: string;
	teamName: string;
	teamId: string;
}

export interface ICreateHostingProjectPayload {
	provider: HostingProvider;
	name: string;
	integrationId?: string;
	environmentId: string;
	siteId: string;
	siteName: string;
	posMappings?: {
		name: string;
		language: string;
	}[];
}

export enum HostingProvider {
	Vercel = 1,
}

export interface IGetHostingProject {
	id: string;
	provider: HostingProvider;
	name: string;
	installationId: string;
	environmentId: string;
	siteId: string;
	host?: string;
}

export interface IValidateInstallationResponse {
	isValid: boolean;
	message: string;
}

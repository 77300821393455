import { ThemeTypings } from '@chakra-ui/styled-system';
import { mdiOpenInNew } from '@mdi/js';
import { Icon, VStack, Text, Button, Box, Flex } from '@chakra-ui/react';

export interface MediaLinkProps {
	iconPath?: string;
	description?: string;
	iconThemeColor?: ThemeTypings['colorSchemes'];
	title: string;
	url: string;
	newWindow?: boolean;
}

export const MediaLink: React.FC<MediaLinkProps> = ({
	iconPath,
	description,
	iconThemeColor,
	title,
	url,
	newWindow,
}) => {
	return (
		<Button
			data-testid="media-link"
			variant="ghost"
			borderRadius="md"
			border="1px solid"
			borderColor="chakra-border-color"
			height="auto"
			width="100%"
			sx={{ '.new-window-icon ': { opacity: 0 } }}
			_hover={{ '.new-window-icon': { opacity: 1 } }}
			p={3}
			onClick={() => {
				window.open(url, '_blank');
			}}
			display="flex"
		>
			<Flex
				width="100%"
				justifyContent="flex-start"
				alignItems="center"
				direction="row"
			>
				{iconPath && (
					<Icon
						layerStyle="icon.subtle"
						boxSize="icon.md"
						mr={4}
						color={iconThemeColor ? iconThemeColor : 'gray'}
					>
						<path d={iconPath} />
					</Icon>
				)}
				<VStack align="flex-start" alignSelf="center" spacing={0.5}>
					<Text noOfLines={1}>{title}</Text>
					{description && (
						<Text noOfLines={1} my={0} variant="small">
							{description}
						</Text>
					)}
				</VStack>
				{newWindow && (
					<Box
						className="new-window-icon"
						marginLeft="auto"
						color={'chakra-placeholder-color'}
						pl={4}
					>
						<Icon>
							<path d={mdiOpenInNew} />
						</Icon>
					</Box>
				)}
			</Flex>
		</Button>
	);
};

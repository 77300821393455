import {
	ITransferConfigurationNode,
	MergeStrategy,
} from '../../../../../models/transferConfiguration';

export const parseNumberToMergeStrategy = (
	mergeStrategy: string,
): MergeStrategy | undefined => {
	const numericValue = parseInt(mergeStrategy, 10);
	if (Object.values(MergeStrategy).includes(numericValue)) {
		return numericValue as MergeStrategy;
	}

	return undefined;
};

export const truncatePathByCharacters = (
	path: string,
	maxCharacters: number,
) => {
	if (path.length <= maxCharacters) {
		return path;
	}

	const segments = path.split('/');
	let truncatedSegments = [segments.pop()];
	path = truncatedSegments.join('/');

	while (path.length < maxCharacters && segments.length > 0) {
		truncatedSegments.unshift(segments.pop());
		path = truncatedSegments.join('/');
	}

	return '.../' + path;
};

export const updateNodeMergeStrategy = (
	mergeStrategyStringValue: string,
	node: ITransferConfigurationNode,
) => {
	const mergeStrategy = parseNumberToMergeStrategy(mergeStrategyStringValue);

	if (mergeStrategy !== undefined) {
		node.mergeStrategy = mergeStrategy;
	}
};

import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import styles from '../../../styles/scrollbar.module.css';
import { XMCSButton } from '../../shared-components';
import { editCreateEnv } from '../../../@xmcloud/core/messages/en';
import { useCreateEditEnvContext } from './hooks/CreateEditEnvContext';
import { useCreateEnvironment } from './hooks/useCreateEnvironment';
import { isEmpty } from 'lodash';
import { requiredEnvFields } from './helper';
import { useEditEnvironment } from './hooks/useEditEnvironment';

const { addCm, addEh, editCm, editEh } = editCreateEnv;

const modalTitle = {
	addCm: addCm,
	editCm: editCm,
	addEh: addEh,
	editEh: editEh,
};

const getKey = (isCreate: boolean, isCm: boolean) => {
	if (isCreate) {
		return isCm ? 'addCm' : 'addEh';
	} else {
		return isCm ? 'editCm' : 'editEh';
	}
};

export const MainModal: React.FC<{
	isOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
}> = ({ isOpen, onClose, children }) => {
	const {
		errors,
		formikHandleSubmit,
		isLoading: isEditCreateContextLoading,
		isCm,
		isCreate,
		setTouched,
	} = useCreateEditEnvContext();
	const {
		isLoading: isCreateEnvLoading,
		shouldRetry,
		onEnvironmentLevelProcess,
		currentEnvironmentId,
	} = useCreateEnvironment();
	const {
		onEditEnvironmentProcess,
		isDisabled,
		isLoading: editEnvIsLoading,
	} = useEditEnvironment();

	const key = getKey(isCreate, isCm);

	const isLoading =
		isCreateEnvLoading || isEditCreateContextLoading || editEnvIsLoading;

	const hasError = !isEmpty(errors);
	const disabledOnError = hasError && !isCreate;

	const onValidateCreateEnvironment = () => {
		if (hasError && !shouldRetry) {
			setTouched({
				...requiredEnvFields,
			});
			return false;
		}
		return true;
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="2xl"
			scrollBehavior="inside"
		>
			<ModalOverlay>
				<ModalOverlay />
				<form
					data-testid="create-edit-environment-form"
					onSubmit={formikHandleSubmit}
				>
					<ModalContent>
						<ModalHeader>{modalTitle[key]}</ModalHeader>
						<ModalCloseButton />
						<ModalBody className={styles['custom-scrollbar']}>
							{children}
						</ModalBody>
						<ModalFooter>
							<XMCSButton
								text="Cancel"
								variant="ghost"
								onClick={onClose}
								size="md"
							/>
							<XMCSButton
								text={shouldRetry ? 'Retry' : 'Save'}
								size="md"
								isLoading={isLoading}
								isDisabled={isDisabled || disabledOnError}
								onClick={() => {
									if (!onValidateCreateEnvironment()) return;
									isCreate &&
										onEnvironmentLevelProcess(
											currentEnvironmentId,
										);
									!isCreate && onEditEnvironmentProcess();
								}}
							/>
						</ModalFooter>
					</ModalContent>
				</form>
			</ModalOverlay>
		</Modal>
	);
};

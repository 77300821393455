import {
	app,
	environments,
	environment,
	documentation,
	credentials,
	connections,
	transferDetails,
} from '../../../@xmcloud/core/messages/en';
import { goTo, mainPaths } from '../../../@xmcloud/core/routes/paths';

export type TabItems = {
	text: string;
	fnName: keyof typeof goTo;
};

export type DocumentationTabItems = {
	text: string;
	pathName: string;
	disabled: boolean;
};

export const projectTabItemsLegacy: TabItems[] = [
	{
		text: environments.environments,
		fnName: 'environments',
	},
	{
		text: app.details,
		fnName: 'projectDetails',
	},
];

export const projectTabItems: TabItems[] = [
	{
		text: app.details,
		fnName: 'projectDetails',
	},
	{
		text: environments.cmEnvironments,
		fnName: 'cmEnvironments',
	},
	{
		text: environments.editingHosts,
		fnName: 'ehEnvironments',
	},
];

export const transferTabItems: TabItems[] = [
	{
		text: transferDetails.status,
		fnName: 'transferStatus',
	},
	{
		text: transferDetails.details,
		fnName: 'transferDetails',
	},
];

const documentationPath = mainPaths.documentation;

export const documentationTabItems: DocumentationTabItems[] = [
	{
		text: documentation.help,
		pathName: `${documentationPath}`,
		disabled: false,
	},
];
export const commonEnvTabItems: TabItems[] = [
	{
		text: environment.details,
		fnName: 'details',
	},
	{
		text: environment.deployment,
		fnName: 'deployments',
	},
	{
		text: environment.sites,
		fnName: 'sites',
	},

	{
		text: environment.logs,
		fnName: 'cmInstanceLogTypes',
	},
	{
		text: environment.variables,
		fnName: 'variables',
	},
];

export const commonEnvTabItemsLegacy: TabItems[] = [
	{
		text: environment.deployment,
		fnName: 'oldDeployments',
	},

	{
		text: environment.sites,
		fnName: 'oldSites',
	},
	{
		text: environment.details,
		fnName: 'oldDetails',
	},
	{
		text: environment.logs,
		fnName: 'oldCmInstanceLogTypes',
	},
	{
		text: environment.variables,
		fnName: 'oldVariables',
	},
];

export const envEHTabItemsLegacy: TabItems[] = [
	{
		text: environment.deployment,
		fnName: 'oldDeployments',
	},
	{
		text: environment.details,
		fnName: 'oldDetails',
	},
	{
		text: environment.logs,
		fnName: 'oldCmInstanceLogTypes',
	},
	{
		text: environment.variables,
		fnName: 'oldVariables',
	},
	{
		text: environment.devSettings,
		fnName: 'oldDevSettings',
	},
];

export const environementTabItems: TabItems[] = [
	...commonEnvTabItems,
	{
		text: environment.devSettings,
		fnName: 'devSettings',
	},
];

export const environementTabItemsLegacy: TabItems[] = [
	...commonEnvTabItemsLegacy,
	{
		text: environment.devSettings,
		fnName: 'oldDevSettings',
	},
];

export const EHEnvironementTabItems: TabItems[] = environementTabItems.filter(
	(item: { fnName: string }) => item.fnName !== 'sites',
);

export const credentialsTabItems: TabItems[] = [
	{
		text: credentials.organization,
		fnName: 'organizationCredentials',
	},
	{
		text: credentials.environment,
		fnName: 'environmentCredentials',
	},
];

export const connectionsTabItems: TabItems[] = [
	{
		text: connections.sourceControl,
		fnName: 'sourceControl',
	},
	{
		text: connections.hosting,
		fnName: 'hosting',
	},
];

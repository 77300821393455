import { TRANSFERFILTER } from '../../@xmcloud/types';
import { useAuthQuery } from '../apiUtils/queryHelper';
import config from '../config/config';
import {
	IGetTransferResponse,
	IPaginatedTransferResponse,
} from '../models/transferModel';

export const useGetTransfer = ({ id }: { id: string }) => {
	const { scope, url, queryKey } = config.transfers.transfer;
	return useAuthQuery(
		queryKey(id),
		(axiosInstance) => {
			return axiosInstance?.get<IGetTransferResponse>(url(id));
		},
		{ staleTime: 5000 },
		scope,
	);
};

export const useGetAllTransfers = (
	pageNumber = 1,
	pageSize = 10,
	filter?: TRANSFERFILTER,
) => {
	const { scope, url, queryKey } = config.transfers.allTransfers;

	return useAuthQuery(
		queryKey(filter),
		(axiosInstance) => {
			return axiosInstance?.get<IPaginatedTransferResponse>(
				url(pageNumber, pageSize, filter),
			);
		},
		{ keepPreviousData: true, staleTime: 5000 },
		scope,
	);
};

import { FC, ReactNode } from 'react';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	FormHelperText,
} from '@chakra-ui/react';
import { Select, Props as SelectProps } from 'chakra-react-select';
import { T } from '@transifex/react';
import { FormPlaceHolder, InfoIcon, TransifexTextAsBold, Text } from '..';
import styles from '../../../styles/common.module.css';

type Props = SelectProps & {
	error?: string | undefined;
	isInvalid?: boolean;
	isRequired?: boolean;
	isLoading?: boolean;
	label: string;
	name: string;
	value?: string;
	onChange: (e: any) => void;
	dataTestId?: string;
	options: Array<{ value: string | boolean | number | null; label: string }>;
	currentValue: unknown;
	helperText?: string;
	onFocus?: () => void;
	isDisabled?: boolean;
	helperBoldText?: string;
	helperTextIcon?: ReactNode;
	isEditable?: boolean;
	onEditClick?: () => void;
	pt?: number;
	placeholder?: string;
	maxW?: string;
	pb?: number;
	showInfoIcon?: boolean;
	infoText?: string;
	onInfoIconClick?: () => void;
	showAsPlaceHolder?: boolean;
	isEditBtnDisabled?: boolean;
	tooltipLabel?: string;
	textContent?: string;
};

export const SelectFormControl: FC<Props> = ({
	error,
	isInvalid,
	isRequired = false,
	label,
	currentValue = '',
	name,
	dataTestId = `select-element-${name}`,
	options,
	helperText = '',
	helperBoldText = '',
	isDisabled = false,
	helperTextIcon,
	pt = 6,
	placeholder = 'Select...',
	maxW = 'md',
	pb = 0,
	showInfoIcon = false,
	infoText = '',
	onInfoIconClick,
	showAsPlaceHolder = false,
	isEditable = false,
	onEditClick,
	isEditBtnDisabled,
	tooltipLabel,
	textContent = '',
	...rest
}) => {
	const value: any =
		options.find((option: any) => option.value === currentValue) || null;
	if (showAsPlaceHolder)
		return (
			<FormPlaceHolder
				{...{
					title: label,
					description: value?.label || currentValue,
					isEditable,
					onEditClick,
					isEditBtnDisabled,
					tooltipLabel,
				}}
			/>
		);
	return (
		<FormControl
			{...{ isInvalid, isRequired, pt, maxW, pb }}
			data-testid={dataTestId}
		>
			<FormLabel>
				<T _str={label} />
				{showInfoIcon && (
					<InfoIcon
						{...{
							label: infoText,
							onClick: onInfoIconClick,
							ml: 2,
						}}
					/>
				)}
			</FormLabel>
			<Select
				{...{
					name,
					options,
					isDisabled,
					placeholder,
					value,
					className: styles['bgWhite'],
					...rest,
				}}
				menuPosition="fixed"
				chakraStyles={{
					dropdownIndicator: (provided) => ({
						...provided,
						bg: 'transparent',
						px: 2,
						cursor: 'inherit',
					}),
					indicatorSeparator: (provided) => ({
						...provided,
						display: 'none',
					}),
				}}
			/>
			{Boolean(helperText) && (
				<FormHelperText>
					{helperTextIcon ? helperTextIcon : null}
					<TransifexTextAsBold
						{...{ text: helperText, boldText: helperBoldText }}
					/>
				</FormHelperText>
			)}
			<FormErrorMessage>{error}</FormErrorMessage>
			{!!textContent && (
				<Text
					{...{ text: textContent, maxW, pt: 2, variant: 'small' }}
				/>
			)}
		</FormControl>
	);
};

import {
	Alert,
	AlertDescription,
	AlertIcon,
	Card,
	CardBody,
	IconButton,
	List,
	ListItem,
	ScaleFade,
	Stack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { commonCw, fifthStep } from '../../../../@xmcloud/core/messages/en';
import { FooterBtnGroups, ReviewFinishCard } from '.';
import { handleStatusText } from '../helpers';
import { HeadingLg, HeadingSm, Icon } from '../../../shared-components';
import {
	ResponseStatusEnum,
	ResponseStatusTypes,
} from '../../../../@xmcloud/utils/helpers';
import { mdiRedo } from '@mdi/js';
import { T } from '@transifex/react';
import { ESourceControl } from '../../../../@xmcloud/types';
import { useCreateProject, useReviewCardList } from '../hooks';

const { IDLE, ERROR } = ResponseStatusEnum;
const { ADO } = ESourceControl;

const { title, statusTitle } = fifthStep;
const { retry, startDeployment } = commonCw;

export const ReviewFinish: React.FC<{
	handleStartDeployment: (...arg: any) => any;
	isLoading: boolean;
	shouldRetry: boolean;
	isFetchingADORepository?: boolean;
	listOfStatus: { status: ResponseStatusTypes; text: string }[];
}> = ({ handleStartDeployment, isLoading, shouldRetry, listOfStatus }) => {
	const { prev, values } = useCreateProject();
	const [showStatusElement, setShowStatusElement] = useState(false);
	const { reviewList } = useReviewCardList();
	const isADOCmProvier = values.cmProvider === ADO;

	return (
		<Stack gap="7" pb="12">
			<HeadingLg text={title} />
			<Stack flexDirection={['column', 'column', 'row', 'row']}>
				<Stack gap="4" w={['100%', '100%', '55%', '55%']}>
					{reviewList.map(({ items, stepNumber, cardTitle, key }) => (
						<ReviewFinishCard
							{...{
								items,
								stepNumber,
								cardTitle,
							}}
							key={key}
						/>
					))}
				</Stack>
				{showStatusElement && (
					<Stack w={['100%', '100%', '45%', '45%']}>
						<ScaleFade initialScale={0.9} in={true}>
							<Card variant="filled">
								<CardBody>
									<List spacing="5">
										<HeadingSm text={statusTitle} />
										{listOfStatus.map(
											({ status, text }) => {
												const showCreateRepoInfo =
													/\brepository\b/i.test(
														text,
													);

												return (
													<StatusListItem
														handleStartDeployment={
															handleStartDeployment
														}
														status={status}
														text={
															handleStatusText(
																text,
																showCreateRepoInfo,
																isADOCmProvier,
															)[status]
														}
														key={text}
													/>
												);
											},
										)}
									</List>
								</CardBody>
							</Card>
						</ScaleFade>
					</Stack>
				)}
			</Stack>
			<FooterBtnGroups
				{...{
					onNextClick: () => {
						setShowStatusElement(true);
						handleStartDeployment(values);
					},
					onPreviousClick: prev,
					isLoading: isLoading,
					py: 0,
					rightBtnText: shouldRetry ? retry : startDeployment,
					colorScheme: 'success',
					showRightIcon: false,
				}}
			/>
		</Stack>
	);
};

const StatusListItem: React.FC<{
	handleStartDeployment: (...arg: any) => any;
	status: ResponseStatusTypes;
	text: string;
}> = ({ status, text, handleStartDeployment }) => {
	const { values } = useCreateProject();
	const isIdleStatus = status === IDLE;
	const isErrorStatus = status === ERROR;
	return (
		<ListItem>
			<Alert
				status={isIdleStatus ? undefined : status}
				{...(isIdleStatus && { colorScheme: 'neutral' })}
				alignItems="center"
			>
				<AlertIcon />
				<>
					<AlertDescription>
						<T _str={text} />
					</AlertDescription>
					{isErrorStatus && (
						<IconButton
							icon={<Icon path={mdiRedo} />}
							aria-label="redo-btn"
							variant="ghost"
							size="xs"
							onClick={() => handleStartDeployment(values)}
						/>
					)}
				</>
			</Alert>
		</ListItem>
	);
};

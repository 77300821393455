import axios, { AxiosInstance } from 'axios';

let authAxios: AxiosInstance | null = null;
const regularAxios: AxiosInstance = axios.create();

const createAuthAxios = (instance: AxiosInstance) => {
	authAxios = instance;
	return authAxios;
};

export { authAxios, regularAxios, createAuthAxios };

/*
    authAxios instance is modified as a side effect by the AxiosProvider, careful when using it outside
*/

import {
	isValid,
	format,
	parseISO,
	toDate,
	millisecondsToSeconds,
} from 'date-fns';

export const getReadableDate = (
	utcDate: string | undefined,
	formatStr: 'PP' | 'PPp' = 'PP',
): string => {
	if (!utcDate) {
		return 'Invalid Date';
	}

	const parsedDate = parseISO(utcDate);
	const isValidDate = isValid(parsedDate);
	if (isValidDate) {
		const messageDate = format(parsedDate, formatStr);
		return messageDate;
	} else {
		return 'Invalid Date';
	}
};

export function getReadableTime(utcDate: string, formatString = 'HH:mm') {
	const parsedDate = parseISO(utcDate);
	const isValidTime = isValid(parsedDate);
	if (isValidTime) {
		const messageTime = format(parsedDate, formatString);
		return messageTime;
	} else {
		return 'Invalid Time';
	}
}

export const durationInHHmmSS = (milliseconds: number): string => {
	const totalSeconds = millisecondsToSeconds(milliseconds);
	const date = toDate(new Date(0, 0, 0, 0, 0, totalSeconds));

	if (milliseconds < 1000) return milliseconds + ' ms';
	if (milliseconds >= 1000 && milliseconds < 60000)
		return millisecondsToSeconds(milliseconds) + ' sec';
	if (milliseconds >= 60000 && milliseconds < 3600000)
		return date.getMinutes() + ' min ' + date.getSeconds() + ' sec';
	if (milliseconds >= 3600000)
		return (
			date.getHours() +
			' h ' +
			date.getMinutes() +
			' min ' +
			date.getSeconds() +
			' sec'
		);
	return '';
};

import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import { placeHolders } from '../../../@xmcloud/core/messages/en';
import { cactusLogo } from '../../../@xmcloud/core/icons';
import { HeadingMd } from '../text';

interface Props {
	item: string;
	pt?: number;
}

export const RenderPlaceholder: React.FC<Props> = ({ item, pt = 12 }) => (
	<Container height="100%" width="100%">
		<Container maxW="sm" width="sm" pt={pt}>
			<Flex justify="center" flexDirection="column" alignItems="center">
				<Box
					borderRadius="2xl"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Image width={28} height={28} src={cactusLogo} alt={item} />
				</Box>
				<HeadingMd
					textAlign="center"
					pt="4"
					text={placeHolders.noItemCreatedYet(item || '')}
				/>

				<Text textAlign="center" variant="subtle" pt="2">
					Change your filter or search parameters and try again.
				</Text>
			</Flex>
		</Container>
	</Container>
);

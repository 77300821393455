import axios from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import config from '../config/config';
import {
	TGetGithubBranches,
	TGetGithubInstallationRepositories,
	TGetGithubRepository,
} from '../models/externalApi';
import { TRepository } from '../pages/create-project/helpers';

const sourceControlInstance = axios.create();

const axiosInstanceInterceptorGithub = (token: string) => {
	sourceControlInstance.defaults.headers.common['Authorization'] =
		`Bearer ${token}`;
	sourceControlInstance.defaults.headers.common['Accept'] =
		'application/vnd.github+json';
	return sourceControlInstance;
};

const PER_PAGE = 100;
const CALL_COUNT = 7;

export const useGetPaginatedGithubRepositories = (
	token: string,
	integrationId: string,
	_enabled = true,
	onSuccess?: (data: any) => void,
) => {
	const callCounts = useRef(1);
	const { url, queryKey } =
		config.sourceControl.get_app_installation_github_repositories;
	const enabled = !!token && _enabled && !!integrationId;
	const axiosInstance = axiosInstanceInterceptorGithub(token);

	const result = useInfiniteQuery({
		queryKey: queryKey(integrationId),
		queryFn: ({ pageParam = 1 }) =>
			axiosInstance
				.get<TGetGithubInstallationRepositories>(
					url(pageParam, PER_PAGE),
				)
				.then((res) => res.data),
		getNextPageParam: (lastPage, allPages) =>
			lastPage.repositories.length < PER_PAGE
				? undefined
				: allPages.length + 1,
		onSuccess: (data) => {
			callCounts.current++;
			const pages = data.pages || [];
			const lastPage = pages[pages.length - 1]?.repositories || [];
			const hasMorePages = lastPage.length === PER_PAGE;
			hasMorePages && onSuccess && onSuccess(lastPage);
		},
		enabled,
	});

	const data = useMemo(
		() => {
			const pages = result.data?.pages || [];
			return pages.map((r) => r.repositories).flat();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[result.data?.pages],
	);

	useEffect(() => {
		// by default it should call at least six call to get first six pages, if there is more pages
		if (callCounts.current < CALL_COUNT && result.hasNextPage) {
			result.fetchNextPage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [callCounts.current]);

	return { ...result, data };
};

export const useGetGitHubPaginatedBranches = ({
	token,
	owner,
	repo,
	onSuccess,
	repositoryNamespace = 'repository',
	_enabled = true,
}: {
	token: string;
	owner: string;
	repo: string;
	onSuccess?: (data: any) => void;
	repositoryNamespace?: TRepository;
	_enabled?: boolean;
}) => {
	const callCounts = useRef(1);
	const [hasMore, setHasMore] = useState(true);
	const { url, queryKey } = config.sourceControl.get_github_repo_branches;
	const axiosInstance = axiosInstanceInterceptorGithub(token);
	const enabled = !!token && !!owner && !!repo && _enabled;

	const result = useInfiniteQuery({
		queryKey: queryKey(owner, repo, repositoryNamespace),
		queryFn: ({ pageParam = 1 }) =>
			axiosInstance
				.get<TGetGithubBranches>(url(owner, repo, pageParam, PER_PAGE))
				.then((res) => res.data),
		getNextPageParam: (lastPage, allPages) =>
			lastPage.length < PER_PAGE ? undefined : allPages.length + 1,
		onSuccess: (data) => {
			callCounts.current++;
			const pages = data.pages || [];
			const lastPage = pages[pages.length - 1] || [];
			const hasMorePages = lastPage.length === PER_PAGE;
			hasMorePages && onSuccess && onSuccess(lastPage);

			if (hasMorePages) {
				setHasMore(true);
			} else {
				setHasMore(false);
			}
		},
		enabled,
	});

	useEffect(() => {
		// by default it should call at least six call to get first six pages, if there is more pages
		if (callCounts.current < CALL_COUNT && hasMore && enabled) {
			result.fetchNextPage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [callCounts.current]);

	const data = useMemo(() => {
		const pages = result.data?.pages || [];
		return pages.flat();
	}, [result.data?.pages]);

	return { ...result, data, hasMore };
};

export const useGetGithubPublicRepository = (
	owner: string,
	repo: string,
	_enabled = true,
) => {
	const { queryKey, url } = config.sourceControl.get_github_repository;
	const enabled = !!owner && !!repo && _enabled;
	return useQuery({
		queryKey: queryKey(owner, repo),
		queryFn: () => axios.get<{ default_branch: string }>(url(owner, repo)),
		enabled,
	});
};

export const useGetGitHubBranches = ({
	token,
	owner,
	repo,
	onSuccess,
	repositoryNamespace = 'repository',
	_enabled = true,
}: {
	token: string;
	owner: string;
	repo: string;
	onSuccess?: () => void;
	repositoryNamespace?: TRepository;
	_enabled?: boolean;
}) => {
	const refetchCount = useRef(0);
	const [isRefetching, setIsRefetching] = useState(false);
	const refetchInterval = useRef(3500);
	const { url, queryKey } = config.sourceControl.get_github_repo_branches;
	const axiosInstance = axiosInstanceInterceptorGithub(token);
	const enabled = !!token && !!owner && !!repo && _enabled;
	const result = useQuery({
		queryKey: queryKey(owner, repo, repositoryNamespace),
		queryFn: () =>
			axiosInstance.get<TGetGithubBranches>(
				url(owner, repo, 1, PER_PAGE),
			),

		enabled,
		refetchInterval: refetchInterval.current,
		retry: 3,
		onSuccess: (data) => {
			if (!data.data.length) {
				setIsRefetching(true);
				return;
			}
			refetchInterval.current = 0;
			setIsRefetching(false);
			onSuccess && onSuccess();
		},
		onError: () => {
			refetchCount.current++;
			if (refetchCount.current > 5) {
				refetchInterval.current = 0;
				setIsRefetching(false);
			}
		},
	});
	const loading = result.isLoading || isRefetching || result.isFetching;
	const branchStatus = loading ? 'loading' : 'success';
	return { ...result, loading, branchStatus };
};

export const useGetGitubRepository = ({
	token,
	owner,
	repo,
	_enabled = true,
	onError,
}: {
	token: string;
	owner: string;
	repo: string;
	_enabled?: boolean;
	onError?: (e: any) => void;
}) => {
	const { queryKey, url } = config.sourceControl.get_github_repository;
	const enabled = !!owner && !!repo && !!token && _enabled;
	const axiosInstance = axiosInstanceInterceptorGithub(token);
	return useQuery({
		queryKey: queryKey(owner, repo),
		queryFn: () =>
			axiosInstance.get<TGetGithubRepository>(url(owner, repo)),
		enabled,
		onError: (e) => {
			onError && onError(e);
		},
	});
};

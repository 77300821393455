import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import { createTransferValidationSchema } from '../../yup-validations';
import { FormikErrors, FormikState, FormikTouched, useFormik } from 'formik';
import { handlePush } from '../../utils/helpers';
import {
	TransferDetailsStep,
	ItemSelectionStep,
	MergeStrategiesStep,
	ReviewAndTransferStep,
} from '../../../app/pages/create-transfer/steps';
import { ITransferConfiguration } from '../../../app/models/transferConfiguration';
import { useSteps } from '@chakra-ui/react';
import { goTo } from '../../core/routes/paths';

export type FormikValues = {
	transferName: string;
	sourceEnvironmentId: string;
	targetEnvironmentId: string;
	configuration: ITransferConfiguration;
};
type TPrev = (a: FormikValues) => FormikValues;

type CreateTransferContextType = {
	activeStep: number;
	setActiveStep: (p: number) => void;
	prev: (transferId?: string) => void;
	next: (transferId?: string) => void;
	errors: FormikErrors<FormikValues>;
	values: FormikValues;
	handleChange: any;
	formikHandleSubmit: any;
	step: ReactNode;
	isLastStep: boolean;
	setFieldError: (field: string, message: string) => void;
	setFieldValue: (field: string, value: any) => void;
	setFieldTouched: any;
	touched: FormikTouched<FormikValues>;
	resetForm: (s?: Partial<FormikState<FormikValues>> | undefined) => void;
	setValues:
		| ((
				values: React.SetStateAction<FormikValues>,
				shouldValidate?: boolean | undefined,
		  ) => Promise<void> | Promise<FormikErrors<FormikValues>>)
		| ((prev: TPrev) => void);
	setTouched: any;
	isValid: boolean;
	initialValues: FormikValues;
	setInitialValues: (values: FormikValues) => void;
};

export const CreateTransferContext = createContext<CreateTransferContextType>(
	{} as CreateTransferContextType,
);

const CreateTransferProvider = ({ children }: { children: ReactNode }) => {
	const [initialValues, setInitialValues] = useState<FormikValues>({
		transferName: '',
		sourceEnvironmentId: '',
		targetEnvironmentId: '',

		configuration: {
			transferId: '',
			nodes: [],
			sourceEnvironmentId: '',
			targetEnvironmentId: '',
			name: '',
		},
	});

	const validationSchema = createTransferValidationSchema();

	const {
		errors,
		values,
		handleChange,
		setFieldError,
		setFieldValue,
		setFieldTouched,
		setValues,
		touched,
		handleSubmit: formikHandleSubmit,
		resetForm,
		setTouched,
		isValid,
	} = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		validateOnBlur: false,
		validateOnMount: true,
		onSubmit: (values: FormikValues) => {},
	});

	const steps = useMemo(
		() => [
			<></>,
			<TransferDetailsStep />,
			<ItemSelectionStep />,
			<MergeStrategiesStep />,
			<ReviewAndTransferStep />,
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);
	const { activeStep, setActiveStep } = useSteps({
		index: 1,
		count: steps.length,
	});

	const isLastStep = activeStep >= steps.length - 1;

	const next = useCallback(
		(transferId?: string) => {
			setActiveStep((prev: number) => {
				if (prev >= steps.length - 1) return prev;
				return prev + 1;
			});
			if (isLastStep) return;

			const path = transferId
				? goTo.transferAgain(transferId, 1)
				: goTo.createTransferTo(activeStep + 1);
			handlePush(path);
		},
		[activeStep, isLastStep, setActiveStep, steps.length],
	);

	const prev = useCallback(
		(transferId?: string) => {
			setActiveStep((prev: number) => {
				if (prev <= 1) return prev;
				return prev - 1;
			});
			const path = transferId
				? goTo.transferAgain(transferId, 1)
				: goTo.createTransferTo(activeStep - 1);
			handlePush(path);
		},
		[activeStep, setActiveStep],
	);

	return (
		<CreateTransferContext.Provider
			value={{
				activeStep,
				setActiveStep,
				prev,
				next,
				errors,
				values,
				handleChange,
				formikHandleSubmit,
				step: steps[activeStep],
				isLastStep,
				setFieldError,
				setFieldValue,
				setFieldTouched,
				touched,
				resetForm,
				setValues,
				setTouched,
				isValid,
				initialValues,
				setInitialValues,
			}}
		>
			{children}
		</CreateTransferContext.Provider>
	);
};

function useCreateTransfer(): CreateTransferContextType {
	const context = useContext(CreateTransferContext);

	if (context === undefined) {
		throw new Error(
			'useCreateTransfer must be used within a CreateTransfer',
		);
	}

	return context;
}

export { useCreateTransfer, CreateTransferProvider };

import { Button, ButtonGroup, IconButton, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import { Icon } from '..';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

export const Pagination: React.FC<{
	totalPages: number;
	cb?: (a: number) => void;
}> = ({ totalPages, cb }) => {
	const [currentPage, setCurrentPage] = useState(0);

	const pageNumbers = [];

	for (let i = 0; i < totalPages; i++) {
		pageNumbers.push(i);
	}
	let startPage = Math.max(0, currentPage - 2);
	let endPage = startPage + 6;
	if (endPage >= totalPages) {
		// If the end page exceeds the total pages, shift the start and end pages back
		const diff = endPage - totalPages + 1;
		startPage -= diff;
		endPage -= diff;
	}

	return (
		<ButtonGroup size="sm" variant="pagination" spacing="1">
			<Tooltip label="Previous">
				<IconButton
					aria-label="pagination-prev"
					icon={<Icon path={mdiChevronLeft} />}
					onClick={() => {
						if (currentPage === 0) return;
						cb && cb(currentPage - 1);
						setCurrentPage((prev) => prev - 1);
					}}
					isDisabled={currentPage === 0}
				/>
			</Tooltip>
			{pageNumbers.slice(startPage, endPage + 1).map((b) => (
				<Button
					key={b}
					isActive={b === currentPage}
					onClick={() => {
						if (b === currentPage) return;
						setCurrentPage(b);
						cb && cb(b);
					}}
				>
					{b + 1}
				</Button>
			))}
			<Tooltip label="Next">
				<IconButton
					aria-label="pagination-next"
					icon={<Icon path={mdiChevronRight} />}
					onClick={() => {
						if (currentPage === totalPages - 1) return;
						cb && cb(currentPage + 1);
						setCurrentPage((prev) => prev + 1);
					}}
					isDisabled={currentPage === totalPages - 1}
				/>
			</Tooltip>
		</ButtonGroup>
	);
};

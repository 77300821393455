import { FC, useEffect, useMemo, useState } from 'react';
import { useGetRepositoryIntegrationsV2 } from '../../../services/repositories';
import {
	LoadingSkeleton,
	RenderPlaceholder,
	Table,
} from '../../../shared-components';
import { app, systemHealthStatus } from '../../../../@xmcloud/core/messages/en';
import { IRepositoryIntegrationDetails } from '../../../models/repositoriesModel';
import { useSourceControlIntegrationsTableColumns } from '../columns/sourcecontrol';
import DeleteSourceControl from './DeleteSourceControl';
import { useQueryClient } from 'react-query';
import { useAuthenticatedAxios } from '../../../apiUtils/AxiosProvider';
import config from '../../../config/config';
import { ColumnDef, PaginationState } from '@tanstack/react-table';
import { useComponentHealthy } from '../../../../@xmcloud/hooks';
import { EnumInNumberSourceControl } from '../../../../@xmcloud/types';
import { t } from '@transifex/native';

const headers = [
	'name',
	'Provider',
	'Connection ID',
	'Created by',
	'Date created',
	'',
];

const { errMsg1: errMsg1txt, errMsg4: errMsg4txt } = systemHealthStatus;
const { connections: connectionstxt } = app;

const SourceControlIntegrationsTable: FC = () => {
	const queryClient = useQueryClient();
	const axiosContext = useAuthenticatedAxios();
	const [openDeleteSourceControl, setOpenDeleteSourceControl] =
		useState(false);
	const [connectionId, setConnectionId] = useState('');

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const pagination = useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize],
	);
	const currentPage = pageIndex + 1;

	const {
		data,
		isLoading: isDataLoading,
		isPreviousData,
		isFetching,
	} = useGetRepositoryIntegrationsV2(currentPage);

	const sourceControlIntegrations = data?.data?.data || [];

	const totalCount = data?.data?.totalCount || 0;

	const {
		isConfigurationHealthy,
		isGithubHealthy,
		isLoading: isComponentHealthLoading,
		isError: isComponentHealthError,
	} = useComponentHealthy({});

	function getTooltipMessage(
		provider: EnumInNumberSourceControl | 0,
	): string {
		if (isComponentHealthError) {
			// Error in getting status
			return t(errMsg1txt);
		}
		if (!isConfigurationHealthy) {
			// Both provider need configuration to delete the connection
			return t(errMsg4txt, {
				variableTxt2: 'delete connection',
				variableTxt: 'configuration',
			});
		}
		if (provider === EnumInNumberSourceControl.GitHub) {
			return !isGithubHealthy
				? t(errMsg4txt, {
						variableTxt2: 'delete connection',
						variableTxt: 'Github',
					})
				: '';
		}

		return '';
	}

	const columns: ColumnDef<IRepositoryIntegrationDetails, any>[] =
		useSourceControlIntegrationsTableColumns(
			setConnectionId,
			setOpenDeleteSourceControl,
			getTooltipMessage,
		);

	useEffect(() => {
		const hasMore = totalCount > pageIndex * pageSize;
		const { url, queryKey } = config.repository.get_repo_integrations_v2;

		if (!isPreviousData && hasMore) {
			queryClient.prefetchQuery({
				queryKey: queryKey(currentPage + 1),
				queryFn: () => axiosContext.get(url(currentPage + 1, 10)),
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPreviousData, currentPage]);

	const isLoading = isDataLoading || isComponentHealthLoading;

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;
	if (sourceControlIntegrations.length === 0)
		return <RenderPlaceholder item={connectionstxt.toLocaleLowerCase()} />;

	return (
		<>
			<Table
				{...{
					columns,
					pageCount: Math.ceil(totalCount / pageSize),
					pagination,
					tableData: sourceControlIntegrations,
					dataTestId: 'paginated-sourcecontrol-integration-table',
					initialSort: [],
					isFetching,
					manualPagination: true,
					onPaginationChange: setPagination,
					headers,
				}}
			/>
			{openDeleteSourceControl && (
				<DeleteSourceControl
					{...{
						openModal: openDeleteSourceControl,
						setOpenModal: setOpenDeleteSourceControl,
						connectionId,
						queryClient,
						currentPage,
					}}
				/>
			)}
		</>
	);
};

export default SourceControlIntegrationsTable;

import { useAuthQuery } from '../apiUtils/queryHelper';
import config from '../config/config';
import { ITenantDetails } from '../models/platformInventoryModel';

const { get_tenant } = config.platformInventory;

export const useGetTenant = ({ tenantId }: { tenantId: string }) => {
	const { url, queryKey } = get_tenant;
	const _enabled = !!tenantId;
	return useAuthQuery(
		queryKey(tenantId),
		(axiosInstance) => {
			return axiosInstance?.get<ITenantDetails>(url(tenantId));
		},
		{ enabled: _enabled },
	);
};

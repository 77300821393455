/* eslint-disable react-hooks/rules-of-hooks */

import React, { ChangeEvent, useState } from 'react';
import { FormControl, FormHelperText, Tag } from '@chakra-ui/react';

export type InputFieldWithTransformationProps = {
	isInvalid?: boolean;
	label: string;
	name: string;
	value?: string;
	pt?: number;
	maxW?: string;
	error?: string;
	onFocus?: () => void;
	onChange?: (e: ChangeEvent<HTMLInputElement> | any) => void;
	setFieldValue?: (
		field: string,
		value: any,
		shouldValidate?: boolean,
	) => void;
	prefixMessage?: string;
	transformer?: (value: string) => string;
};

/**
 * A Higher Order Component that wraps an input field and displays a transformation message based on the input value.
 * This component allows for a custom transformation logic to be applied to the input value before displaying it.
 *
 * @param {React.FC<InputFieldWithTransformationProps>} WrappedComponent - The input component to wrap.
 * @returns {React.FC<InputFieldWithTransformationProps>} A React functional component.
 *
 * @example
 * ```typescriptreact
 * const InputFormControlWithTransformationMessage = WithTransformationMessage(InputFormControl);
 *
 * // Custom transformer function that replaces spaces with underscores
 * const underscoreTransformer = (value: string) => value.replace(/\s+/g, '_');
 *
 * // Usage of WithTransformationMessage with the custom transformer
 * <InputFormControlWithTransformationMessage
 *   name="username"
 *   label="Username"
 *   transformer={underscoreTransformer}
 *   prefixMessage="Username will be displayed as"
 * />
 * ```
 */
export function WithTransformationMessage(
	WrappedComponent: React.FC<InputFieldWithTransformationProps>,
): React.FC<InputFieldWithTransformationProps> {
	return (props: InputFieldWithTransformationProps) => {
		const [transformationMessage, setTransformationMessage] =
			useState<React.ReactNode>('');

		const [displayValue, setDisplayValue] = useState(props.value || '');

		const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			const originalValue = e.target.value;
			setDisplayValue(originalValue);

			const transformedValue = props.transformer
				? props.transformer(originalValue)
				: originalValue.replace(/\s+/g, '-');

			const Message = (
				<>
					{props.prefixMessage || 'Will be created as '}
					<Tag size="sm">{transformedValue}</Tag>
				</>
			);

			if (originalValue !== transformedValue) {
				setTransformationMessage(Message);
			} else {
				setTransformationMessage('');
			}

			if (props.setFieldValue) {
				props.setFieldValue(props.name, transformedValue);
			}

			if (props.onChange) {
				const clonedEvent = {
					...e,
					target: { ...e.target, value: transformedValue },
				};
				props.onChange(clonedEvent);
			}
		};

		return (
			<>
				<WrappedComponent
					{...props}
					value={displayValue}
					onChange={handleOnChange}
					data-testid={props.name}
				/>
				{transformationMessage && (
					<FormControl>
						<FormHelperText data-testid="transformation-message">
							{transformationMessage}
						</FormHelperText>
					</FormControl>
				)}
			</>
		);
	};
}

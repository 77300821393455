import { IValidateInstallationResponse } from '../app/models/hostingModel';

export const HostingInstallationStateMock: string =
	'245J5LVDLUly6wWXsLnMCQbcndIJsfxCyt5ETtr6fNSkhQinSfPiAdz8vPdQJdmbIvdOe6k2gYJOuW3WndluI9psypTYS6G29akn7WSuEgEMN';

export const IValidateInstallationResponseMock: IValidateInstallationResponse =
	{
		isValid: true,
		message: '',
	};

export const GitOpsValidationExceptionValidateInstallationResponseMock = {
	detail: "Vercel doesn't have access to the GitHub account. Please check the Vercel installation",
	status: 400,
	title: 'One or more validation errors occurred.',
	errors: {
		qaxm: [
			"Vercel doesn't have access to the GitHub account. Please check the Vercel installation",
		],
	},
};

import { createTransfer } from '../../../@xmcloud/core/messages/en';

export const TRANSFER_NAME = 'transferName';
export const SOURCE_ENVIRONMENT_ID = 'sourceEnvironmentId';
export const TARGET_ENVIRONMENT_ID = 'targetEnvironmentId';
export const TRANSFER_CONFIGURATION = 'configuration';

const {
	transferDetailsStepTitle,
	itemSelectionStepTitle,
	mergeStrategiesStepTitle,
	reviewAndTransferStepTitle,
} = createTransfer;

export const steps = [
	{ title: transferDetailsStepTitle, index: 1 },
	{ title: itemSelectionStepTitle, index: 2 },
	{ title: mergeStrategiesStepTitle, index: 3 },
	{ title: reviewAndTransferStepTitle, index: 4 },
];

import {
	Box,
	Step,
	StepIcon,
	StepIndicator,
	StepNumber,
	StepSeparator,
	StepStatus,
	StepTitle,
	Stepper,
} from '@chakra-ui/react';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import { goTo } from '../../../@xmcloud/core/routes/paths';
import { useSharedState } from '../../../@xmcloud/hooks';
import { IS_MD_SCREEN } from '../../../@xmcloud/hooks/context/useSharedState';

export const ChakraStepper: React.FC<{
	activeStep: number;
	setActiveStep: (step: number) => void;
	path: string;
	steps: any[];
}> = ({ activeStep, setActiveStep, path, steps }) => {
	const { state: isMdScreen } = useSharedState(IS_MD_SCREEN, false);
	const activeStepIndex = activeStep - 1;
	return (
		<Stepper
			index={activeStepIndex}
			orientation={isMdScreen ? 'horizontal' : 'vertical'}
			h={isMdScreen ? '100%' : '96'}
			gap={isMdScreen ? '1' : '0'}
		>
			{steps.map(({ title, index }) => (
				<Step
					key={title}
					onClick={() => {
						if (activeStepIndex < index) return;
						//@ts-ignore
						const rootPath = goTo[`${path}`];
						setActiveStep(index);
						handlePush(rootPath(index));
					}}
					//@ts-ignore
					cursor={index <= activeStepIndex ? 'pointer' : 'default'}
					gap={isMdScreen ? '0' : '2.5'}
				>
					<StepIndicator>
						<StepStatus
							complete={<StepIcon />}
							incomplete={<StepNumber />}
							active={<StepNumber />}
						/>
					</StepIndicator>
					{!isMdScreen && (
						<Box flexShrink="1" paddingTop="1.5">
							<StepTitle>{title}</StepTitle>
						</Box>
					)}
					<StepSeparator />
				</Step>
			))}
		</Stepper>
	);
};

import { ISite } from '../app/models/environmentModel';
import { HostingProvider } from '../app/models/hostingModel';

export const allSitesMock = {
	data: {
		languages: {
			edges: [
				{
					node: {
						name: 'en',
					},
				},
			],
		},
		renderingHosts: {},
		solutionSites: [
			{
				name: 'dev-new',
				id: '38f7b41400a540bb8678326d99d5b3cd',
				posMappings: [],
			},
			{
				name: 'qa',
				id: '4ec01343cf6e4abc96443cbd76e45a9a',
				posMappings: [],
			},
			{
				name: 'dev',
				id: 'bfa15a958c404ca9b527b838b1312c00',
				posMappings: [],
			},
		],
		sitesToRenderingHosts: [],
		publishSite: {
			operationId: 'mock-operation-id',
		},
		publishingStatus: {
			isDone: false,
			processed: '',
			state: 'INITIALIZING',
			isFailed: false,
		},
	},
};

export const SiteMock: ISite = {
	id: '12345',
	name: 'site123',
	posMappings: [
		{
			name: 'map1',
			language: 'en',
		},
	],
	hostedSite: {
		id: 'prj_TrTVAekhdQYxEsgHiPHveJazK6kg',
		name: 'alexa-xmcloud-vercel-test-dev',
		installationId: '1AX8SfteUihDu07bvQSmNy',
		environmentId: '43pOZzEpdaJw6kdGyQJEOf',
		siteId: 'bfa15a958c404ca9b527b838b1312c00',
		host: 'https://alexa-xmcloud-vercel-test-dev.vercel.app',
		provider: HostingProvider.Vercel,
	},
};

export const hostedSitesMock = [
	{
		id: 'prj_TrTVAekhdQYxEsgHiPHveJazK6kg',
		name: 'alexa-xmcloud-vercel-test-dev',
		installationId: '1AX8SfteUihDu07bvQSmNy',
		environmentId: '43pOZzEpdaJw6kdGyQJEOf',
		siteId: 'bfa15a958c404ca9b527b838b1312c00',
		host: 'https://alexa-xmcloud-vercel-test-dev.vercel.app',
		provider: 0,
	},
	{
		id: 'prj_8oxxvP81gwg5eoQKi1t1wofiEqTH',
		name: 'alexa-xmcloud-vercel-test-dev-new',
		installationId: '1AX8SfteUihDu07bvQSmNy',
		environmentId: '43pOZzEpdaJw6kdGyQJEOf',
		siteId: '38f7b41400a540bb8678326d99d5b3cd',
		host: 'https://alexa-xmcloud-vercel-test-dev-new.vercel.app',
		provider: 0,
	},
];

export const installationsMock = [
	{
		provider: 1,
		organizationId: 'org_Jk9RJbQPa0m9Psul',
		id: '1AX8SfteUihDu07bvQSmNy',
		name: 'sitecore-suman',
		createdBy: 'suma@sitecore.net',
		createdAt: '2022-12-20T14:01:40.3324696Z',
		userName: 'sitecore-suman',
		userId: '5bCTzupojOfvV0UhW78wslZf',
		teamId: null,
		teamName: null,
	},
	{
		provider: 1,
		organizationId: 'org_Jk9RJbQPa0m9Psul',
		id: '2UeQXJhKVl36vWCWlwVafu',
		name: 'onesitecore',
		createdBy: 'anco@sitecore.net',
		createdAt: '2022-10-13T11:58:00.2625607Z',
		userName: 'onesitecore',
		userId: 'vPVlQR57uWFDOWGL6mw0jX3u',
		teamId: null,
		teamName: null,
	},
];

import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { LogTypes } from '../../../../@xmcloud/types';
import { LogSelect, Text } from '../../../shared-components';
import styles from '../../../../styles/scrollbar.module.css';
import { Box, ListItem, Stack, UnorderedList, Spinner } from '@chakra-ui/react';

interface Props {
	logs: string[];
	isProgressStart: boolean;
	currentSelectValue: LogTypes;
	setCurrentSelectValue: (value: LogTypes) => void;
	totalPages: number;
}
const loggerLineHeight = 30;
const { WARN, ERROR, INFO } = LogTypes;

const LogRows: FC<Props> = ({
	logs,
	isProgressStart,
	setCurrentSelectValue,
	currentSelectValue,
	totalPages,
}) => {
	const parentRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	const [currentLogs, setCurrentLogs] = useState(logs);

	const warnLogs = useMemo(
		() => logs.filter((log) => log.includes(` ${WARN} `)),
		[logs],
	);
	const errorLogs = useMemo(
		() => logs.filter((log) => log.includes(` ${ERROR} `)),
		[logs],
	);

	const rowVirtualizer = useVirtualizer({
		count: currentLogs.length,
		getScrollElement: () => parentRef.current,
		estimateSize: useCallback(() => loggerLineHeight, []),
	});

	useEffect(() => {
		if (totalPages <= 1 || currentSelectValue === INFO || isProgressStart)
			return;
		if (currentSelectValue === ERROR) {
			setCurrentLogs(errorLogs);
		}
		if (currentSelectValue === WARN) {
			setCurrentLogs(warnLogs);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isProgressStart]);

	return (
		<>
			<LogSelect
				onChange={(e) => {
					const value = e.value as string;
					setCurrentSelectValue(value as LogTypes);
					const selectedLogs =
						value === ERROR
							? errorLogs
							: value === WARN
								? warnLogs
								: logs;
					setCurrentLogs(selectedLogs);
					rowVirtualizer.scrollOffset &&
						rowVirtualizer.scrollToIndex(0);
				}}
				currentValue={currentSelectValue}
			/>
			<Box
				h="calc(100vh - 310px)"
				overflow="auto"
				bg="gray.800"
				width="100%"
				pb={['12', '12', '4', '4']}
				ref={parentRef}
				className={styles['custom-scrollbar']}
				pt="4"
				pl="2"
			>
				{isProgressStart ? (
					<Stack direction="row" py="3">
						<Spinner size="sm" color="gray.100" mt="0.5" />
						<Text color="gray.100" pl="1" text="Loading logs..." />
					</Stack>
				) : (
					<UnorderedList
						h={`${rowVirtualizer.getTotalSize()}px`}
						position="relative"
						bgColor="transparent"
						width="4500px"
						listStyleType="none"
					>
						{rowVirtualizer.getVirtualItems().map((virtualRow) => {
							const { index, size, start } = virtualRow;
							const row = currentLogs[index];
							return (
								<ListItem
									key={virtualRow.index}
									fontFamily="mono"
									sx={{
										whiteSpace: 'pre-wrap',
										wordWrap: 'normal',
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: `${size}px`,
										transform: `translateY(${start}px)`,
										backgroundColor: 'transparent',
										color: row?.includes(INFO)
											? 'gray.100'
											: row?.includes(ERROR)
												? 'danger'
												: row?.includes(WARN)
													? 'warning'
													: 'gray.100',
									}}
								>
									{row}
								</ListItem>
							);
						})}
					</UnorderedList>
				)}
			</Box>
		</>
	);
};

export default LogRows;

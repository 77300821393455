import { useMemo } from 'react';
import { useEditProject } from '../../../@xmcloud/hooks';
import { ADO_BRANCH_PREFIX } from '../../../@xmcloud/utils/constants';
import { IGetEnvironmentResponse } from '../../models/environmentModel';
import { useGetADOBranches } from '../../services/adoApi';
import { SelectBranch } from './SelectBranch';

export const SelectAdoBranch: React.FC<{
	token: string;
	environment: Partial<IGetEnvironmentResponse>;
}> = ({ token, environment }) => {
	const { values } = useEditProject();
	const { adoOrganization, adoProjectName, repositoryId } = values;
	const { repositoryBranch, id } = environment;
	const { data, isLoading } = useGetADOBranches(
		token,
		adoOrganization,
		adoProjectName,
		repositoryId as string,
	);

	const branches = useMemo(() => {
		let branches =
			data?.data.value.map((r: any) => ({
				label: r.name.replace(ADO_BRANCH_PREFIX, ''),
				value: r.name.replace(ADO_BRANCH_PREFIX, ''),
			})) || [];
		branches.push({ label: 'none', value: '' });
		return branches;
	}, [data]);

	return (
		<SelectBranch
			{...{
				branches,
				isLoading,
				repositoryBranch: repositoryBranch || '',
				id,
			}}
		/>
	);
};

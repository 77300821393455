import { environment } from '../../../../@xmcloud/core/messages/en';
import DeleteForm from './DeleteForm';
import { IGetEnvironmentResponse } from '../../../models/environmentModel';
import { useDeleteEnvironmentMutation } from '../../../services/environments';
import { useQueryClient } from 'react-query';
import config from '../../../config/config';
import { useParams } from 'react-router-dom';
import { handlePush } from '../../../../@xmcloud/utils/helpers';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { useSharedState } from '../../../../@xmcloud/hooks';
import { IS_DELETED_ENVIRONMENT } from '../../../../@xmcloud/hooks/context/useSharedState';

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
	environmentDetails: IGetEnvironmentResponse;
}

const DeleteEnvironment: React.FC<Props> = ({
	openModal,
	setOpenModal,
	environmentDetails,
}) => {
	const params = useParams<{
		projectId: string;
		environmentId?: string;
	}>();
	const { setState: setDeletedEnvironment } = useSharedState(
		IS_DELETED_ENVIRONMENT,
		'',
	);
	const { mutate, isLoading } = useDeleteEnvironmentMutation();
	const queryClient = useQueryClient();

	const handleSubmit = () => {
		mutate(
			{ id: environmentDetails.id },
			{
				onSuccess: () => {
					setDeletedEnvironment(environmentDetails.id);
					const envType = environmentDetails?.type || '';
					if (!!envType) {
						queryClient.invalidateQueries(
							config.environments.environments_queryByType.queryKey(
								//@ts-ignore
								envType,
								environmentDetails.projectId,
							),
							{
								refetchActive: true,
								refetchInactive: true,
							},
						);
					}

					queryClient.invalidateQueries(
						config.projects.project_environments.queryKey(
							environmentDetails.projectId,
						),
						{
							refetchActive: true,
							refetchInactive: true,
						},
					);
					queryClient.invalidateQueries(
						config.projects.project.queryKey(
							environmentDetails.projectId,
						),
						{
							refetchActive: true,
							refetchInactive: true,
						},
					);
					setOpenModal(false);
					if (params?.environmentId) {
						handlePush(goTo.environments(params.projectId!));
					}
				},
			},
		);
	};

	return (
		<DeleteForm
			openModal={openModal}
			title={environment.environment}
			onClose={() => setOpenModal(false)}
			dataTestId="delete-environment-form"
			handleSubmit={handleSubmit}
			isLoading={isLoading}
			checkPhrase={environmentDetails.name}
			formFor="environment"
		/>
	);
};

export default DeleteEnvironment;

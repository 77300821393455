import { useCallback, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import { secondStep, editProject } from '../../../@xmcloud/core/messages/en';
import { useEditProject } from '../../../@xmcloud/hooks';
import { FormikValues } from '../../../@xmcloud/hooks/context/EditProjectContext';
import {
	useGetGitubRepository,
	useGetPaginatedGithubRepositories,
} from '../../services/githubApi';
import { SelectFormControl } from '../../shared-components';
import { REPOSITORY, REPOSITORY_ID, TOptions } from '../create-project/helpers';
import { useGetRepositoriesValidation } from './useGetRepositoriesValidation';

const { chooseRepository } = secondStep;
const { githubRepo, error2 } = editProject;

export const GitHubRepository: React.FC = () => {
	const inputQuery = useRef('');
	const {
		setFieldTouched,
		errors,
		values,
		touched,
		editValues,
		prevValues,
		accessToken,
		isIntegrationValidationFetching,
		setValues,
		setFieldValue,
		setEditValues,
		setPrevValues,
		setFieldError,
	} = useEditProject();

	const { tooltipErrorMsg, isLoading: isSystemStatusLoading } =
		useGetRepositoriesValidation();

	const { editRepository } = editValues;
	const enableRepoField = values.hasAccount && !editRepository;

	const sourceControlIntegrationId = values.sourceControlIntegrationId || '';
	const shouldCallRepositoryApi =
		editRepository && values.repository && !values.repositoryId;
	const shouldCallRepositoriesApi = !values.hasAccount || editRepository;

	const {
		data: getRepositoriesData,
		isLoading: isLoadingGithubRepositories,
		isFetching,
		fetchNextPage,
		hasNextPage,
		status: repositoriesStatus,
	} = useGetPaginatedGithubRepositories(
		accessToken,
		sourceControlIntegrationId,
		shouldCallRepositoriesApi,
		onSuccess,
	);

	const { data: getRepositoryData, isLoading: isGitHubRepositoryLoading } =
		useGetGitubRepository({
			token: accessToken,
			owner: values.account,
			repo: values.repository,
			_enabled: !!shouldCallRepositoryApi,
		});

	const currentRepositoryId = getRepositoryData?.data?.id || '';

	function onSuccess(lastPage: any) {
		const query = inputQuery.current;

		if (query === '' || !hasNextPage || !lastPage.length) return;

		const hasRepo = lastPage.some((r: any) => r.name.includes(query));

		if (!hasRepo) {
			fetchNextPage();
		}
	}

	const repositoryOptions: TOptions = useMemo(
		() =>
			getRepositoriesData?.map((r: any) => ({
				label: r.name,
				value: r.id + '',
			})) || [],
		[getRepositoriesData],
	);

	const handleFetchNextPage = useCallback(() => {
		if (hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage, isFetching]);

	const onInputChange = useCallback(
		(e: any) => {
			if (inputQuery) {
				inputQuery.current = e;
			}

			if (
				e === '' ||
				!hasNextPage ||
				!repositoryOptions?.length ||
				isFetching
			)
				return;

			const hasRepo = repositoryOptions.some((r: any) =>
				r.label.includes(e),
			);

			if (!hasRepo) {
				handleFetchNextPage();
			}
		},
		[handleFetchNextPage, hasNextPage, isFetching, repositoryOptions],
	);

	const debouncedChangeHandler = useMemo(
		() => debounce(onInputChange, 300),
		[onInputChange],
	);

	useEffect(() => {
		return () => {
			debouncedChangeHandler.cancel();
		};
	}, [debouncedChangeHandler]);

	useEffect(() => {
		if (currentRepositoryId && editRepository && !values.repositoryId) {
			setFieldValue(REPOSITORY_ID, `${currentRepositoryId}`);
			if (!prevValues.repositoryId) {
				setPrevValues((prev) => ({
					...prev,
					[REPOSITORY_ID]: `${currentRepositoryId}`,
				}));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editRepository, currentRepositoryId]);

	const hasNoRepository =
		repositoriesStatus === 'success' && getRepositoriesData.length === 0;

	useEffect(() => {
		if (hasNoRepository && editRepository) {
			setFieldTouched(REPOSITORY, true);
			setFieldError(REPOSITORY, error2);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasNoRepository, editRepository]);

	const isLoading =
		isLoadingGithubRepositories ||
		isGitHubRepositoryLoading ||
		isFetching ||
		isIntegrationValidationFetching ||
		isSystemStatusLoading;

	return (
		<SelectFormControl
			{...{
				isInvalid: Boolean(errors.repository && touched.repository),
				isLoading: isLoading,
				options: repositoryOptions,
				showAsPlaceHolder: enableRepoField,
				isEditable: true,
				onChange: (e: any) => {
					if (values.repositoryId === e.value) return;
					setValues((prev: FormikValues) => ({
						...prev,
						[REPOSITORY]: e.label || '',
						[REPOSITORY_ID]: e.value || '',
					}));
				},
				label: enableRepoField ? githubRepo : chooseRepository,
				name: REPOSITORY,
				currentValue: enableRepoField
					? values.repository
					: values.repositoryId,
				onFocus: () => setFieldTouched(REPOSITORY, true),
				error: errors.repository,
				onInputChange: debouncedChangeHandler,
				onMenuScrollToBottom: () => {
					handleFetchNextPage();
				},
				onEditClick: () => {
					setEditValues((prev) => ({
						...prev,
						editRepository: true,
					}));
				},
				isEditBtnDisabled: isSystemStatusLoading || !!tooltipErrorMsg,
				tooltipLabel: tooltipErrorMsg,
			}}
		/>
	);
};

import { ITransferDetailsResponse } from '../app/models/transferDetailsResponse';
import { TransferProcessStatus } from '../app/models/transferModel';
import { TransferMock } from './transfer';

export const TransferDetailsMock: ITransferDetailsResponse = {
	...TransferMock,
	chunksInitialization: {
		id: 'mock-transfer-init',
		name: 'chunksInitialization',
		completedAt: '2021-08-11T16:17:01.542Z',
		startedAt: '2021-08-11T16:17:01.542Z',
		status: TransferProcessStatus.Complete,
		errors: [],
	},
	contentChunkPull: {
		id: 'mock-transfer-pull',
		name: 'contentChunkPull',
		completedAt: '2021-08-11T16:17:01.542Z',
		startedAt: '2021-08-11T16:17:01.542Z',
		status: TransferProcessStatus.InProgress,
		errors: [],
	},
	contentChunkPush: {
		id: 'mock-transfer-push',
		name: 'contentChunkPush',
		completedAt: '2021-08-11T16:17:01.542Z',
		startedAt: '2021-08-11T16:17:01.542Z',
		status: TransferProcessStatus.NotStarted,
		errors: [],
	},
	contentCommitPush: {
		id: 'mock-transfer-commit-push',
		name: 'contentCommitPush',
		completedAt: '2021-08-11T16:17:01.542Z',
		startedAt: '2021-08-11T16:17:01.542Z',
		status: TransferProcessStatus.NotStarted,
		errors: [],
	},
	contentConsume: {
		id: 'mock-transfer-consume',
		name: 'contentConsume',
		completedAt: '2021-08-11T16:17:01.542Z',
		startedAt: '2021-08-11T16:17:01.542Z',
		status: TransferProcessStatus.Failed,
		errors: [
			'Database connection is failed',
			'Test error',
			'What is happened',
		],
	},
};

import { FC, useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
	app,
	projects,
	environment,
} from '../../../../@xmcloud/core/messages/en';
import {
	InputFormControl,
	ModalMainContent,
	ModalWrapper,
	TransifexTextAsBold,
} from '../../../shared-components';
import { t } from '@transifex/native';

const { noMatchingName, required } = projects;
const NAME = 'checkPhrase';

interface Props {
	openModal: boolean;
	onClose: () => void;
	checkPhrase: string;
	title: string;
	handleSubmit: () => void;
	isLoading: boolean;
	dataTestId: string;
	formFor: 'project' | 'environment';
}

const DeleteForm: FC<Props> = ({
	openModal,
	onClose,
	checkPhrase,
	title,
	handleSubmit,
	isLoading = false,
	dataTestId,
	formFor,
}) => {
	const initialValues = useRef({
		checkPhrase: '',
	});
	const isForProject = formFor === 'project';

	const validationSchema = yup.object({
		checkPhrase: yup
			.string()
			.required(t(required))
			.oneOf(
				//@ts-ignore
				[checkPhrase, null],
				t(isForProject ? noMatchingName : environment.noMatchingName),
			),
	});

	const {
		errors,
		values,
		handleChange,
		handleSubmit: formikHandleSubmit,
	} = useFormik({
		enableReinitialize: true,
		initialValues: initialValues.current,
		validationSchema,
		validateOnBlur: false,
		onSubmit: (values) => {
			if (values.checkPhrase === checkPhrase) {
				handleSubmit && handleSubmit();
			}
		},
	});
	return (
		<ModalWrapper
			isOpen={openModal}
			onClose={onClose}
			dataTestId="delete-modal"
			title={`${app.delete} ${title}`}
		>
			<form data-testid={dataTestId} onSubmit={formikHandleSubmit}>
				<ModalMainContent
					analyticsId={dataTestId}
					onClose={onClose}
					rightButtonText={app.delete}
					colorScheme="danger"
					isLoading={isLoading}
					buttonGroupFlexDirection="row-reverse"
				>
					<TransifexTextAsBold
						text={app.deleteConfirm}
						boldText={title}
						as="a"
					/>
					<InputFormControl
						{...{
							isInvalid: Boolean(errors.checkPhrase),
							label: `Type "${checkPhrase}" to confirm`,
							name: NAME,
							value: values.checkPhrase,
							onChange: handleChange,
							error: errors.checkPhrase,
							dataTestId: `delete-${title}-input`,
							pt: 4,
						}}
					/>
				</ModalMainContent>
			</form>
		</ModalWrapper>
	);
};

export default DeleteForm;

import { t } from '@transifex/native';
import { Link, Text, TextProps } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { commonCw } from '../../../../@xmcloud/core/messages/en';
const { learnMore } = commonCw;

interface Props {
	text: string;
	href?: string;
}

export const Microcopy: React.FC<Props & TextProps> = ({
	text,
	href,
	...rest
}) => (
	<Text maxW="lg" variant="small" {...rest}>
		<T _str={text} />
		{!!href && (
			<Link isExternal href={href} boxShadow="none !important" pl="1">
				{t(learnMore)}
			</Link>
		)}
	</Text>
);

import { useEffect } from 'react';
import { handlePush } from '../../../../@xmcloud/utils/helpers';
import { useCreateProjectV3 } from './useCreateProjectV3';

export const useRedirect404 = () => {
	const { enableCreateProjectV3 } = useCreateProjectV3();

	useEffect(() => {
		if (!enableCreateProjectV3) {
			handlePush('/404');
		}
	}, [enableCreateProjectV3]);
	return {};
};

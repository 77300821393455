import { Card, CardBody, Text, Image, CardProps, Flex } from '@chakra-ui/react';
import React from 'react';
import { Icon } from '..';

export const HoverableCardWithIcon: React.FC<
	{
		path?: string;
		description: string;
		src?: string;
		isActive?: boolean;
		iconColor?: string;
	} & CardProps
> = ({
	path,
	description,
	src,
	isActive,
	iconColor = 'neutral',

	...rest
}) => {
	return (
		<Card
			cursor="pointer"
			layerStyle="interactive.raise"
			variant="outlineRaised"
			{...rest}
			{...(Boolean(isActive) && { borderColor: 'primary' })}
		>
			<Flex
				direction="column"
				alignItems="center"
				justifyItems="center"
				pt={5}
			>
				{Boolean(src) && (
					<Image src={src} alt="" width="24" height="24" />
				)}
				{Boolean(path) && (
					<Icon
						data-testid="card-icon"
						borderRadius="full"
						path={path}
						w="5"
						h="5"
						color={iconColor}
					/>
				)}
			</Flex>
			<CardBody>
				<Text variant="subtle" textAlign="center">
					{description}
				</Text>
			</CardBody>
		</Card>
	);
};

export const HoverableCard: React.FC<
	{
		isActive?: boolean;
		children: React.ReactNode;
		disabled?: boolean;
	} & CardProps
> = ({ isActive, children, disabled, ...rest }) => {
	return (
		<Card
			cursor={disabled ? 'block' : 'pointer'}
			layerStyle={disabled ? 'subtle' : 'interactive.raise'}
			variant="outlineRaised"
			{...rest}
			{...(Boolean(isActive) && {
				borderColor: disabled ? 'transparent' : 'primary',
			})}
			{...(Boolean(disabled) && { opacity: 0.4 })}
		>
			{children}
		</Card>
	);
};

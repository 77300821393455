import { FC, useState, ReactNode } from 'react';
import useResizeObserver from 'use-resize-observer';
import { minWidthMd, minWidthSm } from '../../utils/constants';
import styles from '../../../styles/scrollbar.module.css';
import NavigationVertical from '../components/NavigationVertical';
import { useSharedState } from '../../hooks';
import { IS_MD_SCREEN, IS_SM_SCREEN } from '../../hooks/context/useSharedState';
import { TopBar } from '..';
import { useGainsightPX } from '../../../app/services/gainsight';
import {
	Layout,
	LayoutHeader,
	LayoutLeft,
	LayoutMain,
} from '../../../app/shared-components';
import GitopsValidationAlert from '../components/GitopsValidationAlert';

interface Props {
	children: ReactNode;
}

export const MainLayout: FC<Props> = ({ children }) => {
	useGainsightPX();
	const [lgSize, setLgSize] = useState(true);
	const { setState: setSmScreen } = useSharedState(IS_SM_SCREEN, false);
	const { setState: setMdScreen } = useSharedState(IS_MD_SCREEN, false);

	const { ref } = useResizeObserver<HTMLDivElement>({
		onResize: ({ width }) => {
			if (width && width < minWidthMd) {
				setMdScreen(true);
				if (width && width < minWidthSm) {
					setSmScreen(true);
				} else {
					setSmScreen(false);
				}
				setLgSize(false);
			} else {
				setLgSize(true);
				setSmScreen(false);
				setMdScreen(false);
			}
		},
	});
	return (
		<div ref={ref} data-testid="main-layout">
			{/* @ts-ignore */}
			<Layout>
				<LayoutHeader overflow="visible">
					<TopBar {...{ lgSize, setLgSize }} />
				</LayoutHeader>
				<LayoutMain disablePadding>
					{/* @ts-ignore */}
					<Layout width="100%" height="100%">
						{lgSize && (
							<LayoutLeft w="2xs" minWidth="2xs" inset p="5">
								<NavigationVertical />
							</LayoutLeft>
						)}
						<LayoutMain
							layerStyle="section.main"
							className={styles['custom-scrollbar']}
						>
							<GitopsValidationAlert />
							{children}
						</LayoutMain>
					</Layout>
				</LayoutMain>
			</Layout>
		</div>
	);
};

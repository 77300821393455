import { Text, useToast } from '@chakra-ui/react';
import {
	app,
	deploymentLogs,
	deployments,
} from '../../../../@xmcloud/core/messages/en';
import { useCancelDeploymentMutation } from '../../../services/deployments';
import { AlertDialogConfirmationModal } from '../../../shared-components';
import { t } from '@transifex/native';
import { useQueryClient } from 'react-query';
import config from '../../../config/config';
import { DeploymentFilter } from '../../../../@xmcloud/types';

interface Props {
	deploymentId: string;
	environmentId: string;
	setOpenModal: (show: boolean) => void;
	openModal: boolean;
}

const CancelDeployment: React.FC<Props> = ({
	openModal,
	setOpenModal,
	deploymentId,
	environmentId,
}) => {
	const toast = useToast();
	const { mutate, isLoading } = useCancelDeploymentMutation();
	const queryClient = useQueryClient();

	function handleSubmit() {
		mutate(
			{ deploymentId, environmentId },
			{
				onSuccess: () => {
					setOpenModal(false);
					const { queryKey } = config.deployments.allDeployments_v3;
					queryClient.invalidateQueries(
						queryKey(1, DeploymentFilter.all),
					);
					queryClient.invalidateQueries(
						queryKey(1, DeploymentFilter.user),
					);
					queryClient.invalidateQueries(
						config.deployments.deploymentsByStatus.queryKey,
					);
					toast({
						status: 'success',
						description: t(deploymentLogs.deploymentCanceled),
					});
				},
			},
		);
	}

	return (
		<AlertDialogConfirmationModal
			isOpen={openModal}
			onClose={() => setOpenModal(false)}
			onConfirm={handleSubmit}
			title={deployments.cancel}
			leftButtonText={app.ok}
			isLoading={isLoading}
			dataTestId="confirmation-cancel-modal"
		>
			<Text>{t(deployments.cancelConfirmation)}</Text>
		</AlertDialogConfirmationModal>
	);
};

export default CancelDeployment;

import { useAuthQuery } from '../apiUtils/queryHelper';
import config from '../config/config';
import { ITransferDetailsResponse } from '../models/transferDetailsResponse';

/**
 * Get transfer details
 * @param param0 transfer id
 * @returns IGetTransferDetailsResponse
 */
export const useGetTransferDetails = ({ id }: { id: string }) => {
	const { scope, url, queryKey } = config.transfers.transferDetails;
	return useAuthQuery(
		queryKey(id),
		(axiosInstance) => {
			return axiosInstance?.get<ITransferDetailsResponse>(url(id));
		},
		{ staleTime: 5000 },
		scope,
	);
};

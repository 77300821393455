import { VStack } from '@chakra-ui/react';
import {
	sidebarHelpItems as helpItems,
	sidebarSuggestionItems as suggestionItems,
} from '../resources';
import { MediaLink, MediaLinkProps } from './MediaLink';

const Help: React.FC = () => {
	const linkItems = [...helpItems, ...suggestionItems];

	return (
		<>
			<VStack gap="5" align="flex-start">
				{linkItems.map((linkItem: MediaLinkProps, i: number) => {
					return <MediaLink {...linkItem} key={i} newWindow={true} />;
				})}
			</VStack>
		</>
	);
};

export default Help;

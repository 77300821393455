import { useParams } from 'react-router-dom';
import {
	useEnvironmentDetails,
	useProjectDetails,
	useSites,
} from '../../../../@xmcloud/hooks';
import { useGetEditingSecret } from '../../../services/environments';
import { useGetRepositoryIntegration } from '../../../services/repositories';
import {
	AlertDialogConfirmationModal,
	CircularLoading,
	CodeBlock,
	HeadingMd,
	HeadingSm,
	SelectFormControl,
	TransifexTextAsBold,
	Text,
} from '../../../shared-components';
import { useEffect, useMemo, useState } from 'react';
import { useGetTenant } from '../../../services/platformInventory';
import { useFeature } from '../../../feature-flag/features';
import { OLDCombinedProjectDevSettings } from '../../../stale-components/OLDCombinedProjectDevSettings';
import { Card, CardBody, CardHeader, Divider, Stack } from '@chakra-ui/react';
import { app, devSettings } from '../../../../@xmcloud/core/messages/en';
import { EnumInNumberSourceControl } from '../../../../@xmcloud/types';
import { Toggle } from '../components/developer-settings/ToggleDeveloperSettings';
import { NewJssVersionSettings } from '../components/developer-settings/NewJssVersionSettings';
import { isPhoneDevice } from '../../../../@xmcloud/utils/helpers';
import { CombinedLowerJssSettings } from '../components/developer-settings/CombinedLowerJssSettings';
import { EnvironmentTypesEnum } from '../../create-project/helpers';

const {
	chooseSite,
	preview,
	live,
	ContextId,
	info1,
	info2,
	title,
	info,
	txt1,
	txt2,
	txt4,
	noSite,
	step1,
	step2,
	step3,
	title1,
	jssVersionTooltip,
	contextIdTooltip,
} = devSettings;
const { close: closetxt } = app;

const SITE_NAME = 'siteName';
const runCodeLines = [`npm run start:connected`];

const { ADO, GitHub } = EnumInNumberSourceControl;
const { EH } = EnvironmentTypesEnum;

export const DeveloperSettings: React.FC = () => {
	const { environmentId, projectId, ...restParams } = useParams<{
		environmentId: string;
		projectId: string;
		'envType-environments': string;
	}>();
	const developerSettingForLowerJss = useFeature(
		'DeveloperSettingForLowerJss',
	);
	const [isPreview, setIsPreview] = useState(true);
	const [siteName, setSiteName] = useState('');
	const [openInfo, setOpenInfo] = useState({ isOpen: false, info: 1 });
	const [isNewVersion, setIsNewVersion] = useState(true);
	const [isEditingSecretAvailable, setIsEditingSecretAvailable] =
		useState(true);

	const {
		environment,
		cmEnvironmentId,
		platformTenantId,
		isLoading: isEnvironmentLoading,
	} = useEnvironmentDetails(environmentId!);

	const {
		project,
		repository,
		sourceControlIntegrationId,
		sourceControlIntegrationName,
		isLoading: projectIsLoading,
	} = useProjectDetails(projectId!);

	const envType = restParams['envType-environments']?.split('-')[0];
	const isEhEnvironment = envType === EH;
	// NOTE: Eh environment cannot have it's own contextid because an eh environment is relying on the cm environment's contextid
	const currentEnvironmentId = isEhEnvironment
		? cmEnvironmentId
		: environmentId;

	const { data: editingSecretData, isLoading: editingSecretIsLoading } =
		useGetEditingSecret({
			environmentId: currentEnvironmentId!,
			onNotFoundError: (err) => {
				setIsEditingSecretAvailable(false);
			},
		});

	const { sites, environment: cmEnvironmentDetail } =
		useSites(currentEnvironmentId);

	const { data: srcIntegration, isLoading: isIntegrationDataLoading } =
		useGetRepositoryIntegration(sourceControlIntegrationId);
	const { data: tenantData, isLoading: isGetTenantLoading } = useGetTenant({
		tenantId: platformTenantId,
	});

	const cdpEmbeddedTenantID =
		tenantData?.data?.annotations['XMCloud.CDPEmbeddedTenantID'] || '';
	const editingSecret = editingSecretData?.data || '';
	const provider = srcIntegration?.data?.provider || '';
	const isAdoProvider = provider === ADO;
	const isGitProvider = provider === GitHub;
	const adoRepo = repository?.split('/') || [];

	const gitClone = `git clone https://github.com/${sourceControlIntegrationName}/${repository}.git`;
	const adoClone = `git clone https://dev.azure.com/${adoRepo[0]}/${adoRepo[1]}/_git/${adoRepo[2]}`;
	const combinedSourceCodePath = '\\src\\sxastarter';
	const ehSourceCodePath = '\\src';

	const checkedoutCodeLine = [
		`${
			isAdoProvider
				? adoClone
				: isGitProvider
					? gitClone
					: 'clone your repository'
		}`,
		!!repository
			? `cd ${repository}${isEhEnvironment ? ehSourceCodePath : combinedSourceCodePath}`
			: 'cd path to your app directory',
		`npm install`,
	];

	const siteList = useMemo(
		() => sites.map((s) => ({ label: s.name, value: s.name })),
		[sites],
	);

	useEffect(() => {
		if (sites.length && !siteName) {
			setSiteName(sites[0].name);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sites.length]);

	const isLoading =
		projectIsLoading ||
		isEnvironmentLoading ||
		editingSecretIsLoading ||
		isIntegrationDataLoading ||
		isGetTenantLoading;

	if (isLoading) return <CircularLoading />;

	if (developerSettingForLowerJss)
		return (
			<>
				<Stack
					w="100%"
					py={6}
					direction={['column', 'column', 'row', 'row']}
					gap={8}
				>
					<Stack w={['100%', '100%', '70%', '70%']}>
						<HeadingSm text={title} />
						<Stack
							direction="row"
							gap={6}
							pb="4"
							flexWrap="wrap"
							bgColor={['chakra-subtle-bg', 'chakra-body-bg']}
						>
							<SelectFormControl
								{...{
									options: siteList,
									onChange: (e: any) => {
										setSiteName(e.value);
									},
									label: chooseSite,
									name: SITE_NAME,
									currentValue: siteName,
									maxW: '2xs',
									isDisabled: !siteList.length,
									pt: 0,
									showInfoIcon: true,
									infoText: info1,
									placeholder: siteList.length
										? 'Select...'
										: noSite,
									onInfoIconClick: () =>
										isPhoneDevice &&
										setOpenInfo({
											isOpen: true,
											info: 1,
										}),
								}}
							/>
							<Toggle
								setOpenInfo={setOpenInfo}
								setIsPreview={setIsNewVersion}
								dataTestId1="new-jss-version-toggle"
								dataTestId2="old-jss-version-toggle"
								tabText1={'JSS 21.6+'}
								tabText2={'JSS 21.5 or earlier'}
								title={'JSS version'}
								tooltipText={jssVersionTooltip}
							/>
							<Toggle
								setOpenInfo={setOpenInfo}
								setIsPreview={setIsPreview}
								dataTestId1="contextid-preview-button"
								dataTestId2="contextid-live-button"
								tabText1={preview}
								tabText2={live}
								title={ContextId}
								tooltipText={
									!isNewVersion ? contextIdTooltip : info2
								}
								isDisabled={!isNewVersion}
							/>
						</Stack>
						{isNewVersion ? (
							<NewJssVersionSettings
								{...{
									environmentDetail: isEhEnvironment
										? cmEnvironmentDetail
										: environment,
									isPreview,
									editingSecret,
									siteName,
									isEditingSecretAvailable,
								}}
							/>
						) : (
							<CombinedLowerJssSettings
								{...{
									siteName,
									cdpEmbeddedTenantID,
									sites,
									currentEnvironmentId: currentEnvironmentId!,
								}}
							/>
						)}
						<Stack>
							<Divider py="3" />
							<Stack pt={[10, 10, 5, 5]}>
								<HeadingSm text={title1} />
								<Stack gap={5}>
									<Stack>
										<Text pb={[4, 4, 1, 1]} text={step1} />
										<CodeBlock
											items={checkedoutCodeLine}
											clipboardText={checkedoutCodeLine.join(
												'\n',
											)}
											data-testid="code-block"
											key="checkout-code-block"
										/>
									</Stack>
									<Text w="full" text={step2} />
									<Stack>
										<Text w="full" text={step3} />
										<CodeBlock
											items={runCodeLines}
											clipboardText={runCodeLines.join(
												'\n',
											)}
											data-testid="code-block"
											key="run-code-block"
										/>
									</Stack>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
					<Stack w={['100%', '100%', '30%', '30%']} pt={[2, 2, 8, 8]}>
						<Card variant="filled">
							<CardHeader>
								<HeadingMd text={info} />
							</CardHeader>
							<CardBody>
								<Text text={txt1} />
								<Text py={3} text={txt2} />
								<TransifexTextAsBold
									text={txt4}
									boldText={
										isNewVersion
											? 'Site, JSS version'
											: 'Site '
									}
									boldText2={
										isNewVersion ? 'Context' : 'JSS version'
									}
								/>
							</CardBody>
						</Card>
					</Stack>
				</Stack>
				{openInfo.isOpen && (
					<AlertDialogConfirmationModal
						{...{
							isOpen: openInfo.isOpen,
							onClose: () =>
								setOpenInfo({ isOpen: false, info: 1 }),
							shouldShowActionBtn: false,
							rightButtonText: closetxt,
						}}
					>
						<Text
							mt="4"
							text={openInfo.info === 1 ? info1 : info2}
						/>
					</AlertDialogConfirmationModal>
				)}
			</>
		);

	return (
		<OLDCombinedProjectDevSettings
			{...{
				project,
				environmentDetail: environment,
				editingSecret,
				isEditingSecretAvailable,
				sites,
				provider,
			}}
		/>
	);
};

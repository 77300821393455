import {
	CONTENT_TRANSFER,
	useSharedState,
} from '../../../@xmcloud/hooks/context/useSharedState';
import { Box, Switch } from '@chakra-ui/react';
import { useExperimentalFeatures } from '../../../@xmcloud/hooks';
import { useFeature } from '../../feature-flag/features';
import { CardBox, Text } from '../../shared-components';

export const ExperimentalFeatures: React.FC = () => {
	const createProjectV3 = useFeature('CreateProjectV3');
	const contentTransfer = useFeature('ContentTransfer');

	const {
		createProjectV3ExperimentalFeature,
		setCreateProjectV3ExperimentalFeature,
		contentTransferExpFeat,
		setContentTransferExpFeature,
	} = useExperimentalFeatures();
	const { setState: setContentTransferEnabled } = useSharedState(
		CONTENT_TRANSFER,
		contentTransferExpFeat,
	);
	const noExperimentalFeatures = !createProjectV3 && !contentTransfer;

	if (noExperimentalFeatures)
		return (
			<Box
				width="full"
				alignItems="center"
				display="flex"
				justifyContent="center"
				pt="5%"
			>
				<Text
					fontWeight="semibold"
					text="No experimental features found"
				/>
			</Box>
		);

	return (
		<>
			{createProjectV3 && (
				<CardBox
					title="Create projects with CMs and EHs in different repositories or use the same repository"
					description="You can now streamline your project creation by leveraging a single repository, either from a template or using your own code. Additionally, you have the flexibility to create projects with environments spread across different repositories, utilizing the XMcloud template or your custom code."
					mt={5}
				>
					<Switch
						isChecked={createProjectV3ExperimentalFeature}
						onChange={(e) => {
							setCreateProjectV3ExperimentalFeature(
								e.target.checked,
							);
						}}
					/>
				</CardBox>
			)}
			{contentTransfer && (
				<CardBox
					title="Content Transfer"
					description="Transfer content between instances"
					mt={5}
				>
					<Switch
						isChecked={contentTransferExpFeat}
						onChange={(e) => {
							setContentTransferExpFeature(e.target.checked);
							setContentTransferEnabled(e.target.checked);
						}}
					/>
				</CardBox>
			)}
		</>
	);
};

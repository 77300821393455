import {
	DeployProcessStatus,
	ICreateDeploymentResponse,
	IGetDeploymentResponse,
} from '../app/models/deploymentModel';

export const DeploymentMock: IGetDeploymentResponse = {
	projectId: 'mock-project-id-1',
	organizationId: 'mock-org-id',
	environmentId: 'mock-environment-id-1',
	id: 'mock-deployment-id',
	provisioningStatus: DeployProcessStatus.InProgress,
	buildStatus: DeployProcessStatus.Complete,
	deploymentStatus: DeployProcessStatus.Failed,
	postActionStatus: DeployProcessStatus.NotStarted,
	vercelStatus: DeployProcessStatus.NotStarted,
	calculatedStatus: DeployProcessStatus.InProgress,
	createdBy: 'admin',
	createdAt: '2021-08-11T16:17:01.542Z',
	startedAt: '2021-08-11T16:17:01.542Z',
	provisioningStartedAt: '2022-05-10T14:43:47.4326421Z',
	provisioningCompletedAt: '2022-05-10T14:44:40.0306001Z',
	buildStartedAt: '2022-05-10T14:43:46.9633679Z',
	buildCompletedAt: '2022-05-10T14:47:59.2444014Z',
	deploymentStartedAt: null,
	deploymentCompletedAt: null,
	postActionStartedAt: null,
	postActionCompletedAt: null,
	triggerMessage: '<a href="http://www.test.url.com">Commit 123456</a>',
	completedAt: null,
	isCanceled: false,
};

export const DeploymentsMock: IGetDeploymentResponse[] = [
	{
		...DeploymentMock,
		calculatedStatus: DeployProcessStatus.InProgress,
		createdAt: '2021-08-11T16:17:01.542Z',
	},
	{
		...DeploymentMock,
		calculatedStatus: DeployProcessStatus.Complete,
		createdAt: '2021-09-07T16:17:01.542Z',
		startedAt: '2021-08-07T16:17:01.542Z',
		deploymentCompletedAt: '2021-08-07T16:23:02.542Z',
		triggerMessage: 'Deployment created by some other test user',
	},
	{
		...DeploymentMock,
		calculatedStatus: DeployProcessStatus.Failed,
		createdAt: '2021-07-22T16:17:01.542Z',
		triggerMessage:
			"Deployment promoted from deployment '3lHB2EIdGDZH5NjHbQjAL8' and environment 'test-vercel' by suma@sitecore.net",
	},
	{
		...DeploymentMock,
		calculatedStatus: DeployProcessStatus.NotStarted,
		createdAt: '2021-10-22T16:17:01.542Z',
		triggerMessage: 'Deployment created by user@user.com',
	},
	{
		...DeploymentMock,
		calculatedStatus: DeployProcessStatus.Skipped,
		createdAt: '2021-09-22T16:17:01.542Z',
		triggerMessage: 'Deployment created by user@user.com',
	},
];

export const CreateDeploymentResponseMock: ICreateDeploymentResponse = {
	createdAt: '2021-07-22T16:17:01.542Z',
	createdBy: 'admin',
	environmentId: 'test env id',
	id: 'mock-deployment-id',
	organizationId: 'test org id',
	projectId: 'test proj id',
};

export const allMockedDeployments = DeploymentsMock.map((d) => ({
	...d,
	project: 'Test-project	',
	environment: 'Test-env',
}));

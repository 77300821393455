import { Stack, Text as ChakraText } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { commonCw, environment } from '../../../../@xmcloud/core/messages/en';
import {
	AlertDialogConfirmationModal,
	Icon,
	TextBox,
	XMCSButton,
	CopyFieldFormControl,
	ExternalLink,
	Text,
} from '../../../shared-components';
import { mdiSync } from '@mdi/js';
import { useRegenerateContextIdMutation } from '../../../services/environments';
import { useFeature } from '../../../feature-flag/features';
import { learnMoreUrlForContextId } from '../../../../@xmcloud/utils/constants';
import { t } from '@transifex/native';

const {
	contextIdLive: contextIdLivetxt,
	contextIdPreview: contextIdPreviewtxt,
	regenerate: regeneratetxt,
	regenerateContextId: regenerateContextIdtxt,
	regenerateDesc1,
	regenerateDesc2,
	currentContextPreview,
	currentContextLive,
} = environment;
const { learnMore } = commonCw;

export const ContextID: React.FC<{
	previewContextId: string;
	liveContextId: string;
	environmentId: string;
}> = ({ previewContextId, liveContextId, environmentId }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { mutate, isLoading } = useRegenerateContextIdMutation();
	const regenerateContextId = useFeature('RegenerateContextId');

	const items = useMemo(
		() => [
			{ title: contextIdPreviewtxt, subTitle: previewContextId },
			{ title: contextIdLivetxt, subTitle: liveContextId },
		],
		[liveContextId, previewContextId],
	);

	if (!previewContextId || !liveContextId) return <></>;

	const handleSubmit = () => {
		mutate(
			{ environmentId: environmentId },
			{ onSuccess: () => setIsOpen(false) },
		);
	};

	if (!regenerateContextId)
		return (
			<Stack gap={[4, 10]} direction={['column', 'row']}>
				{items.map(({ title, subTitle }) => (
					<TextBox {...{ title, subTitle }} key={title} />
				))}
			</Stack>
		);

	return (
		<Stack gap={[4, 10]} direction={['column', 'row']}>
			{items.map(({ title, subTitle }) => (
				<TextBox {...{ title, subTitle }} key={title} />
			))}
			<XMCSButton
				size="sm"
				variant="ghost"
				colorScheme="primary"
				onClick={() => setIsOpen(true)}
				isLoading={isLoading}
				text={regeneratetxt}
				leftIcon={<Icon path={mdiSync} />}
			/>
			{isOpen && (
				<AlertDialogConfirmationModal
					isOpen={isOpen}
					title={regenerateContextIdtxt}
					onClose={() => setIsOpen(false)}
					dataTestId="generate-contextid-modal"
					isLoading={isLoading}
					onConfirm={handleSubmit}
					leftButtonText={regeneratetxt}
					colorScheme="warning"
					size="xl"
				>
					<ChakraText>
						{t(regenerateDesc1)}
						<ExternalLink
							href={learnMoreUrlForContextId}
							text={learnMore}
						/>
					</ChakraText>
					<Text text={regenerateDesc2} />
					<CopyFieldFormControl
						value={previewContextId}
						label={currentContextPreview}
						key="1"
					/>
					<CopyFieldFormControl
						value={liveContextId}
						label={currentContextLive}
						key="2"
					/>
				</AlertDialogConfirmationModal>
			)}
		</Stack>
	);
};

import {
	Alert as ChakraAlert,
	AlertDescription,
	CloseButton,
	AlertIcon,
	Box,
	AlertProps,
} from '@chakra-ui/react';
import { TransifexTextAsBold } from '..';

type Props = AlertProps & {
	url?: string;
	isVisibleCloseBtn?: boolean;
	description: string;
	boldText?: string;
	boldTextTransform?: 'capitalize' | 'uppercase' | 'lowercase' | 'none';
	boldText2?: string;
	onClose?: () => void;
	children?: React.ReactNode;
};

export const Alert: React.FC<Props> = ({
	url,
	isVisibleCloseBtn = false,
	description,
	boldText = '',
	boldTextTransform = 'capitalize',
	boldText2 = '',
	onClose,
	children,
	...rest
}) => (
	<ChakraAlert status="success" {...rest}>
		<AlertIcon />
		<Box w="full">
			{children ? (
				children
			) : (
				<AlertDescription
					onClick={() => url && window.open(url, '_blank')}
					cursor={url ? 'pointer' : 'default'}
				>
					<TransifexTextAsBold
						{...{
							text: description,
							boldText: boldText,
							textTransform: boldTextTransform,
							boldText2: boldText2,
						}}
					/>
				</AlertDescription>
			)}
		</Box>
		{isVisibleCloseBtn && <CloseButton onClick={onClose} m="-1" />}
	</ChakraAlert>
);

import { Heading, Image, Stack, Text } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { cactusLogo } from '../../../@xmcloud/core/icons';

interface Props {
	title?: string;
	subTitle?: string;
	textVariant?: string;
	fontSize?: string;
	fontWeight?: string;
	mt?: string;
	showCreateButton?: boolean;
	children?: React.ReactNode;
	dataTestId?: string;
}

export const EmptyState: React.FC<Props> = ({
	title = '',
	subTitle = '',
	mt = 5,
	showCreateButton = false,
	children,
	dataTestId = 'empty-state',
}) => {
	return (
		<Stack
			alignItems="center"
			textAlign="center"
			height="60vh"
			spacing={4}
			mt={mt}
			data-testid={dataTestId}
		>
			<Image boxSize="16" src={cactusLogo} alt="Empty icon" />
			<Stack>
				<Heading size="md">{t(title)}</Heading>
				{Boolean(subTitle) && (
					<Text variant="subtle">{t(subTitle)}</Text>
				)}
			</Stack>
			{showCreateButton ? children : null}
		</Stack>
	);
};

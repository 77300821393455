import { useEffect, useMemo } from 'react';
import { editProject, secondStep } from '../../../@xmcloud/core/messages/en';
import { useEditProject } from '../../../@xmcloud/hooks';
import { FormikValues } from '../../../@xmcloud/hooks/context/EditProjectContext';
import { HttpStatusCodes } from '../../apiUtils/resources';
import {
	useGetADOOrganizations,
	useGetADOProfile,
	useGetADOProjects,
	useGetADORepositories,
	useGetADORepository,
} from '../../services/adoApi';
import { SelectFormControl } from '../../shared-components';
import {
	ADO_ORGANIZATION,
	ADO_PROJECT_NAME,
	REPOSITORY,
	REPOSITORY_ID,
	TOptions,
} from '../create-project/helpers';
import { useGetRepositoriesValidation } from './useGetRepositoriesValidation';

const { chooseOrg, chooseProject, chooseRepository } = secondStep;
const { adoRepo, adoProject, adoOrg, error5, error4 } = editProject;
const { NOT_FOUND } = HttpStatusCodes;

export const AdoRepository: React.FC = () => {
	const {
		setFieldTouched,
		errors,
		touched,
		values,
		editValues,
		prevValues,
		accessToken,
		isIntegrationValidationFetching,
		setValues,
		setEditValues,
		setPrevValues,
		setFieldError,
	} = useEditProject();

	const { tooltipErrorMsg, isLoading: isSystemStatusLoading } =
		useGetRepositoriesValidation();

	const sourceControlIntegrationId = values.sourceControlIntegrationId || '';
	const { editOrganization, editProject, editRepository } = editValues;
	const { hasAccount } = values;

	const enableOrgField = hasAccount && !editOrganization;
	const enableProjectField = hasAccount && !editProject;
	const enableRepoField = hasAccount && !editRepository;

	const shouldCallProfileApi = editOrganization || !hasAccount;

	const { data: profileData, isLoading: isLoadingProfile } = useGetADOProfile(
		accessToken,
		sourceControlIntegrationId,
		shouldCallProfileApi,
	);
	const publicAlias = profileData?.data.publicAlias || '';

	function Error(e: any, FIELD: string, name: string) {
		setFieldTouched(FIELD, true);
		if (e.response?.status === NOT_FOUND) {
			setFieldError(FIELD, error4(name));
		} else {
			setFieldError(FIELD, error5);
		}
	}

	const { data: organizationsData, isLoading: isOrganisationLoading } =
		useGetADOOrganizations({
			token: accessToken,
			publicAlias,
			_enabled: shouldCallProfileApi,
			onError: (e) => {
				Error(e, ADO_ORGANIZATION, 'Azure DevOps organization');
			},
		});

	const adoOrganization = values.adoOrganization || '';
	const adoProjectName = values.adoProjectName || '';

	const shouldCallProjectApi = shouldCallProfileApi || editProject;

	const { data: projectsData, isLoading: isLoadingProjects } =
		useGetADOProjects({
			token: accessToken,
			organization: adoOrganization,
			_enabled: shouldCallProjectApi,
			onError: (e) => {
				Error(e, ADO_PROJECT_NAME, 'Azure DevOps project');
			},
		});
	const shouldCallRepositoriesApi = shouldCallProjectApi || editRepository;

	const { data: repositoriesData, isLoading: isLoadingRepositories } =
		useGetADORepositories({
			token: accessToken,
			organization: adoOrganization,
			project: adoProjectName,
			_enabled: shouldCallRepositoriesApi,
			onError: (e) => {
				Error(e, REPOSITORY, 'Repository');
			},
		});

	const shouldCallRepositoryApi =
		editRepository && values.repository && !values.repositoryId;

	const { isFetching: isFetchingADORepository } = useGetADORepository({
		token: accessToken,
		organization: adoOrganization,
		project: adoProjectName,
		repo: values.repository.split('/')[2],
		_enabled: !!shouldCallRepositoryApi,
	});

	const adoOrganizations = useMemo(
		() =>
			organizationsData?.data.value.map((r: any) => ({
				label: r.accountName,
				value: r.accountName,
			})) || [],
		[organizationsData?.data.value],
	);

	const projects = useMemo(
		() =>
			projectsData?.data.value.map((r: any) => ({
				label: r.name,
				value: r.name,
			})) || [],
		[projectsData?.data.value],
	);

	const repositoryOptions: TOptions = useMemo(
		() =>
			repositoriesData?.data.value.map((r: any) => ({
				label: r.name,
				value: r.id,
			})) || [],
		[repositoriesData?.data.value],
	);

	useEffect(() => {
		if (repositoryOptions.length && !values.repositoryId) {
			const repo = values.repository.split('/')[2];

			const repositoryId = repositoryOptions.find(
				(r: any) => r.label === repo,
			)?.value as string;

			setValues((prev: FormikValues) => ({
				...prev,
				[REPOSITORY]: values.repository || '',
				[REPOSITORY_ID]: repositoryId || '',
			}));

			if (!prevValues.repositoryId) {
				setPrevValues((prev) => ({
					...prev,
					[REPOSITORY]: values.repository || '',
					[REPOSITORY_ID]: repositoryId || '',
				}));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [repositoryOptions.length]);

	const isOrgLoading =
		isIntegrationValidationFetching ||
		isOrganisationLoading ||
		isLoadingProfile;

	const isProjectLoading =
		isIntegrationValidationFetching ||
		isLoadingProjects ||
		isLoadingProfile;

	const isRepositoryLoading =
		isIntegrationValidationFetching ||
		isLoadingRepositories ||
		isLoadingProfile ||
		isFetchingADORepository;

	return (
		<>
			<SelectFormControl
				{...{
					isInvalid: Boolean(
						errors.adoOrganization && touched.adoOrganization,
					),
					isLoading: isOrgLoading,
					options: adoOrganizations,
					showAsPlaceHolder: enableOrgField,
					isEditable: true,
					onChange: (e: any) => {
						if (values.adoOrganization === e.value) return;
						setValues((prev: FormikValues) => ({
							...prev,
							[ADO_ORGANIZATION]: e.value || '',
							[ADO_PROJECT_NAME]: '',
							[REPOSITORY]: '',
							[REPOSITORY_ID]: '',
						}));
					},
					label: enableOrgField ? adoOrg : chooseOrg,
					name: ADO_ORGANIZATION,
					currentValue: adoOrganization,
					error: errors.adoOrganization,
					onFocus: () => setFieldTouched(ADO_ORGANIZATION, true),
					onEditClick: () => {
						setEditValues((prev) => ({
							...prev,
							editOrganization: true,
							editProject: true,
							editRepository: true,
						}));
					},
					isEditBtnDisabled:
						isSystemStatusLoading || !!tooltipErrorMsg,
					tooltipLabel: tooltipErrorMsg,
				}}
			/>
			<SelectFormControl
				{...{
					isInvalid: Boolean(
						errors.adoProjectName && touched.adoProjectName,
					),
					isLoading: isProjectLoading,
					options: projects,
					showAsPlaceHolder: enableProjectField,
					isEditable: true,
					onChange: (e: any) => {
						if (values.adoProjectName === e.value) return;
						setValues((prev: FormikValues) => ({
							...prev,
							[ADO_PROJECT_NAME]: e.value || '',
							[REPOSITORY]: '',
							[REPOSITORY_ID]: '',
						}));
					},
					label: enableProjectField ? adoProject : chooseProject,
					name: ADO_PROJECT_NAME,
					currentValue: adoProjectName,
					onFocus: () => setFieldTouched(ADO_PROJECT_NAME, true),
					error: errors.adoProjectName,
					onEditClick: () => {
						setEditValues((prev) => ({
							...prev,
							editProject: true,
							editRepository: true,
						}));
					},
					isEditBtnDisabled:
						isSystemStatusLoading || !!tooltipErrorMsg,
					tooltipLabel: tooltipErrorMsg,
				}}
			/>
			<SelectFormControl
				{...{
					error: errors.repository,
					isInvalid: Boolean(errors.repository && touched.repository),
					isLoading: isRepositoryLoading,
					options: repositoryOptions,
					showAsPlaceHolder: enableRepoField,
					isEditable: true,
					onChange: (e: any) => {
						if (values.repositoryId === e.value) return;
						const adoRepoPath = `${adoOrganization}/${adoProjectName}/${e.label}`; // Organization/Project/Repository
						setValues((prev: FormikValues) => ({
							...prev,
							[REPOSITORY]: e.label ? adoRepoPath : '',
							[REPOSITORY_ID]: e.value || '',
						}));
					},
					label: enableRepoField ? adoRepo : chooseRepository,
					name: REPOSITORY,
					currentValue: enableRepoField
						? values.repository.split('/')[2]
						: values.repositoryId,
					onFocus: () => setFieldTouched(REPOSITORY, true),
					onEditClick: () => {
						setEditValues((prev) => ({
							...prev,
							editRepository: true,
						}));
					},
					isEditBtnDisabled:
						isSystemStatusLoading || !!tooltipErrorMsg,
					tooltipLabel: tooltipErrorMsg,
				}}
			/>
		</>
	);
};

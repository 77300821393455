import { FC, useMemo, useRef } from 'react';
import {
	Alert,
	ModalMainContent,
	ModalWrapper,
	SelectFormControl,
} from '../../../shared-components';
import { useFormik } from 'formik';
import { app, createDeployment } from '../../../../@xmcloud/core/messages/en';
import {
	useGetProjectEnvironmentsList,
	useGetProjectList,
} from '../../../services/projects';
import { createDeploymentSchema } from '../../../../@xmcloud/yup-validations/createDeployment';

const {
	createTitle,
	selectProject,
	selectEnvironment,
	select,
	noEnvironment,
	noEnvInfo,
} = createDeployment;

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
	onSubmit: (values: any) => void;
	isLoading?: boolean;
}

const PROJECT = 'project';
const ENVIRONMENTID = 'environmentId';

const CreateDeploymentForm: FC<Props> = ({
	openModal,
	setOpenModal,
	onSubmit,
	isLoading = false,
}) => {
	const initialValues = useRef({
		project: '',
		environmentId: '',
	}).current;

	const { data: projects, isLoading: projectIsLoading } = useGetProjectList();

	const projectOptions =
		projects?.data.map((project) => ({
			label: project.name,
			value: project.id,
		})) || [];

	const validationSchema = createDeploymentSchema();

	const {
		errors,
		values,
		handleSubmit: formikHandleSubmit,
		setFieldValue,
		setFieldTouched,
		touched,
	} = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		validateOnBlur: true,
		onSubmit: async (values) => {
			onSubmit(values);
		},
	});

	const projectName = useMemo(
		() => projects?.data?.find((p) => p.id === values.project)?.name || '',
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[values.project],
	);

	const { data: environments, isLoading: environmentIsLoading } =
		useGetProjectEnvironmentsList({
			projectId: values.project,
			enabled: !!values.project,
		});

	const environmentOptions =
		environments?.data.map((environment) => ({
			label: environment.name,
			value: environment.id,
		})) || [];
	const environmentLength = environmentOptions.length;

	return (
		<ModalWrapper
			isOpen={openModal}
			title={createTitle}
			onClose={() => setOpenModal(false)}
		>
			<form
				data-testid="create-deployment-form"
				onSubmit={formikHandleSubmit}
			>
				<ModalMainContent
					onClose={() => setOpenModal(false)}
					isLoading={isLoading}
					rightButtonText={app.deploy}
					isDisabled={
						!Boolean(values.project) || !Boolean(environmentLength)
					}
				>
					<SelectFormControl
						error={errors.project}
						isInvalid={Boolean(errors.project && touched.project)}
						isLoading={projectIsLoading}
						label={selectProject}
						name={PROJECT}
						onChange={(e: any) => {
							setFieldTouched(ENVIRONMENTID, false);
							setFieldValue(ENVIRONMENTID, '');
							setFieldValue(PROJECT, e.value);
						}}
						options={projectOptions}
						currentValue={values.project}
						onFocus={() => setFieldTouched(PROJECT, true)}
						pt={-2}
					/>
					<SelectFormControl
						error={errors.environmentId}
						isInvalid={
							Boolean(values.project) &&
							Boolean(errors.environmentId) &&
							Boolean(touched.environmentId)
						}
						isLoading={environmentIsLoading}
						label={selectEnvironment}
						name={ENVIRONMENTID}
						onChange={(e: any) => {
							setFieldValue(ENVIRONMENTID, e.value);
						}}
						options={environmentOptions}
						currentValue={values.environmentId}
						isDisabled={
							!Boolean(values.project) ||
							!Boolean(environmentLength)
						}
						onFocus={() => setFieldTouched(ENVIRONMENTID, true)}
						placeholder={
							!Boolean(values.project) || environmentIsLoading
								? select
								: !Boolean(environmentLength)
									? noEnvironment
									: select
						}
					/>
					{values.project &&
						!environmentLength &&
						!environmentIsLoading && (
							<Alert
								description={noEnvInfo}
								isVisibleCloseBtn={false}
								status="warning"
								marginTop={4}
								boldText={projectName}
								minWidth="100%"
								boldTextTransform="none"
							/>
						)}
				</ModalMainContent>
			</form>
		</ModalWrapper>
	);
};

export default CreateDeploymentForm;

import { useAuthQuery } from '../apiUtils/queryHelper';
import config from '../config/config';
import { IGetSourceControlAccessToken } from '../models/accessToken';

export const useGetSourceControlAccessToken = (
	integrationId: string,
	enabled = Boolean(integrationId),
) => {
	const { url, scope, queryKey } =
		config.accesToken.get_source_control_access_token;
	return useAuthQuery(
		queryKey(integrationId),
		(axiosInstance) => {
			return axiosInstance?.get<IGetSourceControlAccessToken>(
				url(integrationId),
			);
		},
		{ enabled },
		scope,
	);
};

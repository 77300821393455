import { app } from '../../../@xmcloud/core/messages/en';
import { goTo, mainPaths } from '../../../@xmcloud/core/routes/paths';

export const projectPageItems = [
	{ name: app.projects, to: mainPaths.projects },
];
export const transferDetailsPageItems = [
	{ name: app.contenttransfer, to: mainPaths.contenttransfer },
];
export const environmentItems = (
	projectName: string | undefined,
	projectId: string,
	type: 'eh' | 'cm' | 'combined',
) => {
	if (type === 'eh') {
		return [
			{
				name: app.projects,
				to: mainPaths.projects,
			},
			{
				name: projectName ?? '...',
				to: goTo.ehEnvironments(projectId),
			},
		];
	}

	if (type === 'cm') {
		return [
			{
				name: app.projects,
				to: mainPaths.projects,
			},
			{
				name: projectName ?? '...',
				to: goTo.cmEnvironments(projectId),
			},
		];
	}

	return [
		{
			name: app.projects,
			to: mainPaths.projects,
		},
		{
			name: projectName ?? '...',
			to: goTo.environments(projectId),
		},
	];
};

export const deploymentLogsItems = ({
	projectName,
	projectId,
	environmentId,
	environmentName,
	environmentType,
}: {
	projectName: string | undefined;
	projectId: string;
	environmentId: string;
	environmentName: string | undefined;
	environmentType?: string;
}) => {
	if (environmentType === 'eh') {
		return [
			{
				name: app.projects,
				to: mainPaths.projects,
			},
			{
				name: projectName ?? '...',
				to: goTo.ehEnvironments(projectId),
			},
			{
				name: environmentName ?? '...',
				to: goTo.deployments(projectId, environmentId, environmentType),
			},
		];
	}
	if (environmentType === 'cm') {
		return [
			{
				name: app.projects,
				to: mainPaths.projects,
			},
			{
				name: projectName ?? '...',
				to: goTo.cmEnvironments(projectId),
			},
			{
				name: environmentName ?? '...',
				to: goTo.deployments(projectId, environmentId, environmentType),
			},
		];
	}

	return [
		{
			name: app.projects,
			to: mainPaths.projects,
		},
		{
			name: projectName ?? '...',
			to: goTo.projectDetails(projectId),
		},
		{
			name: environmentName ?? '...',
			to: environmentType
				? goTo.deployments(projectId, environmentId, environmentType)
				: goTo.oldDeployments(projectId, environmentId),
		},
	];
};

function categoryFilter(
	rawCategories: string[],
	environmentType?: 'eh' | 'cm' | 'combined',
) {
	if (!environmentType) return rawCategories;

	if (environmentType === 'cm') {
		return rawCategories.filter((category) => category !== 'RenderingHost');
	}

	if (environmentType === 'eh') {
		return rawCategories.filter((category) => category === 'RenderingHost');
	}

	return rawCategories;
}

export { categoryFilter };

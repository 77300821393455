export const repositoryFields = {
	repository: 'repository',
	repositoryId: 'repositoryId',
	sourceControlIntegrationId: 'sourceControlIntegrationId',
	repositoryRelativePath: 'repositoryRelativePath',
	repositoryBranch: 'repositoryBranch',
	sourceControlIntegrationName: 'sourceControlIntegrationName',
	environmentId: 'environmentId',
	deployOnCommit: 'deployOnCommit',
};

export const requiredEnvFields = {
	environmentName: 'environmentName',
	provider: 'provider',
	tenantType: 'tenantType',
	adoProjectName: 'adoProjectName',
	adoOrganization: 'adoOrganization',
	cmEnvironmentId: 'cmEnvironmentId',
	...repositoryFields,
};

export const handleSplitAdoRepoPath = (repository: string) => {
	const [adoOrganization, adoProjectName, adoRepository] =
		repository.split('/');
	return { adoOrganization, adoProjectName, adoRepository };
};

import { useMemo, useRef } from 'react';
import { ESourceControl } from '../../../../@xmcloud/types';
import { useGetGitHubPaginatedBranches } from '../../../services/githubApi';
import { OLDSelectBranches } from './OLDSelectBranches';
import { useCreateProject } from '../hooks';

export const OLDSelectGithubBranches: React.FC<{ token: string }> = ({
	token,
}) => {
	const inputQuery = useRef('');
	const { values } = useCreateProject();
	const owner = values.sourceControlIntegrationName;
	const repo = values.repository;
	const isGithubProvider = values.provider === ESourceControl.GitHub;

	const { data, isLoading, hasMore, fetchNextPage, isFetching } =
		useGetGitHubPaginatedBranches({
			token,
			owner,
			repo,
			onSuccess,
		});

	function onSuccess(lastPage: any) {
		const query = inputQuery.current;

		if (query === '' || !hasMore || !isGithubProvider || !lastPage.length)
			return;

		const hasRepo = lastPage.some((r: any) => r.name.includes(query));

		if (!hasRepo) {
			fetchNextPage();
		}
	}

	const branches = useMemo(() => {
		let branches =
			data?.map((r: any) => ({
				label: r.name,
				value: r.name,
			})) || [];
		branches.push({ label: 'none', value: '' });
		return branches;
	}, [data]);

	return (
		<OLDSelectBranches
			{...{
				branches,
				isLoading,
				hasMore,
				fetchNextPage,
				isFetching,
				inputQuery,
			}}
		/>
	);
};

import * as React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export const LayoutFooter: React.FC<BoxProps> = ({ children, ...rest }) => {
	return (
		<Box
			as="footer"
			backgroundColor="chakra-body-bg"
			flexShrink={0}
			height={8}
			overflow="hidden"
			shadow="xs"
			zIndex={3}
			{...rest}
		>
			{children}
		</Box>
	);
};

/**

 * Sitecore UI LayoutFooter components display names

 */

LayoutFooter.displayName = 'LayoutFooter';

import { mdiAlertCircle, mdiCheckCircle, mdiCircleOutline } from '@mdi/js';
import { deploymentLogs } from '../../../../@xmcloud/core/messages/en';
import { durationInHHmmSS } from '../../../../@xmcloud/utils/dateUtils';
import { IGetDeploymentResponse } from '../../../models/deploymentModel';
import { ILogMessage } from '../../../models/deployLogger';

const {
	build,
	deployment,
	provisioning,
	postActions,
	deploymentFailed,
	deploymentProgress,
	deploymentNotStarted,
	deploymentCompleted,
} = deploymentLogs;

export const processGroups = (d: IGetDeploymentResponse | undefined) => [
	{
		stage: provisioning,
		status: d ? handleStatus(d.provisioningStatus) : '',
		timeElapsed: handleElapsedTime(
			d?.provisioningCompletedAt,
			d?.provisioningStartedAt,
		),
		key: 0,
	},
	{
		stage: build,
		status: d ? handleStatus(d.buildStatus) : '',
		timeElapsed: handleElapsedTime(d?.buildCompletedAt, d?.buildStartedAt),
		key: 1,
	},
	{
		stage: deployment,
		status: d ? handleStatus(d.deploymentStatus) : '',
		timeElapsed: handleElapsedTime(
			d?.deploymentCompletedAt,
			d?.deploymentStartedAt,
		),
		key: 2,
	},
	{
		stage: postActions,
		status: d ? handleStatus(d.postActionStatus) : '',
		timeElapsed: handleElapsedTime(
			d?.postActionCompletedAt,
			d?.postActionStartedAt,
		),
		key: 3,
	},
];

const handleStatus = (s: number) => Object.values(status)[s];
// NOTE: NotStarted = 0, InProgress = 1, Complete = 2, Failed = 3, Skipped = 4
export const status = {
	NotStarted: 'Pending',
	InProgress: 'In progress',
	Complete: 'Complete',
	Failed: 'Failed',
	Skipped: 'Skipped',
};

export const allLogStatuses = [
	'provisioningStatus',
	'buildStatus',
	'deploymentStatus',
	'postActionStatus',
];

export const statusIconList = [
	{ IconName: mdiCircleOutline, iconColor: 'warning' },
	{ isIndeterminate: true },
	{ IconName: mdiCheckCircle, iconColor: 'success' },
	{ IconName: mdiAlertCircle, iconColor: 'danger' },
];

export const handleElapsedTime = (
	completedAt: string | null | undefined,
	startedAt: string | null | undefined,
) => {
	if (!completedAt || !startedAt) return '';
	const duration = Date.parse(completedAt) - Date.parse(startedAt);
	return durationInHHmmSS(duration);
};

export const calculatedAlertStatus = {
	0: 'info',
	1: 'loading',
	2: 'success',
	3: 'error',
	4: 'warning',
};

export const alertStatusTitle = {
	0: deploymentNotStarted,
	1: deploymentProgress,
	2: deploymentCompleted,
	3: deploymentFailed,
	4: '',
};

export type TCalculatedStatus = 0 | 1 | 2 | 3 | 4;
export enum ECalculatedStatus {
	NOT_STARTED,
	IN_PROGRESS,
	COMPLETE,
	FALIED,
	SKIPPED,
}

export type TStages = 'Provisioning' | 'PostActions' | 'Deployment' | 'Build';
export const Stages = [
	'Provisioning',
	'Build',
	'Deployment',
	'PostActions',
] as const;

export type TLogMessages = {
	[key in TStages]: ILogMessage[];
};

export const logMessageTypes = {
	info: 'info',
	error: 'error',
	warn: 'warn',
} as const;

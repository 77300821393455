import { useEditProject } from '../../../@xmcloud/hooks';
import { systemHealthStatus } from '../../../@xmcloud/core/messages/en';

const { errorMsg7, errorMsg8, unableFetchAdoRepos, unableFetchGithubRepos } =
	systemHealthStatus;

export const useGetRepositoriesValidation = () => {
	const {
		values,
		isValidIntegration,
		integrationIdValidationMsg,
		isComponentHealthLoading,
		isAdoProvider,
		isGithubProvider,
		isADOHealthy,
		isGithubHealthy,
		editValues,
	} = useEditProject();

	const { sourceControlIntegrationId } = values;
	const { editRepository } = editValues;

	const errorIndex =
		!isGithubHealthy && isGithubProvider
			? 0
			: !isADOHealthy && isAdoProvider
				? 1
				: !isValidIntegration &&
					  isAdoProvider &&
					  sourceControlIntegrationId
					? 2
					: !isValidIntegration &&
						  isGithubProvider &&
						  sourceControlIntegrationId
						? 3
						: -1;

	const errorMessageList = [
		errorMsg7,
		errorMsg8,
		`${integrationIdValidationMsg}${unableFetchAdoRepos}`,
		`${integrationIdValidationMsg}${unableFetchGithubRepos}`,
	];

	const tooltipErrorMsgList = [
		errorMsg7,
		errorMsg8,
		unableFetchAdoRepos,
		unableFetchGithubRepos,
	];

	const getTooltipErrorMessage = (errorIndex: number): string => {
		return errorIndex !== -1 ? tooltipErrorMsgList[errorIndex] : '';
	};

	const getErrorMessage = (errorIndex: number): string => {
		return errorIndex !== -1 ? errorMessageList[errorIndex] : '';
	};

	const errorMsg = getErrorMessage(errorIndex);
	const tooltipErrorMsg = getTooltipErrorMessage(errorIndex);

	const isLoading = isComponentHealthLoading;
	return { isLoading, errorMsg, tooltipErrorMsg, editRepository };
};

import {
	FormControl,
	FormLabel,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Stack,
	Tooltip,
} from '@chakra-ui/react';
import { t } from '@transifex/native';
import { Icon } from '..';
import { mdiContentCopy } from '@mdi/js';
import { Alert } from '..';

interface Props {
	title: string;
	description: string;
	helperText?: string;
	helperTextColor?:
		| 'warning'
		| 'info'
		| 'success'
		| 'error'
		| 'loading'
		| undefined;
}

export const CopyTextControl: React.FC<Props> = ({
	title,
	description,
	helperText = '',
	helperTextColor = 'warning',
}) => (
	<Stack>
		<FormControl>
			<FormLabel>{t(title)}</FormLabel>
			<InputGroup>
				<Input isReadOnly value={description} />
				<InputRightElement>
					<Tooltip label="Copy to clipboard">
						<IconButton
							onClick={() => {
								navigator.clipboard.writeText(description);
							}}
							variant="ghost"
							icon={<Icon path={mdiContentCopy} />}
							size="sm"
							aria-label={'Copy to clipboard'}
						/>
					</Tooltip>
				</InputRightElement>
			</InputGroup>
		</FormControl>

		{Boolean(helperText) && (
			<Alert description={helperText} status={helperTextColor} mt="3" />
		)}
	</Stack>
);

import { Stack } from '@chakra-ui/react';
import { secondStep } from '../../../../@xmcloud/core/messages/en';
import { Alert, SelectFormControl } from '../../../shared-components';
import { REPOSITORY, REPOSITORY_ID, REPO_BRANCH, TOptions } from '../helpers';
import { ScaleFade } from '@chakra-ui/react';
import { ESourceControl } from '../../../../@xmcloud/types';
import { FormikValues, useCreateProject } from '../hooks/CreateProjectContext';
import { useCallback, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { OLDSelectADOBranches } from './OLDSelectADOBranches';
import { FormFieldLoading } from '../components';
import { OLDSelectGithubBranches } from './OLDSelectGithubBranches';

export const OLDSelectRepositories: React.FC<{
	isLoading: boolean;
	repositoryOptions: TOptions[] | any;
	accessToken: string;
	adoOrganization?: string;
	adoProjectName?: string;
	isFetching?: boolean;
	fetchNextPage?: any;
	hasNextPage?: boolean;
	inputQuery?: React.MutableRefObject<string>;
}> = ({
	isLoading,
	repositoryOptions,
	accessToken,
	adoOrganization = '',
	adoProjectName = '',
	isFetching,
	fetchNextPage,
	hasNextPage,
	inputQuery,
}) => {
	const {
		setValues,
		setFieldTouched,
		values: { repository, provider },
		errors,
		touched,
	} = useCreateProject();

	const showSelectBranches = Boolean(accessToken) && Boolean(repository);

	const isGithubProvider = provider === ESourceControl.GitHub;
	const isADOProvider = provider === ESourceControl.ADO;

	const handleFetchNextPage = useCallback(() => {
		if (hasNextPage && isGithubProvider && !isFetching) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage, isFetching, isGithubProvider]);

	const onInputChange = useCallback(
		(e: any) => {
			if (inputQuery) {
				inputQuery.current = e;
			}

			if (
				e === '' ||
				!hasNextPage ||
				!isGithubProvider ||
				!repositoryOptions?.length ||
				isFetching
			)
				return;

			const hasRepo = repositoryOptions.some((r: any) =>
				r.label.includes(e),
			);

			if (!hasRepo) {
				handleFetchNextPage();
			}
		},
		[
			handleFetchNextPage,
			hasNextPage,
			inputQuery,
			isFetching,
			isGithubProvider,
			repositoryOptions,
		],
	);

	const debouncedChangeHandler = useMemo(
		() => debounce(onInputChange, 300),
		[onInputChange],
	);

	useEffect(() => {
		return () => {
			debouncedChangeHandler.cancel();
		};
	}, [debouncedChangeHandler]);

	if (isLoading) return <FormFieldLoading />;

	if (repositoryOptions.length === 0 && !isLoading && !isFetching)
		return (
			<Stack>
				<Alert
					description={secondStep.errorMsg}
					isVisibleCloseBtn={false}
					boldText={isGithubProvider ? 'GitHub' : 'Azure DevOps'}
					status="error"
					mt="4"
					boldTextTransform="none"
					maxW="630"
				/>
			</Stack>
		);

	return (
		<>
			<SelectFormControl
				{...{
					error: errors.repository,
					isInvalid: Boolean(errors.repository && touched.repository),
					isLoading: isLoading || isFetching,
					options: repositoryOptions,
					onChange: (e: any) => {
						setValues((prev: FormikValues) => ({
							...prev,
							[REPOSITORY]: e.label || '',
							[REPOSITORY_ID]: e.id || '',
							[REPO_BRANCH]: '',
						}));
					},
					label: secondStep.chooseRepository,
					name: REPOSITORY,
					currentValue: repository,
					maxW: 'xs',
					onFocus: () => setFieldTouched(REPOSITORY, true),
					onInputChange: debouncedChangeHandler,
					onMenuScrollToBottom: () => {
						handleFetchNextPage();
					},
				}}
			/>
			{showSelectBranches && (
				<ScaleFade initialScale={0.9} in={showSelectBranches}>
					{isGithubProvider && (
						<OLDSelectGithubBranches token={accessToken} />
					)}
					{isADOProvider && (
						<OLDSelectADOBranches
							adoOrganization={adoOrganization}
							adoProjectName={adoProjectName}
							token={accessToken}
						/>
					)}
				</ScaleFade>
			)}
		</>
	);
};

import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { LayoutMainProps } from './model';

export const LayoutMain: React.FC<LayoutMainProps> = ({
	children,
	disablePadding,
	background,
	inset,
	p,
	...rest
}) => {
	return (
		<Box
			as="main"
			order={2}
			p={disablePadding ? 0 : p}
			background={inset ? 'chakra-subtle-bg' : background}
			shadow={inset ? 'inner' : 'xs'}
			flexGrow={1}
			flexShrink={1}
			overflow="auto"
			{...rest}
		>
			{children}
		</Box>
	);
};

/**

 * Sitecore UI LayoutMain components display names

 */

LayoutMain.displayName = 'LayoutMain';

import {
	ILogMessage,
	ILogMessageListItem,
	LogMessageTypes,
	ProcessKeys,
} from '../app/models/deployLogger';

export const LogMessageMock: ILogMessage = {
	logLevel: LogMessageTypes.Info,
	logMessage: 'Running trail cleanup on database',
	stage: ProcessKeys.Provisioning,
	logTime: '2021-08-11T16:17:01.542Z',
};

export const LogMessagesMock: ILogMessage[] = [
	{
		logLevel: LogMessageTypes.Info,
		logMessage:
			'Job started: Sitecore.ListManagement.Operations.UpdateListOperationsAgent',
		stage: ProcessKeys.PostActions,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Running trail cleanup on database',
		stage: ProcessKeys.Provisioning,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: "'core[data]' (max size: 100MB, running total: 808MB)",
		stage: ProcessKeys.Build,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage:
			"LanguageProvider - Languages' (max size: 1MB, running total: 1MB)",
		stage: ProcessKeys.PostActions,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Starting: Today (asynchronously)',
		stage: ProcessKeys.Deployment,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: '0 SMTP connections disposed.',
		stage: ProcessKeys.Provisioning,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage:
			'Connection Timeout Expired.  The timeout period elapsed while attempting to consume the pre-login handshake acknowledgement.',
		stage: ProcessKeys.Deployment,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Map EnsureAllDeployed started.',
		stage: ProcessKeys.Provisioning,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Scheduling.DatabaseAgent started. Database: master',
		stage: ProcessKeys.PostActions,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'One or more errors occurred.',
		stage: ProcessKeys.PostActions,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Writing runtime config to C:/dev/config.js',
		stage: ProcessKeys.Build,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Starting: Today (asynchronously)',
		stage: ProcessKeys.Build,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Starting: Today (asynchronously)',
		stage: ProcessKeys.Deployment,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Examining schedules (count:5)',
		stage: ProcessKeys.Deployment,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'blah blah blah....',
		stage: ProcessKeys.Provisioning,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Error,
		logMessage:
			'Job started: Sitecore.ListManagement.Operations.UpdateListOperationsAgent',
		stage: ProcessKeys.Deployment,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Running trail cleanup on database',
		stage: ProcessKeys.Build,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Error,
		logMessage: 'Running trail cleanup on database',
		stage: ProcessKeys.Deployment,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Running trail cleanup on database',
		stage: ProcessKeys.PostActions,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage:
			'*****************************************************************',
		stage: ProcessKeys.Build,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Error,
		logMessage: '                                                 ',
		stage: ProcessKeys.Deployment,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Starting: Today (asynchronously)',
		stage: ProcessKeys.Provisioning,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage:
			'Connection Timeout Expired.  The timeout period elapsed while attempting to consume the pre-login handshake acknowledgement.',
		stage: ProcessKeys.Build,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Running trail cleanup agent',
		stage: ProcessKeys.Provisioning,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: '                                                 ',
		stage: ProcessKeys.Build,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Job started: Sitecore.Tasks.DatabaseAgent',
		stage: ProcessKeys.Provisioning,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'HttpModule is being initialized',
		stage: ProcessKeys.Build,
		logTime: '2021-08-11T16:17:01.542Z',
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'One or more errors occurred.',
		stage: ProcessKeys.Build,
		logTime: '2021-08-11T16:17:01.542Z',
	},
];

export const LogMessagesListItemsMock: ILogMessageListItem[] = [
	{
		logLevel: LogMessageTypes.Info,
		logMessage:
			'Job started: Sitecore.ListManagement.Operations.UpdateListOperationsAgent',
		stage: ProcessKeys.PostActions,
		logTime: '2021-08-11T16:17:01.542Z',
		id: 0,
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Running trail cleanup on database',
		stage: ProcessKeys.Provisioning,
		logTime: '2021-08-11T16:17:01.542Z',
		id: 1,
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: "'core[data]' (max size: 100MB, running total: 808MB)",
		stage: ProcessKeys.Build,
		logTime: '2021-08-11T16:17:01.542Z',
		id: 2,
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage:
			"LanguageProvider - Languages' (max size: 1MB, running total: 1MB)",
		stage: ProcessKeys.PostActions,
		logTime: '2021-08-11T16:17:01.542Z',
		id: 3,
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: 'Starting: Today (asynchronously)',
		stage: ProcessKeys.Deployment,
		logTime: '2021-08-11T16:17:01.542Z',
		id: 4,
	},
	{
		logLevel: LogMessageTypes.Info,
		logMessage: '0 SMTP connections disposed.',
		stage: ProcessKeys.Provisioning,
		logTime: '2021-08-11T16:17:01.542Z',
		id: 5,
	},
];

import {
	mdiContentCopy,
	mdiEyeOffOutline,
	mdiEyeOutline,
	mdiOpenInNew,
} from '@mdi/js';
import { Box, HStack, IconButton, Link, Text } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { FC, useState } from 'react';
import { environment } from '../../../../@xmcloud/core/messages/en';
import { Icon, TextBox, XMCSButton } from '../../../shared-components';

const { launchIDE } = environment;

export const ToggledTokenButton: FC<{
	title: string;
	tooltip: string;
	isLoading: boolean;
	btnText: string;
	onClick: () => any;
	href: string;
	token: string;
}> = ({ title, tooltip, isLoading, btnText, onClick, href, token }) => {
	const [showToken, setShowToken] = useState(false);

	return (
		<TextBox title={title} subTitle="" tooltip={tooltip}>
			<Box display="flex" alignItems="center" gap={2}>
				{!Boolean(token) ? (
					<XMCSButton
						size="xs"
						text={btnText}
						mt="1"
						isLoading={isLoading}
						onClick={onClick}
					/>
				) : (
					<HStack>
						<Text fontSize="sm">
							{showToken ? token : '*'.repeat(32)}
						</Text>
						<IconButton
							variant="toggle"
							size="xs"
							aria-label={t(
								showToken ? 'Hide API key' : 'Show API key',
							)}
							icon={
								<Icon
									path={
										showToken
											? mdiEyeOffOutline
											: mdiEyeOutline
									}
								/>
							}
							onClick={() => setShowToken((prev) => !prev)}
						/>
						<IconButton
							variant="toggle"
							size="xs"
							aria-label={t('Copy API key')}
							icon={<Icon path={mdiContentCopy} />}
							onClick={() => {
								if (token) navigator.clipboard.writeText(token);
							}}
						/>
					</HStack>
				)}
				{Boolean(href) && (
					<Link
						ml={2}
						isExternal
						href={href}
						boxShadow="none !important"
						display="flex"
						alignItems="center"
						fontWeight="semibold"
					>
						{t(launchIDE)}
						<Icon path={mdiOpenInNew} ml={1} />
					</Link>
				)}
			</Box>
		</TextBox>
	);
};

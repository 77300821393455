import { VStack } from '@chakra-ui/react';
import {
	ReactNode,
	createContext,
	useState,
	useContext,
	useCallback,
	useMemo,
	useEffect,
	useRef,
} from 'react';
import { type EnvVariable } from './envVarsModel';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash/isEqual';

type EnvVarsContextType = {
	variables: EnvVariable[];
	setVariables: (variables: EnvVariable[]) => void;
	isFormVisible: boolean;
	isLoading: boolean;
	setLoading: (loading: boolean) => void;
	toggleFormVisibility: () => void;
	selectedVariable: EnvVariable | null;
	setSelectedVariable: (variable: EnvVariable | null) => void;
	isExistingProject?: boolean;
};

const EnvVarsContext = createContext<EnvVarsContextType | undefined>(undefined);

type EnvVarsProviderProps = {
	children: ReactNode;
	isExistingProject: boolean;
	initialVariables?: EnvVariable[];
	onVariablesChange?: (variables: EnvVariable[]) => void;
};

/**
 * Provider for managing environment variables state
 * @param children
 * @param isExistingProject, whether the project is existing or not, to determine if the variables are editable, need to be explicitly set for any case
 * @param initialVariables, initial variables to be set on mount
 * @param onVariablesChange, callback to be called when variables change
 */
function EnvVars({
	children,
	isExistingProject,
	initialVariables,
	onVariablesChange,
}: EnvVarsProviderProps) {
	const initialMemoized = useMemo(
		() =>
			initialVariables?.map((variable) => ({
				...variable,
				id: variable.id || uuidv4(),
			})) ?? [],
		[initialVariables],
	);

	const refVariables = useRef<EnvVariable[]>(initialMemoized);

	const [variables, setVariables] = useState<EnvVariable[]>(
		refVariables.current || [],
	);
	const [isFormVisible, setFormVisible] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [selectedVariable, setSelectedVariable] =
		useState<EnvVariable | null>(null);

	const toggleFormVisibility = useCallback(() => {
		setFormVisible((prev) => !prev);
	}, []);

	useEffect(() => {
		if (initialMemoized) {
			setVariables(initialMemoized);
		}
	}, [initialMemoized]);

	// Used for handling single variable (add or update)
	const setSingleVariable = useCallback(
		(newVariable: EnvVariable) => {
			setVariables((prev) => {
				const existingVariable = prev.find(
					(v) => v.id === newVariable.id,
				);

				// If var exists, update it, otherwise, add it
				const updatedVariables = existingVariable
					? prev.map((v) =>
							v.id === newVariable.id ? newVariable : v,
						)
					: [...prev, newVariable];

				// Trigger onVariablesChange with the updated list
				if (onVariablesChange) {
					onVariablesChange(updatedVariables);
				}

				return updatedVariables;
			});
		},
		[onVariablesChange],
	);

	useEffect(() => {
		if (!isEqual(variables, refVariables.current)) {
			refVariables.current = variables;
			if (onVariablesChange) {
				onVariablesChange(variables);
			}
		}
	}, [variables, onVariablesChange]);

	const value = useMemo(
		() => ({
			variables,
			setVariables,
			isFormVisible,
			isLoading,
			toggleFormVisibility,
			selectedVariable,
			setSelectedVariable,
			setSingleVariable,
			setLoading,
			isExistingProject,
		}),
		[
			variables,
			isFormVisible,
			isLoading,
			toggleFormVisibility,
			selectedVariable,
			isExistingProject,
			setSingleVariable,
		],
	);

	return (
		<EnvVarsContext.Provider value={value}>
			<VStack spacing={6} align="flex-start" data-testid="EvVars">
				{children}
			</VStack>
		</EnvVarsContext.Provider>
	);
}

function useEnvVars() {
	const context = useContext(EnvVarsContext);

	if (context === undefined) {
		throw new Error('useEnvVars must be used within EnvVars Provider');
	}

	const { variables, setVariables, isFormVisible, toggleFormVisibility } =
		context;

	const addVariable = useCallback(
		(variable: EnvVariable) =>
			setVariables([...variables, { ...variable, id: uuidv4() }]),

		[variables, setVariables],
	);

	const updateVariable = useCallback(
		(updatedVariable: EnvVariable) => {
			setVariables(
				variables.map((v) =>
					v.id === updatedVariable.id ? updatedVariable : v,
				),
			);
		},
		[variables, setVariables],
	);

	const deleteVariable = useCallback(
		(variableId: string) => {
			setVariables(
				variables.filter((v: EnvVariable) => v.id !== variableId),
			);
		},
		[variables, setVariables],
	);

	return {
		...context,
		variables,
		addVariable,
		updateVariable,
		deleteVariable,
		isFormVisible,
		toggleFormVisibility,
	};
}

function HasEnvVars({ children }: { children: ReactNode }): JSX.Element | null {
	const { variables } = useEnvVars();

	return variables.length ? <>{children}</> : null;
}

export { EnvVarsContext, EnvVars, useEnvVars, HasEnvVars };

import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { TreeView } from './TreeView';
import { useTree } from './useTreeContext';
import {
	ITransferConfigurationNode,
	TransferConfigurationNodeType,
} from '../../../../models/transferConfiguration';

export const TreeComponent = forwardRef((props: TreeComponentProps, ref) => {
	const {
		selectionKeys,
		setSelectionKeys,
		setExpandedKeys,
		nodesToSelectionKeys,
		getExpandedKeys,
		setNodes,
	} = useTree();

	const initSelectionKeys = useMemo<TreeSelectionKeys>(
		() => nodesToSelectionKeys(props.value),
		[nodesToSelectionKeys, props.value],
	);

	const initialExpandedKeys = useMemo<TreeExpandedKeys>(
		() => getExpandedKeys(props.value),
		[getExpandedKeys, props.value],
	);

	useImperativeHandle(ref, () => {
		return {
			onSaveHandler: () => selectionKeys,
		};
	}, [selectionKeys]);

	useEffect(() => {
		setExpandedKeys(initialExpandedKeys);
	}, [initialExpandedKeys, setExpandedKeys]);

	useEffect(() => {
		const data = props.initialValues;
		if (data) {
			const usedKeys = data.nodes.reduce(
				(acc: TreeSelectionKeys, node: ITransferConfigurationNode) => {
					acc[node.id] = {
						...initSelectionKeys[node.id],
						checked: true,
						isBranch:
							node.type ===
							(TransferConfigurationNodeType.Branch as number),
						mergeStrategy: node.mergeStrategy,
					};
					return acc;
				},
				{},
			);

			setSelectionKeys({
				...initSelectionKeys,
				...usedKeys,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setNodes(props.value);
	}, [props, setNodes]);

	useEffect(() => {
		const anySelected = Object.keys(selectionKeys).some(
			(key) => selectionKeys[key]?.checked,
		);
		props.setKeysSelected(anySelected);
	}, [selectionKeys, props]);

	return (
		<TreeView
			value={props.value}
			isReadOnly={props.isReadOnly}
			isDisabled={props.isDisabled}
		></TreeView>
	);
});

import {
	IconButton,
	Menu,
	MenuButton,
	MenuButtonProps,
} from '@chakra-ui/react';
import { Icon } from '../icon-text/Icon';
import { mdiDotsHorizontal } from '@mdi/js';

type Props = {
	children: React.ReactNode;
	ariaLabel: string;
	isLoading?: boolean;
	isDisabled?: boolean;
} & MenuButtonProps;

export const RowActions: React.FC<Props> = ({
	children,
	ariaLabel = 'menu',
	isLoading,
	...rest
}) => {
	return (
		<Menu>
			<MenuButton
				size="sm"
				as={IconButton}
				aria-label={ariaLabel}
				icon={<Icon path={mdiDotsHorizontal} />}
				variant="ghost"
				isLoading={isLoading}
				{...rest}
			/>
			{children}
		</Menu>
	);
};

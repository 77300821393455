import { ITenantClientDetails } from '../app/models/tenantsModel';

export const TenantClientMock: ITenantClientDetails = {
	id: 'mock-client-id-1',
	name: 'Mock client 1',
};

export const TenantClientsMock: ITenantClientDetails[] = [
	{
		id: 'mock-client-id-1',
		name: 'Mock client 1',
	},
	{
		id: 'mock-client-id-2',
		name: 'Mock client 2',
	},
	{
		id: 'mock-client-id-3',
		name: 'Mock client 3',
	},
];

import { string, object } from 'yup';
import {
	firstStep,
	projects,
	secondStep,
	connections,
	editProject,
} from '../core/messages/en';
import '../utils/yupUtils';

const { requiredName } = projects;
const { maxCharacters } = firstStep;
const { repoRequired, orgRequired, projectNameRequired } = secondStep;
const { requiredConnection } = connections;
const { requiredProvider, forbiddenChars, cannotEndWith } = editProject;

export const editProjectValidationSchema = ({
	isADOProvider = false,
}: {
	isADOProvider?: boolean;
}) => {
	return object({
		name: string()
			.required(requiredName)
			.max(100, maxCharacters)
			.testForbidChars(forbiddenChars)
			.endsWithForbidChars(cannotEndWith),
		providerName: string().required(requiredProvider),
		sourceControlIntegrationId: string().required(requiredConnection),
		repository: string().required(repoRequired),
		adoOrganization: isADOProvider
			? string().required(orgRequired)
			: string().notRequired(),
		adoProjectName: isADOProvider
			? string().required(projectNameRequired)
			: string().notRequired(),
	});
};

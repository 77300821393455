import { IOrganizationIdentityModal } from '../app/services/organizations';

export const OrganizationMock: IOrganizationIdentityModal = {
	accountId: '1',
	createdBy: '1',
	defaultDeploymentRegion: '1',
	defaultLanguage: '1',
	displayName: 'SuperDisplayName',
	id: '1',
	mfa: { required: false },
	name: '1',
	phoneNumber: '1',
	region: '1',
	type: 'internal',
};

import { FC, useRef, useState } from 'react';
import { app, allDeployments } from '../../../@xmcloud/core/messages/en';
import CreateDeployment from './components/CreateDeployment';
import {
	Alert,
	Footer,
	HeaderStack,
	TableRef,
	ToggleStack,
	XMCSButton,
} from '../../shared-components';
import DeploymentsTable from './components/DeploymentsTable';
import { useGetDeploymentStatusCount } from '../../services/deployments';
import { Tab, TabList } from '@chakra-ui/react';
import { useSharedState } from '../../../@xmcloud/hooks';
import { IS_SM_SCREEN } from '../../../@xmcloud/hooks/context/useSharedState';
import { DeploymentFilter } from '../../../@xmcloud/types';

const {
	create,
	recentDeployments,
	myDeployments,
	notDeployed,
	alertText,
	alertText3,
	alertText4,
} = allDeployments;

export const DeploymentsPage: FC = () => {
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const [filter, setFilter] = useState<DeploymentFilter>(
		DeploymentFilter.all,
	);
	const tableRef: TableRef = useRef(null);
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);

	return (
		<>
			{!isSmScreen && (
				<HeaderStack title={app.deployments} mt={0}>
					<XMCSButton
						text={create}
						data-testid="create-deployment-button"
						onClick={() => setOpenCreateModal(true)}
					/>
				</HeaderStack>
			)}
			<HeaderAlerInfo />
			<ToggleStack mb={4}>
				<TabList>
					<Tab
						onClick={() => {
							setFilter(DeploymentFilter.all);
							tableRef.current?.onSetPageIndex(0);
						}}
						data-testid="recent-deployment-button"
					>
						{recentDeployments}
					</Tab>
					<Tab
						onClick={() => {
							setFilter(DeploymentFilter.user);
							tableRef.current?.onSetPageIndex(0);
						}}
						data-testid="my-deployment-button"
					>
						{myDeployments}
					</Tab>
					<Tab
						onClick={() => {
							setFilter(DeploymentFilter.skippedQueued);
							tableRef.current?.onSetPageIndex(0);
						}}
						data-testid="not-deployed-button"
					>
						{notDeployed}
					</Tab>
				</TabList>
			</ToggleStack>
			<DeploymentsTable {...{ filter, tableRef }} />
			{Boolean(isSmScreen) && (
				<Footer>
					<XMCSButton
						text={create}
						data-testid="create-deployment-button"
						onClick={() => setOpenCreateModal(true)}
					/>
				</Footer>
			)}
			{openCreateModal && (
				<CreateDeployment
					openModal={openCreateModal}
					setOpenModal={setOpenCreateModal}
				/>
			)}
		</>
	);
};

const HeaderAlerInfo: FC = () => {
	const { data, isLoading } = useGetDeploymentStatusCount();
	const statuses = data?.data;
	const inProgressCount = statuses?.inProgress ?? 0;
	const inQueuedCount = statuses?.notStarted ?? 0;
	const totalCount = inProgressCount + inQueuedCount;
	const hasInprogressOrQueuedCount = totalCount > 0;
	const isProgressCountPlural = inProgressCount > 1;
	const isOnlyInProgress = inProgressCount > 0 && inQueuedCount === 0;
	const isOnlyQueued = inProgressCount === 0 && inQueuedCount > 0;
	const isQueuedPlural = inQueuedCount > 1;

	const description = isLoading
		? ''
		: isOnlyInProgress
			? alertText3(isProgressCountPlural)
			: isOnlyQueued
				? alertText4(isQueuedPlural)
				: alertText;

	if (!isLoading && !hasInprogressOrQueuedCount) return null;

	return (
		<Alert
			{...(inProgressCount > 0 && {
				boldText: `${inProgressCount} deployment${
					isProgressCountPlural ? 's' : ''
				} in progress`,
			})}
			{...(inQueuedCount > 0 && {
				boldText2: `${inQueuedCount} deployment${
					isQueuedPlural ? 's' : ''
				} queued`,
			})}
			description={description}
			boldTextTransform="lowercase"
			mb={4}
			status={isLoading ? 'loading' : 'info'}
		/>
	);
};

import { mdiAlertCircle, mdiCheckCircle } from '@mdi/js';
import { useGetStatus } from '../../../app/services/status';
import { Icon } from '../../../app/shared-components';

const statusIconStyle = {
	position: 'absolute' as 'absolute',
	right: '2',
	top: 0,
	bottom: 0,
	my: 'auto',
	zIndex: 2,
};

const StatusIcons: React.FC = () => {
	const { data } = useGetStatus();
	const status = data?.data;
	const isNotHealthy =
		status && status?.status && status?.status !== 'Healthy';
	return (
		<Icon
			path={isNotHealthy ? mdiAlertCircle : mdiCheckCircle}
			color={isNotHealthy ? 'danger' : 'success'}
			sx={statusIconStyle}
		/>
	);
};
export default StatusIcons;

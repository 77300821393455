import { Stack } from '@chakra-ui/react';
import { GenericErrorTemplate, XMCSButton } from '../../shared-components';
import { useAuth0 } from '@auth0/auth0-react';
import { resetLastSuccessfulLoginOrganizationId } from '../../auth/Auth0Storage';
import { app } from '../../../@xmcloud/core/messages/en';

export const Error401Page: React.FC = () => {
	const { logout } = useAuth0();

	return (
		<Stack>
			<GenericErrorTemplate
				title="Unauthorized"
				subtext="Error 401"
				text="You are not unauthorized to view this page."
				variant="unauthorized"
			/>

			<XMCSButton
				variant="link"
				text={app.loginAgain}
				onClick={() => {
					resetLastSuccessfulLoginOrganizationId();
					logout({
						returnTo: window.location.origin,
					});
				}}
			/>
		</Stack>
	);
};

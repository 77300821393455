const domain: string =
	(window as any)?.env?.REACT_APP_AUTH0_DOMAIN ||
	process.env.REACT_APP_AUTH0_DOMAIN;
const clientId: string =
	(window as any)?.env?.REACT_APP_AUTH0_CLIENT_ID ||
	process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience: string =
	(window as any)?.env?.REACT_APP_AUTH0_AUDIENCE ||
	process.env.REACT_APP_AUTH0_AUDIENCE;
export const apiBaseUrl: string =
	(window as any)?.env?.REACT_APP_API_BASE_URL ||
	process.env.REACT_APP_API_BASE_URL;
export const monitoringBaseUrl: string =
	(window as any)?.env?.REACT_APP_MONITORING_BASE_URL ||
	process.env.REACT_APP_MONITORING_BASE_URL;
export const githubIntegrationBaseUrl: string =
	(window as any)?.env?.REACT_APP_GITHUB_INTEGRATION_URL ||
	process.env.REACT_APP_GITHUB_INTEGRATION_URL;
export const vercelInstallationBaseUrl: string =
	(window as any)?.env?.REACT_APP_VERCEL_INSTALLATION_URL ||
	process.env.REACT_APP_VERCEL_INSTALLATION_URL;
export const identityBaseUrl: string =
	(window as any)?.env?.REACT_APP_IDENTITY_BASE_URL ||
	process.env.REACT_APP_IDENTITY_BASE_URL;
export const portalBaseUrl: string =
	(window as any)?.env?.REACT_APP_PORTAL_BASE_URL ||
	process.env.REACT_APP_PORTAL_BASE_URL;
export const targetedEnvironmentName: string =
	(window as any)?.env?.REACT_APP_TARGETED_ENVIRONMENT_NAME ||
	process.env.REACT_APP_TARGETED_ENVIRONMENT_NAME;
export const xmAppsUrl: string =
	(window as any)?.env?.REACT_APP_XMAPPS_URL ||
	process.env.REACT_APP_XMAPPS_URL;
export const pagesUrl: string =
	(window as any)?.env?.REACT_APP_PAGES_URL ||
	process.env.REACT_APP_PAGES_URL;
export const featureFlagsBaseUrl: string =
	(window as any)?.env?.REACT_APP_FEATURE_FLAGS_BASE_URL ||
	process.env.REACT_APP_FEATURE_FLAGS_BASE_URL;

export const adoIntegrationBaseUrl: string =
	(window as any)?.env?.REACT_APP_ADO_INTEGRATION_BASE_URL ||
	process.env.REACT_APP_ADO_INTEGRATION_BASE_URL;
export const adoIntegrationClientId: string =
	(window as any)?.env?.REACT_APP_ADO_INTEGRATION_CLIENT_ID ||
	process.env.REACT_APP_ADO_INTEGRATION_CLIENT_ID;
export const adoIntegrationRedirectUrl: string =
	(window as any)?.env?.REACT_APP_ADO_INTEGRATION_REDIRECT_URL ||
	process.env.REACT_APP_ADO_INTEGRATION_REDIRECT_URL;
export const adoIntegrationScope: string =
	(window as any)?.env?.REACT_APP_ADO_INTEGRATION_SCOPE ||
	process.env.REACT_APP_ADO_INTEGRATION_SCOPE;

export const gainsightProductKey: string =
	(window as any)?.env?.REACT_APP_GAINSIGHT_PRODUCT_KEY ||
	process.env.REACT_APP_GAINSIGHT_PRODUCT_KEY;

export const edgeUrl: string =
	(window as any)?.env?.REACT_APP_EDGE_URL || process.env.REACT_APP_EDGE_URL;

export const edgePlatformUrl: string =
	(window as any)?.env?.REACT_APP_EDGE_PLATFORM_URL ||
	process.env.REACT_APP_EDGE_PLATFORM_URL;

export const platformInventoryUrl: string =
	(window as any)?.env?.REACT_APP_PLATFORM_INVENTORY_URL ||
	process.env.REACT_APP_PLATFORM_INVENTORY_URL;

/**
 * The name of the runtime environment for this application, for example dev, qa, staging, etc.
 * This is not to be confused with NODE_ENV which is a built-in environment variable defined by Create React App
 * [that you cannot override manually](https://create-react-app.dev/docs/adding-custom-environment-variables/).
 */
export const environment: string =
	(window as any)?.env?.REACT_APP_ENVIRONMENT ||
	process.env.REACT_APP_ENVIRONMENT;

/**
 * How often deployment status updates should be checked. Value in milliseconds.
 */
export const deployStatusUpdateInterval: string =
	(window as any)?.env?.REACT_APP_DEPLOY_STATUS_UPDATE_INTERVAL ||
	process.env.REACT_APP_DEPLOY_STATUS_UPDATE_INTERVAL ||
	'30000';

/**
 * Service to send apm events from this app to.
 * Setting this url also enables APM.
 */
export const apmServerUrl: string =
	(window as any)?.env?.REACT_APP_APM_SERVER_URL ||
	process.env.REACT_APP_APM_SERVER_URL;

/**
 * The label this app appears in APM with, in the list of services.
 * Allowed characters: a-z, A-Z, 0-9, -, _, and space.
 * Optional. Defaults to 'XM Cloud Deploy - UI'.
 */
export const apmServiceName: string =
	(window as any)?.env?.REACT_APP_APM_SERVICE_NAME ||
	process.env.REACT_APP_APM_SERVICE_NAME ||
	'XM Cloud Deploy - UI';

export const getAuthSettings = () => {
	return { domain, clientId, audience };
};

export const isProductionMode = () => process.env.NODE_ENV === 'production';

import { Divider, Stack } from '@chakra-ui/react';
import { ENVIRONMENT_VARIABLES, PROVIDER } from '../create-project/helpers';
import { ESourceControl } from '../../../@xmcloud/types';
import { EnvVars } from '../../features/custom-environment-variables';
import { thirdStep } from '../../../@xmcloud/core/messages/en';
import { CmEnvironment } from './CmEnvironment';
import { useCreateEditEnvContext } from './hooks/CreateEditEnvContext';
import { LoadingSkeleton } from './LoadingSkeleton';
import {
	AdoRepository,
	EnvironmentName,
	EnvironmentVariables,
	GithubRepository,
	Options,
	SetupProviderAccount,
} from '../../features/common-env-fields';

const { info1, nameCmEnv, nameEhEnv } = thirdStep;

const { ADO, GitHub } = ESourceControl;

const repoLookup = {
	[ADO]: (props: any) => <AdoRepository {...props} />,
	[GitHub]: (props: any) => <GithubRepository {...props} />,
	'': () => <></>,
};

export const EnvironmentFields: React.FC = () => {
	const {
		values,
		errors,
		touched,
		handleChange,
		setErrors,
		setFieldTouched,
		setValues,
		setTouched,
		setFieldValue,
		accessToken,
		isIntegrationIdValidationValid,
		integrationValidationErrorMsg,
		isLoading,
		isCm,
		isCreate,
		initialLoading,
	} = useCreateEditEnvContext();

	const CurrentRepository = repoLookup[values[PROVIDER]];
	if (initialLoading && !isCreate) return <LoadingSkeleton />;

	return (
		<Stack gap="6" pl={3}>
			<EnvironmentName
				{...{
					values,
					errors,
					touched,
					handleChange,
					setFieldTouched,
					helperText: info1,
					label: isCm ? nameCmEnv : nameEhEnv,
				}}
			/>
			{!isCm && <CmEnvironment />}
			<Divider />
			<SetupProviderAccount
				{...{
					values,
					errors,
					touched,
					setValues,
					setTouched,
					setErrors,
					setFieldTouched,
					isIntegrationIdValidationValid,
					integrationValidationErrorMsg,
					isSourceControlIntegrationLoading: isLoading,
				}}
			/>
			<CurrentRepository
				{...{
					isSourceControlIntegrationLoading: isLoading,
					accessToken,
					values,
					errors,
					touched,
					setValues,
					setFieldTouched,
					setFieldValue,
					showRepoCombobox: true,
					isByoc: true,
					handleChange,
				}}
			/>
			<Divider />
			<Options
				{...{
					values,
					errors,
					setFieldValue,
					showSLA: isCm,
					isSLAReadOnly: !isCreate,
				}}
			/>
			<Divider />
			{isCreate && (
				<EnvVars
					{...{
						isExistingProject: false,
						initialVariables: values.environmentVariables,
						onVariablesChange: (variables) => {
							setFieldValue(ENVIRONMENT_VARIABLES, variables);
						},
					}}
				>
					<EnvironmentVariables
						variables={values.environmentVariables}
						minW={['full']}
					/>
				</EnvVars>
			)}
		</Stack>
	);
};

import { ITransferDetails } from '../models/transferDetails';
import { ITransferStage } from '../models/transferStage';
import { ITransferDetailsResponse } from '../models/transferDetailsResponse';
import { TransferProcessStatus } from '../models/transferModel';
import { TransferOperation } from '../models/transferOperation';
/**
 * Converts transfer response to transfer details
 * @param transferDetailsResponse
 * @returns {ITransferDetails}
 */
export const convertToTransferDetails = (
	transferDetailsResponse: ITransferDetailsResponse,
): ITransferDetails => {
	const result: ITransferDetails = {
		id: transferDetailsResponse.id,
		name: transferDetailsResponse.name,
		environmentId: transferDetailsResponse.environmentId,
		calculatedStatus: transferDetailsResponse.calculatedStatus,
		organizationId: transferDetailsResponse.organizationId,
		sourceEnvironmentId: transferDetailsResponse.sourceEnvironmentId,
		targetEnvironmentId: transferDetailsResponse.targetEnvironmentId,
		startedAt: transferDetailsResponse.startedAt,
		completedAt: transferDetailsResponse.completedAt,
		createdAt: transferDetailsResponse.createdAt,
		createdBy: transferDetailsResponse.createdBy,
		isCanceled: transferDetailsResponse.isCanceled,
		processedItemCount: transferDetailsResponse.processedItemCount,
		skippedItemCount: transferDetailsResponse.skippedItemCount,
		totalItemCount: transferDetailsResponse.totalItemCount,
		transferInitialization: {
			id: transferDetailsResponse.chunksInitialization.id,
			name: 'Transfer Initialization',
			status: transferDetailsResponse.chunksInitialization.status,
			errors: transferDetailsResponse.chunksInitialization.errors,
		} as ITransferStage,
		transferringContent: {
			id: transferDetailsResponse.contentChunkPull.id,
			name: 'Transferring content',
			status: calculateTransferringContentStatus([
				transferDetailsResponse.contentChunkPull,
				transferDetailsResponse.contentChunkPush,
				transferDetailsResponse.contentCommitPush,
			]),
			errors: transferDetailsResponse.contentChunkPull.errors
				.concat(transferDetailsResponse.contentChunkPush.errors)
				.concat(transferDetailsResponse.contentCommitPush.errors),
		} as ITransferStage,
		movingItemsToDb: {
			id: transferDetailsResponse.contentConsume.id,
			name: 'Moving items to database',
			status: transferDetailsResponse.contentConsume.status,
			errors: transferDetailsResponse.contentConsume.errors,
		} as ITransferStage,
	};
	return result;
};

const calculateTransferringContentStatus = (
	operations: TransferOperation[],
): TransferProcessStatus => {
	if (operations.some((op) => op.status === TransferProcessStatus.Failed))
		return TransferProcessStatus.Failed;

	if (operations.some((op) => op.status === TransferProcessStatus.Cancelled))
		return TransferProcessStatus.Cancelled;

	if (operations.some((op) => op.status === TransferProcessStatus.InProgress))
		return TransferProcessStatus.InProgress;

	return operations.every(
		(op) => op.status === TransferProcessStatus.Complete,
	)
		? TransferProcessStatus.Complete
		: TransferProcessStatus.NotStarted;
};

import { Box } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { cmInstanceLog } from '../../../@xmcloud/core/messages/en';
import { LogTypes } from '../../../@xmcloud/types';
import { Select } from 'chakra-react-select';
const { allLogs, warning, error } = cmInstanceLog;
type TOptions = { value: LogTypes; label: string }[];

interface LogSelectProps {
	isLoading?: boolean;
	value?: string;
	onChange: (e: any) => void;
	options?: TOptions;
	currentValue?: unknown;
}

export const LogSelect: React.FC<LogSelectProps> = ({
	isLoading = false,
	currentValue = '',
	onChange,
	options = [
		{ value: LogTypes.ALL, label: t(allLogs) },
		{ value: LogTypes.ERROR, label: t(error) },
		{ value: LogTypes.WARN, label: t(warning) },
	] as TOptions,
}) => (
	<Box position="relative">
		<Box
			position="absolute"
			right={[1, 5, 5, 5]}
			top={['0.5', '3', '3', '3']}
			zIndex={2}
			width="32"
			fontFamily="body"
		>
			<Select
				{...{ isLoading, options, onChange }}
				value={options.find((option) => option.value === currentValue)}
				data-testid="logs-select"
				placeholder="All logs"
				size="sm"
				chakraStyles={{
					dropdownIndicator: (provided) => ({
						...provided,
						bg: 'transparent',
						px: 2,
						cursor: 'inherit',
					}),
					indicatorSeparator: (provided) => ({
						...provided,
						display: 'none',
					}),
				}}
			/>
		</Box>
	</Box>
);

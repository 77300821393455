import { Link } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { t } from '@transifex/native';
import { portalBaseUrl } from '../../config/envConfig';
import { GenericErrorTemplate } from '../../shared-components';

export const AccessDeniedPage: React.FC = () => (
	<GenericErrorTemplate
		title={t('Access denied')}
		subtext={t('Error 403')}
		text={t("You don't have permission to access this page")}
		variant="access-denied"
		shortcuts={
			<>
				<Link mb={4} href={portalBaseUrl}>
					<T _str="Go to homepage" />
				</Link>
			</>
		}
	/>
);

import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertProps,
	ScaleFade,
	Text,
	Button,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { systemHealthStatus } from '../../../@xmcloud/core/messages/en';

const { btnText } = systemHealthStatus;

type Props = {
	description: string;
	descBtnText?: string;
	variableTxt?: string;
	variableTxt2?: string;
	boldText?: string;
	button?: string;
	buttonLink?: string;
} & AlertProps;

export const AlertStatus: React.FC<Props> = ({
	description = '',
	descBtnText = btnText,
	variableTxt = '',
	variableTxt2 = '',
	boldText = '',
	button = '',
	buttonLink,
	...rest
}) => (
	<ScaleFade initialScale={0.9} in={true}>
		<Alert {...rest}>
			<AlertIcon />
			<AlertDescription whiteSpace="pre-line">
				<T
					_str={description}
					variableTxt={variableTxt}
					variableTxt2={variableTxt2}
					boldText={
						<Text variant="strong" display="inline">
							{boldText}
						</Text>
					}
					button={
						<Button
							variant="link"
							onClick={() =>
								buttonLink && window.open(buttonLink)
							}
						>
							<T _str={button} />
						</Button>
					}
				/>
			</AlertDescription>
		</Alert>
	</ScaleFade>
);

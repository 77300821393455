import {
	Avatar,
	IconButton,
	Menu,
	MenuButton,
	MenuList,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface Props {
	children: ReactNode;
	name: string;
	avatarSrc?: string;
}

const IconMenu: FC<Props> = ({ children, name, avatarSrc }) => {
	return (
		<Menu>
			<MenuButton
				as={IconButton}
				size="sm"
				p={0}
				_focus={{ outline: 0 }}
				data-testid="user-menu-button"
			>
				<Avatar
					size="sm"
					src={avatarSrc}
					overflow="hidden"
					name={name}
				/>
			</MenuButton>
			<MenuList>{children}</MenuList>
		</Menu>
	);
};

export default IconMenu;

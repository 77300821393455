import { useGetSourceControlAccessToken } from '../../app/services/accessToken';
import {
	useGetRepositoryIntegration,
	useValidateSourceControlIntegrationV2,
} from '../../app/services/repositories';

export const useValidateConnection = ({
	sourceControlIntegrationId,
	enabled = true,
}: {
	sourceControlIntegrationId: string;
	enabled?: boolean;
}) => {
	const {
		isIntegrationIdValidationValid,
		integrationValidationErrorMsg,
		isFetching: isIntegrationValidationFetching,
	} = useValidateSourceControlIntegrationV2({
		integrationId: sourceControlIntegrationId,
		enabled,
	});

	const isIntegrationValidationValid = isIntegrationIdValidationValid;
	const integrationIdValidationMsg = integrationValidationErrorMsg;

	const { data: accessTokenData, isLoading: isLoadingAccessToken } =
		useGetSourceControlAccessToken(
			sourceControlIntegrationId!,
			isIntegrationValidationValid,
		);
	const enabledRepoIntegration = !isIntegrationValidationValid;

	//to get provider if integration is not valid due to broken connection
	const { data: IntegrationData, isLoading: isIntegrationDataLoading } =
		useGetRepositoryIntegration(
			sourceControlIntegrationId,
			enabledRepoIntegration,
		);

	const token = accessTokenData?.data.accessToken || '';
	const provider =
		accessTokenData?.data.provider || IntegrationData?.data.provider || 0;

	const isLoading =
		isIntegrationValidationFetching ||
		isLoadingAccessToken ||
		isIntegrationDataLoading;

	return {
		isLoading,
		integrationIdValidationMsg,
		isValidIntegration: isIntegrationValidationValid,
		isNotValidIntegration: !isIntegrationValidationValid,
		token,
		provider: provider as number,
		isIntegrationValidationFetching,
	};
};

import { Flex } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { sites } from '../../../../@xmcloud/core/messages/en';
import config from '../../../config/config';
import { useHostingInstallMutation } from '../../../services/hosting';
import { HostingProvider } from '../../../models/hostingModel';
import { LoadingScreen, StatesPage } from '../../../shared-components';

export const VercelInstallPage: FC = () => {
	const location = useLocation();
	const [installSucceed, setInstallSucceed] = useState(false);
	const { mutate, isLoading } = useHostingInstallMutation();
	const queryClient = useQueryClient();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const code = searchParams.get('code');
		const state = searchParams.get('state');

		if (!(code && state)) return setInstallSucceed(false);

		mutate(
			{
				code: code,
				provider: HostingProvider.Vercel,
				state: state,
			},
			{
				onSuccess: () => {
					setInstallSucceed(true);
					queryClient.invalidateQueries(
						config.hosting.get_installations_list.queryKey,
					);
				},
				onError: (data) => {
					setInstallSucceed(false);
				},
			},
		);
	}, [location.search, mutate, queryClient]);

	return (
		<Flex
			height="full"
			grow="initial"
			justifyContent="center"
			align="center"
		>
			{isLoading ? (
				<LoadingScreen
					data-testid="loading-component"
					message="Loading…"
				/>
			) : (
				<>
					{installSucceed ? (
						<StatesPage
							text={sites.vercelInstallSuccess}
							status="success"
						/>
					) : (
						<StatesPage
							text={sites.vercelInstallFailed}
							status="error"
						/>
					)}
				</>
			)}
		</Flex>
	);
};

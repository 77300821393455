import { mdiAlertCircleOutline, mdiCheckCircleOutline } from '@mdi/js';
import { Heading, Stack } from '@chakra-ui/react';
import { Icon } from '..';

interface Props {
	text: string;
	status: 'success' | 'error';
}

export const StatesPage: React.FC<Props> = ({ text, status }) => (
	<Stack align="center" pb={100} textAlign="center">
		<Icon
			boxSize="14"
			borderRadius="xl"
			bg={status === 'success' ? 'success-bg' : 'danger-bg'}
			color={status === 'success' ? 'success-fg' : 'danger-fg'}
			mb="4"
			path={
				status === 'success'
					? mdiCheckCircleOutline
					: mdiAlertCircleOutline
			}
		/>
		<Heading size="md" maxW="sm" lineHeight="base">
			{text}
		</Heading>
	</Stack>
);

import * as React from 'react';
import { CircularProgress, Text, Box } from '@chakra-ui/react';

export const LoadingScreen: React.FC<{ message?: string }> = ({
	message = 'Loading...',
	...rest
}) => {
	return (
		<Box
			height="full"
			width="full"
			alignItems="center"
			bgColor="whiteAlpha.900"
			display="flex"
			justifyContent="center"
		>
			<Box textAlign="center" {...rest}>
				<CircularProgress
					isIndeterminate
					capIsRound
					color="primary"
					trackColor="neutral-color.200"
				/>
				<Text mt={6}>{message}</Text>
			</Box>
		</Box>
	);
};

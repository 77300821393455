import {
	Checkbox,
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormHelperText,
} from '@chakra-ui/react';
import { T } from '@transifex/react';

type Props = {
	error?: string | undefined;
	label: string;
	name: string;
	isChecked?: boolean;
	onChange?: (e: any) => void;
	dataTestId?: string;
	onFocus?: () => void;
	isDisabled?: boolean;
	helperText?: string;
	maxW?: string;
} & FormControlProps;
export const CheckboxFormControl: React.FC<Props> = ({
	error,
	label,
	name,
	isChecked = false,
	onChange,
	dataTestId,
	onFocus,
	isDisabled,
	helperText = '',
	maxW = 'md',
	...rest
}) => {
	return (
		<FormControl maxW={maxW} {...rest}>
			<Checkbox
				{...{ name, isChecked, onChange, onFocus, isDisabled }}
				data-testid={dataTestId}
			>
				<T _str={label} />
			</Checkbox>
			<FormErrorMessage>{error}</FormErrorMessage>
			{!!helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};

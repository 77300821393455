import { Button, ButtonProps } from '@chakra-ui/react';
import { mdiTrayArrowDown } from '@mdi/js';
import { cmInstanceLog } from '../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';
import { Icon } from '..';

type Props = ButtonProps & {
	text?: string;
};

export const DownloadButton: React.FC<Props> = ({
	text = cmInstanceLog.downloadAll,
	...rest
}) => (
	<Button
		size="sm"
		variant="outline"
		leftIcon={<Icon path={mdiTrayArrowDown} />}
		{...rest}
	>
		{t(text)}
	</Button>
);

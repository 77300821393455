import { Box, Text, Stack, Divider, MenuItem } from '@chakra-ui/react';
import { createTransfer } from '../../../../../@xmcloud/core/messages/en';
import { FC, useEffect, useState } from 'react';
import {
	ComplexTextBox,
	RadioButtonGroup,
	XMCSMenuButton,
} from '../../../../shared-components';
import { createIcon } from '../../../../shared-components/icons/handlers';
import { ITransferConfigurationNode } from '../../../../models/transferConfiguration';
import { t } from '@transifex/native';
import {
	updateNodeMergeStrategy,
	truncatePathByCharacters,
} from './utils/handlers';
import { MergeStrategiesData } from '../../../contenttransfer/handlers';

const {
	transferOptionsStepSelectedItemsBulkActionsTitle,
	transferOptionsStepSelectedItemsTitle,
	transferOptionsStepSelectedItemsAllItemsTitle,
} = createTransfer;

interface SelectedItemsWithMergeStrategiesProps {
	setAllStrategiessSelected: (selected: boolean) => void;
	nodes: ITransferConfigurationNode[];
}

export const SelectedItemsWithMergeStrategies: FC<
	SelectedItemsWithMergeStrategiesProps
> = ({ setAllStrategiessSelected, nodes }) => {
	const [selectedValues, setSelectedValues] = useState<
		Record<string, string>
	>({});

	const setBulkMergeStrategy = (mergeStrategy: string): void => {
		nodes.forEach((node) => {
			handleMergeStrategyChange(mergeStrategy, node);
		});
	};

	useEffect(() => {
		setAllStrategiessSelected(
			nodes.every((node) => node.mergeStrategy !== undefined),
		);
		const selected = nodes.reduce(
			(acc, node) => {
				if (node.mergeStrategy !== undefined) {
					acc[node.id] = node.mergeStrategy.toString();
				}
				return acc;
			},
			{} as Record<string, string>,
		);

		setSelectedValues(selected);
	}, [nodes, setAllStrategiessSelected]);

	const handleMergeStrategyChange = (
		value: string,
		node: ITransferConfigurationNode,
	) => {
		updateNodeMergeStrategy(value, node);

		setAllStrategiessSelected(
			nodes.every((node) => node.mergeStrategy !== undefined),
		);

		setSelectedValues((prevSelectedValues) => ({
			...prevSelectedValues,
			[node.id]: value,
		}));
	};

	return (
		<Stack
			direction="column"
			paddingTop="2"
			flexDirection="column"
			alignItems="flex-start"
			justifyContent="stretch"
			maxW={'fit-content'}
			gap="6"
			key="stack-out-container"
		>
			<Stack direction="row" justifyContent="space-between" width="100%">
				<Text fontWeight="semibold" fontSize={'xl'} key="text-title">
					{t(transferOptionsStepSelectedItemsTitle)}
				</Text>

				<XMCSMenuButton
					text={transferOptionsStepSelectedItemsBulkActionsTitle}
					variant="outline"
				>
					{MergeStrategiesData.map(({ label, value }, index) => (
						<MenuItem
							key={`menu-${label}-${index}`}
							onClick={() => {
								setBulkMergeStrategy(value);
								setAllStrategiessSelected(true);
							}}
						>
							{t(
								`${label} - ${transferOptionsStepSelectedItemsAllItemsTitle}`,
							)}
						</MenuItem>
					))}
				</XMCSMenuButton>
			</Stack>
			<Stack
				flexDirection="column"
				alignItems="flex-start"
				gap="3.5"
				alignSelf="stretch"
				key="stack-inner-container-second"
			>
				{nodes &&
					nodes.map((node) => (
						<Box key={'box-out-merge' + node.id}>
							<Stack
								direction={['column', 'row']}
								alignContent="center"
								alignItems="center"
								flexWrap="wrap"
								alignSelf="stretch"
								key={'stack-merge' + node.id}
								gap="4"
							>
								<Box
									overflow={'hidden'}
									whiteSpace={'nowrap'}
									textOverflow={'ellipsis'}
									w="xs"
									alignItems="flex-start"
									gap="2"
									key={'box-merge' + node.id}
								>
									<ComplexTextBox
										primaryTitle={node.item}
										subTitle={truncatePathByCharacters(
											node.path,
											40,
										)}
										key={'complex-text-box-merge' + node.id}
									>
										{createIcon(node)}
									</ComplexTextBox>
								</Box>
								<Box key={'box-radio-merge' + node.id}>
									<RadioButtonGroup
										maxW="4xl"
										value={selectedValues[node.id] || ''}
										{...{
											label: '',
											name: `radioButtonGroup-${node.id}`,
											onChange: (v) =>
												handleMergeStrategyChange(
													v,
													node,
												),
											radioBtns: MergeStrategiesData,
										}}
										key={'box-radio-group-merge' + node.id}
									/>
								</Box>
							</Stack>
							<Divider />
						</Box>
					))}
			</Stack>
		</Stack>
	);
};

import { FC, useRef } from 'react';
import {
	InputFormControl,
	ModalMainContent,
	ModalWrapper,
	SelectFormControl,
	SwitchFormControl,
} from '../../../shared-components';
import { useFormik } from 'formik';
import { editCreateVariableSchema } from '../../../../@xmcloud/yup-validations/editCreateVariables';
import { t } from '@transifex/native';
import { environmentVariable } from '../../../../@xmcloud/core/messages/en';
import { useParams } from 'react-router-dom';
import { IEnvironmentVariableDetails } from '../../../models/environmentModel';
import { useGetEnvironmentVariables } from '../../../services/environments';
import { onDisabled } from '../../../../@xmcloud/utils/helpers';

const {
	targetAll,
	targetCM,
	targetRH,
	create,
	edit,
	name,
	value,
	secret,
	target,
	renderingHost,
	secretInfo,
} = environmentVariable;

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
	isEdit?: boolean;
	onSubmit: (values: any) => void;
	isLoading?: boolean;
	variableToEdit?: IEnvironmentVariableDetails;
}

const NAME = 'name';
const VALUE = 'value';
const SECRET = 'secret';
const TARGET = 'target';
const RENDERINGHOST = 'renderingHost';

const sanitizeTarget = (target: string | undefined) =>
	!Boolean(target)
		? targetAll
		: target === targetAll || target === targetCM
			? target
			: targetRH;

const EditCreateVariableForm: FC<Props> = ({
	openModal,
	setOpenModal,
	isEdit = false,
	onSubmit,
	isLoading = false,
	variableToEdit,
}) => {
	const { environmentId } = useParams<{
		environmentId: string;
	}>();

	const initialValues = useRef(
		isEdit && variableToEdit
			? {
					name: variableToEdit.name,
					value: !variableToEdit.secret ? variableToEdit.value : '',
					secret: variableToEdit.secret,
					target: sanitizeTarget(variableToEdit?.target),
					renderingHost: !Boolean(variableToEdit.target)
						? targetAll
						: variableToEdit.target,
				}
			: {
					name: '',
					value: '',
					secret: false,
					target: targetAll,
					renderingHost: targetAll,
				},
	).current;

	const { data } = useGetEnvironmentVariables({
		environmentId: environmentId!,
		enabled: !isEdit,
	});

	const targetValueRef = useRef(initialValues.target);

	const variables = data?.data || [];

	const variableNames = variables
		.filter((v) => {
			const sanitizedTarget = sanitizeTarget(v.target);
			return (
				(sanitizedTarget === targetRH ? v.target : sanitizedTarget) ===
				targetValueRef.current
			);
		})
		.map((v) => v.name.toLowerCase());

	const variablesOfOtherTarget = variables
		.filter((v) => {
			const sanitizedTarget = sanitizeTarget(v.target);
			return targetValueRef.current === targetAll
				? sanitizedTarget !== targetAll
				: sanitizedTarget === targetAll;
		})
		.map((v) => v.name.toLowerCase());

	const validationSchema = editCreateVariableSchema({
		variablesOfSelectedTarget: variableNames,
		variablesOfOtherTarget,
		shouldValidateName: !isEdit,
		isAllTargetSelected: targetValueRef.current === targetAll,
	});

	const {
		errors,
		values,
		handleChange,
		handleSubmit: formikHandleSubmit,
		setFieldValue,
		setFieldTouched,
		validateForm,
		touched,
		isValid,
	} = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		validateOnBlur: false,
		onSubmit: async (values) => {
			validateForm();
			onSubmit(values);
		},
	});

	const isDisabled = isEdit
		? onDisabled(isValid, values, initialValues) || isLoading
		: false;

	const shouldShowRenderingHost =
		values.target !== targetAll && values.target !== targetCM;

	return (
		<ModalWrapper
			isOpen={openModal}
			title={isEdit ? edit : create}
			onClose={() => setOpenModal(false)}
		>
			<form
				data-testid="create-edit-variable-form"
				onSubmit={formikHandleSubmit}
			>
				<ModalMainContent
					onClose={() => setOpenModal(false)}
					isLoading={isLoading}
					pt={0}
					isDisabled={isDisabled}
				>
					<InputFormControl
						error={errors.name}
						isInvalid={Boolean(errors.name && touched.name)}
						label={name}
						name={NAME}
						onChange={handleChange}
						value={values.name}
						showAsPlaceHolder={isEdit}
						onFocus={() => setFieldTouched(NAME, true)}
					/>
					<InputFormControl
						error={errors.value}
						isInvalid={Boolean(errors.value && touched.value)}
						label={value}
						name={VALUE}
						onChange={handleChange}
						value={values.value}
						pt={6}
						onFocus={() => setFieldTouched(VALUE, true)}
					/>
					<SwitchFormControl
						error={errors.secret}
						isInvalid={Boolean(errors.secret)}
						label={secret}
						name={SECRET}
						onChange={(e: any) => {
							setFieldValue(SECRET, e.target.checked);
						}}
						isDisabled={isEdit}
						isChecked={values.secret}
						mt={6}
						showInfoIcon={true}
						infoText={secretInfo}
					/>
					<SelectFormControl
						error={errors.target}
						isInvalid={Boolean(errors.target)}
						label={target}
						name={TARGET}
						onChange={(e: any) => {
							setFieldValue(TARGET, e.value);
							setFieldValue(
								RENDERINGHOST,
								e.value === targetRH ? '' : e.value,
							);
							targetValueRef.current = e.value;
						}}
						options={targetInputOptions}
						currentValue={values.target}
					/>
					{shouldShowRenderingHost && (
						<InputFormControl
							error={errors.renderingHost}
							isInvalid={Boolean(errors.renderingHost)}
							label={renderingHost}
							name={RENDERINGHOST}
							onChange={(e: any) => {
								targetValueRef.current = e.target.value;
								handleChange(e);
							}}
							value={values.renderingHost}
							pt={6}
						/>
					)}
				</ModalMainContent>
			</form>
		</ModalWrapper>
	);
};

export default EditCreateVariableForm;

const targetInputOptions = [
	{
		label: t(targetAll),
		value: targetAll,
	},
	{
		label: t(targetCM),
		value: targetCM,
	},
	{
		label: t(targetRH),
		value: targetRH,
	},
];

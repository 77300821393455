export const orgIdStorageKey = 'xmc-deploy-ui:org_id';
export const ORGANIZATION = 'organization';

/**
 * If user never used the app before, identity service determines what organization to use.
 *
 * @see https://community.auth0.com/t/how-do-i-use-organizations-with-the-react-sdk-and-maintain-user-sessions/69949#recipe-2-configuring-multiple-organizations-4
 */
export function fetchLastSuccessfulLoginOrganizationId(): string | undefined {
	const query = new URLSearchParams(window.location.search);
	const orgIdFromURL = query.get(ORGANIZATION);
	const orgIdFromSessionStorage = sessionStorage.getItem(orgIdStorageKey);
	if (orgIdFromURL) {
		return orgIdFromURL;
	} else if (orgIdFromSessionStorage) {
		// Update localStorage
		localStorage.setItem(orgIdStorageKey, orgIdFromSessionStorage);

		// Append to URL
		appendOrgToUrl(query, orgIdFromSessionStorage);

		return orgIdFromSessionStorage;
	} else {
		var orgId = localStorage.getItem(orgIdStorageKey) ?? '';
		// Update sessionStorage
		sessionStorage.setItem(orgIdStorageKey, orgId);

		// Append to URL
		appendOrgToUrl(query, orgId);

		return orgId;
	}
}

function appendOrgToUrl(searchParam: URLSearchParams, orgId: string) {
	searchParam.set(ORGANIZATION, orgId);
}

/**
 * Save the organization id when a user first logs in, later we pass it to the Auth0Provider when app re-loads.
 * Session storage (vs local storage) is used to allow user to change organization for a tab/window and keep the choice for that tab/window between page loads.
 */
export function storeLastSuccessfulLoginOrganizationId(
	organizationId?: string,
) {
	if (organizationId) {
		sessionStorage.setItem(orgIdStorageKey, organizationId);
		localStorage.setItem(orgIdStorageKey, organizationId);
	}
}

export function resetLastSuccessfulLoginOrganizationId() {
	sessionStorage.setItem(orgIdStorageKey, '');
	localStorage.setItem(orgIdStorageKey, '');
}

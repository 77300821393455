import { FC } from 'react';
import { goTo } from '../core/routes/paths';

type Size =
	| '3xs'
	| '2xs'
	| 'xs'
	| 'sm'
	| 'md'
	| 'lg'
	| 'xl'
	| '2xl'
	| '3xl'
	| '4xl'
	| '5xl'
	| '6xl'
	| '7xl'
	| '8xl'
	| 'full';
type ButtonSizes = 'md' | 'sm' | 'xs' | 'lg';

type ButtonVariant = 'ghost' | 'outline' | 'solid' | 'link' | 'unstyled' | 'ai';

export interface CreationStepStatus {
	status: ProjectCreationStepsStatusType;
	successResult?: any;
	error?: any;
}

export interface CreationStepStatus {
	status: ProjectCreationStepsStatusType;
	successResult?: any;
	error?: any;
}

export enum StarterTemplateId {
	StarterTemplate,
	SourceProvider,
}

type OutletProps = {
	routes: (path: string) => Array<{
		key: string;
		exact?: boolean;
		path: string | undefined;
		Element: FC<any>;
		props?: any;
		index: number | string;
	}>;
};

type QueryResponse<T> = {
	config: object;
	data: T;
	headers: Headers;
	request: XMLHttpRequest;
	status: number;
	statusText: string;
};
export type Maybe<T> = T | null;
export type Dict<T = any> = Record<string, T>;

interface UseTableRowProps<D> {
	cells: Array<any>;
	allCells: Array<any>;
	values: any;
	getRowProps: (propGetter?: any) => any;
	index: number;
	original: D;
	id: string;
	subRows: any;
}

export enum LogTypes {
	INFO = 'INFO',
	ERROR = 'ERROR',
	WARN = 'WARN',
	ALL = 'ALL',
}

type TProviders = 'GitHub' | 'Azure';

export type {
	Size,
	OutletProps,
	ButtonVariant,
	ButtonSizes,
	QueryResponse,
	UseTableRowProps,
	TProviders,
};

export enum ProjectCreationStepsTypes {
	CREATE_REPOSITORY,
	CREATE_PROJECT,
	CREATE_ENVIRONMENT,
	LINK_BRANCH_TO_ENVIRONMENT,
	CREATE_DEPLOYMENT,
	START_DEPLOYMENT,
}

export enum ProjectCreationStepsStatusType {
	NOT_STARTED,
	INPROGRESS,
	COMPLETED,
	FAILED,
	SKIPPED,
}

export type CreationStatusReturnType =
	| CreationStepStatus
	| {
			[key: number]: CreationStepStatus;
	  };

export type TRANSFERFILTER = 'all' | 'completed';

export enum DeploymentFilter {
	all = 'all',
	user = 'user',
	skippedQueued = 'skipped&queued',
}

export type PaginatedResponse<T> = {
	data: T[];
	totalCount: number;
	pageSize: number;
	pageNumber: number;
};

export type GoToKeys = keyof typeof goTo;

export type SourceControlType = 'GitHub' | 'ADO' | '';

export enum TenantType {
	NonProduction,
	Production,
}

export enum ESourceControl {
	GitHub = 'GitHub',
	ADO = 'ADO',
}
export enum EnumInNumberSourceControl {
	GitHub = 1,
	ADO = 2,
}

export enum EAccountType {
	USER,
	ORGANIZATION,
}

import {
	TransferProcessStatus,
	IGetTransferResponse,
} from '../app/models/transferModel';

export const TransferMock: IGetTransferResponse = {
	organizationId: 'mock-org-id',
	environmentId: 'mock-environment-id-1',
	id: 'mock-transfer-id',
	name: 'Mocked Transfer',
	sourceEnvironmentId: 'mock-source-environment-id-1',
	targetEnvironmentId: 'mock-target-environment-id-1',
	calculatedStatus: TransferProcessStatus.InProgress,
	createdBy: 'admin',
	createdAt: '2021-08-11T16:17:01.542Z',
	startedAt: '2021-08-11T16:17:01.542Z',
	completedAt: null,
	isCanceled: false,
	totalItemCount: 1000,
	processedItemCount: 100,
	skippedItemCount: 0,
};

export const TransferCompletedMock: IGetTransferResponse[] = [
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Complete,
		totalItemCount: 1000,
		processedItemCount: 1000,
		createdAt: '2021-08-11T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Complete,
		totalItemCount: 1323,
		processedItemCount: 1323,
		createdAt: '2021-09-07T16:17:01.542Z',
		startedAt: '2021-08-07T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Complete,
		totalItemCount: 10,
		processedItemCount: 10,
		createdAt: '2021-07-22T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Complete,
		totalItemCount: 5,
		skippedItemCount: 1,
		processedItemCount: 4,
		createdAt: '2021-10-22T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Complete,
		createdAt: '2021-09-22T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Complete,
		createdAt: '2021-09-22T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Complete,
		totalItemCount: 1000,
		processedItemCount: 1000,
		createdAt: '2021-08-11T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Complete,
		totalItemCount: 1323,
		processedItemCount: 1323,
		createdAt: '2021-09-07T16:17:01.542Z',
		startedAt: '2021-08-07T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Complete,
		totalItemCount: 10,
		processedItemCount: 10,
		createdAt: '2021-07-22T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Complete,
		totalItemCount: 5,
		skippedItemCount: 1,
		processedItemCount: 4,
		createdAt: '2021-10-22T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Complete,
		createdAt: '2021-09-22T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Complete,
		createdAt: '2021-09-22T16:17:01.542Z',
		totalItemCount: 200000,
		processedItemCount: 200000,
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Complete,
		totalItemCount: 150000,
		processedItemCount: 150000,
		createdAt: '2021-10-22T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Complete,
		totalItemCount: 1000000,
		processedItemCount: 1000000,
		createdAt: '2021-10-22T16:17:01.542Z',
	},
];

export const TransfersMock: IGetTransferResponse[] = [
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.InProgress,
		processedItemCount: 1,
		createdAt: '2021-08-11T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.InProgress,
		processedItemCount: 100,
		createdAt: '2021-09-07T16:17:01.542Z',
		startedAt: '2021-08-07T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Failed,
		processedItemCount: 12,
		createdAt: '2021-07-22T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.NotStarted,
		totalItemCount: 0,
		skippedItemCount: 0,
		processedItemCount: 0,
		createdAt: '2021-10-22T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.InProgress,
		totalItemCount: 0,
		skippedItemCount: 0,
		processedItemCount: 0,
		createdAt: '2021-09-22T16:17:01.542Z',
	},
	{
		...TransferMock,
		calculatedStatus: TransferProcessStatus.Cancelled,
		totalItemCount: 0,
		skippedItemCount: 0,
		processedItemCount: 0,
		createdAt: '2021-09-22T16:17:01.542Z',
	},
];

export const allMockedTransfers = TransfersMock.map((d) => ({
	...d,
	project: 'Test-project	',
	environment: 'Test-env',
}));

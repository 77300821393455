import { t } from '@transifex/native';
import { MenuItem, MenuList, Text } from '@chakra-ui/react';
import { credentials } from '../../../../@xmcloud/core/messages/en';
import { GetOrganizationClientResponse } from '../../../models/clientModel';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import { createColumnHelper } from '@tanstack/react-table';
import { RowActions } from '../../../shared-components';

const { label, description, clientId, createdDate } = credentials;
const columnHelper = createColumnHelper<GetOrganizationClientResponse>();

export const organizationCredentialsTableColumns = (
	setSelectedClient: (c: GetOrganizationClientResponse) => void,
	setShowClientRevokeModal: (v: boolean) => void,
) => {
	return [
		columnHelper.accessor('name', {
			header: () => t(label),
			cell: (info) => (
				<Text noOfLines={[1, 4]} whiteSpace="wrap">
					{info.getValue()}
				</Text>
			),
		}),
		columnHelper.accessor('description', {
			header: () => t(description),
			cell: (info) => (
				<Text noOfLines={[2, 4]} whiteSpace="wrap">
					{info.getValue()}
				</Text>
			),
		}),
		columnHelper.accessor('clientId', {
			header: () => t(clientId),
			cell: (info) => (
				<Text noOfLines={1} whiteSpace="wrap">
					{info.getValue()}
				</Text>
			),
		}),
		columnHelper.accessor('createdAt', {
			header: () => t(createdDate),
			cell: (info) => getReadableDate(info.getValue()),
		}),
		columnHelper.display({
			id: 'actions',
			cell: ({ row: { original: organization } }) => {
				return (
					<RowActions ariaLabel="credentials-organization-table-action-menu">
						<MenuList>
							<MenuItem
								onClick={() => {
									setSelectedClient(organization);
									setShowClientRevokeModal(true);
								}}
							>
								{t(credentials.revoke)}
							</MenuItem>
						</MenuList>
					</RowActions>
				);
			},
			maxSize: 0,
		}),
	];
};

// Create abstract client
export interface ICreateClientPayload {
	name: string;
	description: string;
}

export interface ICreateClientResponse {
	name: string;
	description: string;
	clientId: string;
	clientSecret: string;
}

// Create organization client
export type ICreateOrganizationAutomationClientPayload = ICreateClientPayload;
export type ICreateOrganizationAutomationClientResponse = ICreateClientResponse;

// Abstract environment create client
export interface ICreateEnvironmentClientPayload extends ICreateClientPayload {
	projectId: string;
	environmentId: string;
}

export type ICreateEnvironmentClientResponse = ICreateClientResponse;

// Environment create clients
export type ICreateEdgeClientPayload = ICreateEnvironmentClientPayload;
export type ICreateEdgeClientResponse = ICreateEnvironmentClientResponse;
export type ICreateEnvironmentAutomationClientPayload =
	ICreateEnvironmentClientPayload;
export type ICreateEnvironmentAutomationClientResponse =
	ICreateEnvironmentClientResponse;

export enum AuthenticationClientType {
	AutomationClient = 1,
	EdgeAdministrationClient,
	OrganizationAutomationClient,
}

export interface AbstractGetClientResponse {
	id: string;
	name: string;
	description: string;
	clientId: string;
	clientType: AuthenticationClientType;
	createdAt: string;
}

export interface GetEnvironmentClientResponse
	extends AbstractGetClientResponse {
	projectName: string;
	environmentName: string;
}

export interface GetOrganizationClientResponse
	extends AbstractGetClientResponse {}

export interface IGetEnvironmentClientListResponse {
	items: GetEnvironmentClientResponse[];
}

export interface IGetOrganizationClientListResponse {
	items: GetOrganizationClientResponse[];
}

import { useState, FC, useEffect, useRef } from 'react';
import {
	app,
	connections as enConnections,
} from '../../../../@xmcloud/core/messages/en';
import {
	CardBoxList,
	ModalMainContent,
	ModalWrapper,
} from '../../../shared-components';

export interface Props {
	isOpen: boolean;
	setIsOpenOpen: (a: boolean) => void;
	refetch: () => Promise<any>;
	isLoading: boolean;
	url: (state: string, provider: string) => string;
	connections: { name: string; iconName: string }[];
}

const CreateConnection: FC<Props> = ({
	isOpen,
	setIsOpenOpen,
	refetch,
	isLoading,
	url,
	connections,
}) => {
	const [currentConnection, setCurrentConnection] = useState('');
	const state = useRef('');
	useEffect(() => {
		const fetchState = async () => {
			const data = await refetch();

			state.current = data?.data ?? '';
		};
		fetchState();

		return () => {
			state.current = '';
		};
	}, [refetch, url]);

	return (
		<ModalWrapper
			isOpen={isOpen}
			title={enConnections.create}
			onClose={() => setIsOpenOpen(false)}
		>
			<ModalMainContent
				onClose={() => setIsOpenOpen(false)}
				rightButtonText={app.next}
				pt={4}
				pb={6}
				onConfirm={() => {
					window.open(url(state.current, currentConnection));
					setIsOpenOpen(false);
				}}
				isLoading={isLoading}
				isDisabled={!Boolean(currentConnection)}
			>
				<CardBoxList
					{...{
						currentSelection: currentConnection,
						setCurrentSelection: setCurrentConnection,
						items: connections,
					}}
				/>
			</ModalMainContent>
		</ModalWrapper>
	);
};

export default CreateConnection;

import { Stack } from '@chakra-ui/react';
import { mdiPlus } from '@mdi/js';
import { FC, useState } from 'react';
import { useParams } from 'react-router';
import { environments } from '../../../../@xmcloud/core/messages/en';
import {
	IS_SM_SCREEN,
	useSharedState,
} from '../../../../@xmcloud/hooks/context/useSharedState';
import { Footer, Icon, XMCSButton } from '../../../shared-components';
import ProjectEnvironmentsTable from '../tables/ProjectEnvironmentsTable';
import CreateCombinedEnvironment from '../../../stale-components/CreateCombinedEnvironment';
import { EnvironmentTypesEnum } from '../../create-project/helpers';
import { useCreateProjectV3 } from '../../create-project/old-components/useCreateProjectV3';
import { Main as CreateEnvironment } from '../../create-edit-environment/Main';

const { create: createtxt } = environments;
const { EH } = EnvironmentTypesEnum;

export const ProjectEnvironments: FC = () => {
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);
	// check if url has eh-environment
	const params = useParams<{
		'envType-environments': string;
	}>();

	const { enableCreateProjectV3 } = useCreateProjectV3();

	const envType = params['envType-environments']?.split('-')[0];
	const isEhEnv = envType === EH;

	const buttonText = !enableCreateProjectV3
		? 'Create environment'
		: isEhEnv
			? 'Add editing host'
			: 'Add CM environment';

	return (
		<>
			<Stack direction="row" justify="flex-end" py={isSmScreen ? 3 : 5}>
				{!isSmScreen && (
					<XMCSButton
						{...{
							text: buttonText,
							onClick: () => setOpenCreateModal(true),
							...(enableCreateProjectV3 && {
								leftIcon: <Icon path={mdiPlus} />,
							}),
						}}
					/>
				)}
			</Stack>
			<ProjectEnvironmentsTable />
			{Boolean(isSmScreen) && (
				<Footer>
					<XMCSButton
						text={createtxt}
						onClick={() => setOpenCreateModal(true)}
					/>
				</Footer>
			)}
			{openCreateModal && !enableCreateProjectV3 && (
				<CreateCombinedEnvironment
					openModal={openCreateModal}
					setOpenModal={setOpenCreateModal}
				/>
			)}
			{openCreateModal && enableCreateProjectV3 && (
				<CreateEnvironment
					isOpen={openCreateModal}
					onClose={() => setOpenCreateModal(false)}
					isCreate={true}
					isCm={isEhEnv ? false : true}
				/>
			)}
		</>
	);
};

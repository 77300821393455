import { withAuthenticationRequired } from '@auth0/auth0-react';
import { LoadingScreen } from '../shared-components';
import { MainLayout } from '../../@xmcloud/layouts';
import { AzureFeaturesProvider } from '../../@xmcloud/hooks/context/azureFeatureFlags';
import { MainRouter } from '../../@xmcloud/core/routes/MainRouter';

export const MainContainer = withAuthenticationRequired(
	() => {
		return (
			<AzureFeaturesProvider>
				<MainLayout>
					<MainRouter />
				</MainLayout>
			</AzureFeaturesProvider>
		);
	},
	{
		onRedirecting: () => <LoadingScreen />,
	},
);

import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { environment } from '../../../../@xmcloud/core/messages/en';
import { pageSize } from '../../../../@xmcloud/utils/constants';
import { IEnvironmentVariableDetails } from '../../../models/environmentModel';
import { useGetEnvironmentVariables } from '../../../services/environments';
import {
	LoadingSkeleton,
	RenderPlaceholder,
	Table,
} from '../../../shared-components';
import { variablesTableColumns } from '../columns/variables';
import EditVariable from '../environments/EditVariable';
import DeleteVariableConfirmation from '../components/DeleteVariableConfirmation';
import { ColumnDef } from '@tanstack/react-table';

const headers = ['name', 'Value', 'Secret', 'Target', ''];

const VariablesTable: FC<{ isConfigurationHealthy: boolean }> = ({
	isConfigurationHealthy,
}) => {
	const { environmentId } = useParams<{
		environmentId: string;
	}>();
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const { data, isLoading: isEnvironmentVariablesLoading } =
		useGetEnvironmentVariables({
			environmentId: environmentId!,
			enabled: true,
		});

	const variables = data?.data || [];

	const [editVariableModalIsOpen, setEditVariableModalIsOpen] =
		useState(false);
	const [selectedVariable, setSelectedVariable] = useState(
		{} as IEnvironmentVariableDetails,
	);

	const columns: ColumnDef<IEnvironmentVariableDetails, any>[] =
		useMemo(() => {
			return variablesTableColumns({
				setSelectedVariable,
				setEditVariableModalIsOpen,
				setShowDeleteModal,
				isConfigurationHealthy,
			});
		}, [isConfigurationHealthy]);

	const isLoading = isEnvironmentVariablesLoading;

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;
	if (variables.length === 0)
		return (
			<RenderPlaceholder
				item={environment.variables.toLocaleLowerCase()}
			/>
		);

	return (
		<>
			<Table
				{...{
					columns,
					tableData: variables,
					dataTestId: 'variables-table',
					showPagination: variables.length > pageSize,
					initialSort: [{ id: 'name', desc: true }],
					headers,
				}}
			/>
			{showDeleteModal && (
				<DeleteVariableConfirmation
					environmentId={environmentId!}
					variableName={selectedVariable.name}
					target={selectedVariable.target}
					openModal={showDeleteModal}
					setOpenModal={setShowDeleteModal}
				/>
			)}
			{editVariableModalIsOpen && (
				<EditVariable
					openModal={editVariableModalIsOpen}
					setOpenModal={setEditVariableModalIsOpen}
					variable={selectedVariable}
				/>
			)}
		</>
	);
};

export default VariablesTable;

import { AlertStatus } from '../../shared-components';
import { useGetRepositoriesValidation } from './useGetRepositoriesValidation';

export const RepositoryErrorAlert: React.FC = () => {
	const { errorMsg, isLoading, editRepository } =
		useGetRepositoriesValidation();

	if (!errorMsg || isLoading || editRepository) return null;

	return (
		<AlertStatus
			description={errorMsg}
			status="error"
			data-testid="repository-list-error-alert"
			mt={4}
		/>
	);
};

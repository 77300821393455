import { FC } from 'react';
import { AuthenticationClientType } from '../../models/clientModel';
import { useCreateEdgeClientMutation } from '../../services/clients';
import ClientCreatedModal from './components/ClientCreatedModal';
import CreateClientForm from './components/CreateClientForm';

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
}

const CreateEdgeAdministrationClient: FC<Props> = ({
	openModal,
	setOpenModal,
}) => {
	const {
		data,
		mutate: createClient,
		isLoading,
	} = useCreateEdgeClientMutation();

	const clientData = data?.data as any;
	const handleCreateClient = (values: any) => {
		createClient({
			client: {
				name: values.label,
				description: values.description,
				projectId: values.project,
				environmentId: values.environment,
			},
		});
	};

	return (
		<>
			{!data && (
				<CreateClientForm
					{...{
						openModal,
						setOpenModal,
						onSubmit: handleCreateClient,
						isLoading,
						clientType:
							AuthenticationClientType.EdgeAdministrationClient,
					}}
				/>
			)}
			{!!data && (
				<ClientCreatedModal
					{...{
						openModal,
						setOpenModal,
						clientType:
							AuthenticationClientType.EdgeAdministrationClient,
						client: clientData,
					}}
				/>
			)}
		</>
	);
};

export default CreateEdgeAdministrationClient;

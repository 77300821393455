import { FC, useMemo, useState } from 'react';
import {
	Stack,
	useToast,
	VStack,
	Text,
	Skeleton,
	Spinner,
	Box,
} from '@chakra-ui/react';
import {
	app,
	environment as enEnvironment,
} from '../../../../@xmcloud/core/messages/en';
import {
	Alert,
	HoverableCard,
	ModalMainContent,
	ModalWrapper,
} from '../../../shared-components';
import { useUpdateEnvironmentMutation } from '../../../services/environments';
import { useGetRepositoryIntegration } from '../../../services/repositories';
import { IGetProjectResponse } from '../../../models/projectModel';
import { t } from '@transifex/native';
import { IRepositoryBranchDetails } from '../../../models/repositoriesModel';
import { EnumInNumberSourceControl } from '../../../../@xmcloud/types';
import { useGetSourceControlAccessToken } from '../../../services/accessToken';
import { useGetADOBranches } from '../../../services/adoApi';
import { useGetGitHubPaginatedBranches } from '../../../services/githubApi';
import { ADO_BRANCH_PREFIX } from '../../../../@xmcloud/utils/constants';
import { convertProviderEnum } from '../../../../@xmcloud/utils/helpers';
const { requiredBranch, linkToBranchTitle, link, linkedSuccessfully } =
	enEnvironment;

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
	environmentId?: string;
	project: IGetProjectResponse;
	repositoryBranch?: string | null;
}

const LinkEnvToOtherBranch: FC<Props> = ({
	openModal,
	setOpenModal,
	environmentId,
	project,
	repositoryBranch,
}) => {
	const [selectedBranch, setSelectedBranch] = useState(
		{} as IRepositoryBranchDetails,
	);

	const [showError, setShowError] = useState(false);
	const toast = useToast();
	const sourceControlIntegrationId =
		project?.sourceControlIntegrationId || '';

	const { mutate: updateEnvironment, isLoading: updateEnvironmentLoading } =
		useUpdateEnvironmentMutation({ id: environmentId! });

	const { data: accessTokenData, isLoading: isLoadingAccessToken } =
		useGetSourceControlAccessToken(sourceControlIntegrationId);

	const { data: repoIntegrationData, isLoading: isLoadingRepoIntegration } =
		useGetRepositoryIntegration(sourceControlIntegrationId);

	const provider = convertProviderEnum(
		repoIntegrationData?.data?.provider || '',
		'number',
	);
	const isGithubProvider = provider === EnumInNumberSourceControl.GitHub;
	const isAdoProvider = provider === EnumInNumberSourceControl.ADO;

	const token = accessTokenData?.data.accessToken || '';
	const githubRepository = (isGithubProvider && project?.repository) || '';
	const owner =
		(isGithubProvider && project?.sourceControlIntegrationName) || '';
	const repositoryPath =
		(isAdoProvider &&
			project?.repository &&
			project?.repository.split('/')) ||
		[];
	const adoOrganization = repositoryPath[0];
	const adoProjectName = repositoryPath[1];
	const repositoryName = repositoryPath[2];

	const { data: adoBranchesData, isLoading: isLoadingIsAdoBranches } =
		useGetADOBranches(
			token,
			adoOrganization,
			adoProjectName,
			repositoryName,
			isAdoProvider,
		);

	const {
		data: githubBranchesData,
		isLoading: isLoadingGithubBranches,
		hasMore,
		isFetching: isFetchingGithubBranches,
		fetchNextPage: fetchNextPageGithubBranches,
	} = useGetGitHubPaginatedBranches({
		token,
		owner,
		repo: githubRepository,
		_enabled: isGithubProvider,
	});

	const adoBranches = useMemo(
		() =>
			(isAdoProvider &&
				adoBranchesData?.data.value.map((v) => ({
					name: v.name.replace(ADO_BRANCH_PREFIX, ''),
				}))) ||
			[],
		[adoBranchesData?.data.value, isAdoProvider],
	);
	const githubBranches = (isGithubProvider && githubBranchesData) || [];

	const branches = isAdoProvider ? adoBranches : githubBranches;

	const isLoadingRepoBranches =
		isLoadingRepoIntegration ||
		isLoadingAccessToken ||
		isLoadingIsAdoBranches ||
		isLoadingGithubBranches;

	const loading = updateEnvironmentLoading || isLoadingRepoBranches;

	const hasBranches = branches.length > 0;
	const shouldDisableLinkButton =
		branches.length === 1 && Boolean(repositoryBranch);

	function handlePromote() {
		if (!selectedBranch?.name) return setShowError(true);

		updateEnvironment(
			{
				repositoryBranch: selectedBranch.name,
			},
			{
				onSuccess: () => {
					toast({
						status: 'success',
						description: t(linkedSuccessfully, {
							branch: selectedBranch.name,
						}),
					});
					setOpenModal(false);
				},
			},
		);
	}

	const handleScroll = (e: UIEvent) => {
		if (!hasMore && !isGithubProvider) return;
		const target = e.target as HTMLElement;
		const bottom =
			Math.abs(
				target.scrollHeight - target.clientHeight - target.scrollTop,
			) < 1;
		if (
			bottom &&
			hasMore &&
			isGithubProvider &&
			!isFetchingGithubBranches
		) {
			fetchNextPageGithubBranches();
		}
	};

	return (
		<ModalWrapper
			isOpen={openModal}
			title={linkToBranchTitle}
			onClose={() => setOpenModal(false)}
			dataTestId="deploy-build-modal"
			size="lg"
			scrollBehavior="inside"
		>
			<ModalMainContent
				onClose={() => setOpenModal(false)}
				rightButtonText={link}
				isLoading={loading}
				onConfirm={handlePromote}
				isDisabled={!hasBranches || shouldDisableLinkButton}
				onScroll={handleScroll}
			>
				{isLoadingRepoBranches ? (
					<Skeleton h="14" />
				) : hasBranches ? (
					<Stack direction="column" spacing={5}>
						{branches?.map((branch) => (
							<HoverableCard
								data-testid={`cardbox-${branch.name}`}
								key={branch.name}
								onClick={() => {
									setSelectedBranch(branch);
									setShowError(false);
								}}
								disabled={
									Boolean(repositoryBranch) &&
									branch.name === repositoryBranch
								}
								isActive={Boolean(
									selectedBranch?.name === branch.name,
								)}
							>
								<VStack justify="center" align="left" h="16">
									<Text variant="small" pl={3}>
										{branch.name}
									</Text>
								</VStack>
							</HoverableCard>
						))}
						{showError && (
							<Alert
								status="error"
								description={requiredBranch}
								isVisibleCloseBtn={false}
								minW="100%"
							/>
						)}
						{isFetchingGithubBranches ? (
							<Box textAlign="center">
								<Spinner size="sm" color="gray.400" />
							</Box>
						) : null}
					</Stack>
				) : (
					<Text pl={3} fontSize="lg" textAlign="center">
						{t(app.noItemFound, { item: app.branches })}
					</Text>
				)}
			</ModalMainContent>
		</ModalWrapper>
	);
};

export default LinkEnvToOtherBranch;

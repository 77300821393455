import { Box, Stack, Text, Image } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { cactusLogo } from '../../../@xmcloud/core/icons';
import { app, placeHolders } from '../../../@xmcloud/core/messages/en';
import { XMCSButton } from '..';

interface Props {
	title?: string;
	showCreateButton?: boolean;
	onClick?: () => void;
	isDisabled?: boolean;
	mt?: number;
	customTitle?: string;
	customSubTitle?: string;
	iconUrl?: string;
}

export const CreateItemPlaceHolder: React.FC<Props> = ({
	title,
	customTitle = '',
	customSubTitle = app.subTitlePlaceHolder,
	iconUrl = cactusLogo,
	showCreateButton = true,
	onClick = () => {},
	isDisabled = false,
	mt = 0,
}) => (
	<Stack
		direction="column"
		justifyContent="center"
		alignItems="center"
		spacing="4"
		mt={mt}
	>
		<Image boxSize="24" src={iconUrl} alt="Empty icon" />
		<Box textAlign="center">
			<Text fontSize="lg" fontWeight="semibold" mt="4" mb="3">
				{t(
					customTitle
						? customTitle
						: placeHolders.noItemCreatedYet(title || ''),
				)}
			</Text>
			<Text mt="1">{t(customSubTitle)}</Text>
		</Box>

		{showCreateButton && (
			<XMCSButton
				text={`${app.create} ${title}`}
				onClick={onClick}
				isDisabled={isDisabled}
			/>
		)}
	</Stack>
);

import {
	githubIntegrationBaseUrl,
	pagesUrl,
	xmAppsUrl,
	adoIntegrationBaseUrl,
	adoIntegrationClientId,
	adoIntegrationRedirectUrl,
	adoIntegrationScope,
} from '../../app/config/envConfig';

export const pageSize = 10;
export const HOSTEDSITE = 'hostedSite';
export const SIZE_QUERY_PARAMETER = 'size';

export const createSiteLink = (
	platformTenantName?: string,
	organizationId?: string,
) =>
	`${xmAppsUrl}?tab=sites&tenantName=${platformTenantName}&organization=${organizationId}`;

export const vercelGithub = 'https://github.com/apps/vercel';

export const sitePagesUrl = (platformTenantName: string, siteName: string) =>
	`${pagesUrl}/composer/pages/editor?tenantName=${platformTenantName}&sc_site=${siteName}`;

export const experimentalFeaturesKeys = {
	newUiExperiment: 'newUiExperiment',
} as const;

export const minWidthMd = 768;
export const minWidthSm = 480;

export const githubIntegrationUrl = (state: string) =>
	`${githubIntegrationBaseUrl}/?state=${state}&redirect_uri=${window.location.origin}/github-callback`;

export const adoIntegrationUrl = (state: string) =>
	`${adoIntegrationBaseUrl}/?client_id=${adoIntegrationClientId}&response_type=Assertion&state=${state}&redirect_uri=${adoIntegrationRedirectUrl}&scope=${adoIntegrationScope}`;

export const foundationTemplateLearnMoreUrl =
	'https://doc.sitecore.com/xmc/en/developers/xm-cloud/index-en.html?contextId=create-project-starter-foundation';

export const ownCodeLearnMoreUrl =
	'https://doc.sitecore.com/xmc/en/developers/xm-cloud/index-en.html?contextId=create-project-existing-code';

export const NEW_SOURCE_CONTROL_ACCOUNT = 'newSourceControlAccount';

export const ADO_BRANCH_PREFIX = 'refs/heads/';

export const ENVIRONMENT_NAME_VALIDATION_PATTERN =
	'^[a-zA-Z0-9][a-zA-Z0-9_!.\\- ]*$';
export enum EnvironmentClass {
	dev = 'dev',
	qa = 'qa',
	staging = 'staging',
	preprod = 'preprod',
	prod = 'prod',
}

export const siteDeveloperSettingsLink = ({
	platformTenantName = '',
	organizationId = '',
	siteName = '',
}: {
	platformTenantName?: string;
	organizationId?: string;
	siteName: string;
}) =>
	`${xmAppsUrl}/sites/${siteName}/developersettings?tab=sites&organization=${organizationId}&tenantName=${platformTenantName}&view=tiles`;

export const learnMoreUrl = 'http://www.sitecore.com/products/xm-cloud';

export const sitecoreStatusUrl = 'https://status.cloud.sitecore.net/status';

export const FORBID_CHARS = [`"`, `+`, `<`, `>`, `&`];

export const learnMoreUrlForContextId =
	'https://doc.sitecore.com/xmc/en/developers/xm-cloud/index-en.html?contextId=regenerate-context-ids';

import { useParams } from 'react-router-dom';
import { steps } from '../helpers';
import { Box, Stack } from '@chakra-ui/react';
import { ChakraStepper } from '../../../shared-components';
import { handlePush } from '../../../../@xmcloud/utils/helpers';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { useEffect } from 'react';
import _ from 'lodash';
import { useCreateProject } from '../hooks';

export const CombinedFoundationMain: React.FC = () => {
	const { step: stepParam } = useParams<{ step: string }>();

	const { formikHandleSubmit, step, values, activeStep, setActiveStep } =
		useCreateProject();

	function handleStepChange(index: number) {
		setActiveStep(index);
		handlePush(goTo.createProjectTemplate(index));
	}

	useEffect(() => {
		//param interval includes 1-length of steps
		if (_.inRange(Number(stepParam), 1, steps.length + 1)) {
			if (Number(stepParam) === activeStep) return;
			if (!values.name) {
				handleStepChange(1);
			}
			if (stepParam === '2' && !values.name) {
				handleStepChange(1);
			} else if (stepParam === '3' && !values.repository) {
				!values.name ? handleStepChange(1) : handleStepChange(2);
			} else if (Number(stepParam) >= 4 && !values.environmentName) {
				!values.name ? handleStepChange(1) : handleStepChange(3);
			} else {
				handleStepChange(Number(stepParam));
			}
		} else {
			handleStepChange(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepParam]);

	return (
		<Stack direction={['column', 'column', 'row', 'row']} pt="6">
			<Box w={['100%', '100%', '25%', '25%']} pb="4">
				<ChakraStepper
					{...{
						activeStep,
						setActiveStep,
						path: 'createProjectTemplate',
						steps,
					}}
				/>
			</Box>
			<Box w={['100%', '100%', '75%', '75%']}>
				<form
					data-testid="create-project-wizard-form"
					onSubmit={formikHandleSubmit}
				>
					{step}
				</form>
			</Box>
		</Stack>
	);
};

export interface IStatusEntry {
	data: object;
	duration: string;
	status: ComponentStatus;
	tags: string[];
	description?: string;
}

export interface IStatusRecord {
	name: string;
	status: string;
	description?: string;
}

export interface IGetStatusResponse {
	entries: Record<ComponentNames, IStatusEntry>;
	status: string;
	totalDuration: string;
}

export type ComponentNames = keyof typeof statusComponents;

export const statusComponents = {
	Builds: 'Builds',
	Configuration: 'Configuration',
	Deployments: 'Deployments',
	Inventory: 'Inventory',
	Monitoring: 'Monitoring',
	Organizations: 'Organizations',
	Pages: 'Pages',
	Provisioning: 'Provisioning',
	'Container Registry': 'Container Registry',
	GitHub: 'GitHub',
	'Azure DevOps': 'Azure DevOps',
	Cloudflare: 'Cloudflare',
	'Deploy API': 'Deploy API',
};

export const componentStatus = {
	Healthy: 'Healthy',
	Degraded: 'Degraded',
};

export type ComponentStatus = keyof typeof componentStatus;

import { IEnvironmentVariableDetails } from '../app/models/environmentModel';

export const EnvironmentVariablesMock: IEnvironmentVariableDetails[] = [
	{
		secret: false,
		name: 'baseUrl',
		value: 'http://base.url',
		target: 'CM',
	},
	{
		secret: true,
		name: 'secretVariable',
		value: 'very secret stuff',
		target: 'All',
	},
	{
		secret: false,
		name: 'environment',
		value: 'dev',
		target: 'RH1',
	},
];

export const EnvironmentVariableCMMock: IEnvironmentVariableDetails = {
	secret: false,
	name: 'environment',
	value: 'dev',
	target: 'CM',
};

export const EnvironmentVariableRHMock: IEnvironmentVariableDetails = {
	secret: false,
	name: 'environment',
	value: 'dev',
	target: 'RH123',
};

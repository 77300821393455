import { app, connections } from '../../../@xmcloud/core/messages/en';
import { XMCSH3, XMCSTabs } from '../../shared-components';
import { Text } from '@chakra-ui/react';
import { connectionsPaths } from '../../../@xmcloud/core/routes/paths';
import { Outlet, useLocation } from 'react-router-dom';
import { connectionsTabItems } from '../../shared-components/tab/items';

export const ConnectionsPage: React.FC = () => {
	const { pathname } = useLocation();
	const isHostingTabActive = pathname.includes(connectionsPaths.hosting);
	const tabIndex = isHostingTabActive ? 1 : 0;

	return (
		<>
			<XMCSH3 title={app.connections} />
			<Text variant="subtle" my={4}>
				{connections.descriptionText}
			</Text>
			<XMCSTabs
				tabIndex={tabIndex}
				tabItems={connectionsTabItems}
				state={{}}
				args={[]}
			/>
			<Outlet />
		</>
	);
};

import {
	ResponseStatusEnum,
	ResponseStatusTypes,
} from '../../../../@xmcloud/utils/helpers';

const { IDLE, LOADING, ERROR, SUCCESS } = ResponseStatusEnum;

export const handleResponseStaus = (
	s1: ResponseStatusTypes,
	s2?: ResponseStatusTypes,
	s3?: ResponseStatusTypes,
): ResponseStatusTypes =>
	s1 === IDLE && (s2 ? s2 === IDLE : true) && (s3 ? s3 === IDLE : true)
		? IDLE
		: s1 === SUCCESS &&
			  (s2 ? s2 === SUCCESS : true) &&
			  (s3 ? s3 === SUCCESS : true)
			? SUCCESS
			: s1 === ERROR || s2 === ERROR || s3 === ERROR
				? ERROR
				: LOADING;

import {
	List,
	ListItem,
	Stack,
	useClipboard,
	Tooltip,
	IconButton,
	StackProps,
	DarkMode,
} from '@chakra-ui/react';
import { mdiContentCopy } from '@mdi/js';
import { Icon, TerminalLoading, XMCSButton } from '..';
import styles from '../../../styles/scrollbar.module.css';

type Props = StackProps & {
	items: string[];
	clipboardText: string;
	isLoading?: boolean;
	showButtonItem?: boolean;
	buttonItemKey?: string;
	buttonItemValue?: string;
	onItemClick?: () => void;
	isItemLoading?: boolean;
};

export const CodeBlock: React.FC<Props> = ({
	items,
	clipboardText,
	isLoading = false,
	showButtonItem = false,
	buttonItemKey,
	buttonItemValue = '',
	isItemLoading = false,
	onItemClick,
	...rest
}) => {
	const { hasCopied, onCopy } = useClipboard('', 2000);

	return (
		<DarkMode>
			<Stack
				backgroundColor="chakra-body-bg"
				rounded="lg"
				padding="5"
				position="relative"
				{...rest}
			>
				<Tooltip
					label={hasCopied ? 'Copied' : 'Copy to clipboard'}
					closeOnClick={false}
				>
					<IconButton
						icon={<Icon path={mdiContentCopy} />}
						onClick={() => {
							navigator.clipboard.writeText(clipboardText);
							onCopy();
						}}
						aria-label="Copy"
						data-testid="copy-button"
						variant="ghost"
						size="sm"
						position="absolute"
						right="2"
						top="2"
					/>
				</Tooltip>
				{isLoading ? (
					<TerminalLoading />
				) : (
					<List
						spacing={2}
						scrollBehavior="smooth"
						overflow="auto"
						whiteSpace="nowrap"
						className={styles['custom-scrollbar']}
					>
						{items.map((item: string) => (
							<ListItem
								fontFamily="mono"
								backgroundColor="transparent"
								color="gray.100"
								key={item}
							>
								{item}
							</ListItem>
						))}
						{showButtonItem && (
							<ListItem fontFamily="mono" color="gray.100">
								{buttonItemKey}
								{buttonItemValue ? (
									buttonItemValue
								) : (
									<XMCSButton
										text="Generate API key"
										size="xs"
										onClick={onItemClick}
										isLoading={isItemLoading}
									/>
								)}
							</ListItem>
						)}
					</List>
				)}
			</Stack>
		</DarkMode>
	);
};

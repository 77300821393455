import {
	ITransferConfiguration,
	MergeStrategy,
	TransferConfigurationNodeType,
} from '../app/models/transferConfiguration';

export const TransferConfigurationMock: ITransferConfiguration = {
	transferId: 'mock-transfer-id',
	name: 'mock-transfer',
	sourceEnvironmentId: 'mock-environment-id-1',
	targetEnvironmentId: 'mock-environment-id-1',
	nodes: [
		{
			id: 'sitecore-content-id',
			item: 'Solutions',
			type: TransferConfigurationNodeType.File,
			mergeStrategy: MergeStrategy.OverrideExistingTree,
			path: '.../Collection/Site/Home/Solutions',
		},
		{
			id: 'mock-transfer-layout-controller-id-internal',
			item: 'For Customers',
			type: TransferConfigurationNodeType.File,
			mergeStrategy: MergeStrategy.OverrideExistingItem,
			path: '.../Collection/Site/Home/Partners/For Customers',
		},
		{
			id: 'mock-transfer-layout-controller-id-public-internal',
			item: 'For Companies',
			type: TransferConfigurationNodeType.File,
			mergeStrategy: MergeStrategy.OverrideExistingItem,
			path: '.../Collection/Site/Home/Partners/For Companies',
		},
		{
			id: 'sitecore-layout-id',
			item: 'Entire tree',
			type: TransferConfigurationNodeType.File,
			mergeStrategy: MergeStrategy.OverrideExistingItem,
			path: '',
		},
		{
			id: 'sitecore-layout-controllers-id',
			item: 'Site',
			type: TransferConfigurationNodeType.File,
			mergeStrategy: MergeStrategy.OverrideExistingTree,
			path: '.../Collection/Site/Home/Partners/Site',
		},
	],
};

import { FC, useState } from 'react';
import { goTo } from '../../../@xmcloud/core/routes/paths';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import { Outlet } from 'react-router-dom';
import {
	AlertDialogConfirmationModal,
	HeadingMd,
	Text,
	XMCSButton,
} from '../../shared-components';
import { app, firstStep } from '../../../@xmcloud/core/messages/en';
import { useCreateProjectV3 } from './old-components/useCreateProjectV3';
import { CreateProjectProvider } from './hooks';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
} from '@chakra-ui/react';
import styles from '../../../styles/scrollbar.module.css';

const { projects: projectsGoto } = goTo;
const { dialogTitle, dialogDesc, leave } = firstStep;
const { cancel: canceltxt } = app;

export const MainWizardCreateProject: FC = () => {
	const [isOpen, setIsOpen] = useState(true);
	const [openAlertDialog, setOpenAlertDialog] = useState(false);
	const { enableCreateProjectV3 } = useCreateProjectV3();

	function onClose() {
		enableCreateProjectV3 ? setOpenAlertDialog(true) : onConfirm();
	}
	function onConfirm() {
		setIsOpen(false);
		handlePush(projectsGoto);
	}

	return (
		<CreateProjectProvider>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size="full"
				scrollBehavior="inside"
			>
				<ModalOverlay>
					<ModalContent>
						<ModalHeader display="flex" flexDirection="row">
							<Stack w="full">
								<HeadingMd text="Create project" />
							</Stack>
							<ModalCloseButton
								as={() => (
									<XMCSButton
										text={canceltxt}
										variant="outline"
										onClick={onClose}
									/>
								)}
							/>
						</ModalHeader>
						<ModalBody className={styles['custom-scrollbar']}>
							<Outlet />
						</ModalBody>
					</ModalContent>
				</ModalOverlay>
			</Modal>
			<AlertDialogConfirmationModal
				isOpen={openAlertDialog}
				title={dialogTitle}
				onClose={() => setOpenAlertDialog(false)}
				dataTestId="confirmation-modal-to-leave-cw"
				onConfirm={onConfirm}
				leftButtonText={leave}
			>
				<Text text={dialogDesc} />
			</AlertDialogConfirmationModal>
		</CreateProjectProvider>
	);
};

import { TenantType } from '../@xmcloud/types';
import { DeployProcessStatus } from '../app/models/deploymentModel';
import {
	IEdgeCredentials,
	IEnvironmentDetails,
	IGetEnvironmentResponse,
	IGetEnvironmentResponseV2,
	IGetEnvironmentsLimitation,
	IGetRestartEnvironmentResponse,
	IRestartEnvironmentResponse,
} from '../app/models/environmentModel';
import { ICreateProjectEnvironmentResponse } from '../app/models/projectModel';

export const EnvironmentCreateMock: ICreateProjectEnvironmentResponse = {
	projectId: 'mock-project-id',
	organizationId: 'mock-org-id',
	organizationName: 'Mock organization',
	projectName: 'Mock project name',
	id: 'mock-environment-id',
	name: 'Mock environment name',
	createdBy: 'admin',
	createdAt: '2021-08-11T16:17:01.542Z',
	repositoryBranch: '',
	deployOnCommit: true,
};

export const EnvironmentMock: IGetEnvironmentResponse = {
	projectId: 'mock-project-id-1',
	organizationId: 'mock-org-id',
	organizationName: 'Mock organization',
	projectName: 'Mock project name',
	id: 'mock-environment-id-1',
	name: 'Testing',
	platformTenantName: 'anco-platform-1234',
	createdBy: 'admin',
	createdAt: '2021-08-11T16:17:01.542Z',
	host: 'testing-mock.example.com',
	provisioningStatus: DeployProcessStatus.Complete,
	lastUpdatedBy: 'admin',
	lastUpdatedAt: '2021-08-11T16:17:01.542Z',
	repositoryBranch: 'development',
	deployOnCommit: true,
	tenantType: 'nonprod',
	sitecoreMajorVersion: 1,
	sitecoreMinorVersion: 2,
	previewContextId: 'mock-preview-context-id',
	liveContextId: 'mock-live-context-id',
	platformTenantId: 'mock-platform-tenant-id',
};

export const EnvironmentMock2: IGetEnvironmentResponseV2 = {
	...EnvironmentMock,
	repositoryDetails: {
		repository: 'mock-repo',
		repositoryId: 'mock-repo-id',
		repositoryLinkedAt: '2021-08-11T16:17:01.542Z',
		sourceControlIntegrationId: 'mock-source-control-integration-id',
		sourceControlIntegrationName: 'Mock Source Control Integration',
		repositoryRelativePath: 'mock/repo/path',
	},
	editingHostEnvironmentDetails: {
		cmEnvironmentId: 'mock-environment-id-1',
	},
	previewContextId: 'mock-preview-context-id-v2',
	liveContextId: 'mock-live-context-id-v2',
	type: '',
};

export const EnvironmentRestartMock: IRestartEnvironmentResponse = {
	detail: 'Restarting environment',
	status: 202,
	instance: 'mock-instance',
	title: 'Restarting environment',
	type: 'restart',
};

export const EnvironmentRestartStatusMock: IGetRestartEnvironmentResponse = {
	createdAt: '2021-08-11T16:17:01.542Z',
	createdBy: 'admin',
	environmentId: 'mock-environment-id-1',
	id: 'mock-restart-id-1',
	organizationId: 'mock-org-id',
	restartStatus: 0,
};

export const EnvironmentsMock: IGetEnvironmentResponse[] = [
	{
		projectId: 'mock-project-id-1',
		organizationId: 'mock-org-id',
		organizationName: 'Mock organization',
		projectName: 'Mock project name',
		id: 'mock-environment-id-1',
		name: 'Testing',
		platformTenantName: 'anco-platform-1234-testing',
		createdBy: 'admin',
		createdAt: '2021-08-11T16:17:01.542Z',
		host: 'testing-mock.example.com',
		provisioningStatus: DeployProcessStatus.Complete,
		lastUpdatedBy: 'admin',
		lastUpdatedAt: '2021-08-11T16:17:01.542Z',
		repositoryBranch: '',
		deployOnCommit: true,
		tenantType: 'nonprod',
	},
	{
		projectId: 'mock-project-id-1',
		organizationId: 'mock-org-id',
		organizationName: 'Mock organization',
		projectName: 'Mock project name',
		id: 'mock-environment-id-1',
		name: 'UAT',
		platformTenantName: 'anco-platform-1234-uat',
		createdBy: 'admin',
		createdAt: '2021-08-11T16:17:01.542Z',
		host: 'uat-mock.example.com',
		provisioningStatus: DeployProcessStatus.Failed,
		lastUpdatedBy: 'admin',
		lastUpdatedAt: '2021-08-11T16:17:01.542Z',
		repositoryBranch: 'dev',
		deployOnCommit: true,
		tenantType: 'nonprod',
	},
	{
		projectId: 'mock-project-id-1',
		organizationId: 'mock-org-id',
		organizationName: 'Mock organization',
		projectName: 'Mock project name',
		id: 'mock-environment-id-1',
		name: 'Production',
		platformTenantName: 'anco-platform-1234-production',
		createdBy: 'admin',
		createdAt: '2021-08-11T16:17:01.542Z',
		host: 'production-mock.example.com',
		provisioningStatus: DeployProcessStatus.Complete,
		lastUpdatedBy: 'admin',
		lastUpdatedAt: '2021-08-11T16:17:01.542Z',
		repositoryBranch: 'master',
		deployOnCommit: true,
		tenantType: 'nonprod',
	},
];

export const EnvironmentDetailsMock: IEnvironmentDetails = {
	name: 'Testing',
	tenantType: TenantType.NonProduction,
	repositoryBranch: '',
	deployOnCommit: true,
};

export const EnvironmentsLimitationMock: IGetEnvironmentsLimitation = {
	prodCount: 1,
	prodLimit: 1,
	isProdLimitExceeds: true,
	nonProdCount: 1,
	nonProdLimit: 2,
	isNonProdLimitExceeds: false,
};

export const cmInstanceLogsTypeMocks = [
	'Client.log',
	'Crawling.log',
	'Log',
	'Owin.log',
	'Publishing.log',
	'Search.log',
	'Spe.log',
];

export const cmInstanceLogsMocks = [
	{
		name: 'Crawling.log.20230130.073002.txt',
		path: '/api/logs/v1/4jizPs8a0DZbUMelQIlxBU/Crawling.log.20230130.073002.txt',
		lastModified: '2023-01-30T07:30:32Z',
		type: 'Crawling.log',
		size: 1493,
	},
];

export const cmInstanceLogs =
	'43656 07:30:16 INFO  [Index=sitecore_core_index] Initializing SitecoreItemCrawler. DB:core / Root:/sitecore\r\n43658 07:30:17 WARN  [Index=sitecore_horizon_index] Crawling Resumed\r\n43657 07:30:17 ERROR  [Index=sitecore_core_index] Crawling Resumed\r\n ';

export const EdgeTokenResponseMock: IEdgeCredentials = {
	apiKey: '123456789',
	edgeUrl: 'http://edgetokenurl/',
};

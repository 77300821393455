import {
	Text,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Stack,
	ModalHeader,
} from '@chakra-ui/react';
import { app, createTransfer } from '../../../../@xmcloud/core/messages/en';
import { HeadingMd, XMCSButton } from '../../../shared-components';
import styles from '../../../../styles/scrollbar.module.css';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
}

const { createTransferTitle, createTransferDescription } = createTransfer;

export const MainModal: React.FC<Props> = ({ isOpen, onClose, children }) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="full"
			scrollBehavior="inside"
		>
			<ModalOverlay>
				<ModalContent>
					<ModalHeader display="flex" flexDirection="row">
						<Stack w="full">
							<HeadingMd text={createTransferTitle} />
							<Text variant="small" fontWeight="normal">
								{createTransferDescription}
							</Text>
						</Stack>
						<ModalCloseButton
							as={() => (
								<XMCSButton
									text={app.cancel}
									variant="outline"
									onClick={onClose}
								/>
							)}
						/>
					</ModalHeader>
					<ModalBody className={styles['custom-scrollbar']}>
						{children}
					</ModalBody>
				</ModalContent>
			</ModalOverlay>
		</Modal>
	);
};

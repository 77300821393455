import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { SitecoreUser } from '../auth/SitecoreUser';
import {
	resetLastSuccessfulLoginOrganizationId,
	storeLastSuccessfulLoginOrganizationId,
} from '../auth/Auth0Storage';
import { createAuthAxios } from './customAxios';

export const AxiosContext = React.createContext(axios.create());

// Needs to be used inside Auth0Context
export const AuthenticatedAxiosProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const { getAccessTokenSilently, user, logout } = useAuth0<SitecoreUser>();
	const organizationId =
		user?.['https://auth.sitecorecloud.io/claims/org_id'];

	useEffect(() => {
		storeLastSuccessfulLoginOrganizationId(organizationId);
	}, [organizationId]);

	const authAxios = createAuthAxios(axios.create());
	authAxios.interceptors.request.use(async (request) => {
		try {
			const token = await getAccessTokenSilently({
				authorizationParams: {
					organization: organizationId,
					scope: 'openid profile email offline_access',
				},
			});

			if (request && request.headers) {
				request.headers.Authorization = 'Bearer ' + token;
			}
		} catch {
			resetLastSuccessfulLoginOrganizationId();
			logout({
				returnTo: window.location.origin,
			});
		}

		return request;
	});
	return (
		<AxiosContext.Provider value={authAxios}>
			{children}
		</AxiosContext.Provider>
	);
};

export const UnauthenticatedAxiosProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const [unauthAxios] = useState(() => axios.create());
	return (
		<AxiosContext.Provider value={unauthAxios}>
			{children}
		</AxiosContext.Provider>
	);
};
export const useAuthenticatedAxios = () => {
	return React.useContext(AxiosContext);
};

import {
	Table as ChakraTable,
	HStack,
	Stack,
	TableCaption,
	TableContainer,
	TableHeadProps,
	TableProps,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { mdiArrowDown, mdiArrowUp } from '@mdi/js';
import {
	ColumnDef,
	ColumnFiltersState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	Table as ITable,
	OnChangeFn,
	PaginationState,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';
import { T } from '@transifex/react';
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';

import { TablePagination } from '..';
import { TableWrapper } from '../../../styles/tableStyles';
import { Icon } from '../icon-text/Icon';

export type TableType = ITable<unknown>;
export type TableRef = {
	current: {
		onGlobalSearch: (query: string) => void;
		onSetPageIndex: (pageIndex: number) => void;
	} | null;
};

type Props = {
	tableCaptionString?: string;
	pageSize?: number;
	tableProps?: TableProps;
	tableHeadProps?: TableHeadProps;
	pagination?: PaginationState;
	placement?: 'top' | 'bottom';
	isFetching?: boolean;
	initialSort?: SortingState;
	dataTestId?: string;
	tableData: unknown[];
	columns: ColumnDef<any>[] | any;
	showPagination?: boolean;
	pageCount?: number;
	manualPagination?: boolean;
	onPaginationChange?: OnChangeFn<PaginationState> | undefined;
	headers?: string[];
};

export const Table: React.FC<Props> = forwardRef(
	(
		{
			tableCaptionString = '',
			pageSize = 10,
			tableProps,
			tableHeadProps,
			pagination,
			placement = 'top',
			isFetching = false,
			initialSort = [{ id: 'lastUpdatedAt', desc: true }],
			dataTestId = 'data-table',
			tableData = [],
			columns = [],
			headers = [],
			showPagination = true,
			pageCount,
			manualPagination = false,
			onPaginationChange,
		},
		ref,
	) => {
		const data = useMemo(() => tableData, [tableData]);
		const [sorting, setSorting] = useState<SortingState>(initialSort);
		const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
			[],
		);
		const [globalFilter, setGlobalFilter] = useState('');

		const table = useReactTable({
			data,
			columns,
			pageCount,
			state: {
				columnFilters,
				globalFilter,
				sorting,
				...(pagination && { pagination }),
			},
			initialState: {
				sorting: initialSort,
			},
			sortDescFirst: true,
			onSortingChange: setSorting,
			onColumnFiltersChange: setColumnFilters,
			onGlobalFilterChange: setGlobalFilter,
			getCoreRowModel: getCoreRowModel(),
			getFilteredRowModel: getFilteredRowModel(),
			getSortedRowModel: getSortedRowModel(),
			manualPagination,
			...(!manualPagination && {
				getPaginationRowModel: getPaginationRowModel(),
			}),
			...(Boolean(onPaginationChange) && {
				onPaginationChange,
			}),
		});

		useEffect(() => {
			const sorting = table.getState().sorting;
			if (sorting.length === 0) {
				table.setSorting(initialSort);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [table.getState().sorting]);

		useImperativeHandle(ref, () => ({
			onGlobalSearch: (query: string) => {
				setGlobalFilter(String(query));
			},
			onSetPageIndex: (pageIndex: number) => {
				table.setPageIndex(pageIndex);
			},
		}));

		return (
			<TableWrapper {...{ headers }}>
				<TableContainer data-testid={dataTestId}>
					<ChakraTable mb={showPagination ? 0 : 32} {...tableProps}>
						{Boolean(tableCaptionString) && (
							<TableCaption placement={placement}>
								<T _str={tableCaptionString} />
							</TableCaption>
						)}
						<Thead {...tableHeadProps}>
							{table.getHeaderGroups().map((headerGroup) => (
								<Tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<Th key={header.id}>
											{header.isPlaceholder ? null : (
												<HStack
													{...{
														cursor: header.column.getCanSort()
															? 'pointer'
															: 'default',
														onClick:
															header.column.getToggleSortingHandler(),
													}}
												>
													{flexRender(
														header.column.columnDef
															.header,
														header.getContext(),
													)}
													{{
														asc: (
															<Icon
																path={
																	mdiArrowUp
																}
																boxSize="4"
																alignSelf="center"
															/>
														),
														desc: (
															<Icon
																path={
																	mdiArrowDown
																}
																boxSize="4"
																alignSelf="center"
															/>
														),
													}[
														header.column.getIsSorted() as string
													] ?? null}
												</HStack>
											)}
										</Th>
									))}
								</Tr>
							))}
						</Thead>
						<Tbody>
							{table.getRowModel().rows.map((row) => (
								<Tr key={row.id}>
									{row.getVisibleCells().map((cell) => (
										<Td
											key={cell.id}
											w={`${cell.column.getSize()} !important`}
											textAlign={
												cell.id.includes('actions')
													? 'right'
													: 'left'
											}
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext(),
											)}
										</Td>
									))}
								</Tr>
							))}
						</Tbody>
					</ChakraTable>
					{showPagination && (
						<Stack
							pt="4"
							pb={[28, 28, 4, 4]}
							direction="row"
							justifyContent="flex-end"
						>
							<TablePagination
								{...{
									disabledNextBtn: !table.getCanNextPage(),
									disabledPrevBtn:
										!table.getCanPreviousPage(),
									onNextClick: () => table.nextPage(),
									onPrevClick: () => table.previousPage(),
									currentPage:
										table.getState().pagination.pageIndex +
										1,
									totalPages: table.getPageCount(),
									isFetching,
								}}
							/>
						</Stack>
					)}
				</TableContainer>
			</TableWrapper>
		);
	},
);

import { useState, FC } from 'react';
import {
	app,
	credentials as enCredentials,
} from '../../../../@xmcloud/core/messages/en';
import {
	CardBoxList,
	ModalMainContent,
	ModalWrapper,
} from '../../../shared-components';

export interface Props {
	isOpen: boolean;
	setIsOpenOpen: (a: boolean) => void;
	setOpenEdgeModal: (a: boolean) => void;
	setOpenAutomationModal: (a: boolean) => void;
}

const CreateCredentials: FC<Props> = ({
	isOpen,
	setIsOpenOpen,
	setOpenEdgeModal,
	setOpenAutomationModal,
}) => {
	const [currentCredentials, setCurrentCredentials] = useState('');
	return (
		<ModalWrapper
			isOpen={isOpen}
			title={enCredentials.create}
			onClose={() => setIsOpenOpen(false)}
		>
			<ModalMainContent
				onClose={() => setIsOpenOpen(false)}
				rightButtonText={app.next}
				pt={4}
				pb={6}
				onConfirm={() => {
					setIsOpenOpen(false);
					if (currentCredentials === enCredentials.edge) {
						setOpenEdgeModal(true);
					}
					if (currentCredentials === enCredentials.automation) {
						setOpenAutomationModal(true);
					}
				}}
				isDisabled={!Boolean(currentCredentials)}
			>
				<CardBoxList
					{...{
						currentSelection: currentCredentials,
						setCurrentSelection: setCurrentCredentials,
						items: credentials,
						showExternalLinkIcon: false,
					}}
				/>
			</ModalMainContent>
		</ModalWrapper>
	);
};

export default CreateCredentials;

const credentials = [
	{ name: enCredentials.edge, iconName: '' },
	{ name: enCredentials.automation, iconName: '' },
];

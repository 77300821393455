import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { createEditEnvValidationSchema } from '../../../../@xmcloud/yup-validations';
import { FormikErrors, FormikState, FormikTouched, useFormik } from 'formik';
import {
	ESourceControl,
	SourceControlType,
	TenantType,
} from '../../../../@xmcloud/types';
import { EnvVariable } from '../../../features/custom-environment-variables';
import { useValidateSourceControlIntegrationV2 } from '../../../services/repositories';
import { useGetSourceControlAccessToken } from '../../../services/accessToken';
import { IGetEnvironmentResponseV2 } from '../../../models/environmentModel';
import {
	EnvironmentTypesEnum,
	SOURCE_CONTROL_INTEGRATION_ID,
} from '../../create-project/helpers';
import { useGetEnvironmentsLimitation } from '../../../services/projects';
import { useGetAllEnvironmentsLimitation } from '../../../services/environments';
import { useParams } from 'react-router-dom';
import { handleSplitAdoRepoPath } from '../helper';

export type FormikValues = {
	provider: SourceControlType;
	sourceControlIntegrationId: string;
	sourceControlIntegrationName: string;
	repository: string;
	repositoryId: string;
	environmentName: string;
	deployOnCommit: boolean;
	tenantType: TenantType;
	repositoryBranch: string;
	adoOrganization: string;
	adoProjectName: string;
	repositoryRelativePath: string;
	cmEnvironmentId: string;
	availableCmEnvironmentName: string;
	environmentVariables: EnvVariable[];
	repositoryOwner: string;
	environmentType: EnvironmentTypesEnum | '';
};

type CreateEditEnvContextType = {
	errors: FormikErrors<FormikValues>;
	values: FormikValues;
	handleChange: any;
	formikHandleSubmit: any;
	setFieldError: (field: string, message: string) => void;
	setFieldValue: (field: string, value: any) => void;
	setFieldTouched: any;
	touched: FormikTouched<FormikValues>;
	resetForm: (s?: Partial<FormikState<FormikValues>> | undefined) => void;
	setValues: (
		values: React.SetStateAction<FormikValues>,
		shouldValidate?: boolean | undefined,
	) => Promise<void> | Promise<FormikErrors<FormikValues>>;

	setTouched: any;
	setErrors: (errors: FormikErrors<FormikValues>) => void;
	isADOProvider: boolean;
	isGithubProvider: boolean;
	isLoading: boolean;
	accessToken: string;
	isIntegrationIdValidationValid: boolean;
	integrationValidationErrorMsg: string;
	isCm: boolean;
	isCreate: boolean;
	prevValues: FormikValues;
	onClose: () => void;
	initialLoading: boolean;
};

export const CreateEditEnvContext = createContext<CreateEditEnvContextType>(
	{} as CreateEditEnvContextType,
);

const { ADO, GitHub } = ESourceControl;

const providerList = {
	1: GitHub,
	2: ADO,
	'': '',
};

export const initialValues: FormikValues = {
	provider: GitHub,
	sourceControlIntegrationName: '',
	sourceControlIntegrationId: '',
	repository: '',
	repositoryId: '',
	environmentName: '',
	repositoryBranch: '',
	adoProjectName: '',
	adoOrganization: '',
	repositoryRelativePath: './',
	deployOnCommit: false,
	environmentVariables: [],
	availableCmEnvironmentName: '',
	tenantType: 0,
	cmEnvironmentId: '',
	repositoryOwner: '',
	environmentType: '',
};

export const CreateEditEnvironmentProvider: React.FC<{
	children: ReactNode;
	isCm: boolean;
	isCreate: boolean;
	environment?: IGetEnvironmentResponseV2;
	onClose: () => void;
}> = ({ children, isCm, isCreate, environment, onClose }) => {
	const { projectId } = useParams<{ projectId: string }>();
	const currentProvider = useRef('');
	const [prevValues, setPrevValues] = useState(initialValues);

	const {
		data: environmentPerProjectLimitation,
		isLoading: isEnvironmentLimitationLoading,
	} = useGetEnvironmentsLimitation({
		projectId: projectId!,
		enabled: isCreate,
	});

	const {
		data: allEnvironmentsLimitation,
		isLoading: isAllEnvironmentLimitationLoading,
	} = useGetAllEnvironmentsLimitation(isCreate);

	const validationSchema = createEditEnvValidationSchema({
		isAdoProvider: currentProvider.current === ADO,
		environmentPerProjectLimitation,
		allEnvironmentsLimitation,
		isCreate,
		isCm,
	});

	const {
		errors,
		values,
		handleChange,
		setFieldError,
		setFieldValue,
		setFieldTouched,
		setValues,
		touched,
		handleSubmit: formikHandleSubmit,
		resetForm,
		setTouched,
		setErrors,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values: FormikValues) => {},
	});
	const sourceControlIntegrationId =
		values.sourceControlIntegrationId ||
		environment?.repositoryDetails?.sourceControlIntegrationId ||
		'';

	const {
		isFetching: isSourceControlIntegrationFetching,
		isLoading: isSourceControlIntegrationLoading,
		isIntegrationIdValidationValid,
		integrationValidationErrorMsg,
	} = useValidateSourceControlIntegrationV2({
		integrationId: sourceControlIntegrationId,
		enabled: !!sourceControlIntegrationId,
	});

	const shouldCallAccessTokenApi =
		isIntegrationIdValidationValid && !isSourceControlIntegrationFetching;

	const {
		data: accessTokenData,
		isLoading: isAccessTokenLoading,
		isFetching: isAccessTokenFetching,
	} = useGetSourceControlAccessToken(
		sourceControlIntegrationId,
		shouldCallAccessTokenApi,
	);

	const accessToken = accessTokenData?.data.accessToken || '';

	const provider = accessTokenData?.data.provider || '';
	const providerName = providerList[provider as keyof typeof providerList];

	useEffect(() => {
		if (isCreate || !integrationValidationErrorMsg) return;

		setFieldError(
			SOURCE_CONTROL_INTEGRATION_ID,
			integrationValidationErrorMsg,
		);
		setFieldTouched(SOURCE_CONTROL_INTEGRATION_ID, true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [integrationValidationErrorMsg]);

	useEffect(() => {
		setValues((prev: any) => ({
			...prev,
			repositoryRelativePath: isCm
				? './authoring'
				: './headapps/nextjs-starter',
		}));
		setPrevValues((prev: any) => ({
			...prev,
			repositoryRelativePath: isCm
				? './authoring'
				: './headapps/nextjs-starter',
		}));
	}, [isCm, setValues]);

	useEffect(() => {
		if (providerName && environment && !prevValues.environmentName) {
			const isAdo = providerName === ADO;
			const repository = environment?.repositoryDetails?.repository || '';

			const { adoOrganization, adoProjectName, adoRepository } =
				handleSplitAdoRepoPath(repository);
			const updatedValues = {
				environmentName: environment.name,
				cmEnvironmentId:
					environment?.editingHostEnvironmentDetails
						?.cmEnvironmentId || '',
				repository: repository,
				repositoryId: environment?.repositoryDetails?.repositoryId,
				repositoryBranch: environment?.repositoryBranch,
				deployOnCommit: environment.deployOnCommit,
				tenantType: environment.tenantType,
				sourceControlIntegrationId:
					environment?.repositoryDetails
						?.sourceControlIntegrationId || '',
				sourceControlIntegrationName:
					environment?.repositoryDetails
						?.sourceControlIntegrationName,
				repositoryOwner:
					environment?.repositoryDetails
						?.sourceControlIntegrationName,
				repositoryRelativePath:
					environment?.repositoryDetails?.repositoryRelativePath ||
					'./',
				environmentType: environment.type,
				provider: providerName,
				...(isAdo && {
					adoOrganization,
					adoProjectName,
					repository: adoRepository,
				}),
			};
			setValues((prev: any) => ({
				...prev,
				...updatedValues,
			}));
			setPrevValues((prev: any) => ({ ...prev, ...updatedValues }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [environment, providerName]);

	currentProvider.current = values.provider;

	const isLoading =
		isSourceControlIntegrationFetching ||
		isAccessTokenFetching ||
		isEnvironmentLimitationLoading ||
		isAllEnvironmentLimitationLoading;

	const initialLoading =
		(isEnvironmentLimitationLoading ||
			isAllEnvironmentLimitationLoading ||
			isSourceControlIntegrationLoading ||
			isAccessTokenLoading) &&
		!prevValues.environmentName;

	return (
		<CreateEditEnvContext.Provider
			value={{
				errors,
				values,
				handleChange,
				formikHandleSubmit,
				setFieldError,
				setFieldValue,
				setFieldTouched,
				touched,
				resetForm,
				setValues,
				setTouched,
				setErrors,
				isADOProvider: values.provider === ADO,
				isGithubProvider: values.provider === GitHub,
				isLoading,
				accessToken,
				isIntegrationIdValidationValid,
				integrationValidationErrorMsg,
				isCm,
				isCreate,
				prevValues,
				onClose,
				initialLoading,
			}}
		>
			{children}
		</CreateEditEnvContext.Provider>
	);
};

export function useCreateEditEnvContext(): CreateEditEnvContextType {
	const context = useContext(CreateEditEnvContext);

	if (context === undefined) {
		throw new Error(
			'useCreateEditEnv must be used within a CreateEditEnvironmentProvider',
		);
	}

	return context;
}

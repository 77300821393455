import {
	app,
	environment,
	environments,
} from '../../../../@xmcloud/core/messages/en';
import { useRestartEnvironmentMutation } from '../../../services/environments';
import {
	ModalWrapper,
	ModalMainContent,
	Text,
} from '../../../shared-components';
import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';

const {
	restart,
	restartInitiated,
	restartDialogConfirm,
	restartDialogCancel,
	restartDialogDescription,
} = environment;

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
	environmentId: string;
	onSuccess?: (data: any) => void;
	onSubmit?: () => void;
	onError?: (e: AxiosError) => void;
}

const RestartEnvironment: React.FC<Props> = ({
	openModal,
	setOpenModal,
	environmentId,
	onSuccess,
	onSubmit,
	onError,
}) => {
	const { mutate, isLoading } = useRestartEnvironmentMutation(
		environmentId,
		onError,
	);

	const toast = useToast();

	const handleSubmit = () => {
		onSubmit && onSubmit();
		mutate(
			{ environmentId },
			{
				onSuccess: (data) => {
					toast({
						title: restart,
						status: 'success',
						description: restartInitiated,
						duration: 5000,
					});

					setOpenModal(false);
					onSuccess && onSuccess(data);
				},
			},
		);
	};

	return (
		<ModalWrapper
			isOpen={openModal}
			onClose={() => setOpenModal(false)}
			title={`${app.restart} ${environments.environment}`}
		>
			<ModalMainContent
				analyticsId={'restart-environment-form'}
				onClose={() => setOpenModal(false)}
				rightButtonText={restartDialogConfirm}
				leftButtonText={restartDialogCancel}
				colorScheme="primary"
				isLoading={isLoading}
				onConfirm={handleSubmit}
			>
				<Text text={restartDialogDescription} />
			</ModalMainContent>
		</ModalWrapper>
	);
};

export default RestartEnvironment;

import { IContentTreeNode } from '../../../../../models/contentTreeNode';

export const mapTree = (item: IContentTreeNode) => {
	const node: TreeNode = {
		key: item.id,
		label: item?.item,
		type: item.type,
		isBranch: false,
		path: item.path,
		descendants: item?.children?.map((ch) => {
			return mapTree(ch);
		}),
	};

	return node;
};

export const extendedNodes = (nodes: TreeNode[]): TreeNode[] =>
	nodes.reduce((acc: TreeNode[], childNode) => {
		acc.push({
			...childNode,
			leaf: !childNode.descendants,
			checked: false,
			intermediate: false,
		});
		if (childNode.descendants) {
			const ns = childNode.descendants.reduce(
				(a: TreeNode[], n: TreeNode) => {
					acc.push({
						...n,
						leaf: !n.descendants,
						checked: false,
						intermediate: false,
					});
					if (n.descendants) a.push(...extendedNodes(n.descendants));
					return a;
				},
				[],
			);
			acc.push(...ns);
		}
		return acc;
	}, []);

export const findDescendantKeys = (node: TreeNode): string[] =>
	(node.descendants ?? []).reduce((acc: string[], childNode: TreeNode) => {
		acc.push(childNode.key);
		if (childNode.descendants) acc.push(...findDescendantKeys(childNode));
		return acc;
	}, []);

export const getExpandedKeys = (nodes: TreeNode[]): TreeExpandedKeys =>
	nodes.reduce((acc: TreeExpandedKeys, node: TreeNode) => {
		if (node.descendants) {
			acc[node.key] = true;
			acc = {
				...acc,
				...getExpandedKeys(node.descendants),
			};
		}
		return acc;
	}, {});

export const nodesToSelectionKeys = (nodes: TreeNode[]): TreeSelectionKeys =>
	nodes.reduce((acc: TreeSelectionKeys, node: TreeNode) => {
		if (node.descendants?.length) {
			const desc = nodesToSelectionKeys(node.descendants);
			acc = { ...acc, ...desc };
		}
		acc[node.key] = node;

		return acc;
	}, {});

export const prepareData = (nodes: IContentTreeNode[]): TreeNode[] => {
	return nodes.map((item) => mapTree(item));
};

import { IPromoteDeploymentResponse } from '../app/models/deploymentModel';
import {
	IRepositoryIntegrationDetails,
	IRepositoryTemplateDetails,
	IRepositoryProviderDetails,
	ICreateRepositoryResponse,
	ICreateRepositoryPayload,
	IRepositoryData,
	IValidateRepositoryPayload,
	IValidateRepositoryResponse,
	IValidateIntegrationPayload,
	IRepositoryDetails,
} from '../app/models/repositoriesModel';

export const GithubIntegrationMock: IRepositoryIntegrationDetails = {
	account: 'Account 1',
	id: '1',
	provider: 'GitHub',
};

export const GitHubInstallationStateMock: string =
	'18J5LVDLUly6wWXsLnMCQbcndIJsfxCyt5ETtr6fNSkhQinSfPiAdz8vPdQJdmbIvdOe6k2gYJOuW3WndluI9psypTYS6G29akn7WSuEgEMN';

export const GithubIntegrationsMock: IRepositoryIntegrationDetails[] = [
	{
		account: 'Account-1',
		id: 'test-integration-id-1',
		provider: 1,
		createdAt: '2023',
		createdBy: 'user.dk@sitecore.com',
	},
	{
		account: 'test-integration-name-1',
		id: 'test-integration-name-1',
		provider: 'GitHub',
		createdAt: '2023',
		createdBy: 'user.dk@sitecore.com',
	},
	{
		account: 'Account-2',
		id: 'test-integration-id-2',
		provider: 'GitHub',
		createdAt: '2023',
		createdBy: 'other.user@sitecore.com',
		projectIds: ['mock-project-id-1'],
	},
	{
		account: 'Account-ado-1',
		id: 'test-integration-id-3',
		provider: 'ADO',
		createdAt: '2023',
		createdBy: 'other.user@sitecore.com',
	},
];

export const ADOIntegrationMock: IRepositoryIntegrationDetails = {
	account: 'Account-ado-1',
	id: 'test-integration-id-3',
	provider: 'ADO',
	createdAt: '2023',
	createdBy: 'other.user@sitecore.com',
};

export const RepositoryTemplatesMock: IRepositoryTemplateDetails[] = [
	{
		name: 'Basic template',
		url: 'http://some/repo/template',
		title: 'test title',
		owner: 'test owner',
	},
];

export const RepositoryProvidersMock: IRepositoryProviderDetails[] = [
	{
		name: 'ado',
		displayName: 'Azure DevOps',
		logo: 'https://www.azuredevopslabs.com/images/vsts-1-42.png',
		validationPattern: '^[A-Za-z0-9_.-]{1,100}$',
	},
	{
		name: 'github',
		displayName: 'GitHub',
		logo: 'http://some/repo/provider/logo',
		validationPattern: '^[A-Za-z0-9_.-]{1,100}$',
	},
];

export const CreateRepoRequestPayloadMock: ICreateRepositoryPayload = {
	integrationId: 'test-id',
	owner: 'test account',
	repositoryName: 'test name',
	templateRepository: 'test template',
	templateOwner: 'test owner',
};

export const CreateRepoResponseMock: ICreateRepositoryResponse = {
	name: 'test repo',
	fullName: 'test repo full name',
	owner: 'test user account',
	url: 'http://test/repo/url',
	defaultBranch: 'master',
	id: 'test-repo-id',
};

export const EnvironmentDeploymentPromoteResponseMock: IPromoteDeploymentResponse =
	{
		createdAt: '2023',
		createdBy: 'user',
		environmentId: 'mock-environment-id-1',
		id: 'deploymentId',
		organizationId: 'org_test_123',
		projectId: 'projectId',
	};

export const RepositoriesMock: IRepositoryData[] = [
	{
		id: 'test-repo',
		name: 'test-repo',
	},
	{
		id: 'another-test-repo',
		name: 'another-test-repo',
	},
];

export const RepositoryMock: IRepositoryDetails = {
	accountName: 'test account',
	integrationId: 'test integration id',
	name: 'test repo',
	provider: 'test provider',
	validationPattern: '^[A-Za-z0-9_.-]{1,100}$',
};

export const ValidateRepositoryPayloadMock: IValidateRepositoryPayload = {
	repositoryName: 'test',
	integrationId: 'test-id',
};

export const ValidateRepositoryResponseMock: IValidateRepositoryResponse = {
	isValid: true,
	message: '',
};

export const ValidateIntegrationPayloadMock: IValidateIntegrationPayload = {
	integrationId: 'test-id',
};

import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { environment } from '../../../../@xmcloud/core/messages/en';
import { pageSize } from '../../../../@xmcloud/utils/constants';
import { deployStatusUpdateInterval } from '../../../config/envConfig';
import { useGetEnvironmentDeploymentList } from '../../../services/environments';
import {
	AlertStatus,
	LoadingSkeleton,
	RenderPlaceholder,
	Table,
} from '../../../shared-components';
import {
	useDeploymentsTableColumns,
	TDeployments,
} from '../columns/deployments';
import CancelDeployment from '../deployment-logs/CancelDeployment';
import DeployBuildOtherEnv from '../components/DeployBuildOtherEnv';
import { status } from '../deployment-logs/handlers';
import { ColumnDef } from '@tanstack/react-table';
import { useProjectDetails } from '../../../../@xmcloud/hooks';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import { compareAsc } from 'date-fns';

const { deployment: deploymenttxt, repositoryChanged: repositoryChangedtxt } =
	environment;

const headers = ['name', 'Latest deployment', 'Status', ''];

const DeploymentsTable: FC = () => {
	const { projectId, environmentId } = useParams<{
		projectId: string;
		environmentId: string;
	}>();
	const [showCancelDeploymentModal, setshowCancelDeploymentModal] =
		useState(false);
	const [deploymentId, setDeploymentId] = useState('');
	const [deployToOtherEnvModal, setDeployToOtherEnvModal] = useState(false);

	const {
		isLoading: isProjectDetailsLoading,
		projectName,
		repositoryLinkedAt,
	} = useProjectDetails(projectId!);

	const { data, isLoading: isDeploymentsLoading } =
		useGetEnvironmentDeploymentList({
			environmentId: environmentId!,
			projectId: projectId!,
			refetchInterval: parseInt(deployStatusUpdateInterval, 10),
			refetchIntervalInBackground: true,
		});

	const deployments = useMemo(() => {
		const allDeployments = data?.data || [];

		return allDeployments.map((d) => ({
			...d,
			status: Object.values(status)[d.calculatedStatus],
		}));
	}, [data?.data]);

	const columns: ColumnDef<TDeployments, any>[] = useDeploymentsTableColumns(
		setDeploymentId,
		setDeployToOtherEnvModal,
		setshowCancelDeploymentModal,
	);
	const isLoading = isProjectDetailsLoading || isDeploymentsLoading;

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;
	if (deployments.length === 0)
		return <RenderPlaceholder item={deploymenttxt.toLocaleLowerCase()} />;

	const lastDeployment = deployments[deployments.length - 1];
	const lastDeploymentDate = lastDeployment?.createdAt;
	const lastRepoChangeDate =
		repositoryLinkedAt && getReadableDate(repositoryLinkedAt, 'PPp');

	const result = compareAsc(repositoryLinkedAt, lastDeploymentDate);
	//if result is greater than 0, repository changed after last deployment
	const showInfoAlert = result > 0;

	return (
		<>
			{!!showInfoAlert && (
				<AlertStatus
					description={repositoryChangedtxt}
					status="info"
					data-testid="repo-changed-info-alert"
					boldText={projectName}
					variableTxt2={lastRepoChangeDate}
					mb={4}
				/>
			)}
			<Table
				{...{
					columns,
					tableData: deployments,
					dataTestId: 'deployment-table',
					initialSort: [{ id: 'createdAt', desc: true }],
					showPagination: deployments.length > pageSize,
					headers,
				}}
			/>
			{showCancelDeploymentModal && (
				<CancelDeployment
					environmentId={environmentId!}
					deploymentId={deploymentId}
					openModal={showCancelDeploymentModal}
					setOpenModal={setshowCancelDeploymentModal}
				/>
			)}
			{deployToOtherEnvModal && (
				<DeployBuildOtherEnv
					openModal={deployToOtherEnvModal}
					setOpenModal={setDeployToOtherEnvModal}
					deploymentId={deploymentId}
					environmentId={environmentId!}
					projectId={projectId!}
				/>
			)}
		</>
	);
};

export default DeploymentsTable;

import {
	FormControl,
	FormLabel,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Tooltip,
	useClipboard,
} from '@chakra-ui/react';
import { Icon } from '../icon-text/Icon';
import { mdiContentCopy } from '@mdi/js';

export const CopyFieldFormControl: React.FC<{
	value: string;
	label: string;
}> = ({ value, label }) => {
	const { hasCopied, onCopy } = useClipboard(value);
	return (
		<FormControl>
			<FormLabel>{label}</FormLabel>
			<InputGroup>
				<Input isReadOnly value={value} />
				<InputRightElement>
					<Tooltip
						label={hasCopied ? 'Copied' : 'Copy to clipboard'}
						closeOnClick={false}
					>
						<IconButton
							onClick={onCopy}
							variant="ghost"
							icon={<Icon path={mdiContentCopy} />}
							size="sm"
							aria-label="Copy to clipboard"
							data-testid="btn-copy-clipboard"
						/>
					</Tooltip>
				</InputRightElement>
			</InputGroup>
		</FormControl>
	);
};

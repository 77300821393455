import { ESourceControl } from '../../../../@xmcloud/types';
import {
	createCombinedProject,
	secondStep,
} from '../../../../@xmcloud/core/messages/en';
import { HeadingLg, ToggleStack } from '../../../shared-components';
import { IS_BYOC, LINK_ENV_TO_DEFAULT_BRANCH, REPOSITORY } from '../helpers';
import { foundationTemplateLearnMoreUrl } from '../../../../@xmcloud/utils/constants';
import { Stack, Tab, TabList, Text } from '@chakra-ui/react';
import { OLDExistingGithubConnection } from './OLDExistingGithubConnection';
import { OLDExistingADOConnection } from './OLDExistingADOConnection';
import { Microcopy } from './Text';
import { useCreateProject } from '../hooks';

const { combined, owncode } = createCombinedProject;
const { title, title2, usetemplate, useOwnCode, desc2, desc } = secondStep;
const { GitHub, ADO } = ESourceControl;

export const OLDSetupRepository: React.FC = () => {
	const {
		values: { provider, isByoc },
		setValues,
	} = useCreateProject();

	const isGithubConnection = provider === GitHub;
	const isADOConnection = provider === ADO;

	const pageTitle = isByoc ? title2 : title;
	const subTitle = isByoc ? desc2 : desc;

	return (
		<Stack>
			<HeadingLg text={pageTitle} pb="2" />
			<Text>{subTitle}</Text>
			<Stack pt="3" spacing={0}>
				<ToggleStack defaultIndex={isByoc ? 1 : 0}>
					<TabList>
						<Tab
							onClick={() => {
								setValues((prev) => ({
									...prev,
									[IS_BYOC]: false,
									[LINK_ENV_TO_DEFAULT_BRANCH]: true,
									[REPOSITORY]: '',
								}));
							}}
							data-testid="use-xmcloud-template-btn"
							key="use-xmcloud-template-btn"
						>
							{usetemplate}
						</Tab>
						<Tab
							onClick={() => {
								setValues((prev) => ({
									...prev,
									[IS_BYOC]: true,
									[LINK_ENV_TO_DEFAULT_BRANCH]: false,
									[REPOSITORY]: '',
								}));
							}}
							data-testid="use-own-code-btn"
							key="use-own-code-btn"
						>
							{useOwnCode}
						</Tab>
					</TabList>
				</ToggleStack>
				<Microcopy
					text={isByoc ? owncode : combined}
					href={foundationTemplateLearnMoreUrl}
				/>
			</Stack>

			{isGithubConnection && <OLDExistingGithubConnection />}
			{isADOConnection && <OLDExistingADOConnection />}
		</Stack>
	);
};

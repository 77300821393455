import { useParams } from 'react-router-dom';
import { useProjectDetails } from '../../@xmcloud/hooks';
import EditCreateEnvironmentForm from './EditCreateEnvironmentForm';
import { useOLDEnvironmentCreation } from '../pages/create-project/old-components/useOLDEnvironmentCreation';

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
}

const CreateCombinedEnvironment: React.FC<Props> = ({
	openModal,
	setOpenModal,
}) => {
	const { projectId } = useParams<{ projectId: string }>();
	const { isLoading, onEnvironmentCreation, shouldReTryEnvironmentCreation } =
		useOLDEnvironmentCreation({ setOpenModal });
	const { sourceControlIntegrationId } = useProjectDetails(projectId!);
	const shouldCreateDeploy = Boolean(sourceControlIntegrationId);

	const handleCreateEnvironment = (values: any) => {
		onEnvironmentCreation(projectId!, values, shouldCreateDeploy);
	};

	return (
		<EditCreateEnvironmentForm
			{...{
				openModal,
				setOpenModal,
				onSubmit: handleCreateEnvironment,
				isLoading,
				shouldRetry: shouldReTryEnvironmentCreation,
			}}
		/>
	);
};

export default CreateCombinedEnvironment;

import { AxiosError } from 'axios';
import { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetEnvironmentRenderingHostsAndSites } from '../../app/services/environments';
import { useGetHostingProjectsList } from '../../app/services/hosting';
import { useEnvironmentDetails } from './useEnvironmentDetails';

export function useSites(cmEnvironmentId = '') {
	const { environmentId } = useParams<{ environmentId: string }>();
	const { environment } = useEnvironmentDetails(
		Boolean(cmEnvironmentId) ? cmEnvironmentId : environmentId || '',
	);
	const [error, setError] = useState(false);
	const counter = useRef(0);
	const refetchInterval = useRef(30000);

	const allSitesOnError = (err: unknown) => {
		const e = err as AxiosError;
		if (e.message === 'Network Error') {
			setError(true);
		}
	};

	const { data: allSitesData, isLoading: allSitesIsLoading } =
		useGetEnvironmentRenderingHostsAndSites({
			host: environment?.host,
			enabled: Boolean(environment?.host),
			onError: allSitesOnError,
			refetchInterval: refetchInterval.current,
			refetchOnWindowFocus: true,
		});

	const { data: hostedSitesData, isLoading: hostedSitesIsLoading } =
		useGetHostingProjectsList({
			environmentId: environment?.id,
			enabled: Boolean(environment?.id),
			refetchInterval: refetchInterval.current,
			refetchOnWindowFocus: true,
			onSuccess: onSuccessSites,
		});

	function onSuccessSites() {
		counter.current++;
		if (counter.current === 100) {
			refetchInterval.current = refetchInterval.current * 2;
		}
	}

	const sites = useMemo(() => {
		const sites = allSitesData?.data?.data.solutionSites || [];
		const edges = allSitesData?.data?.data.languages?.edges || [];
		const hostedSites = hostedSitesData?.data || [];
		const sitesWithHosting = sites.map((site) => {
			const hostedSite = hostedSites.find(
				(hosted) => hosted.siteId === site.id,
			);
			return { ...site, edges, ...(hostedSite && { hostedSite }) };
		});

		return sitesWithHosting;
	}, [hostedSitesData, allSitesData]);
	const isLoading = allSitesIsLoading || hostedSitesIsLoading;
	return {
		sites,
		isLoading,
		environment,
		error,
	};
}

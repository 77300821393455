import {
	TADOBranches,
	TGetADOOrganizations,
	TGetADOProfile,
	TGetADOProjects,
	TGetADORepositories,
	TGetGithubBranches,
	TGetGithubRepositories,
} from '../app/models/externalApi';

export const GithubRepositoryMock: TGetGithubRepositories = [
	{
		id: '123lsixu',
		name: 'test-repo',
		owner: {},
	},
];

export const GithubBranchesMock: TGetGithubBranches = [
	{
		name: 'test-branch',
		commit: {},
		protected: false,
	},
	{
		name: 'master',
		commit: {},
		protected: false,
	},
	{
		name: 'development',
		commit: {},
		protected: false,
	},
	{
		name: 'testing',
		commit: {},
		protected: false,
	},
];

export const ADOProfile: TGetADOProfile = {
	id: '00000000-0000-0000-0000-000000000000',
	displayName: 'mock_user_name',
	publicAlias: 'Test-User',
	emailAddress: '',
	coreRevision: 1,
	timeStamp: '2021-09-30T08:50:00.0000000Z',
	revision: 1,
};

export const ADOOrganizations: TGetADOOrganizations = {
	count: 1,
	value: [
		{
			accountId: '00000000-0000-0000-0000-000000000000',
			accountUri: 'https://dev.azure.com/mock_account',
			accountName: 'mock_account',
			properties: {},
		},
	],
};

export const ADOProjects: TGetADOProjects = {
	count: 1,
	value: [
		{
			id: '00000000-0000-0000-0000-000000000000',
			name: 'mock_project',
			url: 'https://dev.azure.com/mock_account/mock_project',
			state: 'wellFormed',
			revision: 1,
			visibility: 'private',
			lastUpdateTime: '2021-09-30T08:50:00.0000000Z',
		},
	],
};

export const ADORepositories: TGetADORepositories = {
	value: [
		{
			id: '00000000-0000-0000-0000-000000000000',
			name: 'mock_repository',
			url: 'https://dev.azure.com/mock_account/mock_project/_git/mock_repository',
			project: ADOProjects.value[0],
		},
	],
};

export const ADOBranches: TADOBranches = {
	value: [
		{
			name: 'mock_branch',
			objectId: '0000',
			creator: {},
			url: 'https://dev.azure.com/mock_account/mock_project/_git/mock_repository?version=GBmock_branch',
		},
	],
};

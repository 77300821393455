import { Link, MenuItem, MenuList, Text, Tooltip } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { app, sites } from '../../../../@xmcloud/core/messages/en';
import { HOSTEDSITE, sitePagesUrl } from '../../../../@xmcloud/utils/constants';
import {
	IGetEnvironmentResponse,
	ISite,
} from '../../../models/environmentModel';
import { RowActions } from '../../../shared-components';
import { createColumnHelper } from '@tanstack/react-table';
import { ESourceControl } from '../../../../@xmcloud/types';
import { convertProviderEnum } from '../../../../@xmcloud/utils/helpers';

const columnHelper = createColumnHelper<ISite>();

export const sitesTableColumns = (
	environment: IGetEnvironmentResponse,
	setSelectedSite: (s: ISite) => void,
	setOpenLinkToVercelModal: (v: boolean) => void,
	setOpenUnlinkSiteConfirmation: (v: boolean) => void,
	scProvider: string | number,
) => {
	return [
		columnHelper.accessor('name', {
			header: () => t(app.name),
			cell: (info) => (
				<Link
					href={sitePagesUrl(
						environment?.platformTenantName,
						info.getValue(),
					)}
					isExternal
					boxShadow="none !important"
					noOfLines={2}
					whiteSpace="wrap"
				>
					{info.getValue()}
				</Link>
			),
		}),
		columnHelper.accessor((row) => row?.hostedSite?.siteId, {
			id: 'siteId',
			header: () => t(sites.hostingUrl),
			cell: ({ row: { original: site } }) => {
				const isHostedSite = Object.keys(site).includes(HOSTEDSITE);
				if (!isHostedSite) return <Text>{sites.noSetup}</Text>;

				const url = site[HOSTEDSITE]?.host;

				return (
					<Link href={url} isExternal outline="none !important">
						{url}
					</Link>
				);
			},
			enableSorting: false,
		}),
		columnHelper.display({
			id: 'actions',
			cell: ({ row: { original: site } }) => {
				const isHostedSite = Object.keys(site).includes(HOSTEDSITE);
				const isAdoProvider =
					convertProviderEnum(scProvider, 'string') ===
					ESourceControl.ADO.toLowerCase();
				return (
					<RowActions
						ariaLabel="sites-table-action-menu"
						data-testid="sites-action-menu-button"
					>
						<MenuList>
							<Tooltip
								label={t(sites.adoNotSupportVercel)}
								isDisabled={!isAdoProvider}
								shouldWrapChildren
							>
								<MenuItem
									isDisabled={isAdoProvider}
									onClick={() => {
										setSelectedSite(site);
										isHostedSite
											? setOpenUnlinkSiteConfirmation(
													true,
												)
											: setOpenLinkToVercelModal(true);
									}}
									minW="3xs"
								>
									{t(
										isHostedSite
											? sites.unlinkHost
											: sites.setupHost,
									)}
								</MenuItem>
							</Tooltip>
						</MenuList>
					</RowActions>
				);
			},
			maxSize: 0,
		}),
	];
};

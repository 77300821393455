import { Stack, StackProps } from '@chakra-ui/react';

type Props = StackProps;
export const RowStack: React.FC<Props> = ({ children, my = 4, ...rest }) => (
	<Stack
		direction="row"
		justify="space-between"
		my={my}
		alignItems="center"
		{...rest}
	>
		{children}
	</Stack>
);

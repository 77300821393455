import { FormLabel, FormControl as ChakraFormControl } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { Text } from '../';

export const FormControl: React.FC<{
	isRequired: boolean;
	label: string;
	children: React.ReactNode;
	error: string;
}> = ({ isRequired = false, label = '', children, error }) => (
	<ChakraFormControl isRequired={isRequired}>
		<FormLabel>{t(label)}</FormLabel>
		{children}
		{!!error && <Text pt="1.5" text={error} color="red" />}
	</ChakraFormControl>
);

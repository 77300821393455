import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { t } from '@transifex/native';
import {
	fetchLastSuccessfulLoginOrganizationId,
	ORGANIZATION,
} from '../../auth/Auth0Storage';

interface Props {
	items: { name: string; to?: string }[];
}

export const XMCSBreadCrumb: React.FC<Props> = ({ items }) => {
	const orgId = fetchLastSuccessfulLoginOrganizationId();
	return (
		<Breadcrumb mb="2">
			{items.map(({ name, to }) => (
				<BreadcrumbItem
					{...(items[items.length - 1].name === name && {
						isCurrentPage: true,
					})}
					key={to}
					data-testid={`${name}-breadcrumb-item`}
				>
					<BreadcrumbLink
						as={Link}
						to={to ? `${to}?${`${ORGANIZATION}=${orgId}`}` : '#'}
						maxW="3xs"
						noOfLines={1}
					>
						{t(name)}
					</BreadcrumbLink>
				</BreadcrumbItem>
			))}
		</Breadcrumb>
	);
};

import {
	Button,
	ButtonProps,
	Menu,
	MenuButton,
	MenuList,
} from '@chakra-ui/react';
import { t } from '@transifex/native';
import { FC, ReactNode } from 'react';
import { mdiChevronDown } from '@mdi/js';
import { Icon } from '..';

type Props = {
	text: string;
	children: ReactNode;
	isLoading?: boolean;
} & ButtonProps;

export const XMCSMenuButton: FC<Props> = ({
	text,
	isLoading = false,
	children,
	...rest
}) => {
	return (
		<Menu>
			<MenuButton
				as={Button}
				variant="solid"
				rightIcon={
					<Icon path={mdiChevronDown} layerStyle="menuButtonIcon" />
				}
				size="sm"
				{...rest}
				isLoading={isLoading}
			>
				{t(text)}
			</MenuButton>
			<MenuList zIndex={3}>{children}</MenuList>
		</Menu>
	);
};

import { t } from '@transifex/native';
import { Text } from '@chakra-ui/react';
import { InfoIcon } from '..';
import { isPhoneDevice } from '../../../@xmcloud/utils/helpers';

export const TextInfoIcon: React.FC<{
	text: string;
	tooltipText: string;
	onClick: () => void;
	isDisabled?: boolean;
}> = ({ text, tooltipText, onClick, isDisabled }) => (
	<Text variant="subtle" fontWeight="semibold">
		{t(text)}
		<InfoIcon
			{...{
				label: tooltipText,
				ml: 1.5,
				onClick: () => isPhoneDevice && onClick(),
				isDisabled,
			}}
		/>
	</Text>
);

import { GenericErrorTemplateProps } from './model';
import { Container, Box, Flex, Image, Heading, Text } from '@chakra-ui/react';
import { variants } from './resources';

export const GenericErrorTemplate: React.FC<GenericErrorTemplateProps> = ({
	title,
	subtext,
	text,
	variant,
	shortcuts,
}) => {
	const activeVariant = variants[variant];

	return (
		<Container pt={9} py={8} pb={12} height="100%" width="100%">
			<Container maxW="sm" width="sm" pt={24}>
				<Flex
					justify="center"
					flexDirection="column"
					alignItems="center"
				>
					<Box
						borderRadius="2xl"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<Image
							width={28}
							height={28}
							src={activeVariant.illustration.colored}
							alt={activeVariant.label}
						/>
					</Box>
					<Heading textAlign="center" pt="8">
						{title || activeVariant.label}
					</Heading>
					<Text textAlign="center" variant="subtle" pt="2">
						{subtext || activeVariant.error}
					</Text>
					<Text
						textAlign="center"
						py="6"
						size="md"
						color="chakra-body-text"
					>
						{text}
					</Text>
					{shortcuts && (
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
						>
							{shortcuts}
						</Box>
					)}
				</Flex>
			</Container>
		</Container>
	);
};

import {
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Stack,
	Switch,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, useState } from 'react';
import { app } from '../../../@xmcloud/core/messages/en';
import { isPhoneDevice } from '../../../@xmcloud/utils/helpers';
import { AlertDialogConfirmationModal } from '../modals/AlertDialogConfirmationModal';
import { InfoIcon, Text } from '..';
interface Props {
	error?: string | undefined;
	isInvalid?: boolean;
	isRequired?: boolean;
	label: string;
	name: string;
	isChecked: boolean;
	onChange?: (e: any) => void;
	dataTestId?: string;
	onFocus?: () => void;
	isDisabled?: boolean;
	mt?: number;
	showInfoIcon?: boolean;
	infoText?: string;
	helperText?: string;
}
export const SwitchFormControl: FC<Props> = ({
	error,
	isInvalid,
	isRequired = false,
	label,
	name,
	isChecked = false,
	onChange,
	dataTestId = `switch-element-${name}`,
	onFocus,
	isDisabled = false,
	mt = 0,
	showInfoIcon = false,
	infoText = '',
	helperText = '',
}) => {
	const [openInfo, setOpenInfo] = useState(false);
	return (
		<>
			<FormControl {...{ isInvalid, isRequired, mt }}>
				<Stack direction="row" spacing={-1}>
					<FormLabel>
						<T _str={label} />
					</FormLabel>
					{showInfoIcon && (
						<InfoIcon
							{...{
								isDisabled: isPhoneDevice,
								label: infoText,
								onClick: () =>
									isPhoneDevice && setOpenInfo(true),
							}}
						/>
					)}
				</Stack>
				<Switch
					{...{ name, isChecked, onChange, onFocus, isDisabled }}
					data-testid={dataTestId}
				/>
				<FormErrorMessage>{error}</FormErrorMessage>
				{!!helperText && <FormHelperText>{helperText}</FormHelperText>}
			</FormControl>
			{openInfo && (
				<AlertDialogConfirmationModal
					{...{
						isOpen: openInfo,
						onClose: () => setOpenInfo(false),
						shouldShowActionBtn: false,
						rightButtonText: app.close,
					}}
				>
					<Text mt="4" text={infoText} />
				</AlertDialogConfirmationModal>
			)}
		</>
	);
};

import { Button, ButtonProps } from '@chakra-ui/react';
import { t } from '@transifex/native';

interface Props extends ButtonProps {
	text: string;
	analyticsId?: string;
}

export const XMCSButton: React.FC<Props> = ({ analyticsId, text, ...rest }) => (
	<Button
		size="sm"
		minW="unset"
		data-behavior-analytics-id={analyticsId && `btn-${analyticsId}`}
		{...rest}
		data-testid={`btn-${text}`}
	>
		{t(text)}
	</Button>
);

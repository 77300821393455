import { FC, useState } from 'react';
import { AlertDialogConfirmationModal } from '../../../shared-components';
import { Box, Checkbox, Text, useToast } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { useDeleteHostingProjectMutation } from '../../../services/hosting';
import { sites } from '../../../../@xmcloud/core/messages/en';
import { ISite } from '../../../models/environmentModel';

interface Props {
	setOpenModal: (show: boolean) => void;
	openModal: boolean;
	site: ISite;
}

const UnlinkSiteFromHost: FC<Props> = ({ openModal, setOpenModal, site }) => {
	const toast = useToast();
	const [deleteFromProvider, setDeleteFromProvider] = useState(false);
	const { mutate: unlinkSiteFromHost, isLoading } =
		useDeleteHostingProjectMutation({
			environmentId: site.hostedSite?.environmentId!,
		});

	function handleSubmit() {
		unlinkSiteFromHost(
			{
				projectId: site.hostedSite?.id!,
				deleteFromProvider: deleteFromProvider,
			},
			{
				onSuccess: () => {
					toast({
						status: 'success',
						description: t(sites.unlinkedSuccessfully),
					});
					setOpenModal(false);
				},
			},
		);
	}

	return (
		<AlertDialogConfirmationModal
			isOpen={openModal}
			title={sites.unlinkHost}
			onClose={() => setOpenModal(false)}
			dataTestId="unlink-site-confirmation-modal"
			isLoading={isLoading}
			onConfirm={handleSubmit}
			leftButtonText={sites.unlink}
		>
			<Text>{t(sites.unlinkSiteConfirmation(site.name))}</Text>
			<Box pt={5}>
				<Checkbox
					onChange={(e) => setDeleteFromProvider(e.target.checked)}
				>
					{t(sites.deleteFromProvider)}
				</Checkbox>
			</Box>
		</AlertDialogConfirmationModal>
	);
};

export default UnlinkSiteFromHost;

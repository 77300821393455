import { AlertStatus } from '../../../app/shared-components';
import { systemHealthStatus } from '../../core/messages/en';
import { useComponentHealthy } from '../../hooks';
import { sitecoreStatusUrl } from '../../utils/constants';

const { gitopsError } = systemHealthStatus;
const systemStatusPath = sitecoreStatusUrl;

const GitopsValidationAlert: React.FC = () => {
	const { isDeployAPIHealthy, isLoading } = useComponentHealthy({});
	if (isLoading || isDeployAPIHealthy) return null;
	return (
		<AlertStatus
			description={gitopsError}
			status="error"
			data-testid="gitops-error-alert"
			mt="-4"
			mb="4"
			button="status page"
			buttonLink={systemStatusPath}
		/>
	);
};

export default GitopsValidationAlert;

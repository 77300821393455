import { FC, useMemo } from 'react';
import { Text } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { transfers as transferTitles } from '../../../../@xmcloud/core/messages/en';
import { handleTo } from '../../../../@xmcloud/utils/helpers';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import {
	Link,
	LoadingSkeleton,
	CreateItemPlaceHolder,
	Table,
} from '../../../shared-components';
import { pageSize } from '../../../../@xmcloud/utils/constants';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { IGetTransferResponse } from '../../../models/transferModel';
import { useGetAllTransfers } from '../../../services/transfers';
import { checkmarkLogo } from '../../../../@xmcloud/core/icons';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { CurrentIconText } from '../../deployments/components/DeploymentsTable';
import { StatusHeader } from '../../../shared-components/popover/DeploymentsStatusHeader';

const {
	name,
	source,
	destination,
	date,
	itemsTransfered,
	status,
	progress,
	queued,
	complete,
	failed,
	noCompletedTransfersTitle,
	noPendingTransfersTitle,
	explanation,
} = transferTitles;

export enum TransferQuery {
	all = 'all',
	completed = 'completed',
}

interface Props {
	query: TransferQuery;
	currentPage: number;
	setCurrentPage: (currentPage: number) => void;
}

const headers = [name, source, destination, date, itemsTransfered, status, ''];

const TransfersTable: FC<Props> = ({ query, currentPage, setCurrentPage }) => {
	const { data, isLoading, isFetching } = useGetAllTransfers(
		currentPage,
		pageSize,
		query,
	);
	const transfers = (data?.data.data as IGetTransferResponse[]) || [];

	const columns: ColumnDef<IGetTransferResponse, any>[] =
		useTransferColumns();

	if (isLoading || isFetching)
		return <LoadingSkeleton amount={pageSize + 1} mt={12} />;
	if (transfers.length === 0) {
		return (
			<CreateItemPlaceHolder
				customTitle={
					query === TransferQuery.completed
						? noCompletedTransfersTitle
						: noPendingTransfersTitle
				}
				iconUrl={
					query === TransferQuery.completed
						? undefined
						: checkmarkLogo
				}
				showCreateButton={false}
				customSubTitle=""
			/>
		);
	}
	return (
		<Table
			{...{
				columns,
				tableData: transfers,
				dataTestId: 'transfers-table',
				initialSort: [{ id: 'createdAt', desc: true }],
				showPagination: transfers.length > pageSize,
				headers,
				tableProps: {
					size: 'md',
				},
			}}
		/>
	);
};

export default TransfersTable;

const columnHelper = createColumnHelper<IGetTransferResponse>();

const useTransferColumns = () => {
	return useMemo(
		() => [
			columnHelper.accessor('name', {
				header: () => t(headers[0]),
				cell: ({
					row: {
						original: { name, environmentId },
					},
				}) => (
					<Link
						text={name || ''}
						to={handleTo(goTo.transferStatus(environmentId))}
					/>
				),
				enableSorting: false,
			}),
			columnHelper.accessor('sourceEnvironmentId', {
				header: () => t(headers[1]),
				cell: (info) => info.getValue(),
				enableSorting: false,
			}),
			columnHelper.accessor('targetEnvironmentId', {
				header: () => t(headers[2]),
				cell: (info) => info.getValue(),
				enableSorting: false,
			}),
			columnHelper.accessor('environmentId', {
				header: () => t(headers[3]),
				cell: (info) => info.getValue(),
				enableSorting: false,
			}),
			columnHelper.accessor('createdAt', {
				header: () => t(headers[4]),
				cell: (info) => getReadableDate(info.getValue()),
				enableSorting: false,
			}),
			columnHelper.accessor('processedItemCount', {
				header: () => t(date),
				cell: ({
					row: {
						original: { processedItemCount, totalItemCount },
					},
				}) => (
					<Text noOfLines={2} maxW="15vw">
						{processedItemCount}/{totalItemCount}
					</Text>
				),
				enableSorting: false,
			}),
			columnHelper.accessor('calculatedStatus', {
				header: () => (
					<StatusHeader
						allStatus={statusDescriptions}
						title={explanation}
					/>
				),
				cell: (info) => (
					<CurrentIconText calculatedStatus={info.getValue()} />
				),
				enableSorting: false,
			}),
		],
		[],
	);
};

export const statusDescriptions = [
	{ s: 1, desc: progress },
	{ s: 2, desc: complete },
	{ s: 0, desc: queued },
	{ s: 3, desc: failed },
];

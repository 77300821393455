import { useToast } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { FC, useRef } from 'react';
import { editCreateEnvironment } from '../../@xmcloud/core/messages/en';
import { pickObjectsDifferences } from '../../@xmcloud/utils/helpers';
import { IGetEnvironmentResponse } from '../models/environmentModel';
import { useUpdateEnvironmentMutation } from '../services/environments';
import EditCreateEnvironmentForm, {
	EditCreateEnvironmentFormProps,
} from './EditCreateEnvironmentForm';
import { TenantType } from '../../@xmcloud/types';

const { editSuccess: editSuccesstxt } = editCreateEnvironment;
const { Production, NonProduction } = TenantType;

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
	environment: IGetEnvironmentResponse;
}

const CombinedEditEnvironment: FC<Props> = ({
	openModal,
	setOpenModal,
	environment,
}) => {
	const toast = useToast();
	const { mutate, isLoading, status } = useUpdateEnvironmentMutation({
		id: environment.id,
	});
	const shouldRetry = status === 'error';

	const tenantType =
		environment?.tenantType === 'prod' ? Production : NonProduction;

	const editInitialValues = useRef<EditCreateEnvironmentFormProps>({
		name: environment?.name,
		tenantType,
		repositoryBranch: environment?.repositoryBranch || '',
		deployOnCommit: environment?.deployOnCommit || false,
		linkEnvironmentToDefaultBranch: false,
	}).current;

	const handleEditEnvironment = (values: any) => {
		const updatedValues = pickObjectsDifferences(values, editInitialValues);

		mutate(updatedValues, {
			onSuccess: () => {
				toast({
					status: 'success',
					description: t(editSuccesstxt),
				});
				setOpenModal(false);
			},
		});
	};

	return (
		<EditCreateEnvironmentForm
			{...{
				openModal,
				setOpenModal,
				onSubmit: handleEditEnvironment,
				isEdit: true,
				editInitialValues,
				isLoading,
				shouldRetry,
			}}
		/>
	);
};

export default CombinedEditEnvironment;

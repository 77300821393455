import { t } from '@transifex/native';
import * as yup from 'yup';
import { credentials } from '../core/messages/en';

const automationClientLabelMinLength = 3;
const automationClientLabelMaxLength = 256;
const automationClientDescriptionMaxLength = 140;

export const createClientSchema = (
	shouldValidateProjectAndEnvironment: boolean,
) =>
	yup.object({
		label: yup
			.string()
			.required(credentials.labelRequired)
			.min(
				automationClientLabelMinLength,
				t(credentials.minimumLengthMessage, {
					minLength: automationClientLabelMinLength,
				}),
			)
			.max(
				automationClientLabelMaxLength,
				t(credentials.maximumLengthMessage, {
					maxLength: automationClientLabelMaxLength,
				}),
			)
			.trim(),
		description: yup.string().max(
			automationClientDescriptionMaxLength,
			t(credentials.maximumLengthMessage, {
				maxLength: automationClientDescriptionMaxLength,
			}),
		),
		project: shouldValidateProjectAndEnvironment
			? yup.string().required(credentials.projectRequired)
			: yup.string().notRequired(),
		environment: shouldValidateProjectAndEnvironment
			? yup.string().required(credentials.environmentRequired)
			: yup.string().notRequired(),
	});

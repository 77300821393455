import { Stack } from '@chakra-ui/react';
import { commonCw, thirdStep } from '../../../../@xmcloud/core/messages/en';
import {
	HeadingLg,
	InputFormControl,
	RadioButtonGroup,
} from '../../../shared-components';
import { FooterBtnGroups } from '../components';
import { DEPLOY_ON_COMMIT, ENVIRONMENT_NAME, TENANT_TYPE } from '../helpers';
import { Microcopy } from './Text';
import { useCreateProject } from '../hooks';

const { info1, info2, info3, prodEnv, autoDeploy } = thirdStep;
const { authEnv: authEnvtxt } = commonCw;

export const OLDAuthoringEnvironment: React.FC = () => {
	const {
		values,
		errors,
		setFieldValue,
		handleChange,
		next,
		prev,
		setFieldTouched,
		touched,
	} = useCreateProject();

	function handleNext() {
		if (errors.environmentName) {
			setFieldTouched(ENVIRONMENT_NAME, true);
			return;
		}
		next();
	}

	function handlePrev() {
		prev();
	}

	return (
		<Stack>
			<HeadingLg pb="4" text={authEnvtxt} />
			<InputFormControl
				{...{
					isInvalid: Boolean(
						errors.environmentName && touched.environmentName,
					),
					label: commonCw.envName,
					name: ENVIRONMENT_NAME,
					value: values.environmentName,
					onChange: handleChange,
					error: errors.environmentName,
					maxW: 'sm',
					onFocus: () => setFieldTouched(ENVIRONMENT_NAME, true),
					isRequired: true,
				}}
			/>
			<Microcopy text={info1} maxW="xs" />
			<RadioButtonGroup
				{...{
					label: prodEnv,
					name: TENANT_TYPE,
					defaultValue: values.tenantType.toString(),
					onChange: (v) => setFieldValue(TENANT_TYPE, parseInt(v)),
					description: info2,
					pt: 3,
					radioBtns: TenantTypeOptions,
				}}
				key={TENANT_TYPE}
			/>
			<RadioButtonGroup
				{...{
					label: autoDeploy,
					name: DEPLOY_ON_COMMIT,
					defaultValue: values.deployOnCommit.toString(),
					description: info3,
					onChange: (v) =>
						setFieldValue(DEPLOY_ON_COMMIT, /true/.test(v)),
					pt: 3,
				}}
				key={DEPLOY_ON_COMMIT}
			/>
			<FooterBtnGroups
				{...{
					onNextClick: handleNext,
					onPreviousClick: handlePrev,
					isLoading: false,
					py: 5,
					showRightIcon: false,
				}}
			/>
		</Stack>
	);
};

const TenantTypeOptions = [
	{ label: 'Yes', value: '1' },
	{ label: 'No', value: '0' },
];

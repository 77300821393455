import { mdiPlus } from '@mdi/js';
import { useEnvVars } from './EnvVarsContext';
import { XMCSButton, Icon } from '../../shared-components';
import text from './envVarsTexts';
import { ButtonProps } from '@chakra-ui/react';

const { EnvVarsAddButton: t } = text;

function EnvVarsAddButton(props: ButtonProps) {
	const { toggleFormVisibility, isLoading } = useEnvVars();

	return (
		<XMCSButton
			variant="outline"
			colorScheme="primary"
			size="sm"
			leftIcon={<Icon path={mdiPlus} />}
			onClick={toggleFormVisibility}
			text={t.add}
			isDisabled={isLoading}
			{...props}
		/>
	);
}
export { EnvVarsAddButton };

import { useGetStatus } from '../../app/services/status';
import {
	ComponentNames,
	componentStatus,
	statusComponents,
} from '../../app/models/statusModel';
import { EnumInNumberSourceControl } from '../types';

const { GitHub: GITHUB_IN_NUMBER, ADO: ADO_IN_NUMBER } =
	EnumInNumberSourceControl;

const { Healthy } = componentStatus;
const {
	Builds,
	Deployments,
	Provisioning,
	Monitoring,
	GitHub,
	'Azure DevOps': ADO,
	Cloudflare,
	Configuration,
	Inventory,
	'Container Registry': ContainerRegistry,
} = statusComponents;

export const useComponentHealthy = ({
	provider = 0,
}: {
	provider?: EnumInNumberSourceControl | 0;
}) => {
	const { data, isLoading, isError, isFetching } = useGetStatus();
	const statusData = data?.data;

	function onStatus(...c: ComponentNames[]) {
		if (!statusData || isError) return false;
		if (statusData && statusData?.status === Healthy) return true;
		const names = [...c];

		const result = names.every((name) => {
			const entry = statusData.entries[name];
			return entry?.status === Healthy;
		});
		return result;
	}

	function onFilter(list: ListType) {
		const unHealthyList: ComponentNames[] = [];
		if (!statusData || isError) return unHealthyList;

		if (statusData?.status === Healthy) return unHealthyList;
		const names = Object.values(list) as ComponentNames[];
		names.forEach((name) => {
			const entry = statusData.entries[name];
			if (entry && entry?.status !== Healthy) {
				unHealthyList.push(name);
			}
		});
		return unHealthyList;
	}
	const getUnhealthyWarningList = () => onFilter(WarningsHealth);
	const getUnhealthyBlockerList = () => onFilter(BlockersHealth);

	const isBuildsHealthy = onStatus('Builds');
	const isDeploymentsHealthy = onStatus('Deployments');
	const isProvisioningHealthy = onStatus('Provisioning');
	const isMonitoringHealthy = onStatus('Monitoring');
	const isGithubHealthy = onStatus('GitHub');
	const isADOHealthy = onStatus('Azure DevOps');
	const isConfigurationHealthy = onStatus('Configuration');
	const isDeployAPIHealthy = onStatus('Deploy API');

	const isAdoProvider = provider === ADO_IN_NUMBER;
	const isGithubProvider = provider === GITHUB_IN_NUMBER;

	const isCurrentProviderHealthy = isAdoProvider
		? isADOHealthy
		: isGithubProvider
			? isGithubHealthy
			: true;

	const isBlockersHealthy = onStatus(
		'Cloudflare',
		'Configuration',
		'Inventory',
		'Container Registry',
	);
	const isWarningsHealthy = onStatus(
		'Builds',
		'Deployments',
		'Provisioning',
		'Monitoring',
	);

	return {
		getUnhealthyWarningList,
		getUnhealthyBlockerList,
		isLoading,
		isFetching,
		isBuildsHealthy,
		isDeploymentsHealthy,
		isProvisioningHealthy,
		isMonitoringHealthy,
		isGithubHealthy,
		isADOHealthy,
		isConfigurationHealthy,
		isBlockersHealthy,
		isWarningsHealthy,
		isCurrentProviderHealthy,
		isError,
		isAdoProvider,
		isGithubProvider,
		isDeployAPIHealthy,
	};
};

type ListType =
	| typeof BlockersHealth
	| typeof ProvidersHealth
	| typeof WarningsHealth;

export const BlockersHealth = {
	Cloudflare,
	Configuration,
	Inventory,
	ContainerRegistry,
};

export const ProvidersHealth = {
	GitHub,
	ADO,
};

export const WarningsHealth = {
	Builds,
	Deployments,
	Provisioning,
	Monitoring,
};

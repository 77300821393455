import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useEditProject } from '../../../@xmcloud/hooks';
import { EnumInNumberSourceControl } from '../../../@xmcloud/types';
import { SelectFormControl } from '../../shared-components';
import { ENVIRONMENTS, TOptions } from '../create-project/helpers';

const { GitHub } = EnumInNumberSourceControl;

export const SelectBranch: React.FC<{
	branches: TOptions;
	isLoading: boolean;
	isFetching?: boolean;
	hasMore?: boolean;
	fetchNextPage?: any;
	inputQuery?: React.MutableRefObject<string>;
	repositoryBranch: string;
	id: string | undefined;
}> = ({
	branches,
	isLoading,
	isFetching,
	hasMore,
	fetchNextPage,
	inputQuery,
	repositoryBranch,
	id,
}) => {
	const { values, setValues } = useEditProject();

	const isGithubProvider = values.provider === GitHub;

	const handleFetchNextPage = useCallback(() => {
		if (hasMore && isGithubProvider && !isFetching) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasMore, isFetching, isGithubProvider]);

	const onInputChange = useCallback(
		(e: string) => {
			if (inputQuery) {
				inputQuery.current = e;
			}

			if (
				e === '' ||
				!hasMore ||
				!isGithubProvider ||
				!branches?.length ||
				isFetching
			)
				return;

			const hasRepo = branches.some((r: any) => r.label.includes(e));

			if (!hasRepo) {
				handleFetchNextPage();
			}
		},
		[
			branches,
			handleFetchNextPage,
			hasMore,
			inputQuery,
			isFetching,
			isGithubProvider,
		],
	);

	const debouncedChangeHandler = useMemo(
		() => debounce(onInputChange, 300),
		[onInputChange],
	);

	useEffect(() => {
		return () => {
			debouncedChangeHandler.cancel();
		};
	}, [debouncedChangeHandler]);

	useEffect(() => {
		if (isLoading) return;
		const hasBranch = branches.find(
			(option: any) => option.value === repositoryBranch,
		);
		const isEmptyBranch = hasBranch && hasBranch.value === '';
		const isAutoDeployTrue = values.environments.find(
			(env) => env.id === id && env.deployOnCommit && isEmptyBranch,
		);

		if (!hasBranch || isAutoDeployTrue) {
			setValues((prev) => ({
				...prev,
				environments: prev.environments.map((env) =>
					env.id === id
						? {
								...env,
								repositoryBranch: '',
								deployOnCommit: false,
							}
						: env,
				),
			}));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [branches, id, values.environments]);

	return (
		<SelectFormControl
			{...{
				isLoading: isLoading,
				options: branches,
				onChange: (e: any) => {
					setValues((prev) => ({
						...prev,
						environments: prev.environments.map((env) =>
							env.id === id
								? {
										...env,
										repositoryBranch: e.value,
										deployOnCommit:
											e.value === ''
												? false
												: env.deployOnCommit,
									}
								: env,
						),
					}));
				},
				label: '',
				name: ENVIRONMENTS,
				currentValue: repositoryBranch,
				maxW: 'xs',
				pb: 0,
				pt: 0,
				onInputChange: debouncedChangeHandler,
				onMenuScrollToBottom: () => {
					handleFetchNextPage();
				},
			}}
		/>
	);
};

import { Stack, StackProps, Text, Tooltip } from '@chakra-ui/react';
import { mdiPencilOutline } from '@mdi/js';
import { t } from '@transifex/native';
import { Icon } from '..';
import { XMCSButton } from '../buttons/XMCSButton';

type Props = StackProps & {
	title: string;
	description: string;
	titleValue?: string;
	isEditable?: boolean;
	onEditClick?: () => void;
	isEditBtnDisabled?: boolean;
	tooltipLabel?: string;
};

export const FormPlaceHolder: React.FC<Props> = ({
	title,
	description,
	titleValue = '',
	isEditable = false,
	isEditBtnDisabled = false,
	tooltipLabel = '',
	onEditClick,
	...rest
}) => (
	<Stack pt="5" {...rest}>
		<Stack direction="row" justifyContent="space-between">
			<Text fontWeight="medium" color="neutral.600">
				{t(title, { titleValue })}
			</Text>
			{isEditable && (
				<Tooltip
					label={tooltipLabel}
					aria-label="edit-button-tooltip"
					shouldWrapChildren
					isDisabled={!isEditBtnDisabled}
				>
					<XMCSButton
						leftIcon={<Icon path={mdiPencilOutline} />}
						text="Edit"
						variant="ghost"
						size="xs"
						colorScheme="primary"
						onClick={onEditClick}
						isDisabled={isEditBtnDisabled}
					/>
				</Tooltip>
			)}
		</Stack>
		<Text>{description}</Text>
	</Stack>
);

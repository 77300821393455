import { app } from '../../../@xmcloud/core/messages/en';
import { XMCSH3 } from '../../shared-components';
import Help from './components/Help';

export const DocumentationPage: React.FC = () => (
	<>
		<XMCSH3 title={app.documentation} mb={6} />
		<Help />
	</>
);

import { Checkbox, Stack, Text } from '@chakra-ui/react';
import { editProject } from '../../../@xmcloud/core/messages/en';
import { useEditProject } from '../../../@xmcloud/hooks';
import { EnumInNumberSourceControl } from '../../../@xmcloud/types';
import { IGetEnvironmentResponse } from '../../models/environmentModel';
import { useGetSourceControlAccessToken } from '../../services/accessToken';
import { HeadingXs, LoadingSkeleton } from '../../shared-components';
import { SelectAdoBranch } from './SelectAdoBranch';
import { SelectGitHubBranch } from './SelectGitHubBranch';

const { branch, env, deployOnCommit: deployOnCommittxt } = editProject;
const { ADO, GitHub } = EnumInNumberSourceControl;

export const Environments: React.FC = () => {
	const { values } = useEditProject();
	const { sourceControlIntegrationId, environments } = values;

	const { data: accessTokenData, isLoading: isLoadingAccessToken } =
		useGetSourceControlAccessToken(sourceControlIntegrationId);

	const token = accessTokenData?.data.accessToken || '';

	const isLoading = isLoadingAccessToken;

	if (isLoading) return <LoadingSkeleton amount={3} />;
	if (!environments.length) return null;

	return (
		<Stack pt="6" pb="4">
			<Stack
				direction="row"
				w="100%"
				alignItems="center"
				justifyContent="space-between"
			>
				<HeadingXs w="25%" text={env} />
				<HeadingXs w="45%" text={branch} />
				<HeadingXs
					w="30%"
					text={deployOnCommittxt}
					textAlign="center"
				/>
			</Stack>
			{environments.map((environment) => (
				<Environment key={environment.id} {...{ token, environment }} />
			))}
		</Stack>
	);
};

const Environment: React.FC<{
	token: string;
	environment: Partial<IGetEnvironmentResponse>;
}> = ({ token, environment }) => {
	const { values, setValues } = useEditProject();
	const { provider } = values;
	const isGithubProvider = provider === GitHub;
	const isAdoProvider = provider === ADO;
	const { name, id, deployOnCommit, repositoryBranch } = environment;

	return (
		<Stack direction="row" w="100%" alignItems="center">
			<Text w="25%">{name}</Text>
			<Stack w="50%">
				{isGithubProvider && (
					<SelectGitHubBranch
						{...{
							token,
							environment,
						}}
					/>
				)}
				{isAdoProvider && (
					<SelectAdoBranch
						{...{
							token,
							environment,
						}}
					/>
				)}
			</Stack>
			<Stack w="25%" pl="5">
				<Checkbox
					isChecked={!!deployOnCommit}
					isDisabled={!repositoryBranch}
					onChange={(e) => {
						setValues((prev) => ({
							...prev,
							environments: prev.environments.map((env) =>
								env.id === id
									? {
											...env,
											deployOnCommit: e.target.checked,
										}
									: env,
							),
						}));
					}}
					mt={2}
				/>
			</Stack>
		</Stack>
	);
};

import { AxiosError } from 'axios';

export function isAxiosError(err: any): err is AxiosError {
	return !!(err && (err as AxiosError).isAxiosError === true);
}

export function isError(err: any): err is Error {
	return !!(err && err.stack && err.message);
}

export interface IProblemDetails {
	title: string;
	status: number;
	detail: string;
}

/**
 * Check if an error response follows the standard "Problem Details for HTTP APIs".
 * @see https://datatracker.ietf.org/doc/html/rfc7807
 */
export function isProblemDetails(
	responseData: any,
): responseData is IProblemDetails {
	return !!(
		responseData &&
		responseData.title &&
		responseData.status &&
		responseData.detail
	);
}

export interface IProblemErrors {
	title: string;
	status: number;
	errors: {
		[key: string]: string[];
	};
}

export function isProblemErrors(
	responseData: any,
): responseData is IProblemErrors {
	return !!(
		responseData &&
		responseData.title &&
		responseData.status &&
		responseData.errors
	);
}

export type ErrorResponseDetails = {
	type: string;
	title: string;
	status: number;
	detail: string;
	traceId?: string;
	errors?: {
		[key: string]: string[];
	};
};

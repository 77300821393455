import { useParams } from 'react-router-dom';
import { environments as envTexts } from '../../../../@xmcloud/core/messages/en';
import { IGetEnvironmentResponseV2 } from '../../../models/environmentModel';
import { useGetProjectEnvironmentsList } from '../../../services/projects';
import { useEnvironmentsTableColumns } from '../columns/environments';
import { ColumnDef } from '@tanstack/react-table';
import {
	LoadingSkeleton,
	RenderPlaceholder,
	Table,
} from '../../../shared-components';
import { pageSize } from '../../../../@xmcloud/utils/constants';
import { useCreateProjectV3 } from '../../create-project/old-components/useCreateProjectV3';
import { useGetEnvironmentsByQuery } from '../../../services/environments';
import { EnvironmentTypesEnum } from '../../create-project/helpers';

const { EH, CM } = EnvironmentTypesEnum;

const ProjectEnvironmentsTable: React.FC = () => {
	const { projectId, ...rest } = useParams<{
		projectId: string;
		'envType-environments': string;
	}>();

	const envType = rest['envType-environments']?.split('-')[0] || '';
	const isEhEnv = envType === EH;
	const isCmEnv = envType === CM;
	const hasEnvType = isEhEnv || isCmEnv;

	const { enableCreateProjectV3 } = useCreateProjectV3();

	const { data: environmentsListData, isLoading: isEnvironmentsListLoading } =
		useGetProjectEnvironmentsList({
			projectId: projectId!,
			enabled: !hasEnvType,
		});
	const {
		data: environmentsByQueryData,
		isLoading: isEnvironmentsByQueryLoading,
	} = useGetEnvironmentsByQuery({
		type: envType as EnvironmentTypesEnum,
		projectId: projectId!,
		_enabled: hasEnvType && enableCreateProjectV3,
	});

	let environments = enableCreateProjectV3
		? environmentsByQueryData
		: environmentsListData?.data || [];

	const columns: ColumnDef<IGetEnvironmentResponseV2, any>[] =
		useEnvironmentsTableColumns(envType);

	const isLoading = isEnvironmentsListLoading || isEnvironmentsByQueryLoading;

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;

	if (environments.length === 0)
		return (
			<RenderPlaceholder
				item={envTexts.environments.toLocaleLowerCase()}
			/>
		);

	return (
		<Table
			{...{
				columns: columns,
				tableData: environments,
				dataTestId: 'environments-table',
				showPagination: environments.length > pageSize,
			}}
		/>
	);
};

export default ProjectEnvironmentsTable;

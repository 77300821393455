import { FC } from 'react';
import { Text, Badge, Tooltip, HStack, VStack } from '@chakra-ui/react';
import { IGetEnvironmentResponse } from '../../../models/environmentModel';
import { deployments } from '../../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';
import { mdiLinkVariant } from '@mdi/js';
import { HoverableCard, Icon } from '../../../shared-components';

const { cannotBePromoted, unavailable } = deployments;

interface Props {
	environment: IGetEnvironmentResponse;
	selectedEnvironment: IGetEnvironmentResponse;
	setSelectedEnvironment: (e: IGetEnvironmentResponse) => void;
	setShowError: (e: boolean) => void;
}

const EnvironmentCard: FC<Props> = ({
	environment,
	selectedEnvironment,
	setSelectedEnvironment,
	setShowError,
}) => {
	const { name, repositoryBranch, id } = environment && environment;
	const disabled = Boolean(repositoryBranch);

	return (
		<Tooltip
			hidden={!disabled}
			label={`"${name}" ${t(cannotBePromoted)}`}
			shouldWrapChildren
		>
			<HoverableCard
				disabled={disabled}
				data-testid="environment-list-item"
				key={name}
				onClick={() => {
					setSelectedEnvironment(environment);
					setShowError(false);
				}}
				isActive={Boolean(!disabled && selectedEnvironment?.id === id)}
			>
				<HStack justify="space-between" p={3}>
					<VStack justify="center" align="left" spacing={1} minH={45}>
						<Text variant="small">{name}</Text>
						{disabled && (
							<Text variant="small">
								<Icon path={mdiLinkVariant} mr={2} />
								{repositoryBranch}
							</Text>
						)}
					</VStack>
					<HStack>
						{disabled && <Badge>{t(unavailable)}</Badge>}
					</HStack>
				</HStack>
			</HoverableCard>
		</Tooltip>
	);
};

export default EnvironmentCard;

import * as yup from 'yup';
import { FORBID_CHARS } from './constants';

// Step 1: Define an extension interface
interface StringSchemaWithTestForbidChars {
	testForbidChars(errorMessage: string): yup.StringSchema;
	endsWithForbidChars(errorMessage: string): yup.StringSchema;
}

// Step 2: Use module augmentation to extend the existing yup module
declare module 'yup' {
	interface StringSchema extends StringSchemaWithTestForbidChars {}
}

// Extend yup with a custom regex validation method
yup.addMethod(yup.string, 'testForbidChars', function (errorMessage: string) {
	return this.test('test-forbid-chars', errorMessage, function (value) {
		if (!value) return true;
		const { path, createError } = this;
		const isIncluded = !FORBID_CHARS.some((char) => value.includes(char));
		return isIncluded || createError({ path, message: errorMessage });
	});
});

yup.addMethod(
	yup.string,
	'endsWithForbidChars',
	function (errorMessage: string) {
		return this.test(
			'ends-with-forbid-chars',
			errorMessage,
			function (value) {
				if (!value) return true;
				const { path, createError } = this;
				const isValid = !value.endsWith('?');
				return isValid || createError({ path, message: errorMessage });
			},
		);
	},
);

export { yup };

import React, { useMemo, useRef } from 'react';
import { useEditProject } from '../../../@xmcloud/hooks';
import { IGetEnvironmentResponse } from '../../models/environmentModel';
import { useGetGitHubPaginatedBranches } from '../../services/githubApi';
import { SelectBranch } from './SelectBranch';

export const SelectGitHubBranch: React.FC<{
	token: string;
	environment: Partial<IGetEnvironmentResponse>;
}> = ({ token, environment }) => {
	const inputQuery = useRef('');
	const { values } = useEditProject();
	const { account, repository } = values;
	const { repositoryBranch, id } = environment;

	const { data, isLoading, hasMore, fetchNextPage, isFetching } =
		useGetGitHubPaginatedBranches({
			token,
			owner: account,
			repo: repository,
			onSuccess,
			_enabled: Boolean(account && repository),
		});

	function onSuccess(lastPage: any) {
		const query = inputQuery.current;

		if (query === '' || !hasMore || !lastPage.length) return;

		const hasRepo = lastPage.some((r: any) => r.name.includes(query));

		if (!hasRepo) {
			fetchNextPage();
		}
	}

	const branches = useMemo(() => {
		let branches =
			data?.map((r: any) => ({
				label: r.name,
				value: r.name,
			})) || [];
		branches.push({ label: 'none', value: '' });
		return branches;
	}, [data]);

	return (
		<SelectBranch
			{...{
				branches,
				isLoading,
				isFetching,
				repositoryBranch: repositoryBranch || '',
				id,
			}}
		/>
	);
};

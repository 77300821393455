import { useState } from 'react';
import {
	Box,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Stack,
} from '@chakra-ui/react';
import { t } from '@transifex/native';
import { mdiInformationOutline } from '@mdi/js';
import { CurrentIconText } from '../../pages/deployments/components/DeploymentsTable';
import {
	allDeployments,
	deployments,
} from '../../../@xmcloud/core/messages/en';
import { HeadingSm, Icon } from '..';
const { status } = deployments;
const { explanation } = allDeployments;

export const StatusHeader = ({
	allStatus,
	title = explanation,
}: {
	allStatus: { s: number; desc: string }[];
	title?: string;
}) => {
	const [showPopover, setShowPopover] = useState(false);
	return (
		<Popover isOpen={showPopover} placement="bottom-start">
			<PopoverTrigger>
				<Box
					onMouseEnter={() => {
						return setShowPopover(true);
					}}
					data-testid="deployments-status-header"
					flexDirection="row"
				>
					{t(status)}
					<Icon
						path={mdiInformationOutline}
						ml={1}
						boxSize={5}
						color="neutral"
					/>
				</Box>
			</PopoverTrigger>
			<PopoverContent
				onMouseLeave={() => setShowPopover(false)}
				onBlur={() => setShowPopover(false)}
			>
				<PopoverBody pb={5} whiteSpace="normal">
					<HeadingSm text={title} py="2" />
					<Stack spacing={4} pt={3}>
						{allStatus.map(({ s, desc }) => (
							<CurrentIconText
								calculatedStatus={s}
								textColor="chakra-body-text"
								xsText={desc}
								key={s}
								lineHeight={1}
							/>
						))}
					</Stack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

import { Stack } from '@chakra-ui/react';
import { CheckboxFormControl, HeadingMd } from '../../shared-components';
import {
	deployOnCommitNamespaces,
	EnvironmentTypesEnum,
	TENANT_TYPE,
} from '../../pages/create-project/helpers';
import { thirdStep } from '../../../@xmcloud/core/messages/en';
import { TenantType } from '../../../@xmcloud/types';
import { FormikValues } from '../../pages/create-project/hooks/CreateProjectContext';
const { info2, info3, prodEnv, autoDeploy, options } = thirdStep;

const { Production, NonProduction } = TenantType;

export const Options: React.FC<{
	showSLA?: boolean;
	setFieldValue: any;
	nameSpace?: EnvironmentTypesEnum | 'default';
	values: Partial<FormikValues>;
	isSLAReadOnly?: boolean;
	errors: any;
}> = ({
	showSLA = true,
	setFieldValue,
	values,
	errors,
	nameSpace = 'default',
	isSLAReadOnly = false,
}) => {
	const deployOnCommitNamespace = deployOnCommitNamespaces[nameSpace];

	return (
		<Stack gap="6">
			<HeadingMd text={options} />
			<CheckboxFormControl
				{...{
					label: autoDeploy,
					name: deployOnCommitNamespace,
					isChecked: values[deployOnCommitNamespace],
					onChange: (e) =>
						setFieldValue(
							deployOnCommitNamespace,
							e.target.checked,
						),
					helperText: info3,
					pt: 1,
				}}
			/>
			{showSLA && (
				<CheckboxFormControl
					{...{
						isInvalid: Boolean(errors.tenantType),
						label: prodEnv,
						name: TENANT_TYPE,
						isChecked: values.tenantType === Production,
						onChange: (e) =>
							setFieldValue(
								TENANT_TYPE,
								e.target.checked ? Production : NonProduction,
							),
						helperText: info2,
						isDisabled: isSLAReadOnly,
						error: errors.tenantType,
					}}
				/>
			)}
		</Stack>
	);
};

import { InputFormControl } from '../../shared-components';
import {
	ENVIRONMENT_NAME,
	TEnvironmentName,
	TFormikCommonProps,
} from '../../pages/create-project/helpers';

export const EnvironmentName: React.FC<
	{
		label: string;
		helperText: string;
		environmentNameSpace?: TEnvironmentName;
		handleChange: any;
		setFieldTouched: any;
	} & TFormikCommonProps
> = ({
	values,
	errors,
	touched,
	label,
	helperText,
	handleChange,
	setFieldTouched,
	environmentNameSpace = ENVIRONMENT_NAME,
}) => {
	return (
		<InputFormControl
			{...{
				isInvalid: Boolean(
					errors[environmentNameSpace] &&
						touched[environmentNameSpace],
				),
				label,
				name: environmentNameSpace,
				value: values[environmentNameSpace],
				onChange: handleChange,
				error: errors[environmentNameSpace],
				onFocus: () => setFieldTouched(environmentNameSpace, true),
				isRequired: true,
				helperText,
			}}
		/>
	);
};

import { useAuthQuery } from '../apiUtils/queryHelper';
import config from '../config/config';
import { IGetUserData } from '../models/userModel';

export const useGetCurrentUser = () => {
	return useAuthQuery(
		config.identity.get_user.queryKey,
		async (axiosInstance) => {
			return axiosInstance?.get<IGetUserData>(
				config.identity.get_user.url,
			);
		},
		{},
		config.identity.get_user.scope,
	);
};

import { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { useRepositoryIntegrateMutation } from '../../services/repositories';
import { isProblemDetails } from '../../../@xmcloud/utils/errorUtils';
import { LoadingScreen, StatesPage } from '../../shared-components';
import { connections } from '../../../@xmcloud/core/messages/en';
import { useQueryClient } from 'react-query';
import config from '../../config/config';
import { NEW_SOURCE_CONTROL_ACCOUNT } from '../../../@xmcloud/utils/constants';
import { IRepositoryIntegrationDetails } from '../../models/repositoriesModel';
import { ESourceControl } from '../../../@xmcloud/types';

export const AdoCallback: FC = () => {
	const location = useLocation();
	const queryClient = useQueryClient();
	const { mutate: adoIntegrate, isLoading: adoIntegrateLoading } =
		useRepositoryIntegrateMutation({ skipDefaultErrorHandler: true });
	const [integrationSucceed, setIntegrationSucceed] = useState<boolean>();
	const [integrationMessage, setIntegrationMessage] = useState<string>('');

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const code = searchParams.get('code');
		const state = searchParams.get('state');

		if (code) {
			adoIntegrate(
				{
					code,
					state,
					installationId: null,
					provider: ESourceControl.ADO,
				},
				{
					onSuccess: (res) => {
						const data =
							res.data as unknown as IRepositoryIntegrationDetails;
						const channel = new BroadcastChannel(
							NEW_SOURCE_CONTROL_ACCOUNT,
						);
						channel.postMessage(data);
						queryClient.invalidateQueries(
							config.repository.get_repo_integrations_v2.queryKey(
								1,
							),
						);
						setIntegrationSucceed(true);
						setIntegrationMessage(
							connections.adoInstallationSuccess,
						);
					},
					onError: (e: any) => {
						setIntegrationSucceed(false);
						const responseData = e.response.data as any;
						const generatedMessage = isProblemDetails(responseData)
							? responseData.detail
							: connections.adoInstallationFailed;
						setIntegrationMessage(generatedMessage);
					},
				},
			);
		} else {
			setIntegrationSucceed(false);
			setIntegrationMessage(connections.adoInstallationFailed);
		}
	}, [adoIntegrate, location.search, queryClient]);

	return (
		<Flex
			height="full"
			grow="initial"
			justifyContent="center"
			align="center"
		>
			{adoIntegrateLoading ? (
				<LoadingScreen
					data-testid="loading-component"
					message="Loading…"
				/>
			) : (
				<>
					<StatesPage
						text={integrationMessage}
						status={integrationSucceed ? 'success' : 'error'}
					/>
				</>
			)}
		</Flex>
	);
};

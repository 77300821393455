import { handlePush } from '../../../../@xmcloud/utils/helpers';
import CreateDeploymentForm from './CreateDeploymentForm';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { useDeploymentCreateAndDeploy } from '../../../services/deploymentCreateDeploy';

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
}

const CreateDeployment: React.FC<Props> = ({ openModal, setOpenModal }) => {
	const [deploymentCreateAndDeploy, isLoading] =
		useDeploymentCreateAndDeploy();
	const handleCreateDeployment = (values: any) => {
		deploymentCreateAndDeploy({
			environmentId: values.environmentId,
			onSuccess: (deploymentId: string) => {
				handlePush(
					goTo.deploymentLogs(
						values.project,
						values.environmentId,
						deploymentId,
					),
				);
			},
		});
	};
	return (
		<CreateDeploymentForm
			{...{
				openModal,
				setOpenModal,
				onSubmit: handleCreateDeployment,
				isLoading,
			}}
		/>
	);
};

export default CreateDeployment;

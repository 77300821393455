import { Box, Stack } from '@chakra-ui/react';
import { useCreateTransfer } from '../../../@xmcloud/hooks';
import { steps } from './helpers';
import { ChakraStepper } from '../../shared-components';

export const CreateTransferMain: React.FC<{}> = () => {
	const { formikHandleSubmit, step, activeStep, setActiveStep } =
		useCreateTransfer();

	return (
		<Stack direction={['column', 'column', 'row', 'row']}>
			<Box w={['100%', '100%', '25%', '25%']} pt="10" px="6">
				<ChakraStepper
					{...{
						activeStep,
						setActiveStep,
						path: 'createTransferPath',
						steps,
					}}
				/>
			</Box>
			<Box w={['100%', '100%', '75%', '75%']} pt="10" px="8">
				<form
					data-testid="create-transfer-wizard-form"
					onSubmit={formikHandleSubmit}
				>
					{step}
				</form>
			</Box>
		</Stack>
	);
};
